<div class="page-container">
  <em-card [padding]="false">
    <mat-tab-group
      mat-align-tabs="center"
      [selectedIndex]="selectedStepIndex"
      (selectedIndexChange)="selectedTabChanged($event)"
    >
      <mat-tab [label]="'SHARED_LABEL_ACTIVE_SUBSCRIPTION' | translate">
        <div class="tap-wrapper">
          <em-upgrade-plan (navigateTo)="redirectTo($event)"></em-upgrade-plan>

          <ng-container *ngIf="hasActiveSubscription$ | async">
            <hr class="mt-4 mb-4" />

            <em-cancel-subscription></em-cancel-subscription>
          </ng-container>
        </div>
      </mat-tab>

      <mat-tab [label]="'NAV_INVOICES' | translate">
        <em-external-payment-portal-entry></em-external-payment-portal-entry>

        <em-invoices-list></em-invoices-list>
      </mat-tab>
      <mat-tab [label]="'ACCOUNT_USER_PAYMENT_SECTION_TITLE' | translate">
        <div class="m-auto max-w-[800px]">
          <em-user-payment-form
            class="w-full"
            [inModal]="false"
          ></em-user-payment-form>
        </div>
      </mat-tab>
      <mat-tab
        *ngIf="showPaymentDetails$ | async"
        [label]="'SHARED_LABEL_PAYMENT_DETAILS' | translate"
      >
        <em-external-payment-portal-entry></em-external-payment-portal-entry>

        <em-one-time-payments></em-one-time-payments>
      </mat-tab>
    </mat-tab-group>
  </em-card>
</div>
