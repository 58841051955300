import {NotificationKey, NotificationRespType} from '@em/shared/api-interface';
import {NotificationModel} from '../notification.model';
import {IPusherNotification} from '../pusher-service/pusher.service';

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type payload = {url: string};

export class ProductCsvNotification extends NotificationModel {
  static key: NotificationKey = 'UseCases::Notifications::ProductsCsvCreated';
  readonly url: string;

  constructor(createdAt: Date, workflowUuid: string, url: string) {
    super(createdAt, ProductCsvNotification.key, workflowUuid);
    this.url = url;
  }

  static productCsvFromPusher(
    resp: IPusherNotification,
  ): ProductCsvNotification {
    return new ProductCsvNotification(
      resp['created_at'] ? new Date(resp['created_at'] as string) : new Date(),
      resp.workflow_uuid,
      resp['url'] as string,
    );
  }

  static productCsvFromResp(
    resp: NotificationRespType,
  ): ProductCsvNotification {
    return new ProductCsvNotification(
      resp['created_at'] ? new Date(resp['created_at'] as string) : new Date(),
      resp.workflow_uuid,
      (resp.payload as payload).url,
    );
  }
}
