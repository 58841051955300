import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {EmIconModule} from '../icon/icon.component';
import {EmErrorBoxComponent} from './error-box.component';

@NgModule({
  imports: [CommonModule, EmIconModule],
  declarations: [EmErrorBoxComponent],
  exports: [EmErrorBoxComponent],
})
export class EmErrorBoxModule {}
