import {PlatformId} from '@em/shared/platforms/util';
import {CountryCode, IMoney} from '@em/shared/util-types';
import {
  ExternalCampaignModel,
  ManagedCampaignModel,
  PriorityCode,
} from './campaign.model';

export interface ISearchParams {
  countryCode: CountryCode;
  platformCode: PlatformId;
}

export interface ICampaignsResult {
  state: 'success' | 'unavailable';
  result: ManagedCampaignModel[];
}

export interface IExternalCampaignsResult {
  state: 'success' | 'unavailable';
  result: ExternalCampaignModel[];
}

export interface IUpdatePlatformArgs {
  active?: boolean;
  dailyBudget?: IMoney;
  name?: string;
  targetRoas?: number;
}

export interface IAutomaticCampaignAttributeArgs {
  active: boolean;
  selectedFilterSetUuid: string | null;
  invertProducts?: boolean;
}

export interface IUpdateCampaignArgs {
  platformAttributes?: IUpdatePlatformArgs;
  automaticCampaignAttributes: IAutomaticCampaignAttributeArgs;
  uuid: string;
}

export type facebookCampaignType =
  | 'retargeting'
  | 'lookalike'
  | 'dynamic_product_ads';
export const FACEBOOK_CAMPAIGN_TYPES: facebookCampaignType[] = [
  'retargeting',
  'lookalike',
  'dynamic_product_ads',
];

export interface ICreateCampaignArgs {
  platformAttributes: {
    priority?: PriorityCode;
    name?: string;
    pageId?: string;
    pixelId?: string;
    campaignType?: facebookCampaignType | null;
    targetRoas?: number;
  };
  automaticCampaignAttributes: IAutomaticCampaignAttributeArgs;
}

export interface ICreateCampaignGroupArgs {
  campaigns: ICreateCampaignArgs[];
  countryCode: CountryCode;
  dailyBudget: IMoney;
  platformId: PlatformId;
}
