<div
  class="grid grid-cols-1 gap-4"
  [ngClass]="
    compactView
      ? '@md:grid-cols-2 @3xl:grid-cols-3'
      : '@3xl:grid-cols-2 @6xl:grid-cols-3'
  "
>
  <em-product-group-card
    *ngFor="let group of groups; trackBy: trackByUuid"
    class="filter-set filter-set-{{ group.uuid }}"
    [group]="group"
    [allowDelete]="allowDelete"
    [compactView]="compactView"
  >
  </em-product-group-card>

  <em-add-new-item-card
    *ngIf="allowAddNew"
    [title]="'PRODUCT_FILTER_ADD_FILTERSET' | translate"
    (click)="addNewGroup()"
  ></em-add-new-item-card>
</div>
