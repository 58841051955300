import {EnvVars} from './env-variables';

export type EnvVarKey = keyof typeof EnvVars;

/* istanbul ignore next */
export const configurationHelper = {
  /**
   * Get a feature-flag like ENV value.
   * @param key
   */
  getFlag(key: EnvVarKey): boolean {
    const val = configurationHelper.getConfig(key);

    return !!val && val.trim() !== 'false';
  },

  /**
   * Get a configuration value from injected ENVs
   * @param key
   */
  getConfig(key: EnvVarKey): undefined | string | null {
    const val = EnvVars[key];

    // Handle feature flags
    if (val === 'false') {
      return undefined;
    }

    return val;
  },

  /**
   * Get a configuration value from injected ENVs and raise an error if not set.
   * @param key
   */
  needConfig(key: EnvVarKey): string {
    const val = configurationHelper.getConfig(key);

    if (!val) {
      throw new Error('Missing config value: ' + key);
    }
    return val;
  },

  /**
   * Runtime check for staging or sandbox environment.
   */
  isSandbox(): boolean {
    const hostname = window.location.hostname;
    return /staging/.test(hostname) || /sandbox/.test(hostname);
  },

  /**
   * Runtime check for development environment.
   */
  isDevelopment(): boolean {
    const hostname = window.location.hostname;
    return /localhost/.test(hostname);
  },
};
