<mat-form-field class="w-full">
  <mat-label>{{ label }}</mat-label>
  <textarea
    matInput
    [placeholder]="placeholder"
    [ngModel]="controlValue"
    [disabled]="isDisabled"
    [errorStateMatcher]="errorMatcher"
    (blur)="controlTouched()"
    (ngModelChange)="controlValueChanged($event)"
  ></textarea>
  <mat-hint>{{ hint }}</mat-hint>
  <mat-error>
    <ng-template [ngTemplateOutlet]="errors"></ng-template>
  </mat-error>
  <ng-container matSuffix>
    <ng-content select="[emSuffix]"></ng-content>
  </ng-container>
</mat-form-field>

<ng-template #errors>
  <ng-content select="em-error"></ng-content>
</ng-template>
