import {AfterViewInit, Component, ElementRef, OnDestroy} from '@angular/core';
import {EmSpinnerModule} from '@em/shared/ui';

@Component({
  selector: 'em-block-ui',
  standalone: true,
  imports: [EmSpinnerModule],
  templateUrl: './block-ui.component.html',
  styleUrls: ['./block-ui.component.scss'],
})
export class BlockUiComponent implements AfterViewInit, OnDestroy {
  private _parentDisplay?: string;
  private _parentPosition?: string;

  constructor(private readonly _el: ElementRef) {}

  ngAfterViewInit() {
    const parent = this._el.nativeElement.parentElement;

    if (parent) {
      const parentStyle = window.getComputedStyle(parent);

      this._parentDisplay =
        getComputedStyle(parent)?.getPropertyValue('display');
      this._parentPosition =
        getComputedStyle(parent)?.getPropertyValue('position');

      parent.style.display = 'block';
      parent.style.position = 'relative';

      if (parentStyle.borderRadius) {
        const backdrop =
          this._el.nativeElement.querySelector('.ui-block-backdrop');

        if (backdrop) {
          backdrop.style.borderRadius = parentStyle.borderRadius;
        }
      }
    }
  }

  ngOnDestroy() {
    const parent = this._el.nativeElement.parentElement;

    if (parent) {
      parent.style.display = this._parentDisplay || 'block';
      parent.style.position = this._parentPosition || 'relative';
    }
  }
}
