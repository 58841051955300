<div class="px-8 py-6">
  <em-spinner class="m-16" *ngIf="isLoading"></em-spinner>

  <div *ngIf="showFailedMessage">
    <div class="text-center">
      <em-icon
        [size]="'xl'"
        class="inline-block text-danger-500"
        name="highlight_off"
      ></em-icon>
    </div>
    <div class="text-center font-bold text-lg mt-1 mb-2">
      {{ 'SHARED_PAYMENT_FAILED_TITLE' | translate }}
    </div>
    <div class="text-center mt-1 mb-8">
      {{ 'SHARED_PAYMENT_FAILED_DESC' | translate }}
    </div>
    <div class="text-right">
      <em-text-button color="" (btnClick)="close()">{{
        'SHARED_LABEL_CLOSE' | translate
      }}</em-text-button>
    </div>
  </div>
</div>
