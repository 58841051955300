import {Component, forwardRef, Input, OnChanges} from '@angular/core';
import {
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import {getOptionType, inputType} from '../util';
import {CommonModule} from '@angular/common';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {TranslateModule} from '@ngx-translate/core';
import {IOptionDefinition} from '../option-definition';
import {
  IFilterOptionValues,
  optionValueType,
} from '@em/data-feed/data-access-products';
import {ITypedControlValueAccessor} from '@em/shared/util-types';
import {IProductFilterDefinition} from '../product-filter-definition';
import {ChangesObj} from '@em/shared/util-types';
import {castStringValues} from '../cast-string-values';

export interface IOptionViewModel {
  definition: IOptionDefinition;
  inputType: inputType;
  key: string;
}

@Component({
  selector: 'em-filter-item-options',
  templateUrl: './filter-item-options.component.html',
  styleUrls: ['./filter-item-options.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilterItemOptionsComponent),
      multi: true,
    },
  ],
})
export class FilterItemOptionsComponent
  implements ITypedControlValueAccessor<IFilterOptionValues>, OnChanges
{
  disabled = false;
  currentValues?: IFilterOptionValues = {};
  vms: IOptionViewModel[] = [];

  @Input() definition?: IProductFilterDefinition;
  protected _onChangeCallback?: (
    val: IFilterOptionValues | null | undefined,
  ) => void;
  protected _onTouchedCallback?: () => void;

  constructor() {}

  ngOnChanges(changes: ChangesObj<FilterItemOptionsComponent, 'definition'>) {
    if (changes.definition && changes.definition.currentValue) {
      const definition = changes.definition.currentValue;
      this.vms = definition.availableOptions.map((o) => ({
        key: o.name,
        definition: o,
        inputType: getOptionType(o),
      }));
    }
  }

  getOptionValue(key: string): optionValueType | undefined {
    return this.currentValues ? this.currentValues[key] : undefined;
  }

  setOptionValue(
    key: string,
    value: optionValueType | undefined,
    optionType: IOptionDefinition['type'],
  ) {
    if (!this.currentValues) {
      this.currentValues = {};
    }

    if (value === undefined || value === null) {
      delete this.currentValues[key];
    } else {
      if (optionType === 'boolean') {
        this._handleBooleanValue(key, value as boolean);
      } else {
        this.currentValues[key] = castStringValues(value, optionType);
      }
    }

    this.onTouched();
    if (this._onChangeCallback) this._onChangeCallback(this.currentValues);
  }

  trackByFn(index: number, obj: IOptionViewModel) {
    return obj.key;
  }

  registerOnChange(
    fn: (val: IFilterOptionValues | null | undefined) => void,
  ): void {
    this._onChangeCallback = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouchedCallback = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: IFilterOptionValues | null | undefined): void {
    this.currentValues = obj || {};
  }

  onTouched() {
    if (this._onTouchedCallback) this._onTouchedCallback();
  }

  hasLongText(key: string): boolean {
    return ['exclude_products'].includes(key);
  }

  getPlaceholder(option: IOptionViewModel) {
    return `CAMPAIGN_PERFORMANCE_FILTER_${this.definition?.key}_${option.key}_PLACEHOLDER`.toUpperCase();
  }

  private _handleBooleanValue(key: string, value: boolean) {
    // TODO: For some reason we need to assign boolean values to a dynamic key like so
    // Otherwise we'll be prompted with a nasty "TypeError: Cannot assign to read only property of object"
    const newValue: IFilterOptionValues = {};
    newValue[key] = value;
    this.currentValues = {
      ...this.currentValues,
      ...newValue,
    };
  }
}
