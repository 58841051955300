import {
  NullaryOperators,
  ProductsListDateFilterOperators,
  ProductsListFetchStatusFilterOperators,
  ProductsListNumberFilterOperators,
  ProductsListTextFilterOperators,
} from '@em/shared/api-interface';

export function isNullaryOperator(
  operator:
    | ProductsListTextFilterOperators
    | ProductsListNumberFilterOperators
    | ProductsListDateFilterOperators
    | ProductsListFetchStatusFilterOperators,
): operator is NullaryOperators {
  return operator === 'is_empty' || operator === 'not_empty';
}
