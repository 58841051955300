import {CommonModule} from '@angular/common';
import {Component, Input, OnDestroy, OnInit, inject} from '@angular/core';
import {LocalUserDataService} from '@em/auth/data-access';
import {EmSpinnerModule} from '@em/shared/ui';
import {configurationHelper} from '@em/shared/util-configuration';
import {
  InsightsStripeGateway,
  OneTimeFetchData,
} from '@em/subscription/api-interface';
import {TranslateModule} from '@ngx-translate/core';
import {StripeEmbeddedCheckout} from '@stripe/stripe-js';
import {loadStripe} from '@stripe/stripe-js/pure';
import {combineLatest, from, map, switchMap, tap, throwError} from 'rxjs';

@Component({
  selector: 'em-stripe-one-time-payment',
  templateUrl: './stripe-one-time-payment.component.html',
  styleUrls: ['./stripe-one-time-payment.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, EmSpinnerModule],
})
export class EmStripeOneTimvePaymentComponent implements OnInit, OnDestroy {
  @Input({required: true}) oneTimeFetch?: OneTimeFetchData;

  private readonly _stripeGateway = inject(InsightsStripeGateway);
  private readonly _localStorage = inject(LocalUserDataService);
  private _checkoutObject: StripeEmbeddedCheckout | undefined;

  protected isLoading = true;

  readonly stripe$ = from(
    loadStripe(configurationHelper.needConfig('STRIPE_PUBLIC_API_KEY')),
  );

  ngOnDestroy(): void {
    if (this._checkoutObject) {
      this._checkoutObject.destroy();
    }
  }

  ngOnInit(): void {
    combineLatest([this._fetchClientSecret(), this.stripe$])
      .pipe(
        switchMap(([clientSecret, stripe]) => {
          if (clientSecret && stripe) {
            return from(stripe.initEmbeddedCheckout({clientSecret}));
          } else {
            return throwError(() => 'Failed to load Stripe');
          }
        }),
        tap((checkout) => {
          checkout.mount('#checkout');
          this._checkoutObject = checkout;
          this.isLoading = false;
        }),
      )
      .subscribe();
  }

  private _fetchClientSecret() {
    if (this.oneTimeFetch) {
      return this._stripeGateway
        .postCheckout(this.oneTimeFetch)
        .pipe(map((resp) => resp.stripe.client_secret));
    } else {
      return throwError(() => 'Insights missing');
    }
  }
}
