import {CommonModule} from '@angular/common';
import {Component, OnInit, inject} from '@angular/core';
import {MatTabsModule} from '@angular/material/tabs';
import {ActivatedRoute, Router} from '@angular/router';
import {EmCardModule} from '@em/shared/ui';
import {NamedUrl} from '@em/shared/util-types';
import {TranslateModule} from '@ngx-translate/core';
import {map, tap} from 'rxjs';
import {EmCancelSubscriptionComponent} from '../cancel-subscription/cancel-subscription.component';
import {ContractService} from '@em/subscription/data-access';
import {EmUpgradePlanComponent} from '../upgrade-plan/upgrade-plan.component';
import {UserPaymentFormComponent} from '../user-payment-form/user-payment-form.component';
import {EmExternalPaymentPortalEntryComponent} from '../stripe/external-payment-portal-entry/external-payment-portal-entry.component';
import {EmInvoicesListComponent} from '../stripe/invoices-list/invoices-list.component';
import {EmOneTimePaymentsComponent} from '../one-time-payments/one-time-payments.component';

const TABS: NamedUrl[] = [
  {name: 'subscription', url: '/payment/subscription'},
  {name: 'invoices', url: '/payment/invoices'},
  {name: 'billing-info', url: '/payment/billing-info'},
  {name: 'payment-details', url: '/payment/payment-details'},
];

@Component({
  selector: 'em-subscription-page',
  templateUrl: './subscription-page.component.html',
  styleUrls: ['./subscription-page.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatTabsModule,
    UserPaymentFormComponent,
    EmCardModule,
    EmUpgradePlanComponent,
    EmCancelSubscriptionComponent,
    EmExternalPaymentPortalEntryComponent,
    EmInvoicesListComponent,
    EmOneTimePaymentsComponent,
  ],
})
export class EmSubscriptionPageComponent implements OnInit {
  private readonly _activatedRoute = inject(ActivatedRoute);
  private readonly _router = inject(Router);
  private readonly _contractService = inject(ContractService);

  protected readonly hasActiveSubscription$ =
    this._contractService.activeSubscription$;
  protected selectedStepIndex = 0;
  protected liveContract$ = this._contractService.liveContract$;
  protected showPaymentDetails$ =
    this._contractService.activeSubscription$.pipe(
      map((active) => !active || active.processor !== 'paypal'),
    );

  ngOnInit(): void {
    this._activatedRoute.paramMap
      .pipe(
        tap((paramMap) => {
          const step = paramMap.get('tab');
          this.selectedStepIndex = this.getTabIndex(step);
        }),
      )
      .subscribe();
  }

  getTabIndex(tabName: string | null): number {
    const tab = TABS.find((s) => s.name === tabName);

    return tab ? TABS.indexOf(tab) : 0;
  }

  getTabUrl(tabIndex: number): string {
    return TABS[tabIndex]?.url || '';
  }

  selectedTabChanged(event: number) {
    this._router.navigateByUrl(this.getTabUrl(event));
  }

  redirectTo(url: string) {
    this._router.navigateByUrl(url);
  }
}
