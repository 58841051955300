<em-product-filter-overlay
  overlayTitle="PRODUCT_FILTER_TITLE_CATEGORIES"
  [isCloseable]="false"
  (closed)="openEditFilterSet()"
  class="centered no-padding embedded-content is-modal"
>
  <ng-container data-slot-type="action">
    <em-button
      type="text"
      color="basic"
      class="back-to-overview"
      icon="arrow_back"
      (btnClick)="openEditFilterSet()"
    >
      <span translate>FILTER_BACK_OVERVIEW</span>
    </em-button>
  </ng-container>
  <div data-slot-type="content">
    <ng-content
      select="[data-slot-type=invert-category-selection]"
    ></ng-content>
    <em-miller-columns
      [(selectedCategories)]="selectedCategories"
      [availableCategories]="availableCategories"
    >
    </em-miller-columns>
  </div>
</em-product-filter-overlay>
