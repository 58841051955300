<apx-chart
  [series]="series"
  [chart]="chartOptions.chart"
  [labels]="chartOptions.labels"
  [plotOptions]="chartOptions.plotOptions"
  [colors]="chartOptions.colors"
  [states]="chartOptions.states"
  [tooltip]="chartOptions.tooltip"
  [stroke]="chartOptions.stroke"
  [xaxis]="chartOptions.xaxis"
  [yaxis]="chartOptions.yaxis"
  [dataLabels]="chartOptions.dataLabels"
  [legend]="chartOptions.legend"
  [responsive]="chartOptions.responsive"
  [annotations]="chartOptions.annotations"
>
</apx-chart>
