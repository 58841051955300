/* tslint:disable */
/**
* This file was automatically generated.
* DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
* and run the generate-code script again.
*/
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DeleteReq as DeleteColumnLayoutsReq} from '../../types/request-objects/products/column-layouts/delete';
import {GetReq as GetColumnLayoutsReq} from '../../types/request-objects/products/column-layouts/get';
import {GetResp as GetColumnLayoutsResp} from '../../types/view-models/products/column-layouts/get';
import {PostReq as PostColumnLayoutsReq} from '../../types/request-objects/products/column-layouts/post';
import {PostResp as PostColumnLayoutsResp} from '../../types/view-models/products/column-layouts/post';

@Injectable({
  providedIn: 'root',
})
export class ColumnLayoutsGateway {
  constructor(private _http: HttpClient) {}

  deleteColumnLayouts(params: DeleteColumnLayoutsReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<undefined> {
    const request = params as any as { [param: string]: string | string[]; };
    return this._http.delete<undefined>('/emarketing_api/products/column_layouts/', {...options, params: request});
  }

  getColumnLayouts(params: GetColumnLayoutsReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<GetColumnLayoutsResp> {
    const request = params as any as { [param: string]: string | string[]; };
    return this._http.get<GetColumnLayoutsResp>('/emarketing_api/products/column_layouts/', {...options, params: request});
  }

  postColumnLayouts(params: PostColumnLayoutsReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<PostColumnLayoutsResp> {
    return this._http.post<PostColumnLayoutsResp>('/emarketing_api/products/column_layouts/', params, options);
  }
}
