import {CommonModule} from '@angular/common';
import {Component, inject} from '@angular/core';
import {MatTooltipModule} from '@angular/material/tooltip';
import {FetchProductsStore} from '@em/data-feed/data-access-products';
import {
  EmBoxModule,
  EmIconModule,
  EmSecondaryButtonComponent,
  EmSpinnerModule,
} from '@em/shared/ui';
import {formatRelative} from '@em/shared/util-date-time';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'em-fetch-products-info',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatTooltipModule,
    EmSecondaryButtonComponent,
    EmSpinnerModule,
    EmIconModule,
    EmBoxModule,
  ],
  templateUrl: './fetch-products-info.component.html',
  styleUrls: ['./fetch-products-info.component.scss'],
})
export class EmFetchProductsInfoComponent {
  private readonly _fetchProductsStore = inject(FetchProductsStore);

  protected readonly formatRelative = formatRelative;
  protected lastSuccess$ = this._fetchProductsStore.lastSuccess$;
  protected lastFail$ = this._fetchProductsStore.lastFail$;
  protected inProgress$ = this._fetchProductsStore.inProgress$;
  protected neverFetched$ = this._fetchProductsStore.neverFetched$;

  triggerFetch() {
    this._fetchProductsStore.fetchProducts();
  }
}
