import {NotificationKey} from '@em/shared/api-interface';
import {NotificationModel} from '../notification.model';
import {IPusherNotification} from '../pusher-service/pusher.service';

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions

interface worker {
  key: string;
  payload: string[];
}

export class BatchCompletedNotification extends NotificationModel {
  static key: NotificationKey =
    'UseCases::Workflows::Notifications::BatchCompleted';

  constructor(
    createdAt: Date,
    workflowUuid: string,
    readonly owner_uuid: string,
    readonly failed: boolean | undefined,
    readonly workers: worker[],
  ) {
    super(createdAt, BatchCompletedNotification.key, workflowUuid);
  }

  static batchCompletedFromPusher(
    resp: IPusherNotification,
  ): BatchCompletedNotification {
    return new BatchCompletedNotification(
      resp['created_at'] ? new Date(resp['created_at'] as string) : new Date(),
      resp.workflow_uuid,
      resp.owner_uuid,
      resp.failed,
      resp['workers'] ? (resp['workers'] as worker[]) : [],
    );
  }
}
