import {NgModule} from '@angular/core';
import {EmSpinnerComponent} from './spinner.component';
import {CommonModule} from '@angular/common';

@NgModule({
  imports: [CommonModule],
  declarations: [EmSpinnerComponent],
  exports: [EmSpinnerComponent],
})
export class EmSpinnerModule {}
