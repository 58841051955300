<div *ngIf="group" class="filter-connect">
  <mat-radio-group
    (change)="changeFilterGroupLogicalOperator($event)"
    [value]="value"
    aria-label="Select an option"
  >
    <mat-radio-button color="primary" value="and">{{
      'AND' | translate
    }}</mat-radio-button>
    <mat-radio-button color="primary" value="or">{{
      'OR' | translate
    }}</mat-radio-button>
  </mat-radio-group>

  <button (click)="connectFilters()" class="button-connect">
    <img src="assets/images/products/connect.svg" alt="Connect" />
  </button>
</div>
