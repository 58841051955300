import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  NgModule,
} from '@angular/core';
import {NgApexchartsModule} from 'ng-apexcharts';
import {EmNumberWithUnitPipe, EmSpinnerModule} from '@em/shared/ui';
import {TranslateModule} from '@ngx-translate/core';
import {basicPieChartOptions} from '../../utils/apex-charts.helper';

@Component({
  selector: 'em-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss', '../../pie-chart.shared.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmPieChartComponent {
  @Input() data?: number | null;
  @Input() title?: string;
  @Input() isLoading?: boolean | null;
  @Input() titlePosition?: 'above' | 'below' = 'above';
  @Input() size?: 'sm' | 'md' = 'md';

  readonly chartOptions = basicPieChartOptions;

  constructor() {}

  get fillerValue() {
    return 100 - this.valueOrZero;
  }

  get valueOrZero() {
    return this.data || 0;
  }

  hasData() {
    return this.data !== null && this.data !== undefined;
  }
}

@NgModule({
  declarations: [EmPieChartComponent],
  exports: [EmPieChartComponent],
  imports: [
    CommonModule,
    EmNumberWithUnitPipe,
    NgApexchartsModule,
    TranslateModule,
    EmSpinnerModule,
  ],
})
export class EmPieChartModule {}
