import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  EventEmitter,
  Input,
  Optional,
  Output,
  QueryList,
  Self,
} from '@angular/core';
import {FormGroupDirective, NgControl} from '@angular/forms';
import {EmErrorDirective} from '../../directives/error/error.directive';
import {EmMaterialWrapperComponent} from '../material-wrapper/material-wrapper.component';
import {EmRadioButtonComponent} from './radio-button.component';

@Component({
  selector: 'em-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss'],
})
export class EmRadioGroupComponent<T>
  extends EmMaterialWrapperComponent<T>
  implements AfterViewInit
{
  @Input() orientation: 'horizontal' | 'vertical' = 'vertical';
  @ContentChildren(EmRadioButtonComponent)
  radios!: QueryList<EmRadioButtonComponent<T>>;
  @ContentChild(EmErrorDirective)
  error!: QueryList<EmErrorDirective>;
  @Output() selectionChange: EventEmitter<T> = new EventEmitter();

  hasRadios = false;
  constructor(
    @Optional() @Self() public override ngControl: NgControl,
    @Optional() readonly parentFromGroup: FormGroupDirective,
    private readonly ref: ChangeDetectorRef,
  ) {
    super(ngControl);
  }
  ngAfterViewInit(): void {
    this.ref.detectChanges();
  }
}
