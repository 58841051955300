import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';
import {AsyncSubject, combineLatest, Observable, of, ReplaySubject} from 'rxjs';
import {
  catchError,
  filter,
  map,
  mapTo,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs/operators';
import {MerchantsGateway} from '@em/shared/api-interface/lib/gateways/merchants.gateway';
import {PostLoginResp} from '@em/shared/api-interface/lib/types/view-models/merchants/post-login';
import {CountryCode, LanguageCode, IInitializable} from '@em/shared/util-types';
import {LocalUserDataService} from '../../local-user-data/local-user-data.service';
import {DatafeedGateway} from '@em/shared/api-interface/lib/gateways/datafeed.gateway';
import {RepricingGateway} from '@em/shared/api-interface';
import {
  IPostRegistrationReq,
  RegistrationGateway,
} from '../../gateways/registration/registration.gateway';
import {mapVoid} from '@em/shared/util-rxjs';
import {LocationUrlService, LoggerService} from '@em/shared/util-configuration';
import {MerchantSession} from '../../merchant-session';
import {TokenStorageService} from '../../token-storage/token-storage.service';
import {IMerchantJwt} from '../../setup-status/merchant-jwt';

export type SessionReadyEvent = 'ready';
export type SessionEndedEvent = 'ended';
export type SessionEvent = SessionEndedEvent | SessionReadyEvent;

export interface ILoginResponse {
  country: CountryCode;
  language: LanguageCode;
}

@Injectable({
  providedIn: 'root',
})
export class SessionService implements IInitializable {
  readonly name = 'SessionService';
  readonly sessionChanges = new ReplaySubject<SessionEvent>(1);
  readonly sessionReady: Observable<'ready'>;
  readonly sessionEnded: Observable<'ended'>;
  private readonly _loginResponse = new ReplaySubject<ILoginResponse>(1);
  private readonly _observable: Observable<MerchantSession>;
  private readonly _jwtConfirmed = new AsyncSubject<void>();
  readonly loginResponse = this._loginResponse.pipe(
    switchMap((resp) => this._jwtConfirmed.pipe(mapTo(resp))),
  );

  constructor(
    private readonly _merchantsGateway: MerchantsGateway,
    private readonly _registrationGateway: RegistrationGateway,
    private readonly _tokenStorage: TokenStorageService,
    private readonly _logger: LoggerService,
    private readonly _locationUrl: LocationUrlService,
    private readonly _localUserData: LocalUserDataService,
    private readonly _router: Router,
    private readonly _datafeedGateway: DatafeedGateway,
    private readonly _repricingGateway: RepricingGateway,
  ) {
    this.sessionReady = this.sessionChanges.pipe(
      filter((x) => x === 'ready'),
    ) as Observable<'ready'>;
    this.sessionEnded = this.sessionChanges.pipe(
      filter((x) => x === 'ended'),
    ) as Observable<'ended'>;

    this._observable = this.sessionReady.pipe(
      map(() => {
        const token = this._tokenStorage.decodedToken;
        if (!token) throw new Error('JWT missing');

        this._initializeSentryScope(token.merchant_uuid);
        return new MerchantSession(token);
      }),
      shareReplay({refCount: false, bufferSize: 1}),
    );
  }

  private _isChangingUrl = false;

  get isChangingUrl() {
    return this._isChangingUrl;
  }

  requestMagicLink(email: string): Observable<undefined> {
    return this._merchantsGateway.postMagicLink({email});
  }

  confirmJwt() {
    this._jwtConfirmed.next(undefined);
    this._jwtConfirmed.complete();
  }

  observable(): Observable<MerchantSession> {
    return this._observable;
  }

  initialize(): Observable<void> {
    this._publishSessionEnded();

    if (this.isLoggedIn()) {
      // Has existing session
      this._jwtConfirmed.subscribe(() => {
        this._publishSessionReady();
      });
      return of(undefined);
    }

    // No session at all
    this._publishSessionEnded();
    return of(undefined);
  }

  login(
    email: string,
    password: string,
    afterLogin?: () => Observable<void>,
  ): Observable<void> {
    this._publishSessionEnded();

    return this._merchantsGateway
      .postLogin({email: email.toLowerCase(), password})
      .pipe(
        switchMap((response) => this._onSuccessfulLogin(response, afterLogin)),
        mapVoid(),
      );
  }

  logout(): Observable<undefined> {
    return this._merchantsGateway.postLogout({}).pipe(
      tap(() => {
        this._localUserData.clear();

        this._publishSessionEnded();
      }),
    );
  }

  isJwtValid(): boolean {
    const token = this._tokenStorage.decodedToken;
    if (!token?.['expires_at']) return false;

    const expiresAt = new Date(token['expires_at']);
    return expiresAt > new Date();
  }

  isLoggedIn() {
    return this._tokenStorage.hasToken();
  }

  getMerchantUuid(): string | undefined {
    const jwt = this._tokenStorage.decodedToken;

    if (!jwt) return undefined;
    return jwt.merchant_uuid;
  }

  decodeToken(): IMerchantJwt | undefined | null {
    return this._tokenStorage.decodedToken;
  }

  changeUrlTo(newUrl: string) {
    this.exchangeLoginVia(newUrl);
  }

  // Uses in-app routing instead of a hard redirect/reload and
  // indicates in-app routing is in progress
  navigateToRoute(desiredRoute: string) {
    this._isChangingUrl = true;
    this._router.navigateByUrl(desiredRoute).then(() => {
      this._isChangingUrl = false;
    });
  }

  exchangeLoginVia(url: string) {
    this._isChangingUrl = true;
    this._logger.warn('Changing base url to ' + url);
    const token = this._tokenStorage.getToken();
    this._localUserData.clear();
    this._locationUrl.forceChange(url + '/?jwt=' + token);
  }

  exchangeJwt(exchangeJwt: string) {
    return this._merchantsGateway
      .postExchangeLogin({
        headers: this._getExchangeLoginHeaders(exchangeJwt),
      })
      .pipe(map(({jwt}) => jwt));
  }

  exchangeLogin(
    jwt: string,
    afterLogin: () => Observable<void> = () => of(undefined),
  ): Observable<void> {
    this._resetSession();

    return this._merchantsGateway
      .postExchangeLogin({
        headers: this._getExchangeLoginHeaders(jwt),
      })
      .pipe(
        switchMap((response) => this._onSuccessfulLogin(response, afterLogin)),
        mapVoid(),
      );
  }

  register(
    email: string,
    language: LanguageCode,
    recaptcha_response: string,
    name?: string,
    origin?: string,
  ): Observable<void> {
    this._resetSession();

    const params: IPostRegistrationReq = {
      email,
      language,
      name,
      recaptcha_response,
    };

    // We must not send empty fields
    if (!params.name) delete params['name'];
    return this._registrationGateway.register(params, origin).pipe(
      switchMap((response) =>
        this.exchangeLogin(response.jwt, () =>
          this._merchantsGateway.postSignupEmail({}).pipe(
            switchMap((resp) => {
              // for webstollen origin manuall trigger adding tokens for data-feed and repricing
              if (origin === 'webstollen') {
                return combineLatest([
                  this._datafeedGateway.putSettings({}),
                  this._repricingGateway.putSettings({}),
                ]).pipe(
                  switchMap(() => of(resp)),
                  catchError(() => of(resp)),
                );
              } else {
                return of(resp);
              }
            }),
          ),
        ),
      ),
    );
  }

  private _resetSession() {
    this._publishSessionEnded();
    this._tokenStorage.clearToken();
  }

  private _publishSessionReady() {
    this.sessionChanges.next('ready');
  }

  private _publishSessionEnded() {
    this.sessionChanges.next('ended');
  }

  private _onSuccessfulLogin(
    response: PostLoginResp,
    afterLogin: () => Observable<void> = () => of(undefined),
  ): Observable<void> {
    this._isChangingUrl = false;
    this._tokenStorage.setToken(response.jwt);

    return afterLogin().pipe(
      tap(() => {
        this._publishSessionReady();
        // Ensure the after login callback happens before a possible hard-reload
        this._loginResponse.next({
          country: response.country,
          language: response.language,
        });
      }),
    );
  }

  private _getExchangeLoginHeaders(jwt: string) {
    return {
      Authorization: `Bearer ${jwt}`,
    };
  }

  private _initializeSentryScope(id: string) {
    Sentry.configureScope((scope) => {
      scope.setTag('user.merchant_uuid', id);
    });
  }
}
