import {Injectable} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {SessionService} from '@em/auth/data-access';
import {PartnerModel, PartnerName} from './partner.model';

@Injectable({
  providedIn: 'root',
})
export class PartnerService {
  private readonly _subject = new ReplaySubject<PartnerModel>(1);

  constructor(private readonly _session: SessionService) {
    this._session.observable().subscribe(({partnerName}) => {
      this._change(partnerName as PartnerName);
    });
  }

  observable(): Observable<PartnerModel> {
    return this._subject;
  }

  private _change(partnerName: PartnerName) {
    this._subject.next(new PartnerModel(partnerName));
  }
}
