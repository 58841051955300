import {createReducer, on} from '@ngrx/store';
import {SetupStatusBuilder} from './setup-status';
import {
  incorporateSetupStatusFromBackend,
  invalidateSetupStatus,
  loadSetupStatusFromBackend,
} from './setup-status.actions';
import {ISetupFeatureState, initialSetupState} from './setup-status.state';

export const setupReducer = createReducer(
  initialSetupState,
  on(
    invalidateSetupStatus,
    (state): ISetupFeatureState => ({
      ...state,
      outdated: true,
    }),
  ),
  on(
    loadSetupStatusFromBackend,
    (state): ISetupFeatureState => ({
      ...state,
      loading: true,
    }),
  ),
  on(
    incorporateSetupStatusFromBackend,
    (state, {setupStatus}): ISetupFeatureState => ({
      loading: false,
      status: SetupStatusBuilder.build(setupStatus),
      outdated: false,
    }),
  ),
);
