import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {LocalUserDataService} from '@em/auth/data-access';
import {LocationUrlService} from '@em/shared/util-configuration';
import {QueryParamsService} from '@em/shared/util-web';

/**
 * Provides debug tools to pass in url, like _reset_.
 */
@Injectable({
  providedIn: 'root',
})
export class DebugToolsGuard {
  constructor(
    private readonly _queryParams: QueryParamsService,
    private readonly _localUserData: LocalUserDataService,
    private readonly _locationUrl: LocationUrlService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean {
    const hasReset = this._queryParams.hasParam(state.url, '_reset_');
    if (hasReset) {
      this._reset();
      return false;
    }
    return true;
  }

  /** Perform a total reset of the application. User should be logged out and end up on the login-screen,
   * with a blank application state (client-sided).
   */
  private _reset(): void {
    this._localUserData.clear();
    this._locationUrl.restartWithParam('_reset_', undefined);
  }
}
