import {CommonModule} from '@angular/common';
import {Component, HostBinding, Input, inject} from '@angular/core';
import {ProductsCountService} from '@em/data-feed/data-access-products';
import {EmInfoBoxModule, EmWarningBoxModule} from '@em/shared/ui';
import {DeepPartial} from '@em/shared/util-types';
import {Contract, ContractWithPrice} from '@em/subscription/api-interface';
import {TranslateModule} from '@ngx-translate/core';
import {map} from 'rxjs';
import {ContractService} from '@em/subscription/data-access';
import {PaymentService} from '../services/payment-service/payment.service';
import {SuggestedPlanService} from '../services/suggested-plan/suggested-plan.service';
import {EmPlanBoxComponent} from '../plan-box/plan-box.component';

@Component({
  selector: 'em-suggested-plan',
  templateUrl: './suggested-plan.component.html',
  styleUrls: ['./suggested-plan.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    EmWarningBoxModule,
    EmPlanBoxComponent,
    EmInfoBoxModule,
  ],
  providers: [SuggestedPlanService],
})
export class EmSuggestedPlanComponent {
  @Input() set missingFeatures(value: DeepPartial<Contract> | undefined) {
    this._suggestedPlanService.missingFeatures$.next(value);
  }
  @Input() isFloating?: boolean;
  @Input() set upgradeNeededIf(value: ((c: Contract) => boolean) | undefined) {
    this._suggestedPlanService.upgradeNeededIf = value;
  }

  @HostBinding('class.floating-plan') get valid() {
    return this.isFloating;
  }
  private readonly _contractService = inject(ContractService);
  private readonly _suggestedPlanService = inject(SuggestedPlanService);
  private readonly _paymentService = inject(PaymentService);
  private readonly _productsCountService = inject(ProductsCountService);

  // New Properties
  readonly showSuggestedPlan$ = this._suggestedPlanService.showSuggestedPlan$;
  readonly suggestedPlan$ = this._suggestedPlanService.suggestedPlan$;
  readonly showLivePlan$ = this._suggestedPlanService.showLivePlan$;
  readonly showDowngradeTip$ = this._suggestedPlanService.isDowngradeNeeded$;
  readonly showUpgradeWarning$ = this._suggestedPlanService.isUpgradeNeeded$;
  readonly noPlanExist$ = this._suggestedPlanService.noPlanExist$;
  // End of new properties

  readonly hasProducts$ = this._productsCountService.allProductsCount$;
  readonly showProductsMissing$ = this.hasProducts$.pipe(
    map((count) => !count),
  );

  readonly liveContractWithPrice$ =
    this._contractService.liveContractWithPrice$;

  upgrade(plan: ContractWithPrice) {
    this._paymentService.upgradePlan(plan.contract);
  }
}
