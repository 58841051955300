import {format as formatFn} from 'date-fns';
import {getSelectedLocale} from '../manage-locales';

export default function (
  date: Date | number,
  format: string,
  options?: {
    locale?: Locale;
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
    firstWeekContainsDate?: number;
    useAdditionalWeekYearTokens?: boolean;
    useAdditionalDayOfYearTokens?: boolean;
  },
) {
  return formatFn(date, format, {
    locale: getSelectedLocale(),
    ...options,
  });
}
