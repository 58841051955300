<div *ngIf="state$ | async as state" class="flex flex-col">
  <div class="step-title-wrapper">
    <div class="step-title" translate>ONBOARDING_COMMON_WIZARD_TITLE</div>
  </div>
  <div
    class="step-description"
    [innerHTML]="'ONBOARDING_COMMON_WIZARD_DESCRIPTION' | translate"
  ></div>

  <div class="step-configuration-container">
    <em-change-email [form]="form"></em-change-email>
    <em-change-country [form]="form"></em-change-country>
    <em-change-language [form]="form"></em-change-language>
  </div>

  <em-error-box class="error mb-4" *ngIf="state.error">
    <p>{{ state.error | translate }}</p>
  </em-error-box>

  <em-primary-button
    class="mt-auto ml-auto"
    [disabled]="!canProcess()"
    (btnClick)="process()"
  >
    {{ 'ONBOARDING_BUTTON_CONTINUE' | translate }}
  </em-primary-button>

  <div class="award-grid mt-6">
    <div class="award-item">
      <img
        src="assets/images/emarketing/google-award-icon-03.svg"
        class="award-image"
        alt=""
      />
      <div class="award-title" translate>ONBOARDING_WELCOME_AWARD_1_TITLE</div>
      <div class="award-subtitle" translate>
        ONBOARDING_WELCOME_AWARD_1_SUBTITLE
      </div>
      <div class="award-location" translate>
        ONBOARDING_WELCOME_AWARD_1_LOCATION
      </div>
    </div>
    <div class="award-item">
      <img
        src="assets/images/emarketing/google-award-icon-03.svg"
        class="award-image"
        alt=""
      />
      <div class="award-title">
        <div translate>ONBOARDING_WELCOME_AWARD_2_TITLE</div>
      </div>
      <div class="award-subtitle" translate>
        ONBOARDING_WELCOME_AWARD_2_SUBTITLE
      </div>
      <div class="award-location" translate>
        ONBOARDING_WELCOME_AWARD_2_LOCATION
      </div>
    </div>
    <div class="award-item">
      <img
        src="assets/images/emarketing/google-award-icon-02.svg"
        class="award-image"
        alt=""
      />
      <div class="award-title" translate>ONBOARDING_WELCOME_AWARD_3_TITLE</div>
      <div class="award-subtitle" translate>
        ONBOARDING_WELCOME_AWARD_3_SUBTITLE
      </div>
      <div class="award-location" translate>
        ONBOARDING_WELCOME_AWARD_3_TITLE_LOCATION
      </div>
    </div>
    <div class="award-item">
      <img
        src="assets/images/emarketing/google-award-icon-01.svg"
        class="award-image"
        alt=""
      />
      <div class="award-title" translate>ONBOARDING_WELCOME_AWARD_4_TITLE</div>
      <div class="award-subtitle" translate>
        ONBOARDING_WELCOME_AWARD_4_SUBTITLE
      </div>
    </div>
  </div>
</div>
