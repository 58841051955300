<div>
  <div *ngFor="let displayItem of displayList">
    <ng-container *ngIf="isGroup(displayItem.colDef)">
      <div class="flex items-center">
        <em-icon
          class="cursor-pointer"
          [name]="displayItem.expand ? 'expand_less' : 'expand_more'"
          (click)="expandCollapseGroup(displayItem)"
        ></em-icon>
        <div>
          <em-checkbox
            [(ngModel)]="displayItem.checked"
            [indeterminate]="someChildrenVisible(displayItem)"
            (valueChange)="groupValueChange(displayItem, $event)"
            >{{ displayItem.colDef.headerName }}
          </em-checkbox>
        </div>
      </div>
      <div class="ml-16" *ngIf="displayItem.expand">
        <div *ngFor="let childDisplayItem of displayItem.children">
          <em-checkbox
            *ngIf="isColumn(childDisplayItem.colDef)"
            [(ngModel)]="childDisplayItem.checked"
            (valueChange)="
              colValueChange(childDisplayItem, displayItem, $event)
            "
            >{{ childDisplayItem.colDef.headerName }}
          </em-checkbox>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="isColumn(displayItem.colDef)">
      <em-checkbox
        class="ml-9"
        [(ngModel)]="displayItem.checked"
        (valueChange)="colValueChange(displayItem, undefined, $event)"
        >{{ displayItem.colDef.field }}
      </em-checkbox>
    </ng-container>
  </div>
</div>
