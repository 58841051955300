import {Injectable} from '@angular/core';
import {ProductsGateway} from '@em/shared/api-interface';
import {catchError, switchMap, take} from 'rxjs/operators';
import {NotificationService} from '@em/shared/util-web';
import {CountryService} from '@em/user-account/data-access';
import {EMPTY, Observable} from 'rxjs';

@Injectable()
export class DownloadCsvService {
  constructor(
    private readonly _countryService: CountryService,
    private readonly _notification: NotificationService,
    private readonly _productsGateway: ProductsGateway,
  ) {}

  downloadCsv(): Observable<boolean | undefined> {
    return this._countryService.observable().pipe(
      take(1),
      switchMap((countryCode) =>
        this._productsGateway
          .postCsv({country: countryCode})
          .pipe(this._notification.tapShow('CSV_EXPORT_STARTED')),
      ),
      catchError(() => EMPTY),
    );
  }
}
