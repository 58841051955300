import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  private readonly _logger = window.console;

  constructor() {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  log(message: any, ...args: any[]) {
    this._logger.log(message, ...args);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  debug(message: any, ...args: any[]) {
    this._logger.debug(message, ...args);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  warn(message: any, ...args: any[]) {
    this._logger.warn(message, ...args);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error(message: any, ...args: any[]) {
    this._logger.error(message, ...args);
  }
}
