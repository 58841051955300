<em-card
  [ngClass]="[
    'position-' + robotPosition,
    fillContainer ? 'fill-container' : '',
    'robot-size-' + robotSize
  ]"
>
  <img class="robot-img" src="assets/images/shared/robot-illustration.png" />

  <div *ngIf="showDefaultTitle" class="card-title">
    {{ 'SHARED_LABEL_PERSONAL_ADVISOR_TITLE' | translate }}
  </div>
  <em-spinner
    *ngIf="isLoading; else projectedContent"
    class="m-auto"
  ></em-spinner>
</em-card>

<ng-template #projectedContent>
  <ng-content></ng-content>
</ng-template>
