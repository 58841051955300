import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MATERIAL_SANITY_CHECKS} from '@angular/material/core';
import {MatIconModule} from '@angular/material/icon';
import {EmButtonComponent} from './button.component';

@NgModule({
  imports: [CommonModule, MatButtonModule, MatIconModule],
  declarations: [EmButtonComponent],
  exports: [EmButtonComponent],
  providers: [{provide: MATERIAL_SANITY_CHECKS, useValue: false}],
})
export class EmButtonModule {}
