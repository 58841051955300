/* tslint:disable */
/**
* This file was automatically generated.
* DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
* and run the generate-code script again.
*/
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {GetPurchasesReq} from '../types/request-objects/features/get-purchases';
import {GetPurchasesResp} from '../types/view-models/features/get-purchases';

@Injectable({
  providedIn: 'root',
})
export class FeaturesGateway {
  constructor(private _http: HttpClient) {}

  getPurchases(params: GetPurchasesReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<GetPurchasesResp> {
    const request = params as any as { [param: string]: string | string[]; };
    return this._http.get<GetPurchasesResp>('/payment_api/features/purchases', {...options, params: request});
  }
}
