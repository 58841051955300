<mat-dialog-content>
  <div class="text-xl mb-6" data-testid="header">
    <span>{{ 'SHARED_LABEL_ADD_NEW_LAYOUT' | translate }}</span>
  </div>
  <form [formGroup]="formGroup" (ngSubmit)="addLayout()">
    <em-input
      class="w-full"
      formControlName="name"
      [label]="'SHARED_LABEL_NAME' | translate"
    >
      <em-error>{{ 'SHARED_REUIRED_FIELD' | translate }}</em-error>
    </em-input>

    <div align="right">
      <em-text-button
        color=""
        class="mr-2"
        [mat-dialog-close]="false"
        cdkFocusInitial
      >
        {{ 'ACTIONS_CANCEL' | translate }}
      </em-text-button>
      <em-primary-button type="submit">{{
        'SHARED_SAVE' | translate
      }}</em-primary-button>
    </div>
  </form>
</mat-dialog-content>
