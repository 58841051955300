<div class="page">
  <em-stepper
    #stepper
    class="p-4"
    [linear]="false"
    [showIcons]="false"
    [(selectedIndex)]="selectedStepIndex"
    (selectionChange)="selectedStepChanged($event)"
  >
    <!-- Competitor Radar Groups -->
    <cdk-step [label]="'Competitor Radar Groups'">
      <div class="step-wrapper" *ngIf="selectedStepIndex === 0">
        <div class="header-wrapper">
          <div>
            <div class="header-title">
              <strong>Competitor Radar Groups</strong>
            </div>
            <div>
              For which products shall we monitor (crawl) your competitors (for
              repricing and ads Campaigns)
            </div>
            <div>Note: products in these groups must have valid GTIN</div>
          </div>
        </div>

        <ng-container *ngIf="competitorsRadarGroups$ | async as radarGroups">
          <em-warning-box *ngIf="!radarGroups.length">
            <ng-container description>
              You don't have any Competitor Radar Groups yet, please complete
              the Competitor Radar settings to configure and add Compititor
              Radar Groups
            </ng-container>

            <ng-container actions>
              <em-primary-button [routerLink]="['/insights/settings']"
                >To Settings</em-primary-button
              >
            </ng-container>
          </em-warning-box>

          <em-product-groups-list
            class="@container"
            [groups]="radarGroups"
            [allowAddNew]="false"
            [allowDelete]="true"
          ></em-product-groups-list>
        </ng-container>
      </div>
    </cdk-step>

    <!-- Custom Groups -->
    <cdk-step [label]="'PRODUCT_GROUP_CUSTOM_GROUPS' | translate">
      <div class="step-wrapper" *ngIf="selectedStepIndex === 1">
        <div class="header-wrapper">
          <div>
            <div class="header-title">
              <strong>Custom Product Groups</strong>
            </div>
            <div>
              Create your custom product group to select products for Ads
              campaigns and repricing jobs by applying filters on your products
              list
            </div>
          </div>
          <em-fab-button
            [icon]="'add'"
            [size]="'sm'"
            (btnClick)="addNewGroup('custom')"
          ></em-fab-button>
        </div>

        <em-product-groups-list
          class="@container"
          [groups]="customGroups$ | async"
          (addNew)="addNewGroup('custom')"
        ></em-product-groups-list>
      </div>
    </cdk-step>

    <!-- Template Groups -->
    <cdk-step [label]="'PRODUCT_GROUP_TEMPLATE_GROUPS' | translate">
      <div class="step-wrapper" *ngIf="selectedStepIndex === 2">
        <div class="header-wrapper">
          <div>
            <div class="header-title">
              <strong>Template Product Groups</strong>
            </div>
            <div>
              Choose a template product group to select products for Ads
              Campaigns and repricing jobs
            </div>
          </div>
          <em-fab-button
            [icon]="'add'"
            [size]="'sm'"
            (btnClick)="addNewGroup('template')"
          ></em-fab-button>
        </div>

        <em-product-groups-list
          class="@container"
          [groups]="templateGroups$ | async"
          (addNew)="addNewGroup('template')"
        ></em-product-groups-list>
      </div>
    </cdk-step>
  </em-stepper>

  <em-suggested-plan
    *ngIf="showSuggestedPlan$ | async"
    [isFloating]="true"
  ></em-suggested-plan>
</div>
