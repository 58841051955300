<ng-container *ngIf="componentState | async as compState">
  <div class="page-container">
    <em-block-ui *ngIf="compState.loading"></em-block-ui>
    <div class="title-bar">
      <div class="intro-title" translate>ENTERPRISE_PAGE_TITLE</div>
      <button mat-flat-button
              color="primary"
              (click)="logout()"
              *ngIf="compState.authState === 'done'">
        <mat-icon aria-hidden="false"
                  aria-label="logout icon">
          logout
        </mat-icon>
        <span>{{'BUTTON_LOGOUT' | translate}}</span>
      </button>
    </div>

    <h2 *ngIf="compState.noAccountsSelectable" class="no-accounts">
      {{'ENTERPRISE_NO_ADWORDS_ACCOUNTS' | translate}}
    </h2>

    <div class="login-with-google-container" *ngIf="compState.authState === 'unauthorized'">
      <button mat-flat-button
              [disabled]="!compState.sdkReady"
              color="primary"
              (click)="loginWithGoogle()">
        {{'ENTERPRISE_LOGIN_WITH_GOOGLE' | translate}}
      </button>
    </div>

    <ng-container *ngIf="compState.adwordsAccounts.total">
      <div class="search-container">
        <input mat-input [(ngModel)]="searchValue" [placeholder]="'ENTERPRISE_SEARCH_PLACEHOLDER' | translate">
      </div>
      <div class="adwords-account-list-header" *ngIf="compState.authState !== 'unauthorized'">
        <div class="label" (click)="changeOrderBy('id')">
          <span translate>ADWORDS_ACCOUNT_ID</span>
          <ng-container *ngIf="isActive('id')">
            <mat-icon *ngIf="orderDirection === 'asc'" class="sort-icon">arrow_drop_up</mat-icon>
            <mat-icon *ngIf="orderDirection === 'desc'" class="sort-icon">arrow_drop_down</mat-icon>
          </ng-container>
        </div>

        <div class="label" (click)="changeOrderBy('name')">
          <span translate>ADWORDS_ACCOUNT_NAME</span>
          <ng-container *ngIf="isActive('name')">
            <mat-icon *ngIf="orderDirection === 'asc'" class="sort-icon">arrow_drop_up</mat-icon>
            <mat-icon *ngIf="orderDirection === 'desc'" class="sort-icon">arrow_drop_down</mat-icon>
          </ng-container>
        </div>

        <div class="label" (click)="changeOrderBy('managedByName')">
          <span translate>ADWORDS_ACCOUNT_MANAGED_BY</span>
          <ng-container *ngIf="isActive('managedByName')">
            <mat-icon *ngIf="orderDirection === 'asc'" class="sort-icon">arrow_drop_up</mat-icon>
            <mat-icon *ngIf="orderDirection === 'desc'" class="sort-icon">arrow_drop_down</mat-icon>
          </ng-container>
        </div>

        <div class="label with-tooltip" matTooltip="{{ 'ADWORDS_ACCOUNT_DATE_RANGE' | translate }}">
          <mat-icon class="info-icon">info</mat-icon>
          <span translate>ADWORDS_ACCOUNT_COSTS</span>
        </div>

        <div class="label with-tooltip" matTooltip="{{ 'ADWORDS_ACCOUNT_DATE_RANGE' | translate }}">
          <mat-icon class="info-icon">info</mat-icon>
          <span translate>ADWORDS_ACCOUNT_CONVERSIONS</span>
        </div>

        <div class="label with-tooltip" matTooltip="{{ 'ADWORDS_ACCOUNT_DATE_RANGE' | translate }}">
          <mat-icon class="info-icon">info</mat-icon>
          <span translate>ADWORDS_ACCOUNT_CLICKS</span>
        </div>

        <div class="label with-tooltip" matTooltip="{{ 'ADWORDS_ACCOUNT_DATE_RANGE' | translate }}">
          <mat-icon class="info-icon">info</mat-icon>
          <span translate>ADWORDS_ACCOUNT_REVENUE</span>
        </div>

        <div class="label" (click)="changeOrderBy('currencyCode')">
          <span translate>ADWORDS_ACCOUNT_CURRENCY</span>
          <ng-container *ngIf="isActive('currencyCode')">
            <mat-icon *ngIf="orderDirection === 'asc'" class="sort-icon">arrow_drop_up</mat-icon>
            <mat-icon *ngIf="orderDirection === 'desc'" class="sort-icon">arrow_drop_down</mat-icon>
          </ng-container>
        </div>

        <div class="label end" translate>ADWORDS_ACCOUNT_ACTIONS</div>
      </div>
      <div class="adwords-account-list-container">
        <cdk-virtual-scroll-viewport itemSize="48" class="example-viewport">
          <div
            *cdkVirtualFor="let account of compState.adwordsAccounts.search(searchValue) | orderBy:orderBy:orderDirection; trackBy: adwordsTrackByFn"
            class="container">
            <div class="details-item head">
              {{account.id | formatAdwordsId}}
            </div>

            <div class="details-item name" [title]="account.name">
              <div class="value">
                {{account.name}}
                <span *ngIf="!account.name" class="noname" translate>ADWORDS_ACCOUNT_NO_NAME</span>
              </div>
            </div>

            <div class="details-item managed-by">
              <div class="value" [title]="account.managedByName">{{account.managedByName}}</div>
              <div class="value value-dimmed" [title]="account.managedById">{{account.managedById}}</div>
            </div>
            <ng-container
              *ngIf="getPerformanceData(account.id, compState.performanceData, account.currencyCode, account.loginCustomerId) as performance else skelleton">
              <div class="details-item costs">
                <div class="value">{{performance.costs | withCurrency}}</div>
              </div>
              <div class="details-item conversions">
                <div class="value">{{performance.conversions | allowZero}}</div>
              </div>
              <div class="details-item clicks">
                <div class="value">{{performance.clicks | allowZero}}</div>
              </div>
              <div class="details-item revenue">
                <div class="value">{{performance.revenue | withCurrency}}</div>
              </div>
            </ng-container>
            <ng-template #skelleton>
              <div class="details-item costs">
              </div>
              <div class="details-item conversions">
                <div class="value">
                  <em-spinner class="em-spinner-mini"></em-spinner>
                </div>
              </div>
              <div class="details-item clicks">
              </div>
              <div class="details-item revenue"></div>
            </ng-template>

            <div class="details-item currency">
              <div class="value">{{account.currencyCode }} ({{account.currencyCode | currencySymbol}})</div>
            </div>

            <div class="action">
              <div class="note-wrapper" *ngIf="account.jwt === false">
                <mat-icon>info</mat-icon>
                <div class="note">
                  {{'ENTERPRISE_UNAUTHORIZED_NOTE' | translate}}
                </div>
              </div>

              <button mat-flat-button
                      color="primary"
                      (click)="create(account.id, account.currencyCode, account.loginCustomerId)"
                      *ngIf="account.jwt === null">
                {{'ENTERPRISE_CREATE_BUTTON' | translate}}
              </button>

              <a mat-flat-button
                 emSingleUse
                 color="primary"
                 [href]="'/?jwt=' + account.jwt"
                 target="_blank"
                 *ngIf="account.jwt !== null && account.jwt !== false">
                <mat-icon aria-hidden="false"
                          aria-label="open_in_new icon">
                  open_in_new
                </mat-icon>
                {{'ENTERPRISE_LOGIN_BUTTON' | translate}}
              </a>
            </div>
          </div>
        </cdk-virtual-scroll-viewport>
      </div>
    </ng-container>
  </div>
</ng-container>
