<div class="options-wrapper">
  <em-icon-button
    color=""
    icon="menu"
    [matMenuTriggerFor]="aboveMenu"
  ></em-icon-button>

  <mat-menu #aboveMenu="matMenu" xPosition="before">
    <form [formGroup]="chartFormGroup" (click)="$event.stopPropagation()">
      <em-checkbox formControlName="outliers">
        {{ 'OUTLIERS_BOUNDARIES' | translate }}
      </em-checkbox>
      <em-checkbox formControlName="mostlyUsedPrice">
        {{ 'ALGO_MOSTLY_USED_PRICE' | translate }}
      </em-checkbox>
    </form>
  </mat-menu>
</div>
<ng-container *ngIf="chartData$ | async as chartData">
  <em-scatter-chart
    [series]="chartData.series"
    [config]="chartData.config"
  ></em-scatter-chart>
</ng-container>
