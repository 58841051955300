import {IGenericFilterSetting} from '@em/data-feed/data-access-products';
import {
  buildProductFilterDefinition,
  ProductFilterDefinition,
  validateProductFilter,
} from './product-filter-definition';
import {IProductFilterError} from './product-filter-error';

const filterSchema = require('./selected_filters.json');
export const PRODUCT_FILTER_DEFINITIONS = buildProductFilterDefinition(
  filterSchema.definitions.selected_filters.items.anyOf,
);

// Add additional settings not set by schema
PRODUCT_FILTER_DEFINITIONS['roas'].availableOptions[0].step = 0.01; // `roas` threshold-stepper
PRODUCT_FILTER_DEFINITIONS['source_created_at'].availableOptions[0].step = 1; // `source_created_at` threshold-stepper

export function validateAgainst(
  filterSettings: IGenericFilterSetting[],
  definitions: {[key: string]: ProductFilterDefinition},
): IProductFilterError[] {
  const errors: IProductFilterError[] = [];

  for (const filter of filterSettings) {
    const definition = definitions[filter.key];

    if (!definition) throw new Error(`Filter ${filter.key} not found`);

    errors.push(...validateProductFilter(definition, filter));
  }

  return errors;
}
