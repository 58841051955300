<div class="grid grid-cols-1 gap-3">
  <div>
    <div>{{ 'SHARED_LABEL_CHANEGD_PRICE' | translate }}:</div>
    <!-- Price Column -->
    <div *ngIf="priceColumnOption">
      <em-filter-item-option-input
        [filterOption]="priceColumnOption"
        [value]="getOptionValue(priceColumnOption.key)"
        [parentFilterKey]="parentFilterKey"
        (valueChanged)="
          setOptionValue(
            priceColumnOption.key,
            $event,
            priceColumnOption.definition.type
          )
        "
      ></em-filter-item-option-input>
    </div>
  </div>
  <div>
    <div>{{ 'SHARED_LABEL_NUMBER_OF_CHANGES' | translate }}:</div>
    <div class="grid grid-cols-2 gap-1">
      <!-- Operator -->
      <div *ngIf="operatorOption">
        <em-filter-item-option-input
          [filterOption]="operatorOption"
          [value]="getOptionValue(operatorOption.key)"
          [parentFilterKey]="parentFilterKey"
          (valueChanged)="
            setOptionValue(
              operatorOption.key,
              $event,
              operatorOption.definition.type
            )
          "
        ></em-filter-item-option-input>
      </div>

      <!-- Value/Times -->
      <div *ngIf="valueOption">
        <em-filter-item-option-input
          [filterOption]="valueOption"
          [value]="getOptionValue(valueOption.key)"
          [parentFilterKey]="parentFilterKey"
          (valueChanged)="
            setOptionValue(valueOption.key, $event, valueOption.definition.type)
          "
        ></em-filter-item-option-input>
      </div>
    </div>
  </div>

  <!-- Insufficient fetches-->
  <div *ngIf="includeInsufficientOption">
    <em-filter-item-option-input
      [filterOption]="includeInsufficientOption"
      [value]="getOptionValue(includeInsufficientOption.key)"
      [parentFilterKey]="parentFilterKey"
      (valueChanged)="
        setOptionValue(
          includeInsufficientOption.key,
          $event,
          includeInsufficientOption.definition.type
        )
      "
    ></em-filter-item-option-input>
  </div>

  <!-- Source -->
  <div *ngIf="sourceOption">
    <div>{{ 'SHARED_LABEL_SOURCE' | translate }}:</div>
    <em-filter-item-option-input
      [filterOption]="sourceOption"
      [value]="getOptionValue(sourceOption.key)"
      [parentFilterKey]="parentFilterKey"
      (valueChanged)="
        setOptionValue(sourceOption.key, $event, sourceOption.definition.type)
      "
    ></em-filter-item-option-input>
  </div>

  <!-- Tolerance  -->
  <div *ngIf="toleranceEnabledOption">
    <em-filter-item-option-input
      [filterOption]="toleranceEnabledOption"
      [value]="getOptionValue(toleranceEnabledOption.key)"
      [parentFilterKey]="parentFilterKey"
      (valueChanged)="
        setOptionValue(
          toleranceEnabledOption.key,
          $event,
          toleranceEnabledOption.definition.type
        )
      "
    ></em-filter-item-option-input>
  </div>

  <div
    *ngIf="toleranceEnabledOption && getOptionValue(toleranceEnabledOption.key)"
  >
    <div>
      {{
        'G_FILTER_PRICE_CHANGES_TOLERANCE_PERCENT_INPUT_PLACEHOLDER' | translate
      }}
    </div>

    <!-- Tolerance Percentage -->
    <div *ngIf="tolerancePercentOption">
      <em-filter-item-option-input
        [filterOption]="tolerancePercentOption"
        [value]="getOptionValue(tolerancePercentOption.key)"
        [parentFilterKey]="parentFilterKey"
        (valueChanged)="
          setOptionValue(
            tolerancePercentOption.key,
            $event,
            tolerancePercentOption.definition.type
          )
        "
      ></em-filter-item-option-input>
    </div>
  </div>
</div>
