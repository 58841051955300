import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, Input, inject} from '@angular/core';
import {
  FilterSetPreviewPercentageStore,
  IGenericFilterSetting,
} from '@em/data-feed/data-access-products';
import {BackEndSelectedFilters} from '@em/shared/api-interface';
import {EmBoxModule} from '@em/shared/ui';
import {EmPieChartModule} from '@em/shared/ui-charts';
import {isNotNullOrUndefined} from '@em/shared/util-rxjs';
import {CountryCode} from '@em/shared/util-types';
import {map} from 'rxjs';

@Component({
  selector: 'em-filter-set-preview',
  standalone: true,
  imports: [CommonModule, EmPieChartModule, EmBoxModule],
  templateUrl: './filter-set-preview.component.html',
  styleUrls: ['./filter-set-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FilterSetPreviewPercentageStore],
})
export class EmFilterSetPreviewComponent {
  @Input({required: true}) title?: string;
  @Input({required: true}) set percentageOf(
    value: number | IGenericFilterSetting[] | null,
  ) {
    if (value !== null) {
      this._previewPercentageStore.updatePercentageOf({
        selected_filters: value as BackEndSelectedFilters,
      });
    }
  }
  @Input({required: true}) set filters(value: IGenericFilterSetting[] | null) {
    if (value) {
      this._previewPercentageStore.updateFilters({
        selected_filters: value as BackEndSelectedFilters,
      });
    }
  }
  @Input({required: true}) set country(value: CountryCode | null) {
    if (value) {
      this._previewPercentageStore.updateCountry(value);
    }
  }

  private readonly _previewPercentageStore = inject(
    FilterSetPreviewPercentageStore,
  );

  readonly percentage$ = this._previewPercentageStore.percentage$.pipe(
    isNotNullOrUndefined(),
    map((v) => v * 100),
  );
  readonly isLoading$ = this._previewPercentageStore.isLoading$;
}
