import {PostReq} from '@em/shared/api-interface/lib/types/request-objects/automatic-filters/post';
import {GetResp as GetAutomaticFiltersResp} from '@em/shared/api-interface/lib/types/view-models/automatic-filters/get';
import {
  GetManagedCampaignsResp,
  PostAutomaticFiltersReq,
} from '@em/shared/api-interface';
import {v4 as uuidv4} from 'uuid';
import {
  ConnectItemType,
  ConnectLogicalOperator,
  FilterSetLink,
  IAppliedCampaign,
  IFilterSet,
  IGenericFilterSetting,
} from '../types/filtering-types';
import {ProductGroup} from '../types/product-group';
import {CountryCode} from '@em/shared/util-types';
import {ProductGroupType} from '../types/product-group-type';
import {isTemplateGroup} from '../product-groups/group-templates/group-templates';

// Map BE model to FE model
export function mapFilterSet(productGroup: ProductGroup): IFilterSet {
  const categoryFilter = productGroup.selected_filters.find(
    (f) => f.key === 'category',
  );
  const selectedCategories: string[] = categoryFilter
    ? (categoryFilter.options as {categories: string[]}).categories
    : [];
  const invertCategorySelection = (
    categoryFilter as {
      uuid: string;
      parent_uuid: string;
      key: 'category';
      options: {
        categories: string[];
        exclude_products?: boolean;
      };
    }
  )?.options.exclude_products;

  return {
    uuid: productGroup.uuid,
    name: productGroup.name,
    country: productGroup.country,
    isGoogleSPD: productGroup.is_google_spd || false,
    isPriceCrawl: productGroup.is_price_crawl,
    selectedFilters: productGroup.selected_filters
      .filter((f) => f.key !== 'category')
      .map((f) => ({
        uuid: f.uuid,
        parent_uuid: f.parent_uuid,
        key: f.key,
        options: f.options || {},
      })),
    categoryFilter: {
      inverted: invertCategorySelection,
      categories: selectedCategories,
    },
    manuallyAddedProducts: productGroup.manually_added_products,
    manuallyRemovedProducts: productGroup.manually_removed_products,
    productCount: null,
    appliedCampaigns: productGroup.campaign_configuration_uuids.map(
      (uuid: string) => ({uuid, name: ''}),
    ),
    type: productGroup.type,
    links: productGroup.links.map(
      (link: GetAutomaticFiltersResp[0]['links'][0]) => ({
        operator: link.operator as ConnectLogicalOperator,
        type: link.type as ConnectItemType,
        parent_uuid: link.parent_uuid || null,
      }),
    ),
    excludeSetId: productGroup.excluded_set_uuid,
  };
}

// Map FE model to BE model
export function buildSaveRequest(
  filterSet: IFilterSet,
  country: CountryCode,
): PostAutomaticFiltersReq {
  return {
    country,
    name: filterSet.name,
    uuid: filterSet.uuid,
    selected_filters: _buildSelectedFiltersFromGroup(
      filterSet.selectedFilters,
      filterSet.categoryFilter,
    ),
    manually_added_products: filterSet.manuallyAddedProducts,
    manually_removed_products: filterSet.manuallyRemovedProducts,
    links: filterSet.links,
    is_google_spd: filterSet.isGoogleSPD || false,
    is_price_crawl: filterSet.isPriceCrawl || false,
    excluded_set_uuid: filterSet.excludeSetId || null,
  };
}

export function buildProductGroupType(productGroup: {
  name: string;
}): ProductGroupType {
  return isTemplateGroup(productGroup.name) ? 'template' : 'custom';
}

export function findMatchingCampaigns(
  appliedCampaigns: IAppliedCampaign[],
  managedCampaigns: GetManagedCampaignsResp,
): IAppliedCampaign[] {
  // flatMap as a "better" way to implement `[anAppliedCampaign, null].filter((x) !!x) as IAppliedCampaign[];`
  return appliedCampaigns.flatMap((appliedCampaign) => {
    const campaign = managedCampaigns.find(
      (managedCampaign) =>
        appliedCampaign.uuid === managedCampaign.campaign_configuration_uuid,
    );

    if (!campaign || !campaign.platform_attributes) return [];

    return {
      uuid: campaign.campaign_configuration_uuid,
      name: campaign.platform_attributes.name,
      platform: campaign.platform,
      attributes: {
        invertedProducts:
          campaign.automatic_campaign_attributes?.invert_products,
      },
    };
  });
}

export function buildSelectedFilters(
  selectedFilters: IGenericFilterSetting[],
  categoryFilter: {
    inverted?: boolean;
    categories: string[];
  },
): PostReq['selected_filters'] {
  const filters = [...(selectedFilters as PostReq['selected_filters'])];

  if (categoryFilter.categories.length) {
    filters.push({
      key: 'category',
      options: {
        categories: categoryFilter.categories,
        exclude_products: !!categoryFilter.inverted,
      },
      uuid: uuidv4(),
      parent_uuid: uuidv4(),
    });
  }

  return filters;
}

function _buildSelectedFiltersFromGroup(
  selectedFilters: IGenericFilterSetting[],
  categoryFilter: {
    inverted?: boolean;
    categories: string[];
  },
): PostAutomaticFiltersReq['selected_filters'] {
  const filters = [
    ...selectedFilters,
  ] as PostAutomaticFiltersReq['selected_filters'];

  if (categoryFilter.categories.length) {
    filters.push({
      key: 'category',
      options: {
        categories: categoryFilter.categories,
        exclude_products: !!categoryFilter.inverted,
      },
      uuid: uuidv4(),
      parent_uuid: uuidv4(),
    });
  }

  return filters;
}

export function duplicateGroup(filterSet: IFilterSet): IFilterSet {
  const newLinks: FilterSetLink[] = [];
  const newFilters: IGenericFilterSetting[] = [];
  const oldNewLinkIds: {[key: string]: string} = {};

  // Add new links track old ids so filters can be linked correctly
  filterSet.links.forEach((oldLink) => {
    if (oldLink.parent_uuid) {
      const newUuid = uuidv4();
      oldNewLinkIds[oldLink.parent_uuid] = newUuid;
      newLinks.push({...oldLink, parent_uuid: newUuid});
    } else {
      newLinks.push({...oldLink});
    }
  });

  filterSet.selectedFilters.forEach((oldFilter) => {
    const mapOfOldLink = oldNewLinkIds[oldFilter.parent_uuid];
    if (mapOfOldLink) {
      newFilters.push({
        ...oldFilter,
        uuid: uuidv4(),
        parent_uuid: mapOfOldLink,
      });
    } else {
      newFilters.push({...oldFilter, uuid: uuidv4(), parent_uuid: uuidv4()});
    }
  });

  return {
    ...filterSet,
    uuid: undefined,
    name: 'Copy Of ' + filterSet.name,
    appliedCampaigns: [],
    links: newLinks,
    selectedFilters: newFilters,
  };
}
