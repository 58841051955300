import {GOOGLE} from '@em/shared/platforms/util';
import {PipelineSourceType} from '../types/pipeline-source-type';

export const AvailablePipelineSources: Set<{
  name: PipelineSourceType;
  logo: string;
  disabled?: boolean;
}> = new Set([
  {name: 'merchant_center', logo: GOOGLE.logos['merchantCenter']},
  {name: 'csv', logo: 'assets/images/shared/csv-file.png'},
  {
    name: 'datafeed_api',
    logo: 'assets/images/emarketing/emarketing_icon_logo.svg',
  },
]);
