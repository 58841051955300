<ng-container *ngIf="showLock$ | async">
  <em-premium-lock
    class="cursor-pointer"
    (click)="showPaywall()"
  ></em-premium-lock>
  <div
    class="paywall-overlay"
    (click)="showPaywall()"
    data-testid="overlay-div"
  ></div>
</ng-container>
