<form [formGroup]="form" *ngIf="form">
  <label>{{ 'ONBOARDING_CHANGE_COUNTRY_LABEL' | translate }}</label>

  <em-select formControlName="country">
    <em-option>--</em-option>
    <em-option *ngFor="let country of countries" [value]="country">
      <div class="option-content">
        <em-country-flag
          class="mr-4"
          [size]="'lg'"
          [countryCode]="country"
        ></em-country-flag>
        <div class="label">{{ 'COUNTRY_' + country | translate }}</div>
      </div>
    </em-option>

    <em-error *ngIf="form?.controls.country.hasError('required')">{{
      'VALIDATION_FIELD_REQUIRED' | translate
    }}</em-error>
  </em-select>
</form>
