import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
  selector: 'em-error-box',
  templateUrl: './error-box.component.html',
  styleUrls: ['./error-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmErrorBoxComponent {
  constructor() {}
}
