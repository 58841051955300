import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {SessionService} from '@em/auth/data-access';
import {LinkInternallyCommand} from '@em/shared/util-types';
import {LocationUrlService} from '@em/shared/util-configuration';
import {TokenStorageService} from '@em/auth/data-access';
import {Observable, combineLatest} from 'rxjs';
import {filter, map, shareReplay, startWith} from 'rxjs/operators';
import {IMenuItem, INavigation} from '../nav-menu-items-types';
import {NavigationFactory} from '../navigation.factory';
import {WhiteLabelService} from '@em/shared/white-label';
import {IntercomService} from '../../support/intercom.service';

@Injectable()
export class NavigationService {
  private readonly _navigation: Observable<INavigation>;

  readonly mainItemsObservable$: Observable<IMenuItem[]>;
  readonly userMenuItems$: Observable<IMenuItem[]>;
  readonly activeItem$: Observable<IMenuItem | undefined>;
  readonly logoUrl$: Observable<string>;

  constructor(
    private readonly _whiteLabelService: WhiteLabelService,
    private readonly _router: Router,
    private readonly _tokenStorage: TokenStorageService,
    private readonly _session: SessionService,
    private readonly _locationUrl: LocationUrlService,
    private readonly _intercom: IntercomService,
  ) {
    this._navigation = this._whiteLabelService.observable().pipe(
      map((theme) => ({
        mainItems: NavigationFactory.buildMainItems(),
        userMenuItems: NavigationFactory.buildUserMenuItems(),
        logoUrl: theme.logo,
      })),
      shareReplay({refCount: false, bufferSize: 1}),
    );

    const navigationEnd$ = this._router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map((event) => event as NavigationEnd),
      startWith(null),
    );

    this.mainItemsObservable$ = combineLatest([
      navigationEnd$,
      this._navigation,
    ]).pipe(map(([, navigation]) => this._setActiveItem(navigation.mainItems)));

    this.userMenuItems$ = this._navigation.pipe(
      map((navModel) => navModel.userMenuItems),
    );

    this.activeItem$ = combineLatest([
      this.mainItemsObservable$,
      this.userMenuItems$,
      this._router.events.pipe(startWith(null)),
    ]).pipe(
      map(([mainItems, userMenuItems]) =>
        this._getActiveItem(mainItems, userMenuItems),
      ),
    );

    this.logoUrl$ = this._navigation.pipe(map((topNav) => topNav.logoUrl));
  }

  isAdmin(): boolean {
    return !!this._tokenStorage.decodedToken?.admin;
  }

  logout() {
    this._intercom.clear();
    this._session.logout().subscribe(() => {
      this._locationUrl.forceChange('/auth/login');
    });
  }

  isActiveRoute(item: IMenuItem): boolean {
    if (item.activePaths?.length) {
      return item.activePaths.some((path) =>
        this._router.isActive(path, {
          paths: 'subset',
          queryParams: 'subset',
          fragment: 'ignored',
          matrixParams: 'ignored',
        }),
      );
    }

    const command = item.command;
    if (command instanceof LinkInternallyCommand) {
      return this._router.isActive(command.sectionLink, false);
    }

    return false;
  }

  private _getActiveItem(mainItems: IMenuItem[], userMenuItems: IMenuItem[]) {
    let allItems = (mainItems || []).concat(userMenuItems || []);

    if (mainItems.length) {
      mainItems.forEach(
        (item) => (allItems = allItems.concat(item.items || [])),
      );
    }
    return allItems.find((item) => this.isActiveRoute(item));
  }

  private _setActiveItem(items: IMenuItem[]) {
    const finalItems: IMenuItem[] = [];
    for (const item of items) {
      let subItemActive = false;
      const subItems: IMenuItem[] = [];

      if (item.items?.length) {
        for (const subItem of item.items) {
          const isActive = this.isActiveRoute(subItem);
          subItems.push({...subItem, isActive});
          subItemActive = subItemActive || isActive || subItemActive;
        }
        item.items = subItems;
      }

      finalItems.push({
        ...item,
        isActive: this.isActiveRoute(item) || subItemActive,
      });
    }

    return finalItems;
  }
}
