/* tslint:disable */
/**
* This file was automatically generated.
* DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
* and run the generate-code script again.
*/
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {GetActiveReq} from '../../types/request-objects/contracts/subscription/get-active';
import {GetActiveResp} from '../../types/view-models/contracts/subscription/get-active';
import {PostCancelReq} from '../../types/request-objects/contracts/subscription/post-cancel';
import {PostCancelResp} from '../../types/view-models/contracts/subscription/post-cancel';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionGateway {
  constructor(private _http: HttpClient) {}

  getActive(params: GetActiveReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<GetActiveResp> {
    const request = params as any as { [param: string]: string | string[]; };
    return this._http.get<GetActiveResp>('/payment_api/contracts/subscription/active', {...options, params: request});
  }

  postCancel(params: PostCancelReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<PostCancelResp> {
    return this._http.post<PostCancelResp>('/payment_api/contracts/subscription/cancel', params, options);
  }
}
