import {CommonModule} from '@angular/common';
import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {ProductsGateway} from '@em/shared/api-interface/lib/gateways/products.gateway';
import {PostSearchProductsOnlyReq} from '@em/shared/api-interface/lib/types/request-objects/products/post-search-products-only';
import {PostSearchProductsOnlyResp} from '@em/shared/api-interface/lib/types/view-models/products/post-search-products-only';
import {EmButtonModule, EmSearchInputComponent} from '@em/shared/ui';
import {CountryCode, ITypedControlValueAccessor} from '@em/shared/util-types';
import {TranslateModule} from '@ngx-translate/core';
import isEqual from 'lodash-es/isEqual';
import pull from 'lodash-es/pull';
import {Subject} from 'rxjs';
import {distinctUntilChanged, map, switchMap, tap} from 'rxjs/operators';
import {ProductFilterOverlayComponent} from '../product-filter-overlay/product-filter-overlay.component';
import {ProductItemComponent} from '../product-item/product-item.component';
import {ChangesObj} from '@em/shared/util-types';

export interface IManualProductSelectionValues {
  manuallyAdded: string[];
  manuallyRemoved: string[];
}

export type listName = 'manuallyAdded' | 'manuallyRemoved';

export class ManualSelectableProduct {
  targetList: listName | null;
  selected: boolean;
  deleted: boolean;

  constructor(
    readonly id: string,
    readonly gtin: string | undefined,
    readonly title: string,
    selected = false,
    targetList: listName | null = null,
    deleted = false,
    readonly costOfGoods: number | null | undefined = null,
  ) {
    this.selected = selected;
    this.targetList = targetList;
    this.deleted = deleted;
  }
}

@Component({
  selector: 'em-manual-product-selection',
  templateUrl: './manual-product-selection.component.html',
  styleUrls: ['./manual-product-selection.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    ProductFilterOverlayComponent,
    ProductItemComponent,
    EmButtonModule,
    EmSearchInputComponent,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ManualProductSelectionComponent),
      multi: true,
    },
  ],
})
export class ManualProductSelectionComponent
  implements
    ITypedControlValueAccessor<IManualProductSelectionValues>,
    OnInit,
    OnDestroy,
    OnChanges
{
  @Input() countryCode?: CountryCode | null;
  @Input() productLimit: number | null = null;
  @Input() costOfGoodsRequired = false;
  @Output() backClick = new EventEmitter<void>();
  availableProducts: ManualSelectableProduct[] = [];
  selectedProducts: ManualSelectableProduct[] = [];
  totalProducts: number | undefined = 0;
  disabled = false;
  currentValues: IManualProductSelectionValues = {
    manuallyAdded: [],
    manuallyRemoved: [],
  };
  private _lastSearchText?: string;
  private _lastPage = 0;
  private readonly _searchParams = new Subject<PostSearchProductsOnlyReq>();
  private readonly _productsPerPage = 200;
  isLoadingProducts = false;

  private _onChangeCallback?: (
    val: IManualProductSelectionValues | null | undefined,
  ) => void;
  private _onTouchedCallback?: () => void;

  constructor(private readonly _productsGateway: ProductsGateway) {}

  get limitReached(): boolean {
    if (this.productLimit === null) return false;

    return this.selectedProducts.length >= this.productLimit;
  }

  getShownProductCount(): string {
    if (this.totalProducts) {
      return `${this.availableProducts.length} / ${this.totalProducts}`;
    }

    return '0 / 0';
  }

  addAllFilteredToList(to: listName): void {
    this.availableProducts
      .filter((product) => !this.costOfGoodsRequired || !!product.costOfGoods)
      .filter((product) => !this.selectedProducts.includes(product))
      .forEach((product) => {
        this.onAdded(to, product);
      });
  }

  removeAllFromList(removeFrom: listName): void {
    this.productsFromList(removeFrom).forEach((item) => {
      this.remove(item);
    });
  }

  ngOnDestroy(): void {
    this._searchParams.complete();
  }

  ngOnChanges(
    changes: ChangesObj<ManualProductSelectionComponent, 'countryCode'>,
  ) {
    if (changes.countryCode) this._loadProducts();
  }

  ngOnInit() {
    this._searchParams
      .pipe(
        distinctUntilChanged(isEqual),
        tap(() => (this.isLoadingProducts = true)),
        switchMap((params) =>
          this._productsGateway.postSearchProductsOnly(params),
        ),
        map((response) => this._buildSelectableProducts(response)),
      )
      .subscribe((products) => {
        if (this._lastPage === 0) {
          this.availableProducts = products.result;
        } else {
          this.availableProducts.push(...products.result);
        }

        this.totalProducts = products.totalCount;
        this.isLoadingProducts = false;
      });

    this._loadProducts();
  }

  searchProducts(val?: string) {
    this._lastSearchText = val;
    this._loadProducts();
  }

  onAdded(targetList: listName, product: ManualSelectableProduct) {
    product.selected = true;
    product.targetList = targetList;

    this.selectedProducts.push(product);
    this.currentValues[targetList] = [
      ...this.currentValues[targetList],
      product.id,
    ];

    this.onTouched();
  }

  remove(product: ManualSelectableProduct) {
    if (!product.targetList) return;

    pull(this.selectedProducts, product);
    this.currentValues[product.targetList] = [
      ...this.currentValues[product.targetList].filter(
        (id) => id !== product.id,
      ),
    ];

    this._resetProduct(product);
    this.onTouched();
  }

  productsFromList(targetList: listName): ManualSelectableProduct[] {
    return this.selectedProducts.filter((p) => p.targetList === targetList);
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnChange(
    fn: (val: IManualProductSelectionValues | null | undefined) => void,
  ): void {
    this._onChangeCallback = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouchedCallback = fn;
  }

  writeValue(obj: IManualProductSelectionValues | null | undefined): void {
    this.currentValues = {
      manuallyAdded: obj?.manuallyAdded ?? [],
      manuallyRemoved: obj?.manuallyRemoved ?? [],
    };

    // Once the form is initialized with possible pre-selected product-ids,
    // we need to collect data for these ids and build a view model for these.
    this._updateFromPatchValue();
  }

  onTouched() {
    if (this._onChangeCallback) this._onChangeCallback(this.currentValues);
  }

  openEditFilterSet() {
    this.backClick.emit();
  }

  private _resetProduct(product: ManualSelectableProduct) {
    product.selected = false;
    product.targetList = null;
  }

  private _buildSelectableProducts(response: PostSearchProductsOnlyResp) {
    return {
      result: response.result.map((p) => this._findOrBuildEntity(p, [])),
      totalCount: response.total_count,
    };
  }

  private _findOrBuildEntity(
    product: PostSearchProductsOnlyResp['result'][0],
    deletedOfferIds: string[],
  ): ManualSelectableProduct {
    const isDeleted = deletedOfferIds.includes(product.id);
    const preSelectedListName = this._preDefinedListName(product.id);

    return (
      this.selectedProducts.find((p) => p.id === product.id) ||
      this.availableProducts.find((p) => p.id === product.id) ||
      new ManualSelectableProduct(
        product.id,
        product.gtin || undefined,
        product.title || '',
        !!preSelectedListName,
        preSelectedListName,
        isDeleted,
        product.cost_of_goods_sold,
      )
    );
  }

  private _preDefinedListName(id: string): listName | null {
    return this.currentValues.manuallyAdded.includes(id)
      ? 'manuallyAdded'
      : this.currentValues.manuallyRemoved.includes(id)
      ? 'manuallyRemoved'
      : null;
  }

  private _clearProducts() {
    this.availableProducts = [];
    this.selectedProducts = [];
    this.currentValues = {
      manuallyAdded: [],
      manuallyRemoved: [],
    };
    this.totalProducts = 0;
  }

  private _updateFromPatchValue() {
    if (!this.countryCode) {
      return;
    }
    const offerIds = [
      ...this.currentValues.manuallyAdded,
      ...this.currentValues.manuallyRemoved,
    ];

    this._productsGateway
      .postSearchProductsOnly({
        count:
          this.currentValues.manuallyAdded.length +
          this.currentValues.manuallyRemoved.length,
        page: 0,
        country: this.countryCode,
        ids: offerIds,
        include_cost_of_goods: this.costOfGoodsRequired,
      })
      .subscribe(({result}) => {
        const availableOfferIds = result.map(({id}) => id);
        const deletedOfferIds = offerIds.filter(
          (selected) => !availableOfferIds.includes(selected),
        );
        const deletedProducts: Array<PostSearchProductsOnlyResp['result'][0]> =
          deletedOfferIds.map((id) => ({
            id,
            title: '',
            gtin: null,
          }));
        this.selectedProducts = [...result, ...deletedProducts].map((p) =>
          this._findOrBuildEntity(p, deletedOfferIds),
        );
      });
  }

  loadMoreProducts() {
    this._loadProducts(this._lastPage + 1);
  }

  private _loadProducts(page: number = 0) {
    this._lastPage = page;
    if (!this.countryCode) {
      this._clearProducts();
    } else {
      this._searchParams.next({
        count: this._productsPerPage,
        page: this._lastPage,
        country: this.countryCode,
        search: this._lastSearchText,
        include_cost_of_goods: this.costOfGoodsRequired,
      });
    }
  }
}
