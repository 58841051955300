<div class="content">
  <img src="assets/images/shared/app-error.svg" />
  <div class="mat-headline text-titleColor font-medium">
    <span [translate]="titleKey() | async"></span>
  </div>
  <div [translate]="descriptionKey() | async"></div>
  <div class="debug-values">
    <p *ngIf="merchantUuid() | async as merchantUuid">
      UUID: {{ merchantUuid }}
    </p>
  </div>

  <div class="actions">
    <em-button
      class="mr-4"
      type="secondary"
      color="basic"
      (btnClick)="getSupport()"
    >
      <span translate>APP_ERROR_GET_SUPPORT</span>
    </em-button>

    <a href="/?_reset_">
      <em-button type="secondary">
        <span translate>APP_ERROR_BUTTON_RESTORE</span>
      </em-button>
    </a>
  </div>
  <div class="hidden" (click)="throwError()">*</div>
</div>
