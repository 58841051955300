<em-icon class="error-icon" name="warning_amber"></em-icon>
<div>
  <div class="error-title"><ng-content select="[title]"></ng-content></div>
  <div class="error-description">
    <ng-content select="[description]"></ng-content>
  </div>
</div>

<div class="ml-auto flex-shrink-0">
  <ng-content select="[actions]"></ng-content>
</div>
