/* tslint:disable */
/**
* This file was automatically generated.
* DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
* and run the generate-code script again.
*/
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {GetReq as GetManagedCampaignsReq} from '../types/request-objects/managed-campaigns/get';
import {GetResp as GetManagedCampaignsResp} from '../types/view-models/managed-campaigns/get';
import {GetPerformanceReq} from '../types/request-objects/managed-campaigns/get-performance';
import {GetPerformanceResp} from '../types/view-models/managed-campaigns/get-performance';
import {GetSuggestedCpcReq} from '../types/request-objects/managed-campaigns/get-suggested-cpc';
import {PostAutomaticCampaignsReq} from '../types/request-objects/managed-campaigns/post-automatic-campaigns';
import {PostAutomaticCampaignsResp} from '../types/view-models/managed-campaigns/post-automatic-campaigns';

@Injectable({
  providedIn: 'root',
})
export class ManagedCampaignsGateway {
  constructor(private _http: HttpClient) {}

  getManagedCampaigns(params: GetManagedCampaignsReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<GetManagedCampaignsResp> {
    const request = params as any as { [param: string]: string | string[]; };
    return this._http.get<GetManagedCampaignsResp>('/emarketing_api/managed_campaigns/', {...options, params: request});
  }

  getPerformance(params: GetPerformanceReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<GetPerformanceResp> {
    const request = params as any as { [param: string]: string | string[]; };
    return this._http.get<GetPerformanceResp>('/emarketing_api/managed_campaigns/performance', {...options, params: request});
  }

  getSuggestedCpc(params: GetSuggestedCpcReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<undefined> {
    const request = params as any as { [param: string]: string | string[]; };
    return this._http.get<undefined>('/emarketing_api/managed_campaigns/suggested_cpc', {...options, params: request});
  }

  postAutomaticCampaigns(params: PostAutomaticCampaignsReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<PostAutomaticCampaignsResp> {
    return this._http.post<PostAutomaticCampaignsResp>('/emarketing_api/managed_campaigns/automatic_campaigns', params, options);
  }
}
