import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {FormGroup, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatBadgeModule} from '@angular/material/badge';
import {MatIconModule} from '@angular/material/icon';
import {
  EditProductGroupStore,
  FilterGroup,
  FilterGroupItem,
} from '@em/data-feed/data-access-products';
import {EmMoreInfoModule} from '@em/shared/ui';
import {TranslateModule} from '@ngx-translate/core';
import {Observable, map, of} from 'rxjs';
import {DropdownFilterItemOptionsComponent} from '../dropdown-filter-item-options/dropdown-filter-item-options.component';
import {FilterItemOptionsComponent} from '../filter-item-options/filter-item-options.component';
import {ModePriceOptionsComponent} from '../mode-price-options/mode-price-options.component';
import {NestedToggleOptionsComponent} from '../nested-toggle-options/nested-toggle-options.component';
import {
  ProductFilterDefinition,
  getFilterGroupI18nKey,
} from '../product-filter-definition';
import {WithCompetitorsFilterItemOptionsComponent} from '../with-competitors-filter-item-options/with-competitors-filter-item-options.component';
import {GooglePricesOptionsComponent} from '../google-prices-options/google-prices-options.component';
import {PriceChangesFilterItemOptionsComponent} from '../price-changes-filter-item-options/price-changes-filter-item-options.component';
import {EmFetchStatusFilterItemOptionsComponent} from '../fetch-status-filter-item-options/fetch-status-filter-item-options.component';

@Component({
  selector: 'em-filter-item',
  templateUrl: './filter-item.component.html',
  styleUrls: ['./filter-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MatIconModule,
    MatBadgeModule,
    NestedToggleOptionsComponent,
    FilterItemOptionsComponent,
    DropdownFilterItemOptionsComponent,
    WithCompetitorsFilterItemOptionsComponent,
    GooglePricesOptionsComponent,
    ModePriceOptionsComponent,
    PriceChangesFilterItemOptionsComponent,
    EmFetchStatusFilterItemOptionsComponent,
    EmMoreInfoModule,
  ],
})
export class FilterItemComponent implements OnInit {
  count$: Observable<number> = of(0);

  @Input() filter?: ProductFilterDefinition;
  @Input() action?: string;
  @Input() form?: FormGroup;
  @Output() actionTriggered = new EventEmitter<void>();

  @HostBinding('class.cursor-pointer') get c1() {
    return this.action !== 'delete';
  }
  getFilterGroupI18nKey = getFilterGroupI18nKey;

  get filterKey(): string {
    return this.form?.value.key || this.filter?.key || '';
  }
  constructor(private readonly _editGroupStore: EditProductGroupStore) {}

  triggerAction(): void {
    this.actionTriggered.emit();
  }

  ngOnInit(): void {
    if (this.filter) {
      this.count$ = this._editGroupStore.group$.pipe(
        map(
          (group) =>
            group.selectedFilters
              .map((filter) =>
                (filter as FilterGroup).items
                  ? (filter as FilterGroup).items
                  : filter,
              )
              .flatten()
              .filter(
                (filter: FilterGroupItem) => filter.key === this.filter?.key,
              )
              .toList().size,
        ),
      );
    }
  }
}
