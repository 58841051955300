import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {ProductGroup} from '@em/data-feed/data-access-products';
import {
  EmAddNewItemCardComponent,
  EmFabButtonComponent,
  EmSelectModule,
} from '@em/shared/ui';
import {TranslateModule} from '@ngx-translate/core';
import {EmProductGroupNamePipe} from '../../pipes/product-group-name/product-group-name.pipe';
import {EmProductGroupCardComponent} from '../product-group-card/product-group-card.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'em-select-product-group',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MatTooltipModule,
    EmSelectModule,
    EmProductGroupNamePipe,
    EmProductGroupCardComponent,
    EmFabButtonComponent,
    EmAddNewItemCardComponent,
  ],
  templateUrl: './select-product-group.component.html',
  styleUrls: ['./select-product-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmSelectProductGroupComponent {
  @Input({required: true}) groups?: ProductGroup[] | null;
  @Input() set value(value: string | null | undefined) {
    this.formControl.setValue(value || null);
  }
  @Input() showPreview? = false;
  @Input() showAddNew? = false;
  @Input() set required(isRequierd: boolean) {
    this.isRequired = isRequierd;
    this.formControl.setValidators(isRequierd ? [Validators.required] : []);
    this.formControl.updateValueAndValidity();
  }

  @Output() groupChanged = new EventEmitter<string>();
  @Output() groupEdited = new EventEmitter<string>();
  @Output() addNewClick = new EventEmitter<void>();

  protected isRequired = false;
  formControl = new FormControl('');

  get selectedGroup(): ProductGroup | undefined {
    return this.groups?.find((g) => g.uuid === this.formControl.value);
  }
}
