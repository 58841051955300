/**
 * Based on a given ratio (something around 0.6 - golden ratio), calculates
 * a series of widths which follow a roughly geometric scale (monotonic-decreasing).
 */
export class GeometricScaler {
  private readonly ratio: number;

  constructor(ratio: number) {
    this.ratio = ratio;
  }

  /**
   * Get width for x'th position, given total n position
   * @param x
   * @param n
   */
  get(x: number, n: number): number {
    if (x >= n) {
      return 100;
    }

    const factor = Math.pow(this.ratio, n - x);
    let norm = 1;
    for (let i = 1; i < n; i++) {
      norm += Math.pow(this.ratio, i);
    }
    norm = norm * (1.0 - this.ratio);

    // Scale to 100% and "cast" to integer
    // eslint-disable-next-line no-bitwise
    return ((factor / norm) * 100) | 0;
  }
}
