import {Component, Input, TemplateRef, ViewChild} from '@angular/core';

@Component({
  selector: 'em-setup-step',
  templateUrl: './setup-step.component.html',
  styleUrls: ['./setup-step.component.scss'],
})
export class EmSetupStepComponent {
  @Input() icon?: string;
  @Input() title?: string;
  @Input() description?: string;
  @Input() completed?: boolean | null;
  @Input() disabled?: boolean | null;

  @ViewChild('templateRef') templateRef:
    | TemplateRef<EmSetupStepComponent>
    | undefined;
  constructor() {}
}
