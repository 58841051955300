import {CommonModule} from '@angular/common';
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {EmAddNewItemCardComponent} from '@em/shared/ui';
import {TranslateModule} from '@ngx-translate/core';
import {EmProductGroupCardComponent} from '../product-group-card/product-group-card.component';
import {ProductGroup} from '@em/data-feed/data-access-products';

@Component({
  selector: 'em-product-groups-list',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    EmProductGroupCardComponent,
    EmAddNewItemCardComponent,
  ],
  templateUrl: './product-groups-list.component.html',
  styleUrls: ['./product-groups-list.component.scss'],
})
export class EmProductGroupsListComponent {
  @Input() groups: ProductGroup[] | undefined | null;
  @Input() allowAddNew = true;
  @Input() allowDelete = true;
  @Input() compactView = false;
  @Output() addNew = new EventEmitter<void>();

  addNewGroup() {
    this.addNew.emit();
  }

  trackByUuid(index: number, group: ProductGroup) {
    return group.uuid;
  }
}
