import {Action} from '@ngrx/store';
import {CurrencyCode} from '@em/shared/util-types';
import {IAdwordsAccount, IPerformanceData} from './enterprise.reducers';

export enum ActionTypes {
  LOAD_GOOGLE_SDK = '[Enterprise] Load Google SDK',
  SDK_READY = '[Enterprise] Google SDK Ready',

  OAUTH_SIGN_IN = '[Enterprise] Google Oauth Sign In',
  OAUTH_SIGN_IN_SUCCESS = '[Enterprise] Google Oauth Sign In Success',
  OAUTH_SIGN_IN_FAILED = '[Enterprise] Google Oauth Sign In Failed',

  AUTH_STARTED = '[Enterprise] Google OAuth Started',
  AUTH_COMPLETED = '[Enterprise] Google OAuth Completed',
  AUTH_FAILED = '[Enterprise] Google OAuth Failed',

  LOAD_ADWORDS_ACCOUNTS = '[Enterprise] Load Accounts',
  LOAD_ADWORDS_ACCOUNTS_SUCCESS = '[Enterprise] Load Accounts Success',

  OPEN_ACCOUNT_CREATION_FLOW = '[Enterprise] Open Account Creation Flow',
  ACCOUNT_CREATION_SUCCESS = '[Enterprise] Account Creation Success',
  ACCOUNT_CREATION_FAILED = '[Enterprise] Account Creation Failed',

  LOAD_PERFORMANCE_DATA = '[Enterprise] Load Performance Data',
  LOAD_PERFORMANCE_DATA_SUCCESS = '[Enterprise] Load Performance Data Success',

  NOOP = '[Enterprise] Do Nothing - No Token To Restore',

  DELETE_OAUTH_TOKEN = '[Enterprise] Delete Oauth Token',
}

export class LoadGoogleSdk implements Action {
  readonly type = ActionTypes.LOAD_GOOGLE_SDK;

  constructor() {}
}

export class SdkReady implements Action {
  readonly type = ActionTypes.SDK_READY;

  constructor() {}
}

export class OauthSignIn implements Action {
  readonly type = ActionTypes.OAUTH_SIGN_IN;

  constructor() {}
}

export class OauthSignInSuccess implements Action {
  readonly type = ActionTypes.OAUTH_SIGN_IN_SUCCESS;

  constructor(readonly payload: {accessToken: string}) {}
}

export class OauthSignInFailed implements Action {
  readonly type = ActionTypes.OAUTH_SIGN_IN_FAILED;

  constructor() {}
}

export class OauthStarted implements Action {
  readonly type = ActionTypes.AUTH_STARTED;

  constructor() {}
}

export class AuthCompleted implements Action {
  readonly type = ActionTypes.AUTH_COMPLETED;

  constructor(readonly payload: {code: string}) {}
}

export class AuthFailed implements Action {
  readonly type = ActionTypes.AUTH_FAILED;

  constructor(readonly payload: {error?: string}) {}
}

export class LoadAdwordsAccounts implements Action {
  readonly type = ActionTypes.LOAD_ADWORDS_ACCOUNTS;

  constructor(readonly payload: {accessToken: string}) {}
}

export class LoadAdwordsAccountsSuccess implements Action {
  readonly type = ActionTypes.LOAD_ADWORDS_ACCOUNTS_SUCCESS;

  constructor(
    readonly payload: {
      adwordsAccounts: IAdwordsAccount[];
      hasOauthToken: boolean;
    },
  ) {}
}

export class OpenAccountCreationFlow implements Action {
  readonly type = ActionTypes.OPEN_ACCOUNT_CREATION_FLOW;

  constructor(
    readonly payload: {
      adwordsId: number;
      currencyCode: CurrencyCode;
      loginCustomerId: number;
    },
  ) {}
}

export class AccountCreationSuccess implements Action {
  readonly type = ActionTypes.ACCOUNT_CREATION_SUCCESS;

  constructor(readonly payload: {jwt: string; adwordsId: number}) {}
}

export class AccountCreationFailed implements Action {
  readonly type = ActionTypes.ACCOUNT_CREATION_FAILED;

  constructor() {}
}

export class LoadPerformanceData implements Action {
  readonly type = ActionTypes.LOAD_PERFORMANCE_DATA;

  constructor(readonly payload: {adwordsId: number; loginCustomerId: number}) {}
}

export class LoadPerformanceDataSuccess implements Action {
  readonly type = ActionTypes.LOAD_PERFORMANCE_DATA_SUCCESS;

  constructor(
    readonly payload: {adwordsId: number; performanceData: IPerformanceData},
  ) {}
}

export class Noop implements Action {
  readonly type = ActionTypes.NOOP;

  constructor() {}
}

export class DeleteOauthToken implements Action {
  readonly type = ActionTypes.DELETE_OAUTH_TOKEN;

  constructor() {}
}

export type EnterpriseActions =
  | LoadGoogleSdk
  | SdkReady
  | OauthSignIn
  | OauthSignInSuccess
  | OauthSignInFailed
  | LoadAdwordsAccounts
  | LoadAdwordsAccountsSuccess
  | OpenAccountCreationFlow
  | OauthStarted
  | AuthCompleted
  | AuthFailed
  | AccountCreationSuccess
  | AccountCreationFailed
  | LoadPerformanceData
  | LoadPerformanceDataSuccess
  | Noop
  | DeleteOauthToken;
