import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MATERIAL_SANITY_CHECKS} from '@angular/material/core';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {EmButtonModule} from '../button/button.module';
import {EmMessageOnErrorComponent} from './message-on-error.component';

@NgModule({
  imports: [CommonModule, MatIconModule, EmButtonModule, TranslateModule],
  declarations: [EmMessageOnErrorComponent],
  exports: [EmMessageOnErrorComponent],
  providers: [{provide: MATERIAL_SANITY_CHECKS, useValue: false}],
})
export class EmMessageOnErrorModule {}
