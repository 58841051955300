import {configurationHelper} from '@em/shared/util-configuration';
import {ITheme} from '../../theme';

export const EMARKETING_THEME: ITheme = {
  loginLogo: 'assets/images/emarketing/logo-login.svg',
  logo: 'assets/images/emarketing/logo-dark.svg',
  url: configurationHelper.getConfig('WHITELABEL_URL_EMARKETING'),
  favicons: '/assets/favicons/emarketing/index.json',
  themeClass: 'emarketing-theme',
  title: 'emarketing',
  displayName: 'emarketing',
  isPremiumPartner: true,
};
