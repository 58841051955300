import {ProductsListSortingCols} from '@em/shared/api-interface';

export const SORTABLE_COLUMNS: ProductsListSortingCols[] = [
  'id',
  'gtin',
  'title',
  'description',
  'price',
  'shipping',
  'shipping_service',
  'current_price',
  'full_price',
  'sale_price',
  'sale_price_start_date',
  'sale_price_end_date',
  'cost_of_goods_sold',
  'target_country',
  'content_language',
  'mpn',
  'brand',
  'link',
  'image_link',
  'mobile_link',
  'availability',
  'stock_quantity',
  'size',
  'condition',
  'gender',
  'age_group',
  'adult',
  'material',
  'color',
  'pattern',
  'google_product_category',
  'product_type',
  'parent_id',
  'energy_efficiency_class',
  'is_bundle',
  'multipack',
  'promotion_id',
  'custom_label0',
  'custom_label1',
  'custom_label2',
  'custom_label3',
  'custom_label4',
  'source_created_at',
  'ad_spend',
  'avg_cpc',
  'clicks',
  'conversion_rate',
  'conversions',
  'suggested_price',
  'predicted_impressions_change_fraction',
  'predicted_clicks_change_fraction',
  'predicted_conversions_change_fraction',
  'benchmark_price',
  'cpo',
  'ctr',
  'impressions',
  'ad_cost_ratio',
  'net_revenue',
  'bing_ad_spend',
  'bing_avg_cpc',
  'bing_clicks',
  'bing_conversion_rate',
  'bing_conversions',
  'bing_cpo',
  'bing_ctr',
  'bing_impressions',
  'bing_net_revenue',
  'min_price',
  'min_price_full',
  'min_shipping_costs',
  'min_price_competitor',
  'diff_price_full',
  'diff_shipping_costs',
  'conversion_value',
  'diff_suggested_price',
  'diff_suggested_price_percentage',
  'revenue_increase',
  'additional_ad_spend',
  'increase_net_revenue',
  'fetch_status',
];
