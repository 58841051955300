import {NotificationKey, NotificationRespType} from '@em/shared/api-interface';
import {NotificationModel} from '../notification.model';
import {IPusherNotification} from '../pusher-service/pusher.service';

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type payload = {sample_category: string; category_count: number};

export class CategoryFetchNotification extends NotificationModel {
  static key: NotificationKey =
    'Datafeed::Domain::Notifications::CategoriesFetched';
  readonly categoryCount: number;
  readonly sampleCategory: string;

  constructor(
    createdAt: Date,
    workflowUuid: string,
    categoryCount: number,
    sampleCategory: string,
  ) {
    super(createdAt, CategoryFetchNotification.key, workflowUuid);
    this.sampleCategory = sampleCategory;
    this.categoryCount = categoryCount;
  }

  static categoryFetchFromPusher(
    resp: IPusherNotification,
  ): CategoryFetchNotification {
    return new CategoryFetchNotification(
      resp['created_at'] ? new Date(resp['created_at'] as string) : new Date(),
      resp['workflow_uuid'],
      Number.parseInt(resp['category_count'] as string, 10),
      resp['sample_category'] as string,
    );
  }

  static categoryFetchFromResp(
    resp: NotificationRespType,
  ): CategoryFetchNotification {
    return new CategoryFetchNotification(
      resp.created_at ? new Date(resp.created_at as string) : new Date(),
      resp.workflow_uuid,
      (resp.payload as payload).category_count,
      (resp.payload as payload).sample_category,
    );
  }
}
