import {
  PriceCrawl,
  PriceCrawlScheduleFrequency,
} from '@em/subscription/api-interface';

export function getHigherFrequency(
  crawlJobs: PriceCrawl[],
): PriceCrawlScheduleFrequency | undefined {
  const freqList = crawlJobs?.map((c) => c.schedule.type);

  if (freqList?.includes('custom')) {
    return 'custom';
  } else if (freqList?.includes('monthly')) {
    return 'monthly';
  } else if (freqList?.includes('biweekly')) {
    return 'biweekly';
  } else if (freqList?.includes('weekly')) {
    return 'weekly';
  } else if (freqList?.includes('daily')) {
    return 'daily';
  }
  return undefined;
}
