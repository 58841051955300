import {DOCUMENT} from '@angular/common';
import {
  ApplicationRef,
  Component,
  Inject,
  NgZone,
  OnInit,
  Optional,
} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {
  SessionService,
  hideAppBlocker,
  showAppBlocker,
  validateJwt,
  selectAppBlockerActive,
} from '@em/auth/data-access';
import {FetchProductsStore} from '@em/data-feed/data-access-products';
import {
  AVAILABLE_LANGUAGES,
  isIndependentRoute,
} from '@em/shared/util-configuration';
import {WINDOW} from '@em/shared/util-web';
import {
  LanguageCode,
  IInitializable,
  INITIALIZABLE,
} from '@em/shared/util-types';
import {registerIconsWith} from '@em/shared/ui';
import {
  loadOnboardingData,
  RedirectAfterOnboardingService,
  ChallengeService,
} from '@em/onboarding/data-access';
import {SetupStatusService} from '@em/auth/data-access';
import {
  configurationHelper,
  LoggerService,
} from '@em/shared/util-configuration';
import {setLocaleByName} from '@em/shared/util-date-time';
import {
  ContractService,
  RepricingProductsCountStore,
} from '@em/subscription/data-access';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {GoogleTagManagerService} from 'angular-google-tag-manager';
import {forkJoin} from 'rxjs';
import {map, switchMap, take, tap} from 'rxjs/operators';
import {LanguageService} from '@em/user-account/data-access';

@Component({
  selector: 'em-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private readonly _store: Store,
    private readonly _appref: ApplicationRef,
    private readonly _translate: TranslateService,
    private readonly _session: SessionService,
    private readonly _setupStatusService: SetupStatusService,
    private readonly _challengeService: ChallengeService,
    private readonly _language: LanguageService,
    private readonly _matIconRegistry: MatIconRegistry,
    private readonly _domSanitizer: DomSanitizer,
    private readonly _logger: LoggerService,
    private readonly _ngZone: NgZone,
    private readonly _gtmService: GoogleTagManagerService,
    private readonly _redirectService: RedirectAfterOnboardingService,
    private readonly _contractService: ContractService,
    private readonly _fetchProductStore: FetchProductsStore,
    private readonly _repricingProductsCount: RepricingProductsCountStore,
    @Inject(DOCUMENT) private readonly _document: Document,
    @Inject(WINDOW) private readonly _window: Window,
    @Inject(INITIALIZABLE)
    @Optional()
    private readonly _initializables?: IInitializable[],
  ) {
    if (configurationHelper.getConfig('GOOGLE_TAG_MANAGER_ID')) {
      this._gtmService.addGtmToDom();
    }

    this._translate.addLangs(AVAILABLE_LANGUAGES);

    // Fallback language for when a translation isn't found in the current language.
    this._translate.setDefaultLang('en');

    // check for users browserLanguage, fallback to english if not included in AVAILABLE_LANGUAGES
    this._translate.use(this._getUserLanguage());

    this._language
      .observable()
      .pipe(
        switchMap((languageModel) => {
          const finalLang = AVAILABLE_LANGUAGES.includes(languageModel.code)
            ? languageModel.code
            : 'en';

          // configure the date-time library locale depending on the language selected
          setLocaleByName(finalLang);
          return this._translate.use(finalLang);
        }),
      )
      .subscribe();

    registerIconsWith(this._matIconRegistry, this._domSanitizer);

    this._appref.isStable
      .pipe(take(1))
      .subscribe(() =>
        this._ngZone.run(() => this._store.dispatch(validateJwt())),
      );

    this._initializeServices();

    this._session.observable().subscribe(() => {
      if (isIndependentRoute(this._window.location.pathname)) return;

      this._store.dispatch(loadOnboardingData());
    });

    // on first session ready invalidate setupstatus
    this._session
      .observable()
      .pipe(take(1))
      .subscribe(() => {
        this._setupStatusService.invalidateSetupStatus();
      });

    // trigger challenge check on sessione ready (after user login)
    this._session.sessionReady
      .pipe(
        take(1),
        tap(() => this._store.dispatch(showAppBlocker())),
        switchMap(() => this._challengeService.processChallenges(false)),
        take(1),
        tap(() => {
          this._store.dispatch(hideAppBlocker());
        }),
        switchMap((processResult) =>
          this._redirectService.redirect(processResult),
        ),
      )
      .subscribe();

    // Load Subscription Contract on user login
    this._contractService.loadContract(
      this._session.sessionReady.pipe(
        take(1),
        map(() => undefined),
      ),
    );

    // Check and monitor last product fetch after user login
    this._fetchProductStore.loadLastFetches(
      this._session.sessionReady.pipe(
        take(1),
        map(() => undefined),
      ),
    );

    // Load all repricing products count
    this._repricingProductsCount.loadProductsCount(
      this._session.sessionReady.pipe(
        take(1),
        map(() => undefined),
      ),
    );
  }

  navigationAvailable(): boolean {
    return (
      !isIndependentRoute(this._window.location.pathname) &&
      this._session.isLoggedIn()
    );
  }

  ngOnInit() {
    this._store
      .select(selectAppBlockerActive)
      .pipe(
        tap((isActive) =>
          isActive ? this._showLoading() : this._hideLoading(),
        ),
      )
      .subscribe();
  }

  private _initializeServices() {
    if (this._initializables) {
      // Start all initializations
      const initializeObservables = this._initializables.map((x) => {
        this._logger.debug(`Starting initialization of ${x.name}`);

        return x
          .initialize()
          .pipe(tap(() => this._logger.debug(`Initialized ${x.name}`)));
      });

      // Wait for all to complete
      forkJoin(initializeObservables).subscribe(() => {
        this._logger.debug('All services initialized');
      });
    }
  }

  private _getUserLanguage(): string {
    const browserLanguage = this._translate.getBrowserLang();
    const isAvailableLanguage = AVAILABLE_LANGUAGES.includes(
      browserLanguage as LanguageCode,
    );

    return isAvailableLanguage ? browserLanguage || 'en' : 'en';
  }

  private _showLoading(): void {
    const splash = this._document.getElementsByClassName('splash').item(0);
    if (!splash) return;

    splash.classList.remove('hide-splash', 'hidden');
  }

  private _hideLoading(): void {
    const splash = this._document.getElementsByClassName('splash').item(0);
    if (!splash) return;

    setTimeout(() => {
      splash.classList.add('hidden');
      splash.classList.add('hide-splash');
    }, 1000);
  }
}
