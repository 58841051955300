import {Injectable, inject} from '@angular/core';
import {Router} from '@angular/router';
import {APP_ROUTES} from '@em/shared/util-configuration';
import {SessionService} from '../../services/session/session.service';

/**
 * Checks if a user is logged in. If not, redirects to login.
 */
@Injectable({
  providedIn: 'root',
})
export class ValidSessionGuard {
  private readonly _appRouts = inject(APP_ROUTES);

  constructor(
    private readonly _session: SessionService,
    private readonly _router: Router,
  ) {}

  canActivate(): boolean {
    // TODO: Use `session.isJwtValid()`
    if (this._session.isLoggedIn()) return true;

    this._router.navigateByUrl(this._appRouts.login);
    return false;
  }
}
