import {environment} from '../../environments/environment';

export const providePusherAuthEndpoint = (): string => {
  const emarketingApiSettings = environment.apiDomainSettings.find(
    (settings) => settings.name === 'emarketing',
  );

  if (!emarketingApiSettings) {
    return '';
  } else {
    return (
      emarketingApiSettings.serviceDomain +
      '/emarketing_api/notifications/authorize_channel'
    );
  }
};
