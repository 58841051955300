import {Inject, Injectable} from '@angular/core';
import {WINDOW} from '@em/shared/util-web';
import {APP_ROUTES, IAppRoutes} from '../app-routes';

@Injectable({
  providedIn: 'root',
})
export class LocationUrlService {
  private _changeInProgress = false;

  constructor(
    @Inject(WINDOW) private readonly _window: Window,
    @Inject(APP_ROUTES) private readonly _appRoutes: IAppRoutes,
  ) {}

  forceChange(target: string) {
    if (this._changeInProgress) return;
    this._changeInProgress = true;
    this._window.location.href = target;
  }

  restartWithParam(key: string, value: string | undefined, samePath?: boolean) {
    if (this._changeInProgress) return;
    this._changeInProgress = true;
    const params = new URLSearchParams(this._window.location.search);
    params.delete(key);
    if (value) {
      params.append(key, value);
    }

    const url = new URL(this._window.location.toString());
    if (!samePath) url.pathname = this._appRoutes.home;
    url.search = params.toString();
    this._window.location.assign(url.toString());
  }

  current() {
    return this._window.location.toString();
  }

  removeParams() {
    const url = new URL(this._window.location.href);
    url.search = '';
    this._window.history.pushState('', '', url.toString());
  }
}
