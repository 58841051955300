<div class="px-8 py-6">
  <em-icon-button
    class="close-btn"
    [icon]="'close'"
    [color]="''"
    (btnClick)="close()"
  ></em-icon-button>
  <em-stripe-payment
    *ngIf="data.contract"
    [contract]="data.contract"
  ></em-stripe-payment>
  <em-stripe-one-time-payment
    *ngIf="data.oneTimeFetch"
    [oneTimeFetch]="data.oneTimeFetch"
  ></em-stripe-one-time-payment>
</div>
