import {CommonModule} from '@angular/common';
import {Component, forwardRef} from '@angular/core';
import {
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {TranslateModule} from '@ngx-translate/core';
import {DropdownFilterItemOptionsComponent} from '../dropdown-filter-item-options/dropdown-filter-item-options.component';
import {IProductFilterDefinition} from '../product-filter-definition';

@Component({
  selector: 'em-with-competitors-options',
  templateUrl: './with-competitors-filter-item-options.component.html',
  styleUrls: ['./with-competitors-filter-item-options.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatSlideToggleModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WithCompetitorsFilterItemOptionsComponent),
      multi: true,
    },
  ],
})
export class WithCompetitorsFilterItemOptionsComponent extends DropdownFilterItemOptionsComponent {
  override _generateViewModel(definition: IProductFilterDefinition) {
    // reorder the options to put the source at the end
    const beforeSort = super._generateViewModel(definition);
    return [
      ...beforeSort.filter((o) => o.key !== 'source'),
      ...beforeSort.filter((o) => o.key === 'source'),
    ];
  }
}
