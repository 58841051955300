import {ProductsListSorting} from '@em/shared/api-interface';
import {SortModelItem} from 'ag-grid-community';
import {isSortableColumn} from '../is-sortable-column/is-sortable-column';

export function getSorting(
  sortModelList: SortModelItem[],
): ProductsListSorting | undefined {
  let sorting: ProductsListSorting | undefined;

  if (sortModelList.length) {
    const sortModelItem = sortModelList[0];

    if (isSortableColumn(sortModelItem.colId)) {
      sorting = {
        field_name: sortModelItem.colId,
        direction: sortModelItem.sort,
      };
    }
  }

  return sorting;
}
