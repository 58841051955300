import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MATERIAL_SANITY_CHECKS} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {EmErrorDirective} from '../../directives/error/error.directive';
import {EmErrorModule} from '../../directives/error/error.module';
import {EmFormFieldModule} from '../../directives/form-field/form-field.module';
import {EmPrefixDirective} from '../../directives/form-field/prefix.directive';
import {EmOptionGroupComponent} from '../option-group/option-group.component';
import {EmOptionGroupModule} from '../option-group/option-group.module';
import {EmOptionComponent} from '../option/option.component';
import {EmOptionModule} from '../option/option.module';
import {EmSelectTriggerDirective} from './select-trigger.directive';
import {EmSelectComponent} from './select.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    EmOptionGroupModule,
    EmOptionModule,
    EmErrorModule,
    EmFormFieldModule,
  ],
  declarations: [EmSelectComponent, EmSelectTriggerDirective],
  exports: [
    EmSelectComponent,
    EmOptionComponent,
    EmOptionGroupComponent,
    EmErrorDirective,
    EmPrefixDirective,
    EmSelectTriggerDirective,
  ],
  providers: [{provide: MATERIAL_SANITY_CHECKS, useValue: false}],
})
export class EmSelectModule {}
