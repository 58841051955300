import {Injectable} from '@angular/core';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {LanguageService} from '@em/user-account/data-access';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class MatPaginatorIntlService extends MatPaginatorIntl {
  constructor(
    private readonly _translate: TranslateService,
    private readonly _language: LanguageService,
  ) {
    super();

    _language.observable().subscribe(() => {
      this._updateTranslations();
    });
  }

  private _updateTranslations() {
    this.firstPageLabel = this._translate.instant('PAGINATION_FIRST_PAGE');
    this.lastPageLabel = this._translate.instant('PAGINATION_LAST_PAGE');
    this.itemsPerPageLabel = this._translate.instant(
      'PAGINATION_ITEMS_PER_PAGE',
    );
    this.nextPageLabel = this._translate.instant('PAGINATION_NEXT_PAGE');
    this.previousPageLabel = this._translate.instant(
      'PAGINATION_PREVIOUS_PAGE',
    );
    this.getRangeLabel = (page, pageSize, length) => {
      if (length === 0 || pageSize === 0) {
        return this._translate.instant('PAGINATION_RANGE_LABEL_EMPTY', {
          length,
        });
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex =
        startIndex < length
          ? Math.min(startIndex + pageSize, length)
          : startIndex + pageSize;
      return this._translate.instant('PAGINATION_RANGE_LABEL', {
        startIndex: startIndex + 1,
        endIndex,
        length,
      });
    };

    this.changes.next();
  }
}
