import {HttpErrorResponse} from '@angular/common/http';
import {Injectable, inject} from '@angular/core';

import {
  AddProductGroupReq,
  AutomaticFiltersGateway,
  BackEndProductGroup,
} from '@em/shared/api-interface';
import {
  ComponentStoreBase,
  ComponentStoreStateBase,
  CountryCode,
} from '@em/shared/util-types';
import {tapResponse} from '@ngrx/component-store';
import {filter, map, mergeMap, switchMap, tap} from 'rxjs/operators';

import {isNotNullOrUndefined} from '@em/shared/util-rxjs';
import {Map} from 'immutable';
import {
  EMPTY,
  Observable,
  Subject,
  combineLatest,
  isObservable,
  of,
} from 'rxjs';
import {buildProductGroupType} from '../product-groups-details/helpers';
import {ProductGroup} from '../types/product-group';
import {buildGroupFromTemplate} from './build-group-from-template/build-group-from-template';
import {
  IProductGroupTemplate,
  PRODUCTS_GROUP_TEMPLATES,
} from './group-templates/group-templates';

type errorTypes = 'loadGroups' | 'addGroup' | 'deleteGroup';

export interface ProductGroupsState
  extends ComponentStoreStateBase<errorTypes> {
  groupsByCountry: Map<CountryCode, ProductGroup[]>;
  loadingFlags: Map<CountryCode, boolean>;

  isAdding?: boolean;
}

@Injectable()
export class ProductGroupsStore extends ComponentStoreBase<
  ProductGroupsState,
  errorTypes
> {
  // Injections
  private readonly _autmaticFilterGateway = inject(AutomaticFiltersGateway);

  // Public Selectors
  readonly groupsByCountry$ = this.select((s) => s.groupsByCountry);
  readonly loadingFlags$ = this.select((s) => s.loadingFlags);

  readonly isAdding$: Observable<boolean | undefined> = this.select(
    (s) => s.isAdding,
  );
  readonly groupSaved$: Subject<string> = new Subject<string>();

  // Privates
  private readonly _updateGroupsByCountry = this.updater(
    (
      state,
      toUpdate: {country: CountryCode; groups: BackEndProductGroup[]},
    ): ProductGroupsState => ({
      ...state,
      groupsByCountry: state.groupsByCountry.set(
        toUpdate.country,
        toUpdate.groups.map(
          (g) =>
            ({
              ...g,
              country: toUpdate.country,
              type: buildProductGroupType(g),
            } as ProductGroup),
        ),
      ),
    }),
  );
  private readonly _removeGroup = this.updater(
    (state, groupId: string): ProductGroupsState => {
      // find the group
      const [country, groupList] =
        state.groupsByCountry.findEntry(
          (list) => !!list.find((g) => g.uuid === groupId),
        ) || [];

      if (country && groupList) {
        return {
          ...state,
          groupsByCountry: state.groupsByCountry.set(
            country,
            groupList?.filter((g) => g.uuid !== groupId),
          ),
        };
      } else {
        return {...state};
      }
    },
  );
  private readonly _updateLoadingFlags = this.updater(
    (
      state,
      toUpdate: {country: CountryCode; isLoading: boolean},
    ): ProductGroupsState => ({
      ...state,
      loadingFlags: state.loadingFlags.set(
        toUpdate.country,
        toUpdate.isLoading,
      ),
    }),
  );

  constructor() {
    super();

    this.setState({
      groupsByCountry: Map<CountryCode, ProductGroup[]>(),
      loadingFlags: Map<CountryCode, boolean>(),
    });
  }

  // Effects
  readonly loadGroups = this.effect<CountryCode>((country$) =>
    country$.pipe(
      mergeMap((country) => {
        this._updateLoadingFlags({country, isLoading: true});
        return this._autmaticFilterGateway
          .getAutomaticFilters({
            country,
          })
          .pipe(
            tapResponse(
              (groups) => {
                this._updateGroupsByCountry({country, groups});
                this._updateLoadingFlags({country, isLoading: false});
              },
              (error: HttpErrorResponse) => {
                this.addError({
                  httpError: error,
                  errorMessage: {
                    key: 'loadGroups',
                  },
                });
                this._updateLoadingFlags({country, isLoading: false});
              },
            ),
          );
      }),
    ),
  );

  readonly addGroup = this.effect<AddProductGroupReq>((groupToAdd$) =>
    groupToAdd$.pipe(
      mergeMap((group) => {
        this.patchState({isAdding: true});
        return this._autmaticFilterGateway.postAutomaticFilters(group).pipe(
          tapResponse(
            (addedGroup) => {
              this.patchState({
                isAdding: false,
              });
              this.loadGroups(group.country);
              this.groupSaved$.next(addedGroup.uuid);
            },
            (error: HttpErrorResponse) => {
              this.addError({
                httpError: error,
                errorMessage: {
                  key: 'addGroup',
                },
                statePayload: {isAdding: false},
              });
            },
          ),
        );
      }),
    ),
  );

  readonly addGroupByTemplateName = this.effect<{
    templateName: string;
    country: CountryCode;
  }>((templateName$) =>
    templateName$.pipe(
      // ToDo: make sure the gorups of this country is loaded
      mergeMap(({templateName, country}) => {
        const existingGroups = this.get((s) => s.groupsByCountry.get(country));

        const template = PRODUCTS_GROUP_TEMPLATES.find(
          (temp) => temp.name === templateName,
        );
        const alreadyExist = (existingGroups || []).find(
          (g) => g.name === templateName,
        );

        if (template && !alreadyExist) {
          const postReq = buildGroupFromTemplate(country, template);
          this.addGroup(postReq);
        }

        return EMPTY;
      }),
    ),
  );

  readonly deleteGroup = this.effect<string>((groupUuid$) =>
    groupUuid$.pipe(
      switchMap((uuid) =>
        this._autmaticFilterGateway.deleteAutomaticFilters({uuid}).pipe(
          tapResponse(
            () => {
              this._removeGroup(uuid);
            },
            (error: HttpErrorResponse) => {
              this.addError({
                httpError: error,
                errorMessage: {
                  key: 'deleteGroup',
                },
              });
            },
          ),
        ),
      ),
    ),
  );

  // Add all insightes templates to this user
  readonly addGroupsFromTemplates = this.effect<{
    country: CountryCode;
    templates: IProductGroupTemplate[];
  }>((trigger$) =>
    trigger$.pipe(
      tap(({country, templates}) => {
        templates.forEach((group) =>
          this.addGroupByTemplateName({country, templateName: group.name}),
        );
      }),
    ),
  );

  getLoadingState(country: CountryCode): Observable<boolean | undefined> {
    return this.loadingFlags$.pipe(map((flags) => flags.get(country)));
  }

  getGroups(
    country$: CountryCode | Observable<CountryCode>,
    forceLoad?: boolean,
  ): Observable<ProductGroup[]> {
    // return the groups by country
    return (isObservable(country$) ? country$ : of(country$)).pipe(
      tap((country) => {
        // Load the country groups if forced to load
        if (forceLoad) {
          this.loadGroups(country);
        }

        // Load the country groups if not exist
        const exist = this.get((s) => s.groupsByCountry).get(country);
        const isLoading = this.get((s) => s.loadingFlags.get(country));

        if (!exist && !isLoading) {
          this.loadGroups(country);
        }
      }),
      switchMap((country) =>
        this.groupsByCountry$.pipe(
          map((groupsMap) => groupsMap.get(country)),
          isNotNullOrUndefined(),
        ),
      ),
    );
  }

  getGroup(
    groupId: string | Observable<string>,
    country: CountryCode | Observable<CountryCode>,
  ): Observable<ProductGroup | undefined> {
    return combineLatest([
      this.getGroups(this._makeObservable(country)),
      this._makeObservable(groupId),
      this._makeObservable(country).pipe(
        switchMap((c) => this.getLoadingState(c)),
      ),
    ]).pipe(
      filter(([, , isLoading]) => !isLoading),
      map(([groups, id]) => groups.find((g) => g.uuid === id)),
    );
  }

  private _makeObservable<T>(source: T | Observable<T>): Observable<T> {
    if (isObservable(source)) {
      return source;
    } else {
      return of(source);
    }
  }
}
