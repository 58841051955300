export interface ICollection<T> {
  loading: boolean;
  loaded: boolean;
  list: T[];
}

export interface ICollectionObject<T> {
  loading: boolean;
  loaded: boolean;
  data: T | undefined;
}

export function initialCollection<T>(): ICollection<T> {
  return {
    loading: false,
    loaded: false,
    list: [] as T[],
  };
}
