import {Contract} from '@em/subscription/api-interface';

export function isGoogleCampaignsEnabled(contract: Contract): boolean {
  return !!contract?.advertising?.google_campaigns;
}

export function isGoogleCampaignsDisabled(contract: Contract): boolean {
  return !contract?.advertising?.google_campaigns;
}

export function isGoogleUploadEnabled(contract: Contract): boolean {
  return !!contract?.datafeed?.google_upload;
}

export function isGoogleUploadDisabled(contract: Contract): boolean {
  return !contract?.datafeed?.google_upload;
}

export function isGooglePricesEnabled(contract: Contract): boolean {
  return !!contract?.google_prices?.enabled;
}

export function isGooglePricesDisabled(contract: Contract): boolean {
  return !contract?.google_prices?.enabled;
}
