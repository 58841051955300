export type PartnerName =
  | 'emarketing'
  | 'datafeedwatch'
  | 'lightspeed'
  | 'plentymarkets'
  | 'prestashop'
  | 'shopify'
  | 'shopware'
  | 'webstollen'
  | 'competitors'
  | 'markets';

export class PartnerModel {
  constructor(readonly key: PartnerName) {}

  static get(partnerName: PartnerName): PartnerModel {
    return new PartnerModel(partnerName);
  }

  hasLandingPage(): boolean {
    return this.key === 'markets' || this.key === 'competitors';
  }

  supportsRepricingShopToken(): boolean {
    return this.key === 'webstollen';
  }

  isPremiumPartner(): boolean {
    return false; // currently no premium partners exist
  }

  isEmarketing(): boolean {
    return this.key === 'emarketing';
  }
}
