import {NgModule, Pipe, PipeTransform} from '@angular/core';
import formatRelative from '../../functions/format-relative';

@Pipe({
  name: 'relativeToNow',
})
export class RelativeToNowPipe implements PipeTransform {
  constructor() {}

  transform(value: Date | undefined): string {
    if (!value) return '';

    return formatRelative(value);
  }
}

@NgModule({
  declarations: [RelativeToNowPipe],
  exports: [RelativeToNowPipe],
})
export class RelativeToNowModule {}
