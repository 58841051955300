import {createAction} from '@ngrx/store';

export const showAppBlocker = createAction('[App] show Blocker');
export const hideAppBlocker = createAction('[App] hide Blocker');

export const redirectToLogin = createAction('[App] redirect to login');

export const startAngularRouting = createAction('[App] start angular routing');
export const initAngularRouting = createAction(
  '[App] initialize angular routing',
);

export const validateJwt = createAction('[App] validate jwt');
export const jwtValid = createAction('[App] jwt valid');
export const jwtInvalid = createAction('[App] jwt invalid');
