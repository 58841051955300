import {NgModule, Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'orderByTranslated',
})
export class OrderByTranslatedPipe implements PipeTransform {
  constructor(private readonly _translateService: TranslateService) {}

  transform(array: string[], i18nKeyPrefix: string): string[] {
    const translated = this._translateItems(array, i18nKeyPrefix);
    const ordered = translated.sort((a, b) => a.label.localeCompare(b.label));

    return ordered.map((sortedObject) => sortedObject.key);
  }

  private _translateItems(
    array: string[],
    i18nKeyPrefix: string,
  ): Array<{label: string; key: string}> {
    return array.map((value) => ({
      key: value,
      label: this._translateService.instant(i18nKeyPrefix + value),
    }));
  }
}

@NgModule({
  declarations: [OrderByTranslatedPipe],
  exports: [OrderByTranslatedPipe],
})
export class OrderByTranslatedPipeModule {}
