import {Injectable} from '@angular/core';

import {
  AcceleratorGateway,
  WorkFlowBackendResp,
} from '@em/shared/api-interface';
import {
  ComponentStoreBase,
  ComponentStoreStateBase,
} from '@em/shared/util-types';
import {HttpErrorResponse} from '@angular/common/http';
import {WorkflowsService} from '@em/activities/data-access';
import {isNotNullOrUndefined} from '@em/shared/util-rxjs';
import {tapResponse} from '@ngrx/component-store';
import {EMPTY, switchMap, tap, withLatestFrom} from 'rxjs';

type errorTypes = 'fetchProducts' | 'checkStatus';

export interface FetchProductsState
  extends ComponentStoreStateBase<errorTypes> {
  inProgress: boolean;
  neverFetched?: boolean;
  lastSuccess?: Date;
  lastFail?: Date;
  uploadFinished?: boolean;

  triggeredWorkflowUuid?: string;
}

@Injectable()
export class FetchProductsStore extends ComponentStoreBase<
  FetchProductsState,
  errorTypes
> {
  readonly inProgress$ = this.select((s) => s.inProgress);
  readonly neverFetched$ = this.select((s) => s.neverFetched);
  readonly lastSuccess$ = this.select((s) => s.lastSuccess);
  readonly lastFail$ = this.select((s) => s.lastFail);
  readonly triggeredWorkflowUuid$ = this.select((s) => s.triggeredWorkflowUuid);
  readonly uploadFinished$ = this.select((s) => s.uploadFinished);

  constructor(
    private readonly _accelerator: AcceleratorGateway,
    private readonly _workflowsService: WorkflowsService,
  ) {
    super({inProgress: false});
  }

  // load the details about the last fetch, if last fetch failed set also the last success
  loadLastFetches = this.effect<void>((trigger$) =>
    trigger$.pipe(
      tap(() => {
        this.patchState({inProgress: true});
      }),
      switchMap(() =>
        this._workflowsService
          .getAndMonitorWorkflow(
            'UseCases::Workflows::ProductPipelinesWorkflow',
            this._filterProductsFetch,
            undefined,
            this.triggeredWorkflowUuid$.pipe(isNotNullOrUndefined()),
          )
          .pipe(
            tapResponse(
              ({inProgress, lastFail, lastSuccess, justFinished}) => {
                this.patchState({
                  lastFail: lastFail
                    ? new Date(lastFail.failed_at as string)
                    : undefined,
                  lastSuccess: lastSuccess
                    ? new Date(lastSuccess.finished_at as string)
                    : undefined,
                  inProgress: inProgress ? true : false,
                  neverFetched: !lastFail && !lastSuccess,
                  uploadFinished: justFinished,
                });
              },
              (error: HttpErrorResponse) => {
                this.addError({
                  httpError: error,
                  errorMessage: {
                    key: 'checkStatus',
                  },
                  statePayload: {},
                });
              },
            ),
          ),
      ),
    ),
  );

  // manually trigger products fetch, and set the inProgressWorkflow to the uuid returned to start the status check
  fetchProducts = this.effect<void>((trigger$) =>
    trigger$.pipe(
      withLatestFrom(this.inProgress$),
      switchMap(([, inProgress]) => {
        if (inProgress) {
          return EMPTY;
        } else {
          this.patchState({inProgress: true});
          return this._accelerator.postProductFetch().pipe(
            tapResponse(
              (resp) => {
                this.patchState({
                  triggeredWorkflowUuid: resp.uuid,
                });
              },
              (error: HttpErrorResponse) => {
                this.addError({
                  httpError: error,
                  errorMessage: {
                    key: 'fetchProducts',
                  },
                  statePayload: {
                    inProgress: false,
                  },
                });
              },
            ),
          );
        }
      }),
    ),
  );

  private _filterProductsFetch(workFlows: WorkFlowBackendResp): boolean {
    const except: string[] = (workFlows.args?.['except'] as string[]) || [];
    return !except || !except.includes('product_fetch');
  }
}
