import {FACEBOOK} from '../facebook/facebook';
import {GOOGLE} from '../google/google';
import {MICROSOFT} from '../microsoft/microsoft';
import {IPlatform} from './platform';

export type PlatformId = 'google-shopping' | 'facebook' | 'bing';

export const AvailablePlatforms = new Map<PlatformId, IPlatform>([
  ['google-shopping', GOOGLE],
  ['facebook', FACEBOOK],
  ['bing', MICROSOFT],
]);
