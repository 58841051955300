<div mat-dialog-title translate>{{ data.title }}</div>
<div mat-dialog-content *ngIf="data.description" data-testid="description">
  <p [innerHTML]="data.description | translate"></p>
</div>
<mat-dialog-actions align="end">
  <em-secondary-button class="mr-4" [mat-dialog-close]="false" color="">
    <span translate>{{ data.cancelLabel || 'ACTIONS_CANCEL' }}</span>
  </em-secondary-button>

  <em-primary-button
    [mat-dialog-close]="true"
    [color]="data.confirmType || 'primary'"
  >
    <span translate>{{ data.confirmLabel || 'ACTIONS_CONFIRM' }}</span>
  </em-primary-button>
</mat-dialog-actions>
