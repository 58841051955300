import {CommonModule} from '@angular/common';
import {Component} from '@angular/core';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {EmPrimaryButtonComponent} from '@em/shared/ui';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'em-payment-required',
  templateUrl: './payment-required.component.html',
  styleUrls: ['./payment-required.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    MatDialogModule,
    EmPrimaryButtonComponent,
  ],
})
export class EmPaymentRequiredComponent {
  constructor() {}
}
