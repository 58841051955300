import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Injectable} from '@angular/core';
import {ComponentStore, tapResponse} from '@ngrx/component-store';
import {delay, switchMap, tap, withLatestFrom} from 'rxjs/operators';

export interface SideMenuBehaviourState {
  mode: 'over' | 'side';
  isOpen: boolean;
  isMobileView: boolean;
  size: 'small' | 'full';
  isShrinkMode: boolean;
}

@Injectable()
export class SideMenuBehaviourStore extends ComponentStore<SideMenuBehaviourState> {
  private _freezMouse = false;
  constructor(protected readonly _breakpointObserver: BreakpointObserver) {
    super();

    this._screenSizeChange();
  }

  // Selectors
  readonly isOpen$ = this.select((state) => state.isOpen);
  readonly navMode$ = this.select((state) => state.mode);
  readonly showMobileMenu$ = this.select((state) => state.isMobileView);
  readonly isSmall$ = this.select((state) => state.size === 'small');
  readonly isShrinkMode$ = this.select((state) => state.isShrinkMode);

  // Updaters
  readonly openMenu = this.updater(
    (state): SideMenuBehaviourState => ({
      ...state,
      isOpen: true,
    }),
  );

  readonly closeMenu = this.updater(
    (state): SideMenuBehaviourState => ({
      ...state,
      isOpen: false,
    }),
  );

  readonly toggleMenu = this.updater(
    (state): SideMenuBehaviourState => ({
      ...state,
      isOpen: !state.isOpen,
    }),
  );

  readonly menuItemClicked = this.updater(
    (state): SideMenuBehaviourState => ({
      ...state,
      isOpen: state.isMobileView ? false : state.isOpen,
    }),
  );

  // Effects
  readonly toggleShrinkMenu = this.effect((trigger$) =>
    trigger$.pipe(
      withLatestFrom(this.isShrinkMode$),
      tap(([, isShrinkMode]) => {
        this.patchState({
          size: isShrinkMode ? 'full' : 'small',
          isShrinkMode: !isShrinkMode,
        });
        this._freezMouse = true;
      }),
      delay(300),
      tap(() => (this._freezMouse = false)),
    ),
  );

  readonly mouseOverMenu = this.effect<boolean>((mouseOver$) =>
    mouseOver$.pipe(
      withLatestFrom(this.isShrinkMode$),
      tap(([, isShrinkMode]) => {
        if (isShrinkMode && !this._freezMouse) {
          this.patchState({size: 'full'});
        }
      }),
    ),
  );

  readonly mouseOutMenu = this.effect<boolean>((mouseOut$) =>
    mouseOut$.pipe(
      withLatestFrom(this.isShrinkMode$),
      tap(([, isShrinkMode]) => {
        if (isShrinkMode && !this._freezMouse) {
          this.patchState({size: 'small'});
        }
      }),
    ),
  );

  // Different state of the menu depends on the screen size
  private readonly _screenSizeChange = this.effect((trigger$) =>
    trigger$.pipe(
      switchMap(() =>
        this._breakpointObserver
          .observe([Breakpoints.XSmall, Breakpoints.Small])
          .pipe(
            tapResponse(
              (state) => {
                this.setState({
                  isMobileView: state.matches,
                  isOpen: !state.matches,
                  mode: state.matches ? 'over' : 'side',
                  size: 'full',
                  isShrinkMode: false,
                });
              },
              () => {},
            ),
          ),
      ),
    ),
  );
}
