import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  EventEmitter,
  Input,
  Optional,
  Output,
  QueryList,
  Self,
} from '@angular/core';
import {NgControl} from '@angular/forms';
import {FloatLabelType} from '@angular/material/form-field';
import {EmMaterialWrapperComponent} from '../material-wrapper/material-wrapper.component';
import {EmOptionGroupComponent} from '../option-group/option-group.component';
import {EmOptionComponent} from '../option/option.component';
import {EmSelectTriggerDirective} from './select-trigger.directive';
import {EmPrefixDirective} from '../../directives/form-field/prefix.directive';

const defaultCompareWith = (a: unknown, b: unknown) => a === b;
@Component({
  selector: 'em-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class EmSelectComponent<T>
  extends EmMaterialWrapperComponent<T>
  implements AfterViewInit
{
  @Input() label: string | undefined;
  @Input() placeholder: string | undefined;
  @Input() hint: string | undefined;
  @Input() multiple: boolean | undefined;
  @Input() floatLabel: FloatLabelType = 'auto';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() compareWith: (o1: any, o2: any) => boolean = defaultCompareWith;
  @Output() selectionChange: EventEmitter<T> = new EventEmitter();
  @ContentChild(EmSelectTriggerDirective)
  selectTrigger!: EmSelectTriggerDirective;
  @ContentChild(EmPrefixDirective) emPrefix: EmPrefixDirective | undefined;
  @ContentChildren(EmOptionComponent)
  options!: QueryList<EmOptionComponent>;
  @ContentChildren(EmOptionGroupComponent)
  optionGroups!: QueryList<EmOptionGroupComponent>;

  constructor(
    @Optional() @Self() public override ngControl: NgControl,
    protected readonly ref: ChangeDetectorRef,
  ) {
    super(ngControl);
  }

  ngAfterViewInit(): void {
    this.ref.detectChanges();
  }
}
