<div class="wrapper">
  <em-spinner [overContent]="true" *ngIf="isLoading"></em-spinner>
  <div class="text-wrapper" *ngIf="title">
    <div class="header">{{ title }}</div>
  </div>
  <div class="chart-wrapper">
    <apx-chart
      [series]="values"
      [chart]="chartOptions.chart"
      [labels]="chartOptions.labels"
      [plotOptions]="chartOptions.plotOptions"
      [colors]="chartOptions.colors"
      [tooltip]="chartOptions.tooltip"
      [stroke]="chartOptions.stroke"
      [states]="chartOptions.states"
      [dataLabels]="chartOptions.dataLabels"
      [legend]="chartOptions.legend"
      [responsive]="chartOptions.responsive"
    >
    </apx-chart>

    <div class="number-indicator" *ngIf="hasData()">
      {{ data?.withValue | numberWithUnit: '%':0 }}
    </div>
    <div class="no-data-indicator" *ngIf="!hasData()">
      <span translate>DASHBOARD_CHART_NO_DATA</span>
    </div>
  </div>
</div>
