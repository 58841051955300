/* tslint:disable */
/**
* This file was automatically generated.
* DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
* and run the generate-code script again.
*/
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {GetPerformanceReq} from '../types/request-objects/enterprise/get-performance';
import {GetPerformanceResp} from '../types/view-models/enterprise/get-performance';
import {PostSignupReq} from '../types/request-objects/enterprise/post-signup';
import {PostSignupResp} from '../types/view-models/enterprise/post-signup';
import {PostSocialLoginReq} from '../types/request-objects/enterprise/post-social-login';
import {PostSocialLoginResp} from '../types/view-models/enterprise/post-social-login';

@Injectable({
  providedIn: 'root',
})
export class EnterpriseGateway {
  constructor(private _http: HttpClient) {}

  getPerformance(params: GetPerformanceReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<GetPerformanceResp> {
    const request = params as any as { [param: string]: string | string[]; };
    return this._http.get<GetPerformanceResp>('/emarketing_api/enterprise/performance', {...options, params: request});
  }

  postSignup(params: PostSignupReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<PostSignupResp> {
    return this._http.post<PostSignupResp>('/emarketing_api/enterprise/signup', params, options);
  }

  postSocialLogin(params: PostSocialLoginReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<PostSocialLoginResp> {
    return this._http.post<PostSocialLoginResp>('/emarketing_api/enterprise/social_login', params, options);
  }
}
