/* tslint:disable */
/**
* This file was automatically generated.
* DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
* and run the generate-code script again.
*/
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {GetKpiReq} from '../../types/request-objects/performance/platform/get-kpi';
import {GetKpiResp} from '../../types/view-models/performance/platform/get-kpi';
import {GetTimeseriesReq} from '../../types/request-objects/performance/platform/get-timeseries';
import {GetTimeseriesResp} from '../../types/view-models/performance/platform/get-timeseries';

@Injectable({
  providedIn: 'root',
})
export class PlatformGateway {
  constructor(private _http: HttpClient) {}

  getKpi(params: GetKpiReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<GetKpiResp> {
    const request = params as any as { [param: string]: string | string[]; };
    return this._http.get<GetKpiResp>('/emarketing_api/performance/platform/kpi', {...options, params: request});
  }

  getTimeseries(params: GetTimeseriesReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<GetTimeseriesResp> {
    const request = params as any as { [param: string]: string | string[]; };
    return this._http.get<GetTimeseriesResp>('/emarketing_api/performance/platform/timeseries', {...options, params: request});
  }
}
