<div
  class="flex gap-6 items-center justify-center"
  *ngIf="!showPreview || groups?.length"
>
  <em-select
    class="w-auto min-w-[250px]"
    [formControl]="formControl"
    [subscriptSizing]="'dynamic'"
    (selectionChange)="groupChanged.emit($event)"
    [label]="'SHARED_LABEL_PRODUCT_GROUP' | translate"
    [required]="isRequired"
  >
    <em-option *ngFor="let productGroup of groups" [value]="productGroup.uuid">
      {{ productGroup.name | productGroupName }}
    </em-option>
    <em-error>{{ 'SHARED_REUIRED_FIELD' | translate }}</em-error>
  </em-select>

  <div *ngIf="showAddNew">
    <em-fab-button
      matTooltip="{{ 'PRODUCT_FILTER_ADD_FILTERSET' | translate }}"
      icon="add"
      size="sm"
      (btnClick)="addNewClick.emit()"
    >
    </em-fab-button>
  </div>
</div>

<em-product-group-card
  class="max-w-[450px] w-full"
  *ngIf="showPreview && formControl.value"
  [group]="selectedGroup"
  (updated)="groupEdited.emit(selectedGroup?.uuid)"
>
</em-product-group-card>

<ng-container *ngIf="showPreview && !groups?.length">
  <em-add-new-item-card
    class="max-w-[350px] w-full"
    [title]="'PRODUCT_FILTER_ADD_FILTERSET' | translate"
    [asBox]="true"
    (click)="addNewClick.emit()"
  ></em-add-new-item-card>
  <div
    class="text-center text-danger-500 -mt-6"
    *ngIf="isRequired && !groups?.length"
  >
    {{ 'SHARED_REUIRED_FIELD' | translate }}
  </div>
</ng-container>
