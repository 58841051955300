import {NotificationKey, NotificationRespType} from '@em/shared/api-interface';
import {NotificationModel} from '../notification.model';
import {IPusherNotification} from '../pusher-service/pusher.service';

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type payload = {product_count: number; sample_product: string};

export class ProductFetchNotification extends NotificationModel {
  static key: NotificationKey =
    'Datafeed::Domain::Notifications::ProductsFetched';
  readonly productCount: number;
  readonly sampleProduct: string;

  constructor(
    createdAt: Date,
    workflowUuid: string,
    productCount: number,
    sampleProduct: string,
  ) {
    super(createdAt, ProductFetchNotification.key, workflowUuid);
    this.productCount = productCount;
    this.sampleProduct = sampleProduct;
  }

  static productFetchFromPusher(
    resp: IPusherNotification,
  ): ProductFetchNotification {
    return new ProductFetchNotification(
      resp['created_at'] ? new Date(resp['created_at'] as string) : new Date(),
      resp.workflow_uuid,
      Number.parseInt(resp['product_count'] as string, 10),
      resp['sample_product'] as string,
    );
  }

  static productFetchFromResp(
    resp: NotificationRespType,
  ): ProductFetchNotification {
    return new ProductFetchNotification(
      resp['created_at'] ? new Date(resp['created_at'] as string) : new Date(),
      resp.workflow_uuid,
      (resp.payload as payload).product_count,
      (resp.payload as payload).sample_product,
    );
  }
}
