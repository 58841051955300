import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {map, skipWhile, tap} from 'rxjs/operators';
import {
  loadOnboardingData,
  selectOnboardingGuardInfo,
} from '@em/onboarding/data-access';

@Injectable()
export class OnboardingCompletedGuard {
  constructor(private readonly _store: Store) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return this._store.select(selectOnboardingGuardInfo).pipe(
      tap(({isLoaded, isLoading}) => {
        if (!isLoaded && !isLoading) {
          this._store.dispatch(loadOnboardingData());
        }
      }),
      skipWhile(({isLoaded}) => !isLoaded),
      map(({isOnboardingCompleted}) => isOnboardingCompleted),
    );
  }
}
