import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {EmButtonModule} from '@em/shared/ui';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'em-product-filter-overlay',
  templateUrl: './product-filter-overlay.component.html',
  styleUrls: ['./product-filter-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TranslateModule, EmButtonModule],
})
export class ProductFilterOverlayComponent {
  @Input() overlayTitle?: string;
  @Input() isCloseable = false;
  @Output() closed = new EventEmitter<void>();

  constructor() {}

  close() {
    this.closed.emit();
  }
}
