import {CommonModule} from '@angular/common';
import {Component, forwardRef} from '@angular/core';
import {
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {TranslateModule} from '@ngx-translate/core';
import {DropdownFilterItemOptionsComponent} from '../dropdown-filter-item-options/dropdown-filter-item-options.component';
import {IOptionViewModel} from '../filter-item-options/filter-item-options.component';
import {IProductFilterDefinition} from '../product-filter-definition';
import {EmFilterItemOptionInputComponent} from '../filter-item-option-input/filter-item-option-input.component';

@Component({
  selector: 'em-price-changes-options',
  templateUrl: './price-changes-filter-item-options.component.html',
  styleUrls: ['./price-changes-filter-item-options.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatSlideToggleModule,
    EmFilterItemOptionInputComponent,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PriceChangesFilterItemOptionsComponent),
      multi: true,
    },
  ],
})
export class PriceChangesFilterItemOptionsComponent extends DropdownFilterItemOptionsComponent {
  sourceOption?: IOptionViewModel;
  priceColumnOption?: IOptionViewModel;
  valueOption?: IOptionViewModel;
  operatorOption?: IOptionViewModel;
  lastDataPointsOption?: IOptionViewModel;
  toleranceEnabledOption?: IOptionViewModel;
  includeInsufficientOption?: IOptionViewModel;
  tolerancePercentOption?: IOptionViewModel;
  parentFilterKey = 'price_changes';
  override _generateViewModel(definition: IProductFilterDefinition) {
    const superValue = super._generateViewModel(definition);

    this.sourceOption = superValue.find((option) => option.key === 'source');
    this.valueOption = superValue.find((option) => option.key === 'value');
    this.operatorOption = superValue.find(
      (option) => option.key === 'operator',
    );
    this.lastDataPointsOption = superValue.find(
      (option) => option.key === 'last_data_points',
    );
    this.toleranceEnabledOption = superValue.find(
      (option) => option.key === 'tolerance_enabled',
    );
    this.includeInsufficientOption = superValue.find(
      (option) => option.key === 'include_with_insufficient_data',
    );
    this.tolerancePercentOption = superValue.find(
      (option) => option.key === 'tolerance_percent',
    );
    this.priceColumnOption = superValue.find(
      (option) => option.key === 'column',
    );

    // Always set last_data_points to 7 for now
    if (this.lastDataPointsOption) {
      this.setOptionValue(
        this.lastDataPointsOption.key,
        7,
        this.lastDataPointsOption.definition.type,
      );
    }

    return superValue;
  }
}
