<mat-slide-toggle
  [ngClass]="isStatusToggle ? 'em-status-toggle' : ''"
  [(ngModel)]="controlValue"
  [color]="color"
  [disabled]="!!isDisabled"
  [labelPosition]="labelPosition"
  (ngModelChange)="controlValueChanged($event); controlTouched()"
  (change)="valueChange.emit($event.checked)"
  ><ng-content></ng-content
></mat-slide-toggle>
