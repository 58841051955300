import {Injectable} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {EmConfirmDialogComponent} from '../../components/dialogs/confirm-dialog/confirm-dialog.component';
import {EmInfoDialogComponent} from '../../components/dialogs/info-dialog/info-dialog.component';
import {IDialogConfig} from './dialog-config';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private readonly _matDialog: MatDialog) {}

  confirm(config: IDialogConfig): Observable<boolean | undefined> {
    return this._matDialog
      .open<EmConfirmDialogComponent, IDialogConfig, boolean>(
        EmConfirmDialogComponent,
        {
          data: config,
          maxWidth: 800,
        },
      )
      .afterClosed();
  }

  info(
    config: IDialogConfig,
    displayConfig?: MatDialogConfig,
  ): Observable<void | undefined> {
    return this._matDialog
      .open<EmInfoDialogComponent, IDialogConfig, void>(EmInfoDialogComponent, {
        data: config,
        maxWidth: 600,
        ...displayConfig,
      })
      .afterClosed();
  }
}
