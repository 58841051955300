import {configurationHelper} from '@em/shared/util-configuration';
import {Environment} from './environment-type';
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: Environment = {
  production: true,
  test: false,
  apiDomainSettings: [
    {
      name: 'repricing',
      serviceDomain: configurationHelper.needConfig('REPRICING_GATEWAY_URL'),
      apiPrefix: 'api',
    },
    {
      name: 'emarketing',
      serviceDomain: configurationHelper.needConfig('EMARKETING_GATEWAY_URL'),
      apiPrefix: 'emarketing_api',
    },
    {
      name: 'shop-system',
      serviceDomain: configurationHelper.needConfig('SHOPSYSTEM_GATEWAY_URL'),
      apiPrefix: 'configuration_api',
    },
    {
      name: 'market_insights',
      serviceDomain: configurationHelper.needConfig(
        'MARKET_INSIGHTS_GATEWAY_URL',
      ),
      apiPrefix: 'market_insights_api',
      replacePrefixWith: 'configuration_api',
    },
    {
      name: 'payment',
      serviceDomain: configurationHelper.needConfig('PAYMENT_GATEWAY_URL'),
      apiPrefix: 'payment_api',
      replacePrefixWith: 'configuration_api',
    },
  ],
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
