<img
  class="header-image"
  src="assets/images/shared/subscription-required.svg"
/>
<button
  mat-icon-button
  tabindex="-1"
  class="close-icon"
  [mat-dialog-close]="false"
>
  <mat-icon aria-label="close icon"> close </mat-icon>
</button>
<div mat-dialog-content>
  <h1 mat-dialog-title>
    <span translate>PAYMENT_REQUIRED_GENERAL_TITLE</span>
  </h1>
  <p class="strong" translate>PAYMENT_REQUIRED_GENERAL_DATA_TITLE</p>
  <p translate>PAYMENT_REQUIRED_GENERAL_DATA_TEXT</p>
  <p class="strong" translate>PAYMENT_REQUIRED_GENERAL_REVENUES_TITLE</p>
  <p translate>PAYMENT_REQUIRED_GENERAL_REVENUES_TEXT</p>
  <p class="strong" translate>PAYMENT_REQUIRED_GENERAL_TOOLS_TITLE</p>
  <p translate>PAYMENT_REQUIRED_GENERAL_TOOLS_TEXT</p>
</div>
<div mat-dialog-actions>
  <em-primary-button
    class="w-full"
    [fullWidth]="true"
    tabindex="-1"
    [mat-dialog-close]="true"
  >
    <span translate>PAYMENT_REQUIRED_ACTION_UPGRADE_PREMIUM</span>
  </em-primary-button>
</div>
