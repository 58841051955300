import {v4 as uuidv4} from 'uuid';
import {IGenericFilterSetting} from '../../types/filtering-types';

export function includeFoundPrices(
  filters: IGenericFilterSetting[],
): IGenericFilterSetting[] {
  return [
    ...filters,
    {
      key: 'fetch_status',
      options: {operator: 'eq', value: 'found'},
      uuid: uuidv4(),
      parent_uuid: uuidv4(),
    },
  ];
}
