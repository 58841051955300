import {v4 as uuidv4} from 'uuid';
import {IGenericFilterSetting} from '../../types/filtering-types';

export function getPriceChangeFilters(
  operator: 'ge',
  changes = 1,
  last_data_points = 7,
  column:
    | 'min_price'
    | 'min_price_full'
    | 'avg_price'
    | 'avg_price_full' = 'avg_price',
  include_with_insufficient_data = false,
  tolerance_percent: number | undefined = undefined,
  source: 'google-shopping' | 'idealo' | undefined = undefined,
): IGenericFilterSetting[] {
  return [
    {
      uuid: uuidv4(),
      parent_uuid: uuidv4(),
      key: 'price_changes',
      options: {
        value: changes,
        operator,
        last_data_points,
        column,
        source,
        include_with_insufficient_data,
        tolerance_enabled: tolerance_percent !== undefined,
        tolerance_percent,
      },
    },
  ];
}
