import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';

export const registerIconsWith = (
  registry: MatIconRegistry,
  sanitizer: DomSanitizer,
) => {
  registry.addSvgIconResolver((name, namespace) =>
    namespace === ''
      ? sanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/${name}.svg`)
      : null,
  );
};
