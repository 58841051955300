import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatBadgeModule} from '@angular/material/badge';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatToolbarModule} from '@angular/material/toolbar';
import {RouterModule} from '@angular/router';
import {TranslateWithErrorReportingModule} from '@em/shared/util-configuration';
import {CountrySelectionModule} from './country-selection/country-selection.component';
import {UserMenuModule} from './user-menu/user-menu.component';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatBadgeModule,
    MatListModule,
    MatSnackBarModule,
    MatMenuModule,
    RouterModule,
    TranslateWithErrorReportingModule,
    CountrySelectionModule,
    UserMenuModule,
  ],
})
export class NavModule {}
