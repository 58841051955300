/* tslint:disable */
/**
* This file was automatically generated.
* DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
* and run the generate-code script again.
*/
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {GetSettingsResp} from '../types/view-models/merchants/get-settings';
import {GetSetupStatusResp} from '../types/view-models/merchants/get-setup-status';
import {PatchSettingsReq} from '../types/request-objects/merchants/patch-settings';
import {PostChallengeReq} from '../types/request-objects/merchants/post-challenge';
import {PostChallengeResp} from '../types/view-models/merchants/post-challenge';
import {PostContactRequestEmailReq} from '../types/request-objects/merchants/post-contact-request-email';
import {PostExchangeLoginResp} from '../types/view-models/merchants/post-exchange-login';
import {PostLoginReq} from '../types/request-objects/merchants/post-login';
import {PostLoginResp} from '../types/view-models/merchants/post-login';
import {PostLogoutReq} from '../types/request-objects/merchants/post-logout';
import {PostMagicLinkReq} from '../types/request-objects/merchants/post-magic-link';
import {PostOnboardingRequestEmailReq} from '../types/request-objects/merchants/post-onboarding-request-email';
import {PostResetPasswordReq} from '../types/request-objects/merchants/post-reset-password';
import {PostSignupEmailReq} from '../types/request-objects/merchants/post-signup-email';
import {PostSocialLoginReq} from '../types/request-objects/merchants/post-social-login';
import {PostSocialLoginResp} from '../types/view-models/merchants/post-social-login';

@Injectable({
  providedIn: 'root',
})
export class MerchantsGateway {
  constructor(private _http: HttpClient) {}

  getSettings(options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<GetSettingsResp> {
    return this._http.get<GetSettingsResp>('/emarketing_api/merchants/settings', options);
  }

  getSetupStatus(options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<GetSetupStatusResp> {
    return this._http.get<GetSetupStatusResp>('/emarketing_api/merchants/setup_status', options);
  }

  patchSettings(params: PatchSettingsReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<undefined> {
    return this._http.patch<undefined>('/emarketing_api/merchants/settings', params, options);
  }

  postChallenge(params: PostChallengeReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<PostChallengeResp> {
    return this._http.post<PostChallengeResp>('/emarketing_api/merchants/challenge', params, options);
  }

  postContactRequestEmail(params: PostContactRequestEmailReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<undefined> {
    return this._http.post<undefined>('/emarketing_api/merchants/contact_request_email', params, options);
  }

  postExchangeLogin(options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<PostExchangeLoginResp> {
    return this._http.post<PostExchangeLoginResp>('/emarketing_api/merchants/exchange_login', {}, options);
  }

  postLogin(params: PostLoginReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<PostLoginResp> {
    return this._http.post<PostLoginResp>('/emarketing_api/merchants/login', params, options);
  }

  postLogout(params: PostLogoutReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<undefined> {
    return this._http.post<undefined>('/emarketing_api/merchants/logout', params, options);
  }

  postMagicLink(params: PostMagicLinkReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<undefined> {
    return this._http.post<undefined>('/emarketing_api/merchants/magic_link', params, options);
  }

  postOnboardingRequestEmail(params: PostOnboardingRequestEmailReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<undefined> {
    return this._http.post<undefined>('/emarketing_api/merchants/onboarding_request_email', params, options);
  }

  postResetPassword(params: PostResetPasswordReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<undefined> {
    return this._http.post<undefined>('/emarketing_api/merchants/reset_password', params, options);
  }

  postSignupEmail(params: PostSignupEmailReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<undefined> {
    return this._http.post<undefined>('/emarketing_api/merchants/signup_email', params, options);
  }

  postSocialLogin(params: PostSocialLoginReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<PostSocialLoginResp> {
    return this._http.post<PostSocialLoginResp>('/emarketing_api/merchants/social_login', params, options);
  }
}
