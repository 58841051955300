<div class="header">
  <ng-content select="[data-slot-type=action]"></ng-content>
  <span class="title" *ngIf="overlayTitle">{{ overlayTitle | translate }}</span>
  <em-button
    class="closs"
    type="icon"
    color="basic"
    icon="close"
    *ngIf="isCloseable"
    (btnClick)="close()"
  ></em-button>
</div>
<ng-content class="content" select="[data-slot-type=content]"></ng-content>
