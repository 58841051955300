<ng-container *ngIf="filterOption">
  <mat-form-field
    class="form-field-sm w-full"
    subscriptSizing="dynamic"
    data-testid="select-field"
    *ngIf="showDropDown"
  >
    <mat-select
      [ngModel]="value"
      [multiple]="multipleValues"
      (ngModelChange)="valueChanged.emit($event)"
      [required]="!!filterOption.definition.required"
      [placeholder]="showPlaceholder ? (placeholder | translate) : ''"
    >
      <mat-option *ngIf="!filterOption.definition.required" [value]="null">
      </mat-option>
      <mat-option
        *ngFor="let val of filterOption.definition.selectableValues"
        [value]="val"
      >
        {{ getValueLabel(val) | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field
    class="form-field-sm w-full"
    subscriptSizing="dynamic"
    data-testid="input-field"
    *ngIf="showTextInput"
  >
    <input
      matInput
      [ngModel]="value"
      (ngModelChange)="valueChanged.emit($event)"
      [min]="filterOption.definition.minimum"
      [max]="filterOption.definition.maximum"
      [step]="filterOption.definition.step"
      [required]="filterOption.definition.required"
      (blur)="touched.emit(true)"
      [placeholder]="showPlaceholder ? (placeholder | translate) : ''"
      [type]="filterOption.inputType"
    />
  </mat-form-field>

  <mat-slide-toggle
    *ngIf="showToggle"
    [ngModel]="value"
    (ngModelChange)="valueChanged.emit($event)"
    color="primary"
    data-testid="toggle-field"
  >
    <span>{{ placeholder | translate }}</span>
  </mat-slide-toggle>
</ng-container>
