import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
  EmButtonModule,
  EmCheckboxModule,
  EmInputModule,
  EmRadioModule,
  EmSelectModule,
  EmSpinnerModule,
} from '@em/shared/ui';
import {ServicesListComponent} from './services-list.component';
import {MatStepperModule} from '@angular/material/stepper';
import {TranslateModule} from '@ngx-translate/core';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatStepperModule,
    MatIconModule,
    EmCheckboxModule,
    EmButtonModule,
    EmInputModule,
    EmSelectModule,
    EmRadioModule,
    TranslateModule,
    EmSpinnerModule,
  ],
  declarations: [ServicesListComponent],
})
export class ServicesListModule {}
