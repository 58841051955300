import {HttpErrorResponse} from '@angular/common/http';
import {ComponentStore} from '@ngrx/component-store';
import {EntityState} from '@ngrx/entity';
import {Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';

export interface errorMessage<T> {
  key: T;
  title: string;
  description?: string;
}

export interface ComponentStoreStateBase<
  Errortypes extends string | number | symbol,
> {
  errorMessages?: Array<errorMessage<Errortypes>>;
  isLoading?: boolean;
}

export interface EntityStateBase<Errortypes extends string | number | symbol, T>
  extends EntityState<T> {
  errorMessages?: Array<errorMessage<Errortypes>>;
  isLoading?: boolean;
}

export abstract class ComponentStoreBase<
  T extends ComponentStoreStateBase<E> | EntityStateBase<E, unknown>,
  E extends string | number | symbol,
> extends ComponentStore<T> {
  readonly errorMessages$: Observable<Array<errorMessage<E>> | undefined> =
    this.select((state) => state.errorMessages);

  readonly isLoading$: Observable<boolean | undefined> = this.select(
    (state) => state.isLoading,
  );

  readonly hasError$: Observable<boolean> = this.select(
    (state) => !!state.errorMessages?.length,
  );

  readonly addError = this.updater(
    (
      state,
      payload: {
        httpError?: HttpErrorResponse;
        errorMessage?: Partial<errorMessage<E>>;
        statePayload?: Partial<T>;
      },
    ): T => {
      if (payload.httpError) {
        return {
          ...state,
          isLoading: false,
          errorMessages: [
            {
              title: 'APP_ERROR_UNKNOWN_ERROR_TITLE',
              description: payload.httpError.error
                ? payload.httpError.error.error
                : '',
              ...payload.errorMessage,
            },
          ],
          ...payload.statePayload,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          errorMessages: [payload.errorMessage],
          ...payload.statePayload,
        };
      }
    },
  );

  readonly startLoading = this.updater(
    (state): T => ({
      ...state,
      errorMessages: undefined,
      isLoading: true,
    }),
  );

  constructor(defaultState?: T) {
    super(defaultState);
  }

  getError(key: E): Observable<errorMessage<E> | undefined> {
    return this.errorMessages$.pipe(
      map((errors) => errors?.find((er) => er.key === key)),
      filter((er) => !!er),
    );
  }
}
