<ng-container *ngIf="product">
  <div class="inner" [class.disabled]="disabled">
    <div class="text">
      <span class="title" *ngIf="!product.deleted">{{ product.title }}</span>
      <span class="title" *ngIf="product.deleted"
        >{{ 'PRODUCT_ITEM_DELETED' | translate }} ({{ product.id }})</span
      >
      <span *ngIf="product.gtin">
        <span translate>PRODUCT_ITEM_GTIN</span>: {{ product.gtin }}
      </span>
    </div>
    <div class="product-actions">
      <em-button
        type="icon"
        color="basic"
        size="sm"
        *ngIf="showIncludeIcon"
        [disabled]="disabled"
        (btnClick)="addToIncluded()"
      >
        <mat-icon aria-hidden="false" aria-label="add icon" class="add-set">
          add_circle_outline
        </mat-icon>
      </em-button>

      <em-button
        type="icon"
        color="basic"
        size="sm"
        *ngIf="showExcludeIcon"
        [disabled]="disabled"
        (btnClick)="addToExcluded()"
      >
        <mat-icon
          aria-hidden="false"
          aria-label="add icon"
          class="add-set filter-list"
        >
          remove_circle_outline
        </mat-icon>
      </em-button>
    </div>

    <ng-content></ng-content>
  </div>
</ng-container>
