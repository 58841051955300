import {createReducer, on} from '@ngrx/store';
import {IMerchantModel} from '../merchant.model';
import {basicDataConfirmed, loadMerchantSuccess} from './action';

export interface IMerchantFeatureState {
  data?: IMerchantModel;
}

export interface IState {
  merchant: IMerchantFeatureState;
}

export const initialMerchantState: IMerchantFeatureState = {
  data: undefined,
};

export const merchantReducer = createReducer(
  initialMerchantState,
  on(
    loadMerchantSuccess,
    (state, {data}): IMerchantFeatureState => ({
      data,
    }),
  ),
  on(
    basicDataConfirmed,
    (state, {isConfirmed}): IMerchantFeatureState => ({
      ...state,
      data: {
        ...(state.data as IMerchantModel),
        basicDataConfirmed: isConfirmed,
      },
    }),
  ),
);
