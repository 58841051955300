import {PlatformId} from '@em/shared/platforms/util';
import {List} from 'immutable';
import {FilterGroupItem} from './FilterGroupItem';
import {CountryCode} from '@em/shared/util-types';
import {ProductGroupType} from './product-group-type';

export interface IPropertyDefinition {
  [key: string]: {
    type: string;
    enum: string[];
    additionalProperties: boolean;
    properties: IPropertyDefinition;
    required: string[];
  };
}

export interface IFilterDefinition {
  properties: IPropertyDefinition;
  required: string[];
}

export type optionValueType =
  | string
  | boolean
  | number
  | string[]
  | number[]
  | unknown;

export interface IFilterOptionValues {
  [key: string]: optionValueType;
}

export interface IGenericFilterSetting {
  uuid: string;
  parent_uuid: string;
  key: string;
  options: IFilterOptionValues;
}

export enum ConnectLogicalOperator {
  AND = 'and',
  OR = 'or',
}

export enum ConnectItemType {
  GROUP = 'group',
  ITEM = 'item',
}

export interface FilterGroup {
  uuid: string;
  items: List<FilterGroupItem>;
}

export interface IFilterGroupLink {
  parent_uuid: string | null;
  type: ConnectItemType;
  operator: ConnectLogicalOperator;
}

export interface IAppliedCampaign {
  uuid: string;
  name?: string;
  attributes?: {
    invertedProducts?: boolean;
  };
  platform?: PlatformId;
}

export interface EditFilterSet {
  uuid?: string;
  name: string;
  country?: CountryCode;
  selectedFilters: List<FilterGroup>;
  categoryFilter: {
    inverted?: boolean;
    categories: string[];
  };
  manuallyAddedProducts: string[];
  manuallyRemovedProducts: string[];
  productCount: number | null;
  appliedCampaigns: IAppliedCampaign[];
  type: ProductGroupType;
  links: FilterSetLink[];
  isGoogleSPD?: boolean;
  isPriceCrawl?: boolean;
  excludeSetId: string | null;
}

export interface IFilterSet {
  uuid?: string;
  name: string;
  country?: CountryCode;
  selectedFilters: IGenericFilterSetting[];
  categoryFilter: {
    inverted?: boolean;
    categories: string[];
  };
  manuallyAddedProducts: string[];
  manuallyRemovedProducts: string[];
  productCount: number | null;
  offerIds?: string[];
  appliedCampaigns: IAppliedCampaign[];
  type: ProductGroupType;
  links: FilterSetLink[];
  isGoogleSPD?: boolean;
  isPriceCrawl?: boolean;
  excludeSetId: string | null;
}

export interface ICampaignFilterSet {
  uuid: string;
  name: string;
  type: ProductGroupType;
}

export interface FilterSetLink {
  type: ConnectItemType;
  operator: ConnectLogicalOperator;
  parent_uuid?: null | string;
}

export type GooglePricesFilterType =
  | 'suggested_price'
  | 'predicted_impressions_change_fraction'
  | 'predicted_conversions_change_fraction'
  | 'predicted_clicks_change_fraction'
  | 'benchmark_price';

// TODO: Remove this construct. We want to include all filters anyways
export const AVAILABLE_FILTER_DEFINITION_KEYS = [
  'product_performance',
  'gtin',
  'roas',
  'price',
  'cogs',
  'profit_margin',
  'suggested_price',
  'fetch_status',
  'price_changes',
  'outliers',
  'source_created_at',
  'with_costs',
  'with_clicks',
  'with_conversions',
  'with_impressions',
  'with_competitors',
  'best_price',
  'mode_price_v2',
  'brand',
  'title',
  'availability',
  'custom_labels',
];

export const EXCLUDED_RADAR_FILTERS = [
  'product_performance',
  'roas',
  'outliers',
  'with_costs',
  'with_clicks',
  'with_conversions',
  'with_impressions',
  'with_competitors',
  'best_price',
  'mode_price_v2',
  'fetch_status',
  'suggested_price',
];

export const GOOGLE_PRICES_FILTER_TYPES: GooglePricesFilterType[] = [
  'suggested_price',
  'predicted_impressions_change_fraction',
  'predicted_conversions_change_fraction',
  'predicted_clicks_change_fraction',
  'benchmark_price',
];
