import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MATERIAL_SANITY_CHECKS} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {EmErrorDirective} from '../../directives/error/error.directive';
import {EmErrorModule} from '../../directives/error/error.module';
import {EmFormFieldModule} from '../../directives/form-field/form-field.module';
import {EmSuffixDirective} from '../../directives/form-field/suffix.directive';
import {EmTextAreaComponent} from '../text-area/text-area.component';
import {EmInputComponent} from './input.component';
import {EmPrefixDirective} from '../../directives/form-field/prefix.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    EmErrorModule,
    EmFormFieldModule,
  ],
  declarations: [EmInputComponent, EmTextAreaComponent],
  exports: [
    EmInputComponent,
    EmTextAreaComponent,
    EmErrorDirective,
    EmSuffixDirective,
    EmPrefixDirective,
  ],
  providers: [{provide: MATERIAL_SANITY_CHECKS, useValue: false}],
})
export class EmInputModule {}
