import {Injectable} from '@angular/core';
import {
  ComponentStoreBase,
  ComponentStoreStateBase,
} from '@em/shared/util-types';
import {List, fromJS} from 'immutable';
import {v4 as uuidv4} from 'uuid';
import {FilterGroupItem} from '../../types/FilterGroupItem';
import {
  ConnectItemType,
  ConnectLogicalOperator,
  EditFilterSet,
  FilterGroup,
  FilterSetLink,
  IFilterSet,
  IGenericFilterSetting,
} from '../../types/filtering-types';
import {ProductGroupType} from '../../types/product-group-type';

export type overlayStates =
  | 'none'
  | 'filter-sets-overview'
  | 'edit-filter-set'
  | 'edit-manual-products'
  | 'edit-categories';

export const emptyFilterSet: EditFilterSet = {
  name: '',
  selectedFilters: List(),
  categoryFilter: {
    inverted: undefined,
    categories: [],
  },
  manuallyAddedProducts: [],
  manuallyRemovedProducts: [],
  productCount: null,
  appliedCampaigns: [],
  type: 'custom' as ProductGroupType,
  links: [],
  isGoogleSPD: false,
  excludeSetId: null,
};

type errorTypes = '';

export interface EditProductGroupState
  extends ComponentStoreStateBase<errorTypes> {
  group: EditFilterSet;
  overlayState: overlayStates;
}

@Injectable()
export class EditProductGroupStore extends ComponentStoreBase<
  EditProductGroupState,
  errorTypes
> {
  readonly group$ = this.select((s) => s.group);
  readonly groupLinks$ = this.select((s) => s.group.links);

  readonly updateOverlayState = this.updater<overlayStates>(
    (state, overlayState): EditProductGroupState => ({...state, overlayState}),
  );

  readonly updateGroup = this.updater<IFilterSet>(
    (state, filterSet): EditProductGroupState => ({
      ...state,
      group: {
        ...filterSet,
        selectedFilters: this._buildFilterGroupList(filterSet.selectedFilters),
      },
    }),
  );

  readonly patchLinkedList = this.updater<FilterSetLink>(
    (state, link): EditProductGroupState => ({
      ...state,
      group: {
        ...state.group,
        links: state.group.links
          .filter((x: FilterSetLink) => !(x.parent_uuid === link.parent_uuid))
          .concat(link),
      },
    }),
  );

  readonly updateFilterGroupLinks = this.updater<FilterGroup>(
    (state, update): EditProductGroupState => {
      let links = state.group?.links;
      let filters = state.group?.selectedFilters;

      if (update) {
        filters =
          filters &&
          filters.map((filterGroup: FilterGroup) =>
            filterGroup.uuid === update.uuid ? update : filterGroup,
          );

        links = links && links.map((link: FilterSetLink) => link);
      }

      return {
        ...state,
        group: {
          ...state.group,
          selectedFilters: filters || List(),
          links: links || [],
        },
      };
    },
  );

  readonly connectFilterGroups = this.updater<{
    target: FilterGroup;
    origin: FilterGroup;
    operator: ConnectLogicalOperator;
  }>(
    (state, {target, origin, operator}): EditProductGroupState => ({
      ...state,
      group: {
        ...state.group,
        links: this._buildLinksFromConnectGroups(
          target,
          state.group.links,
          operator,
        ),
        selectedFilters: state.group.selectedFilters
          .filter((group: FilterGroup) => group.uuid !== origin.uuid)
          .map((group: FilterGroup) =>
            group.uuid === target.uuid
              ? {
                  ...target,
                  items: target.items.concat(origin.items),
                }
              : group,
          ),
      },
    }),
  );

  readonly disconnectFilterGroupItem = this.updater<{
    disconnectGroup: FilterGroup;
    filterGroupItem: FilterGroupItem;
    index: number;
  }>(
    (
      state,
      {disconnectGroup, filterGroupItem, index},
    ): EditProductGroupState => {
      const filterGroup: FilterGroup = {
        uuid: uuidv4(),
        items: fromJS([filterGroupItem]),
      };

      const links = state.group
        ? (state.group.links
            .map((link: FilterSetLink) => {
              if (
                link.parent_uuid === disconnectGroup.uuid &&
                disconnectGroup.items.size === 1
              ) {
                return null;
              }
              return link;
            })
            .filter((x) => x) as unknown as FilterSetLink[])
        : [];

      return {
        ...state,
        group: {
          ...state.group,
          links,
          selectedFilters: state.group.selectedFilters.insert(
            index && index > -1 ? index : state.group.selectedFilters.size,
            filterGroup,
          ),
        },
      };
    },
  );

  readonly addSelectedItem = this.updater<{
    item: FilterGroupItem;
    index?: number;
  }>((state, toAdd): EditProductGroupState => {
    const uuid = uuidv4();

    toAdd.item = new FilterGroupItem({
      ...toAdd.item.data.toJS(),
      parent_uuid: uuid,
    } as IGenericFilterSetting);

    let selectedFilters: List<FilterGroup>;
    if (toAdd.index !== undefined) {
      selectedFilters = state.group.selectedFilters.insert(toAdd.index, {
        uuid,
        items: fromJS([toAdd.item]),
      });
    } else {
      selectedFilters = state.group.selectedFilters.push({
        uuid,
        items: fromJS([toAdd.item]),
      });
    }

    return {
      ...state,
      group: {
        ...state.group,
        selectedFilters,
        links:
          state.group.links.length > 0
            ? state.group.links
            : [
                {
                  parent_uuid: null,
                  type: ConnectItemType.GROUP,
                  operator: ConnectLogicalOperator.AND,
                },
              ],
      },
    };
  });

  readonly removeSelectedItem = this.updater<string>(
    (state, uuid): EditProductGroupState => ({
      ...state,
      group: {
        ...state.group,
        selectedFilters: state.group.selectedFilters
          .map((filterGroup: FilterGroup) => ({
            ...filterGroup,
            items: filterGroup.items.filter(
              (item: FilterGroupItem) => item.uuid !== uuid,
            ),
          }))
          .filter((filterGroup: FilterGroup) => filterGroup.items.size > 0),
        links: state.group.links.filter((link) => !(link.parent_uuid === uuid)),
      },
    }),
  );

  readonly removeFilterGroup = this.updater<FilterGroup>(
    (state, groupToRemove): EditProductGroupState => ({
      ...state,
      group: {
        ...state.group,
        selectedFilters: state.group.selectedFilters.filter(
          (filterGroup: FilterGroup) => filterGroup.uuid !== groupToRemove.uuid,
        ),
        links: state.group.links.filter(
          (link: FilterSetLink) => !(link.parent_uuid === groupToRemove.uuid),
        ),
      },
    }),
  );

  constructor() {
    super({group: emptyFilterSet, overlayState: 'edit-filter-set'});
  }

  private _buildFilterGroupList(
    selectedFilters: IGenericFilterSetting[],
  ): List<FilterGroup> {
    return fromJS(selectedFilters.map((x) => new FilterGroupItem(x)))
      .groupBy((x: FilterGroupItem) => x.parentUuid)
      .map((group: List<FilterGroupItem>) => ({
        uuid: group.get(0)?.parentUuid,
        items: group,
      }))
      .toList();
  }

  private _buildLinksFromConnectGroups(
    target: FilterGroup,
    links: FilterSetLink[],
    operator: ConnectLogicalOperator,
  ): FilterSetLink[] {
    const exists: FilterSetLink | undefined = links.find(
      (link: FilterSetLink) => link.parent_uuid === target.uuid,
    );

    return exists
      ? links
      : links.concat({
          parent_uuid: target.uuid,
          operator,
          type: ConnectItemType.ITEM,
        });
  }
}
