<div class="grid grid-cols-2 gap-1">
  <div *ngFor="let vm of vms; trackBy: trackByFn">
    <div class="mt-2 mb-4" *ngIf="vm.key === 'source'"></div>
    <mat-form-field
      class="form-field-sm w-full"
      subscriptSizing="dynamic"
      *ngIf="
        vm.definition.selectableValues.length > 0 ||
          vm.definition.type === 'array';
        else defaultInput
      "
    >
      <mat-select
        [ngModel]="getOptionValue(vm.key)"
        [multiple]="vm.definition.type === 'array'"
        (ngModelChange)="setOptionValue(vm.key, $event, vm.definition.type)"
        [required]="vm.definition.required"
        [placeholder]="
          (vm.key === 'source' ? 'SHARED_LABEL_SOURCE' : '') | translate
        "
      >
        <mat-option *ngIf="!vm.definition.required" [value]="null">
        </mat-option>
        <mat-option
          *ngFor="let val of vm.definition.selectableValues"
          [value]="val"
        >
          {{ i18nDropdownKey(val, vm.definition.name) | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <ng-template #defaultInput>
      <mat-form-field
        class="form-field-sm w-full"
        subscriptSizing="dynamic"
        *ngIf="vm.definition.type !== 'boolean'"
      >
        <input
          matInput
          [disabled]="disabled"
          [ngModel]="getOptionValue(vm.key)"
          (ngModelChange)="setOptionValue(vm.key, $event, vm.definition.type)"
          [min]="vm.definition.minimum"
          [max]="vm.definition.maximum"
          [step]="vm.definition.step"
          [required]="vm.definition.required"
          (blur)="onTouched()"
          [placeholder]="placeholder | translate"
          [type]="vm.inputType"
        />
      </mat-form-field>
    </ng-template>
  </div>
</div>

<ng-container *ngIf="booleanOptions().length > 0">
  <div class="flex-column options">
    <div *ngFor="let vm of booleanOptions()">
      <mat-slide-toggle
        (change)="setOptionValue(vm.key, $event.checked, vm.inputType)"
        [checked]="getOptionValue(vm.key)"
        [ngClass]="{'two-lined-toggle': hasLongText(vm.key)}"
        color="primary"
      >
        <span>{{
          'CAMPAIGN_PERFORMANCE_FILTER_' + vm.key.toUpperCase() + '_LABEL'
            | translate
        }}</span>
      </mat-slide-toggle>
    </div>
  </div>
</ng-container>
