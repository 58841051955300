import {Component, Input, OnInit} from '@angular/core';
import {MatRadioChange} from '@angular/material/radio';
import {
  ConnectItemType,
  ConnectLogicalOperator,
  EditProductGroupStore,
  FilterGroup,
  FilterSetLink,
  IFilterGroupLink,
} from '@em/data-feed/data-access-products';
import {ComponentBase} from '@em/shared/ui';

@Component({
  selector: 'em-filter-group-connect',
  templateUrl: './filter-group-connect.component.html',
  styleUrls: ['./filter-group-connect.component.scss'],
})
export class FilterGroupConnectComponent
  extends ComponentBase
  implements OnInit
{
  @Input() group?: FilterGroup;
  @Input() connectGroup?: FilterGroup;

  value: ConnectLogicalOperator | null = null;

  constructor(private readonly _editGroupStore: EditProductGroupStore) {
    super();
  }

  ngOnInit(): void {
    this._subscribe(this._editGroupStore.groupLinks$, (links) => {
      if (links) {
        const foundLink = links.find(
          (link: FilterSetLink) => link.parent_uuid === null,
        );

        this.value = foundLink ? foundLink.operator : null;
      } else {
        this.value = null;
      }
    });
  }

  changeFilterGroupLogicalOperator($event: MatRadioChange) {
    if (!this.connectGroup) return;

    const link: IFilterGroupLink = {
      parent_uuid: null,
      operator: $event.value,
      type: ConnectItemType.GROUP,
    };

    this._editGroupStore.patchLinkedList(link);
  }

  connectFilters() {
    if (!this.group) return;
    if (!this.connectGroup) return;

    this._editGroupStore.connectFilterGroups({
      target: this.connectGroup,
      origin: this.group,
      operator: this.value || ConnectLogicalOperator.AND,
    });
  }
}
