<div>
  <div class="mb-4 pb-4 border-b border-b-gray-200">
    <div class="font-semibold">Customize Your Perfect Plan</div>
    <div>
      Build a plan that suits your needs, ensuring you get exactly what you
      want. <br />Select from the following features and create a personalized
      plan that fits your needs.
    </div>
  </div>

  <em-warning-box class="mb-8" *ngIf="noPlanExist$ | async">
    <ng-container title>{{
      'SHARED_WARNING_PLAN_MISSING_TITLE' | translate
    }}</ng-container>
    <ng-container description>
      {{ 'SHARED_WARNING_PLAN_MISSING' | translate }}
    </ng-container>
  </em-warning-box>

  <em-warning-box class="mb-8" *ngIf="showProductsMissing$ | async">
    <ng-container title>{{
      'SHARED_WARNING_NO_PRODUCTS_TITLE' | translate
    }}</ng-container>
    <ng-container description>
      {{ 'SHARED_WARNING_NO_PRODUCTS' | translate }}
    </ng-container>
    <ng-container actions>
      <em-primary-button [routerLink]="['/pipelines/list']">{{
        'SHARED_LABEL_SETUP' | translate
      }}</em-primary-button>
    </ng-container>
  </em-warning-box>

  <div class="flex gap-8 justify-around" *ngIf="showPlans$ | async">
    <div class="w-[500px]" *ngIf="suggestedContract$ | async as toEditContract">
      <div class="features-list">
        <div class="feature-section">
          <div class="feature section-title">
            <div class="flex w-full justify-between">
              <em-checkbox [ngModel]="true" disabled="true">
                <div class="flex">
                  <em-icon name="file_download" class="mr-1"></em-icon>
                  {{ 'PLAN_LABLE_IMPORT_DATA' | translate }}
                </div></em-checkbox
              >
              <ng-container
                *ngIf="!toEditContract.product_management?.total_products"
              >
                <ng-container
                  *ngTemplateOutlet="
                    dataMissingIcon;
                    context: {url: '/pipelines/list'}
                  "
                ></ng-container
              ></ng-container>
            </div>
          </div>
          <div class="feature sub-feature">
            <em-icon class="text-success-500" name="check"></em-icon>

            {{ 'PLAN_LABLE_PRODUCTS_COUNT' | translate }}:
            {{ productsCount$ | async }}
          </div>
          <div class="feature sub-feature">
            <em-icon class="text-success-500" name="check"></em-icon>

            {{ 'PLAN_LABLE_UPLOAD_PRODUCTS' | translate }}
          </div>
        </div>

        <div class="feature-section">
          <div class="feature section-title">
            <div class="flex w-full justify-between">
              <em-checkbox
                [value]="(toEditContract.price_crawls?.length || 0) > 0"
                [disabled]="editableContract?.price_crawls?.length === 0"
                (valueChange)="updatePriceCrawl($event)"
              >
                <div class="flex">
                  <em-icon name="radar" class="mr-1"></em-icon>
                  {{ 'NAV_COMPETITORS_RADAR' | translate }}
                </div>
              </em-checkbox>
              <ng-container *ngIf="hasPriceCrawl$ | async; else missingCrawl">
                <em-icon
                  name="edit"
                  size="sm"
                  class="cursor-pointer text-primary-500"
                  routerLink="/insights/settings/data-crawling"
                ></em-icon>
              </ng-container>
              <ng-template #missingCrawl>
                <ng-container
                  *ngTemplateOutlet="
                    dataMissingIcon;
                    context: {url: '/insights/settings/data-crawling'}
                  "
                ></ng-container>
              </ng-template>
            </div>
          </div>
          <ng-container *ngIf="hasPriceCrawl$ | async">
            <div class="feature sub-feature">
              <em-icon class="text-success-500" name="check"></em-icon>

              {{ 'PLAN_LABLE_PRODUCTS_COUNT' | translate }} :
              {{ totalCrawlProducts$ | async }}
            </div>

            <div class="feature sub-feature">
              <em-icon class="text-success-500" name="check"></em-icon>

              {{ 'SHARED_LABEL_PRODUCTS_SOURCES' | translate }}:
              {{ priceCrawlSourcesCount$ | async }}
            </div>

            <div class="feature sub-feature">
              <em-icon class="text-success-500" name="check"></em-icon>

              {{ 'SHARED_LABEL_FREQUENCY' | translate }}:

              {{
                'SHARED_LABEL_' + (crawlHigherFrequencyTitle$ | async)
                  | translate
              }}
            </div>
          </ng-container>
        </div>

        <div class="feature-section">
          <div class="feature">
            <em-checkbox
              [ngModel]="toEditContract.google_prices?.enabled"
              (ngModelChange)="updateGooglePrice($event)"
            >
              <div class="flex">
                <img
                  [src]="Google.logos['googlePrices']"
                  width="20"
                  class="mr-2"
                />
                {{ 'SHARED_LABEL_GOOGLE_PRICES' | translate }}
              </div>
            </em-checkbox>
          </div>
        </div>

        <div class="feature-section">
          <div class="feature">
            <div class="flex w-full justify-between">
              <em-checkbox
                [ngModel]="toEditContract.repricing?.enabled"
                (ngModelChange)="updateRepricing($event)"
              >
                <div class="flex">
                  <em-icon
                    class="text-primary-500 mr-2"
                    name="local_offer"
                  ></em-icon>

                  {{ 'NAV_REPRICING' | translate }}
                  <ng-container
                    *ngIf="
                      repricingProductsCount$ | async as repricingProductsCount
                    "
                  >
                    ({{ 'PLAN_LABLE_PRODUCTS_COUNT' | translate }}:
                    {{ repricingProductsCount }})
                  </ng-container>
                </div>
              </em-checkbox>
              <ng-container
                *ngIf="(repricingProductsCount$ | async) === undefined"
              >
                <ng-container
                  *ngTemplateOutlet="
                    dataMissingIcon;
                    context: {url: '/insights/settings/data-crawling'}
                  "
                ></ng-container
              ></ng-container>
            </div>
          </div>
        </div>

        <div class="feature-section">
          <div class="feature">
            <em-checkbox
              [ngModel]="toEditContract.datafeed?.csv_export"
              (ngModelChange)="updateDatafeed({csv_export: $event})"
            >
              <div class="flex">
                <img
                  src="/assets/images/shared/csv-file.png"
                  width="20"
                  class="mr-2"
                />
                {{ 'PLANS_CSV_EXPORT' | translate }}
              </div>
            </em-checkbox>
          </div>
        </div>

        <div class="feature-section">
          <div class="feature section-title p-2 ml-1">
            <div class="flex w-full justify-between">
              <div class="flex">
                <img [src]="Google.logos.campaign" width="20" class="mr-2" />
                {{ 'NAV_GOOGLE_SHOPPING' | translate }}
              </div>
            </div>
          </div>

          <div class="feature sub-feature">
            <em-checkbox
              [ngModel]="toEditContract.datafeed?.google_upload"
              (ngModelChange)="updateDatafeed({google_upload: $event})"
            >
              {{ 'SHARED_DF_UPLOAD' | translate }}
              {{ 'ONBOARDING_SERVICE_DATAFEED_GOOGLE' | translate }}
            </em-checkbox>
          </div>

          <div class="feature sub-feature">
            <em-checkbox
              [ngModel]="toEditContract.advertising?.google_campaigns"
              (ngModelChange)="updateAds({google_campaigns: $event})"
            >
              {{ 'CAMPAIGN_TYPE_GOOGLE_ADS' | translate }} +
              {{ 'INTEGRATIONS_CONVERSION_TRACKER_TITLE' | translate }}
            </em-checkbox>
          </div>

          <div class="feature sub-feature">
            <em-checkbox
              [ngModel]="toEditContract.advertising?.google_css"
              (ngModelChange)="updateAds({google_css: $event})"
            >
              {{ 'SHARED_GOOGLE_CSS_PROGRAM' | translate }}
            </em-checkbox>
          </div>
        </div>

        <div class="feature-section">
          <div class="feature section-title p-2 ml-1">
            <div class="flex w-full justify-between">
              <div class="flex">
                <img [src]="facebook.logos.campaign" width="20" class="mr-2" />
                {{ 'NAV_FACEBOOK' | translate }}
              </div>
            </div>
          </div>

          <div class="feature sub-feature">
            <em-checkbox
              [ngModel]="toEditContract.datafeed?.meta_upload"
              (ngModelChange)="updateDatafeed({meta_upload: $event})"
            >
              {{ 'SHARED_DF_UPLOAD' | translate }}
              {{ 'SHARED_LABEL_FACEBOOK_MERCHANT_CENTER' | translate }}
            </em-checkbox>
          </div>

          <div class="feature sub-feature">
            <em-checkbox
              [ngModel]="toEditContract.advertising?.meta_campaigns"
              (ngModelChange)="updateAds({meta_campaigns: $event})"
            >
              {{ 'SHARED_LABEL_FACEBOOK_RETARGETING' | translate }},
              {{ 'SHARED_LABEL_FACEBOOK_LOOKALIKE' | translate }},
              {{ 'SHARED_FACEBOOK_DYNAMIC_ADS' | translate }} +
              {{ 'SHARED_FACEBOOK_CONVERSION_TRACKING' | translate }}
            </em-checkbox>
          </div>
        </div>

        <div class="feature-section">
          <div class="feature section-title p-2 ml-1">
            <div class="flex w-full justify-between">
              <div class="flex">
                <img [src]="microsoft.logos.campaign" width="20" class="mr-2" />
                {{ 'NAV_BING' | translate }}
              </div>
            </div>
          </div>

          <div class="feature sub-feature">
            <em-checkbox
              [ngModel]="toEditContract.datafeed?.microsoft_upload"
              (ngModelChange)="updateDatafeed({microsoft_upload: $event})"
            >
              {{ 'SHARED_DF_UPLOAD' | translate }}
              {{ 'SHARED_LABEL_MICROSOFT_MC' | translate }}
            </em-checkbox>
          </div>

          <div class="feature sub-feature">
            <em-checkbox
              [ngModel]="toEditContract.advertising?.microsoft_campaigns"
              (ngModelChange)="updateAds({microsoft_campaigns: $event})"
            >
              {{ 'SHARED_LABEL_MICROSOFT_SMART_SHOPPING' | translate }} +
              {{ 'SHARED_MICROSOFT_CONVERSION_TRACKING' | translate }}
            </em-checkbox>
          </div>
        </div>
      </div>
    </div>

    <em-plan-box
      *ngIf="liveContractWithPrice$ | async as liveContract"
      class="mt-4 w-[280px]"
      [contract]="liveContract"
      [current]="true"
    ></em-plan-box>

    <ng-container *ngIf="showSuggestedPlan$ | async">
      <em-plan-box
        *ngIf="suggestedPlan$ | async as suggestedPlan"
        class="mt-4 w-[280px]"
        [contract]="suggestedPlan"
        [highlightDiff]="liveContractWithPrice$ | async"
        [recommended]="true"
        (subscribe)="upgrade()"
      ></em-plan-box>
    </ng-container>
  </div>

  <ng-template #dataMissingIcon let-url="url">
    <div
      (click)="closeAndNavigate(url)"
      class="flex justify-between items-center text-danger-500 cursor-pointer"
    >
      Setup Missing
      <em-icon class="ml-1" name="warning_amber" size="sm"></em-icon>
    </div>
  </ng-template>
</div>
