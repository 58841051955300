import {Pipe, PipeTransform} from '@angular/core';
import {CurrencyCode, CURRENCY_SYMBOLS} from '@em/shared/util-types';

@Pipe({
  name: 'currencySymbol',
  standalone: true,
})
export class EmCurrencySymbolPipe implements PipeTransform {
  transform(value: CurrencyCode | undefined | null): string | undefined {
    if (value === undefined || value === null) {
      return undefined;
    }

    return CURRENCY_SYMBOLS[value] || value;
  }
}
