import {ISetupStatus} from './setup-status';

export const initialSetupStatus: ISetupStatus = {
  loaded: false,
  contract: null,
  merchant: {
    hasEmail: false,
    hasGoogleOAuth: false,
    hasAdwordsConnection: false,
    hasMerchantCenter: false,
    hasFullSetup: false,
  },

  merchantCenter: {
    availableCountries: [],
  },

  hasAutomaticCampaigns: false,

  datafeed: {
    isSetup: false,
    canConnect: false,
    hasProductFetch: false,
  },

  repricing: {
    isSetup: false,
    canConnect: false,
  },

  hasGoogleSPD: false,
  facebookSetup: {
    hasFacebookAccount: false,
    hasFacebookAuth: false,
    hasFullSetup: false,
  },
};
