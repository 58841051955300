/**
 * Returns the difference between two arrays.
 *
 * @template T The type of elements in the arrays.
 * @param {T[]} arr1 The first array.
 * @param {T[]} arr2 The second array.
 * @returns {T[]} An array containing elements that are present in the first array but not in the second array.
 * @example
 * const arr1 = [1, 2, 3, 4, 5];
 * const arr2 = [3, 4, 5, 6, 7];
 * const diff = difference(arr1, arr2); // Output: [1, 2]
 */
export function difference<T>(arr1: T[], arr2: T[]): T[] {
  return [arr1, arr2].reduce((a, b) => a.filter((c) => !b.includes(c)));
}
