import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {distinctUntilChanged, map, switchMap} from 'rxjs/operators';
import {ITheme} from './theme';
import {WhiteLabelService} from './white-label.service';

export interface IFavicons {
  links: string[];
}

@Injectable({
  providedIn: 'root',
})
export class ThemingService {
  constructor(
    private readonly _whiteLabel: WhiteLabelService,
    private readonly _http: HttpClient,
  ) {}

  faviconsObservable(): Observable<IFavicons> {
    return this._whiteLabel
      .observable()
      .pipe(switchMap((theme) => this._getFavicons(theme)));
  }

  titleObservable(): Observable<string> {
    return this._whiteLabel.observable().pipe(
      map((theme) => theme.title),
      distinctUntilChanged(),
    );
  }

  private _getFavicons(themeChange: ITheme): Observable<IFavicons> {
    return this._http.get<IFavicons>(themeChange.favicons);
  }
}
