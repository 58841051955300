import {Injectable, inject} from '@angular/core';

import {
  ProductsGateway,
  ProductsListFilter,
  ProductsListSorting,
} from '@em/shared/api-interface';
import {
  map,
  shareReplay,
  startWith,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';

import {CountryService} from '@em/user-account/data-access';
import {BehaviorSubject, Observable, Subject, combineLatest, of} from 'rxjs';
import {ProductGroupsStore} from '../product-groups/product-groups.store';
import {isNotNullOrUndefined} from '@em/shared/util-rxjs';
import {GroupsProductsService} from '../product-groups/groups-products.service';

export interface SearchParams {
  page: number;
  count: number;
  sorting?: ProductsListSorting;
  filter?: ProductsListFilter;
  group?: string;
}

@Injectable()
export class ProductsListService {
  private readonly _productsGateway = inject(ProductsGateway);
  private readonly _countryService = inject(CountryService);
  private readonly _productGroupsStore = inject(ProductGroupsStore);
  private readonly _groupsProductsService = inject(GroupsProductsService);

  private readonly _reloadTotalCount$ = new Subject<void>();
  private readonly _isEmpty$ = new BehaviorSubject<boolean>(false);
  private readonly _filteredProductsCount$ = new BehaviorSubject<
    number | undefined
  >(undefined);

  readonly isEmpty$ = this._isEmpty$.asObservable();
  readonly filteredProductsCount$ = this._filteredProductsCount$.asObservable();
  readonly allProductsCount$: Observable<number> = combineLatest([
    this._countryService.observable(),
    this._reloadTotalCount$.pipe(startWith(true)),
  ]).pipe(
    switchMap(([countryCode]) =>
      this._productsGateway.getCount({country: countryCode}),
    ),
    map((respons) => respons.product_count || 0),
    shareReplay({refCount: false, bufferSize: 1}),
  );

  reloadTotalCount() {
    this._reloadTotalCount$.next();
  }

  loadProductsBatch(searchParams: SearchParams) {
    this._isEmpty$.next(false);

    return this._countryService.observable().pipe(
      switchMap((country) => {
        if (searchParams.group) {
          return combineLatest([
            of(country),
            this._productGroupsStore.getGroup(searchParams.group, country).pipe(
              isNotNullOrUndefined(),
              take(1),
              switchMap((group) =>
                this._groupsProductsService.getProductsIds(group),
              ),
            ),
          ]);
        } else {
          return combineLatest([of(country), of(null)]);
        }
      }),
      take(1),
      switchMap(([country, idsList]) => {
        // Add the products ids filter if exist
        const finalFilter: ProductsListFilter = idsList
          ? {
              ...searchParams.filter,
              product_id: {operator: 'in', value: idsList},
            }
          : searchParams.filter;

        return this._productsGateway.postSearch({
          country,
          count: searchParams.count,
          page: searchParams.page,
          filter: finalFilter,
          sorting: searchParams.sorting,
        });
      }),
      tap((result) => {
        if (result.total_count === 0) {
          this._isEmpty$.next(true);
        }

        this._filteredProductsCount$.next(result.total_count);
      }),
    );
  }
}
