import {ProductsListItem} from '@em/shared/api-interface';
import {ValueGetterFunc, ValueGetterParams} from 'ag-grid-community';

export const moneyValueGetter =
  (
    valueProperty: keyof ProductsListItem,
    currenctyProperty: keyof ProductsListItem,
  ): ValueGetterFunc =>
  (params: ValueGetterParams) => ({
    value:
      params?.data?.[valueProperty] != null
        ? params?.data?.[valueProperty]
        : undefined,
    currency: params?.data?.[currenctyProperty] || undefined,
  });
