import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {INITIALIZABLE} from '@em/shared/util-types';
import {PusherService} from './pusher-service/pusher.service';

@NgModule({
  imports: [CommonModule],
  providers: [],
})
export class NotificationsFeatureModule {
  static forRoot(): ModuleWithProviders<NotificationsFeatureModule> {
    return {
      ngModule: NotificationsFeatureModule,
      providers: [
        {
          provide: INITIALIZABLE,
          multi: true,
          useExisting: PusherService,
        },
      ],
    };
  }
}
