import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {PipelineResp, ProductPipelinesGateway} from '@em/shared/api-interface';
import {DatafeedGateway} from '@em/shared/api-interface/lib/gateways/datafeed.gateway';
import {
  ComponentStoreBase,
  ComponentStoreStateBase,
} from '@em/shared/util-types';
import {tapResponse} from '@ngrx/component-store';
import {EMPTY, combineLatest} from 'rxjs';
import {filter, switchMap, take, tap} from 'rxjs/operators';
import {DatafeedStore} from '../datafeed-store/datafeed.store';
import {PipelineListStore} from '../pipeline-list/pipeline-list.store';
import {FetchProductsStore} from '@em/data-feed/data-access-products';
import {PluginName} from '@em/shop-system/data-access-settings';

const pluginsWithSettings: PluginName[] = [
  'plentymarkets',
  'prestashop',
  'shopify',
  'shopware',
];

type errorTypes = 'load-pipeline' | 'add-pipeline';

export interface PipelineSetupState
  extends ComponentStoreStateBase<errorTypes> {
  pipeline?: PipelineResp;
  sourceSetupCompleted?: boolean;
  shopSystemName?: PluginName | null;
  shopToken?: string | null;

  pipelineSaved?: boolean;
}

const initialState: PipelineSetupState = {isLoading: false};

@Injectable()
export class PipelineSetupStore extends ComponentStoreBase<
  PipelineSetupState,
  errorTypes
> {
  readonly pipeline$ = this.select((state) => state?.pipeline);
  readonly source$ = this.select((state) => state?.pipeline?.source);
  readonly uuid$ = this.select((state) => state?.pipeline?.uuid);
  readonly mappingEngineUuid$ = this.select(
    (state) => state?.pipeline?.mapping_engine_uuid,
  );

  readonly sourceSetupCompleted$ = this.select(
    (state) => state.sourceSetupCompleted,
  );
  readonly pipelineOptions$ = this.select(
    (state) => state?.pipeline?.source_options,
  );
  readonly shopSystemName$ = this.select((state) => state.shopSystemName);
  readonly shopToken$ = this.select((state) => state.shopToken);
  readonly hasPluginSettings$ = this.select(
    (state) =>
      state?.pipeline?.source === 'datafeed_api' &&
      !!state.shopSystemName &&
      pluginsWithSettings.includes(state.shopSystemName),
  );
  readonly pipelineSaved$ = this.select((state) => state?.pipelineSaved);

  readonly updateSourceStupeCompleted = this.updater<boolean>(
    (state, sourceSetupCompleted): PipelineSetupState => ({
      ...state,
      sourceSetupCompleted,
    }),
  );

  readonly updatePipelineOptions = this.updater<{[k: string]: unknown}>(
    (state, pipelineOptions): PipelineSetupState => ({
      ...state,
      pipeline: {
        ...state.pipeline,
        source_options: {
          ...(state.pipeline?.source_options || {}),
          ...pipelineOptions,
        },
      } as PipelineResp,
    }),
  );

  constructor(
    private readonly _datafeedGateway: DatafeedGateway,
    private readonly _fetchProductsStore: FetchProductsStore,
    private readonly _datafeedStore: DatafeedStore,
    private readonly _pipelinesGateway: ProductPipelinesGateway,
    private readonly _pipelineListStore: PipelineListStore,
  ) {
    super(initialState);
    this.loadDatafeedInfo();
  }

  readonly loadPipeline = this.effect<string | null | undefined>((uuid$) =>
    uuid$.pipe(
      switchMap((uuid) => {
        if (uuid) {
          this.startLoading();
          return this._pipelinesGateway.getProductPipelines({uuid}).pipe(
            tapResponse(
              (pipeline) => {
                this.patchState({
                  pipeline,
                  isLoading: false,
                });
              },
              (err: HttpErrorResponse) => {
                this.addError({
                  httpError: err,
                  errorMessage: {
                    key: 'load-pipeline',
                  },
                  statePayload: {
                    pipeline: undefined,
                  },
                });
              },
            ),
          );
        } else {
          this.patchState({
            pipeline: undefined,
            isLoading: false,
          });
          return EMPTY;
        }
      }),
    ),
  );

  readonly loadDatafeedInfo = this.effect<void>((trigger$) =>
    trigger$.pipe(
      switchMap(() =>
        combineLatest([this.source$, this.sourceSetupCompleted$]),
      ),
      filter(
        ([source, isCompleted]) => source === 'datafeed_api' && !!isCompleted,
      ),
      tap(() => this._datafeedStore.loadPlugin(true)),
      switchMap(() =>
        combineLatest([
          this._datafeedStore.pluginName$,
          this._datafeedGateway.getSettings(),
        ]).pipe(
          tapResponse(
            ([pluginName, settings]) => {
              this.patchState({
                shopSystemName: pluginName,
                shopToken: settings.shop_token,
              });
            },
            () => {
              this.patchState({
                shopSystemName: null,
                shopToken: null,
              });
            },
          ),
        ),
      ),
    ),
  );

  readonly savePipeline = this.effect<void>((trigger$) =>
    trigger$.pipe(
      switchMap(() => {
        this.patchState({pipelineSaved: undefined});
        const currentPipeline = this.get((s) => s.pipeline);
        if (currentPipeline) {
          this.startLoading();
          return this._pipelinesGateway
            .postProductPipelines({
              active: currentPipeline.active,
              source: currentPipeline.source,
              source_options: currentPipeline?.source_options,
              uuid: currentPipeline?.uuid,
            })
            .pipe(
              tapResponse(
                (newPipeline) => {
                  this._pipelineListStore.loadPipelines(true);
                  this.patchState({
                    pipeline: newPipeline,
                    isLoading: false,
                    pipelineSaved: true,
                  });
                },
                (err: HttpErrorResponse) => {
                  this.addError({
                    httpError: err,
                    errorMessage: {
                      key: 'add-pipeline',
                    },
                    statePayload: {pipelineSaved: false},
                  });
                },
              ),
            );
        } else {
          return EMPTY;
        }
      }),
    ),
  );

  triggerProductsFetch() {
    return this.pipeline$.pipe(
      take(1),
      tap((pipeline) => {
        if (pipeline?.active) {
          this._fetchProductsStore.fetchProducts();
        }
      }),
    );
  }
}
