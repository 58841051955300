export const EnvVars = {
  FACEBOOK_APP_ID: process.env['FACEBOOK_APP_ID'],
  FACEBOOK_APP_SCOPE: process.env['FACEBOOK_APP_SCOPE'],
  FACEBOOK_GRAPH_API_VERSION: process.env['FACEBOOK_GRAPH_API_VERSION'],
  GOOGLE_CLIENT_ID: process.env['GOOGLE_CLIENT_ID'],
  GOOGLE_CLIENT_SCOPE: process.env['GOOGLE_CLIENT_SCOPE'],
  GOOGLE_TAG_MANAGER_ID: process.env['GOOGLE_TAG_MANAGER_ID'],
  HEROKU_RELEASE_VERSION: process.env['HEROKU_RELEASE_VERSION'],
  INTERCOM_APP_ID: process.env['INTERCOM_APP_ID'],
  INTERCOM_ENABLED: process.env['INTERCOM_ENABLED'],
  PUBLIC_SENTRY_DSN: process.env['PUBLIC_SENTRY_DSN'],
  RACK_ENV: process.env['RACK_ENV'],
  SHOPSYSTEM_WEBCOMPONENTS_URL: process.env['SHOPSYSTEM_WEBCOMPONENTS_URL'],
  INSIGHTS_WEBCOMPONENTS_URL: process.env['INSIGHTS_WEBCOMPONENTS_URL'],
  SOURCE_VERSION: process.env['SOURCE_VERSION'],
  WHITELABEL_URL_EMARKETING: process.env['WHITELABEL_URL_EMARKETING'],
  PUSHER_APP_KEY: process.env['PUSHER_APP_KEY'],
  SIGNUP_GATEWAY_URL: process.env['SIGNUP_GATEWAY_URL'],
  WEBSTOLLEN_SIGNUP_GATEWAY_URL: process.env['WEBSTOLLEN_SIGNUP_GATEWAY_URL'],
  REPRICING_GATEWAY_URL: process.env['REPRICING_GATEWAY_URL'],
  EMARKETING_GATEWAY_URL: process.env['EMARKETING_GATEWAY_URL'],
  PAYMENT_GATEWAY_URL: process.env['PAYMENT_GATEWAY_URL'],
  BING_CLIENT_ID: process.env['BING_CLIENT_ID'],
  BING_CLIENT_SCOPE: process.env['BING_CLIENT_SCOPE'],
  BING_REDIRECT_URL: process.env['BING_REDIRECT_URL'],
  SHOPSYSTEM_GATEWAY_URL: process.env['SHOPSYSTEM_GATEWAY_URL'],
  MARKET_INSIGHTS_GATEWAY_URL: process.env['MARKET_INSIGHTS_GATEWAY_URL'],
  RECAPTCHA_SITE_KEY: process.env['RECAPTCHA_SITE_KEY'],
  STRIPE_PUBLIC_API_KEY: process.env['STRIPE_PUBLIC_API_KEY'],
};
