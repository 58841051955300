<div class="mb-2">
  <mat-form-field class="form-field-sm w-full" subscriptSizing="dynamic">
    <mat-select
      [ngModel]="keyFormControl?.value"
      (ngModelChange)="keyValueChanged($event)"
    >
      <mat-option *ngFor="let key of filterKeyOptions" [value]="key">
        {{ i18nFilterKey(key) | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div class="grid grid-cols-2 gap-1">
  <div>
    <mat-form-field class="form-field-sm w-full" subscriptSizing="dynamic">
      <mat-select
        [ngModel]="optionsValue.operator"
        (ngModelChange)="optionValueChange('operator', $event)"
        [required]="!!operatorOption?.definition?.required"
      >
        <mat-option
          *ngFor="let val of operatorOption?.definition?.selectableValues"
          [value]="val"
        >
          {{ i18nDropdownKey(val) | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field
      class="form-field-sm w-full"
      subscriptSizing="dynamic"
      *ngIf="showValueField"
    >
      <input
        matInput
        [ngModel]="optionsValue.value"
        (ngModelChange)="
          optionValueChange('value', $event, valueOption?.inputType || 'string')
        "
        [disabled]="disabled"
        [min]="valueOption?.definition?.minimum"
        [max]="valueOption?.definition?.maximum"
        [step]="valueOption?.definition?.step"
        [required]="!!valueOption?.definition?.required"
        (blur)="form?.markAllAsTouched()"
        [placeholder]="
          'CAMPAIGN_PERFORMANCE_FILTER_VALUE_PLACEHOLDER' | translate
        "
        [type]="valueOption?.inputType || ''"
      />
    </mat-form-field>
  </div>
</div>
