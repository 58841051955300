/* eslint-disable max-len */
import {getPluginLogo} from './get-plugin-logo';

export interface ShopSystemPluginInfo {
  title: string;
  link: string;
  docsLink: string;
  logo: string;
  available: boolean;
}

export const EmarketinShopSystemPlugins: ShopSystemPluginInfo[] = [
  {
    title: 'plentymarkets',
    link: 'https://marketplace.plentymarkets.com/emarketing_6198',
    docsLink:
      'https://support.emarketing.com/de/articles/4984473-plentymarkets-installation-des-emarketing-datenfeed-und-conversion-tracking-plugins',
    logo: getPluginLogo('plentymarkets'),
    available: true,
  },
  {
    title: 'JTL Shop',
    link: 'https://www.webstollen.de/emarketing-Plugin',
    docsLink:
      'https://support.emarketing.com/de/articles/3690295-jtl-plugin-installation',
    logo: getPluginLogo('jtl_4'),
    available: true,
  },
  {
    title: 'Shopware',
    link: 'https://store.shopware.com/easym93787370712f/datenfeedmanagement-shopping/kampagnenmangement-sw6.html',
    docsLink:
      'https://support.emarketing.com/de/articles/3678786-shopware-installation-des-emarketing-datenfeed-und-conversion-tracking-plugins',
    logo: getPluginLogo('shopware'),
    available: true,
  },
  {
    title: 'Shopify',
    link: 'https://apps.shopify.com/emarketing?surface_detail=emarketing&surface_inter_position=1&surface_intra_position=1&surface_type=search&locale=de',
    docsLink:
      'https://support.emarketing.com/de/articles/3339984-emarketing-mit-plugin-nutzen',
    logo: getPluginLogo('shopify'),
    available: true,
  },
  {
    title: 'Prestashop',
    link: 'https://addons.prestashop.com/de/sea-bezahlte-werbung-affiliate-plattformen/18716-microsoft-ads-google-ads-social-media-by-emarketing.html',
    docsLink:
      'https://addons.prestashop.com/de/sea-bezahlte-werbung-affiliate-plattformen/18716-microsoft-ads-google-ads-social-media-by-emarketing.html',
    logo: getPluginLogo('prestashop'),
    available: true,
  },
  {
    title: 'Lightspeed',
    link: 'https://www.lightspeedhq.de/ecommerce/store/apps/emarketing/',
    docsLink:
      'https://support.emarketing.com/en/articles/4984940-lightspeed-install-the-emarketing-app-for-lightspeed',
    logo: getPluginLogo('lightspeed'),
    available: true,
  },
  {
    title: 'Magento',
    link: 'https://commercemarketplace.adobe.com/partner/emarketing/',
    docsLink:
      'https://support.emarketing.com/de/articles/3339984-emarketing-mit-plugin-nutzen',
    logo: getPluginLogo('magento'),
    available: true,
  },
];
