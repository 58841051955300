<div class="px-8 py-6">
  <em-spinner
    class="m-auto"
    *ngIf="isLoading"
    [overContent]="true"
  ></em-spinner>

  <ng-container *ngIf="showSuccess">
    <em-success-box class="mb-4">
      <ng-container description>
        {{ 'CANCEL_SUBSCRIPTION_SUCCESS' | translate }}
      </ng-container>
    </em-success-box>
    <div class="text-right">
      <em-secondary-button class="mr-4" [mat-dialog-close]="false" color="">
        <span>{{ 'SHARED_LABEL_CLOSE' | translate }}</span>
      </em-secondary-button>
    </div>
  </ng-container>

  <em-error-box class="mb-4" *ngIf="showError && !showSuccess">
    <ng-container description>
      {{ 'CONTACT_REQUEST_FAILED' | translate }}
    </ng-container>
  </em-error-box>

  <ng-container *ngIf="!showSuccess">
    <div class="text-center">
      <em-icon
        class="inline-block m-auto text-danger-500"
        name="sentiment_very_dissatisfied"
        size="xl"
      ></em-icon>
    </div>
    <div class="mb-2 text-center font-semibold">
      {{ 'CANCEL_SUBSCRIPTION_TITLE' | translate }}
    </div>
    <div class="mb-6">{{ 'CANCEL_SUBSCRIPTION_DESC' | translate }}</div>

    <em-input
      class="w-full"
      [label]="'SHARED_LABEL_CANCEL_REASON' | translate"
      [(ngModel)]="cancelReason"
    >
    </em-input>

    <div class="text-right">
      <em-secondary-button class="mr-4" [mat-dialog-close]="false" color="">
        <span>{{ 'SHARED_LABEL_KEEP_SUBSCRIPTION' | translate }}</span>
      </em-secondary-button>

      <em-primary-button
        class="mr-4"
        [color]="'danger'"
        (btnClick)="confirmCancelation()"
      >
        <span>{{ 'SHARED_LABEL_CANCEL_SUBSCRIPTION' | translate }}</span>
      </em-primary-button>
    </div>
  </ng-container>
</div>
