/// <reference types="google.accounts" />
import {Injectable} from '@angular/core';
import {configurationHelper} from '@em/shared/util-configuration';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {ExternalSdkLoaderService, ISdk} from '@em/shared/util-web';

const SDK_URL = 'https://accounts.google.com/gsi/client';

export const gapiSdk: ISdk = {
  id: 'gapi',
  src: SDK_URL,
  sdkRoot: 'gapi',
  async: true,
  onload: true,
  onerror: true,
};

@Injectable({
  providedIn: 'root',
})
export class GapiAuthService {
  constructor(private readonly _sdkLoader: ExternalSdkLoaderService) {}

  oauthSignIn(scope?: string): Observable<string> {
    return new Observable((subscriber) => {
      this._sdkLoader.loadSdk<unknown>(gapiSdk).subscribe(
        (auth) => {
          if (!auth || !google) {
            subscriber.error(new Error('Google SDK not loaded'));
            return;
          }

          const client = google.accounts.oauth2.initTokenClient({
            client_id: configurationHelper.needConfig('GOOGLE_CLIENT_ID'),
            scope: scope || 'email',
            callback: (tokenResponse: {access_token: string}) => {
              subscriber.next(tokenResponse.access_token);
              subscriber.complete();
            },
          });

          client.requestAccessToken();
        },
        (err: unknown) => subscriber.error(err),
      );
    });
  }

  authorizeUser(): Observable<string> {
    return new Observable((subscriber) => {
      this._sdkLoader.loadSdk<unknown>(gapiSdk).subscribe(
        (auth) => {
          if (!auth || !google) {
            subscriber.error(new Error('Google SDK not loaded'));
            return;
          }
          const requiredScopes = configurationHelper.needConfig(
            'GOOGLE_CLIENT_SCOPE',
          );
          const client = google.accounts.oauth2.initCodeClient({
            client_id: configurationHelper.needConfig('GOOGLE_CLIENT_ID'),
            scope: requiredScopes,
            callback: (response: {code: string; scope: string}) => {
              // make sure all the scopes are checked by the user
              if (this._hasAllPermissions(requiredScopes, response.scope)) {
                subscriber.next((response as {code: string}).code);
                subscriber.complete();
              } else {
                subscriber.error(new Error('Missing Permissions'));
              }
            },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            error_callback: () => {
              subscriber.error(new Error('Google Window Closed'));
            },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } as any);

          client.requestCode();
        },
        (err: unknown) => subscriber.error(err),
      );
    });
  }

  loadSdk(): Observable<void> {
    return this._sdkLoader.loadSdk<unknown>(gapiSdk).pipe(
      catchError((error: unknown) =>
        throwError(
          `Google Platform SDK did not load properly. Reason: ${JSON.stringify(
            error,
          )}`,
        ),
      ),
      map(() => {}),
    );
  }

  private _hasAllPermissions(expected: string, userConsents: string) {
    const expectedScopes = expected.split(' ');
    const userConsentsScopes = userConsents.split(' ');

    for (const expectedScope of expectedScopes) {
      if (!userConsentsScopes.includes(expectedScope)) {
        return false;
      }
    }

    return true;
  }
}
