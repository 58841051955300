import {Injectable} from '@angular/core';
import {ProductsGateway} from '@em/shared/api-interface/lib/gateways/products.gateway';
import {combineLatest, forkJoin, Observable, of} from 'rxjs';
import {catchError, map, take, tap} from 'rxjs/operators';
import {CountryService} from '@em/user-account/data-access';
import {ProductFilterDefinition} from '../product-filter-definition';

@Injectable({
  providedIn: 'root',
})
export class ExtendFilterDefinitionService {
  constructor(
    private readonly _productsGateway: ProductsGateway,
    private readonly _countryService: CountryService,
  ) {}

  // Filter definition read from the selected_filter.json needs to be extended
  // for some filter, to load the option values from the back-end
  extendFilterDefinition(filterDefinitions: {
    [key: string]: ProductFilterDefinition;
  }): Observable<{[key: string]: ProductFilterDefinition}> {
    const obsList = [];

    if (filterDefinitions['custom_labels']) {
      obsList.push(
        this._addCustomLabelOptions(filterDefinitions['custom_labels']),
      );
    }

    if (obsList.length) {
      return forkJoin(obsList).pipe(map(() => filterDefinitions));
    } else {
      return of(filterDefinitions);
    }
  }

  private _addCustomLabelOptions(filterDefinition: ProductFilterDefinition) {
    return combineLatest([
      this._countryService.observable(),
      this._productsGateway.getCustomLabels().pipe(),
    ]).pipe(
      take(1),
      tap(([country, customLabelsResp]) => {
        const customLabelsList =
          (customLabelsResp.custom_labels[country] as string[]) || [];
        const operandOption = filterDefinition.availableOptions.find(
          (options) => options.name === 'operand',
        );
        if (operandOption) {
          operandOption.selectableValues.length = 0;
          operandOption.selectableValues.push(...customLabelsList);
        }
      }),
      catchError(() => of(false)),
    );
  }
}
