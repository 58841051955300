import {
  ChangeDetectionStrategy,
  Component,
  Input,
  NgModule,
  OnChanges,
} from '@angular/core';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {ApexOptions, NgApexchartsModule} from 'ng-apexcharts';
import {ApexChartsHelper} from '../../utils/apex-charts.helper';
import {CommonModule} from '@angular/common';

import {IShareKpi} from '@em/shared/platforms/util';
import {EmSpinnerModule, EmNumberWithUnitPipe} from '@em/shared/ui';

@Component({
  selector: 'em-pie-chart-shared',
  templateUrl: './pie-chart-shared.component.html',
  styleUrls: [
    './pie-chart-shared.component.scss',
    '../../pie-chart.shared.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmPieChartSharedComponent implements OnChanges {
  @Input() data?: IShareKpi | null;
  @Input() title?: string;
  @Input() translationKey?: string;
  @Input() isLoading?: boolean | null;
  readonly chartOptions: ApexOptions;

  constructor(private readonly _translate: TranslateService) {
    this.chartOptions = ApexChartsHelper.mergePieChartOptions({
      colors: ['#FF7901', '#FF790066', '#F4F5F7'],
      tooltip: {
        enabled: true,
        followCursor: true,
        custom: ({w, seriesIndex}) => this._getTooltipContent({w, seriesIndex}),
      },
    });
  }

  get values() {
    return this.data
      ? [this.data.withValue, this.data.withoutValue, this.data.unknown]
      : [];
  }

  ngOnChanges(): void {
    if (this.translationKey) {
      this._updateLabels();
    }
  }

  hasData(): boolean {
    return !!this.data && this.data.unknown !== 100;
  }

  private _getTooltipContent({
    w,
    seriesIndex,
  }: {
    w: {globals: {labels: string[]; series: number[]}};
    seriesIndex: number;
  }): string {
    const value = `${w.globals.series[seriesIndex].toFixed(1)}%`;

    return `
      <div class="tooltip">
        <div>${this._translate.instant(w.globals.labels[seriesIndex], {
          value,
        })}</div>
      </div>
    `;
  }

  private _updateLabels() {
    this.chartOptions.labels = [
      `${this.translationKey}_WITH_VALUE`,
      `${this.translationKey}_WITHOUT_VALUE`,
      `${this.translationKey}_UNKNOWN`,
    ];
  }
}

@NgModule({
  declarations: [EmPieChartSharedComponent],
  exports: [EmPieChartSharedComponent],
  imports: [
    CommonModule,
    EmNumberWithUnitPipe,
    NgApexchartsModule,
    TranslateModule,
    EmSpinnerModule,
  ],
})
export class EmPieChartSharedModule {}
