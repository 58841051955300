<ng-container *ngIf="item?.command; else nonCommandTemp">
  <a
    mat-list-item
    [emBindCommand]="item?.command"
    [disabled]="disabled"
    [class.active-list-item]="item?.isActive"
    (click)="itemClicked.emit(item)"
  >
    <mat-icon matListItemIcon *ngIf="item?.iconName">{{
      item?.iconName
    }}</mat-icon>

    <img
      matListItemIcon
      *ngIf="item?.iconImage && !item?.iconName"
      [src]="item?.iconImage"
      class="item-icon"
    />

    <span matListItemLine>{{ item?.title | translate }}</span>
  </a>

  <div *ngIf="item?.isBeta" class="beta-tag">BETA</div>
</ng-container>

<ng-template #nonCommandTemp>
  <a
    mat-list-item
    *ngIf="item?.targetPath"
    [routerLink]="item?.targetPath"
    [class.active-list-item]="item?.isActive"
    [disabled]="disabled"
    (click)="itemClicked.emit(item)"
  >
    <mat-icon matListItemIcon *ngIf="item?.iconName">{{
      item?.iconName
    }}</mat-icon>

    <img
      matListItemIcon
      *ngIf="item?.iconImage && !item?.iconName"
      [src]="item?.iconImage"
      class="item-icon"
    />

    <span matListItemLine>{{ item?.title | translate }}</span>
  </a>

  <a
    mat-list-item
    [disabled]="disabled"
    *ngIf="!item?.targetPath"
    [ngClass]="{'nested-item-active': item?.isActive || openSubList}"
    (click)="openSubList = !openSubList"
  >
    <mat-icon matListItemIcon *ngIf="item?.iconName">{{
      item?.iconName
    }}</mat-icon>

    <img
      matListItemIcon
      *ngIf="item?.iconImage && !item?.iconName"
      [src]="item?.iconImage"
      class="item-icon"
    />

    <span matListItemLine>{{ item?.title | translate }}</span>
  </a>

  <div *ngIf="item?.isBeta" class="beta-tag">BETA</div>

  <div
    class="sub-list-wrapper"
    *ngIf="
      !hideNested && item?.items?.length && (item?.isActive || openSubList)
    "
    [@expandCollapse]
  >
    <mat-nav-list class="sub-list">
      <em-nav-item
        *ngFor="let subItem of item?.items"
        [item]="subItem"
        [disabled]="disabled"
        (click)="itemClicked.emit(item)"
      ></em-nav-item>
    </mat-nav-list>
  </div>
</ng-template>
