import {Injectable, inject} from '@angular/core';
import {SetupStatusService} from '@em/auth/data-access';
import {ExportJob, ProductExportsGateway} from '@em/shared/api-interface';
import {ManagedCampaignsGateway} from '@em/shared/api-interface/lib/gateways/managed-campaigns.gateway';
import {GetManagedCampaignsResp} from '@em/shared/api-interface';
import {Observable, combineLatest, forkJoin, iif, of} from 'rxjs';
import {catchError, map, shareReplay, switchMap, take} from 'rxjs/operators';
import {IMappedCampaignsData} from './notifications.factory';
import {CountryService} from '@em/user-account/data-access';

@Injectable()
export class NotificationSupplDataService {
  private readonly _productsExportsGateway = inject(ProductExportsGateway);
  private readonly _setupStatus = inject(SetupStatusService);
  private readonly _country = inject(CountryService);
  private readonly _managedCampaignsGateway = inject(ManagedCampaignsGateway);

  readonly exportJobs$: Observable<ExportJob[]> = this._productsExportsGateway
    .getList({})
    .pipe(
      shareReplay({bufferSize: 1, refCount: false}),
      catchError(() => of([])),
    );

  readonly setupObs$ = combineLatest(
    this._setupStatus.observable(),
    this._country.observable(),
  ).pipe(take(1));

  readonly facebookCampaigns$ = this.setupObs$.pipe(
    switchMap(([{facebookSetup}, country]) =>
      iif(
        () => facebookSetup?.hasFullSetup,
        this._managedCampaignsGateway
          .getManagedCampaigns({
            country,
            platform: 'facebook',
          })
          .pipe(catchError(() => of([]))),
        of<GetManagedCampaignsResp>([]),
      ),
    ),
  );

  readonly googleCampaigns$ = this.setupObs$.pipe(
    switchMap(([{merchant}, country]) =>
      iif(
        () => merchant?.hasFullSetup,
        this._managedCampaignsGateway
          .getManagedCampaigns({
            country,
            platform: 'google-shopping',
          })
          .pipe(catchError(() => of([]))),
        of<GetManagedCampaignsResp>([]),
      ),
    ),
  );

  readonly bingCampaigns$ = this.setupObs$.pipe(
    switchMap(([, country]) =>
      iif(
        () => true,
        this._managedCampaignsGateway
          .getManagedCampaigns({
            country,
            platform: 'bing',
          })
          .pipe(catchError(() => of([]))),
        of<GetManagedCampaignsResp>([]),
      ),
    ),
  );

  readonly allCampaignsHashed$ = forkJoin(
    this.googleCampaigns$,
    this.facebookCampaigns$,
    this.bingCampaigns$,
  ).pipe(
    map(([fb, google, bing]) => {
      const hashCampaigns: IMappedCampaignsData = {};
      [...fb, ...google, ...bing].forEach((c) => {
        if (c.automatic_campaign_attributes?.uuid) {
          hashCampaigns[c.automatic_campaign_attributes.uuid] = {
            name: c.platform_attributes?.name,
            campaignConfigurationUuid: c.campaign_configuration_uuid,
          };
        }
      });
      return hashCampaigns;
    }),
    shareReplay({refCount: false, bufferSize: 1}),
  );
}
