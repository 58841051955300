import {
  ProductsListBooleanFilterCols,
  ProductsListDateFilterCols,
  ProductsListFetchStatusFilterCols,
  ProductsListFilter,
  ProductsListFilterCols,
  ProductsListNumberFilterCols,
  ProductsListTextFilterCols,
} from '@em/shared/api-interface';
import {addBooleanFilter} from '../filter-types/boolean-filter/add-boolean-filter/add-boolean-filter';
import {isBooleanFilter} from '../filter-types/boolean-filter/is-boolean-filter/is-boolean-filter';
import {addDateFilter} from '../filter-types/date-filter/add-date-filter/add-date-filter';
import {isDateFilter} from '../filter-types/date-filter/is-date-filter/is-date-filter';
import {isFilterableColumn} from '../is-filterable-column/is-filterable-column';
import {FilterModel} from '../filter-model';
import {addNumberFilter} from '../filter-types/number-filter/add-number-filter/add-number-filter';
import {isNumberFilter} from '../filter-types/number-filter/is-number-filter/is-number-filter';
import {addTextFilter} from '../filter-types/text-filter/add-text-filter/add-text-filter';
import {isTextFilter} from '../filter-types/text-filter/is-text-filter/is-text-filter';
import {isFetchStatusFilter} from '../filter-types/fetch-status-filter/is-fetch-status-filter/is-fetch-status-filter';
import {addFetchStatusFilter} from '../filter-types/fetch-status-filter/add-fetch-status-filter/add-fetch-status-filter';

export function buildFilters(filterModel: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key in ProductsListFilterCols]: FilterModel<any>;
}): ProductsListFilter {
  const filter: ProductsListFilter = {};

  for (const key in filterModel) {
    if (
      Object.prototype.hasOwnProperty.call(filterModel, key) &&
      isFilterableColumn(key)
    ) {
      const element = filterModel[key];

      if (isTextFilter(element)) {
        addTextFilter(filter, key as ProductsListTextFilterCols, element);
      } else if (isBooleanFilter(element)) {
        addBooleanFilter(filter, key as ProductsListBooleanFilterCols, element);
      }
      if (isFetchStatusFilter(element)) {
        addFetchStatusFilter(
          filter,
          key as ProductsListFetchStatusFilterCols,
          element,
        );
      } else if (isNumberFilter(element)) {
        addNumberFilter(filter, key as ProductsListNumberFilterCols, element);
      } else if (isDateFilter(element)) {
        addDateFilter(filter, key as ProductsListDateFilterCols, element);
      }
    }
  }

  return Object.keys(filter).length > 0 ? filter : undefined;
}
