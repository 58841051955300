/* tslint:disable */
/**
* This file was automatically generated.
* DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
* and run the generate-code script again.
*/
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DeleteReq as DeleteProductGroupsReq} from '../types/request-objects/product-groups/delete';
import {DeleteResp as DeleteProductGroupsResp} from '../types/view-models/product-groups/delete';
import {GetListReq} from '../types/request-objects/product-groups/get-list';
import {GetListResp} from '../types/view-models/product-groups/get-list';
import {GetReq as GetProductGroupsReq} from '../types/request-objects/product-groups/get';
import {GetResp as GetProductGroupsResp} from '../types/view-models/product-groups/get';
import {GetProductsCountReq} from '../types/request-objects/product-groups/get-products-count';
import {GetProductsCountResp} from '../types/view-models/product-groups/get-products-count';
import {PostPrioritiesReq} from '../types/request-objects/product-groups/post-priorities';
import {PostPrioritiesResp} from '../types/view-models/product-groups/post-priorities';
import {PostReq as PostProductGroupsReq} from '../types/request-objects/product-groups/post';
import {PostResp as PostProductGroupsResp} from '../types/view-models/product-groups/post';

@Injectable({
  providedIn: 'root',
})
export class ProductGroupsGateway {
  constructor(private _http: HttpClient) {}

  deleteProductGroups(params: DeleteProductGroupsReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<DeleteProductGroupsResp> {
    const request = params as any as { [param: string]: string | string[]; };
    return this._http.delete<DeleteProductGroupsResp>('/api/product_groups/', {...options, params: request});
  }

  getList(params: GetListReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<GetListResp> {
    const request = params as any as { [param: string]: string | string[]; };
    return this._http.get<GetListResp>('/api/product_groups/list', {...options, params: request});
  }

  getProductGroups(params: GetProductGroupsReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<GetProductGroupsResp> {
    const request = params as any as { [param: string]: string | string[]; };
    return this._http.get<GetProductGroupsResp>('/api/product_groups/', {...options, params: request});
  }

  getProductsCount(params: GetProductsCountReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<GetProductsCountResp> {
    const request = params as any as { [param: string]: string | string[]; };
    return this._http.get<GetProductsCountResp>('/api/product_groups/products_count', {...options, params: request});
  }

  postPriorities(params: PostPrioritiesReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<PostPrioritiesResp> {
    return this._http.post<PostPrioritiesResp>('/api/product_groups/priorities', params, options);
  }

  postProductGroups(params: PostProductGroupsReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<PostProductGroupsResp> {
    return this._http.post<PostProductGroupsResp>('/api/product_groups/', params, options);
  }
}
