import {optionValueType} from '@em/data-feed/data-access-products';

export function castStringValues(
  value: optionValueType,
  desiredType: string,
): optionValueType {
  if (desiredType === 'integer' && typeof value === 'string') {
    return Number.parseInt(value, 10);
  }

  if (desiredType === 'number' && typeof value === 'string') {
    return Number.parseFloat(value);
  }

  return value;
}
