import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {RouterModule} from '@angular/router';
import {EmCardModule} from '@em/shared/ui';
import {TranslateModule} from '@ngx-translate/core';
import {OnboardingContainerComponent} from './onboarding-container.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    EmCardModule,
    TranslateModule,
  ],
  declarations: [OnboardingContainerComponent],
})
export class OnboardingContainerModule {}
