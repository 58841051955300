import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MATERIAL_SANITY_CHECKS} from '@angular/material/core';
import {TranslateModule} from '@ngx-translate/core';
import {EmCardModule} from '../card/card.module';
import {EmSpinnerModule} from '../spinner/spinner.module';
import {EmPersonalAdvisorComponent} from './personal-advisor.component';

@NgModule({
  imports: [CommonModule, EmCardModule, EmSpinnerModule, TranslateModule],
  declarations: [EmPersonalAdvisorComponent],
  exports: [EmPersonalAdvisorComponent],
  providers: [{provide: MATERIAL_SANITY_CHECKS, useValue: false}],
})
export class EmPersonalAdvisorModule {}
