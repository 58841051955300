import {CommonModule} from '@angular/common';
import {Component, Input, NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'em-filter-item-plain',
  templateUrl: './filter-item-plain.component.html',
  styleUrls: ['./filter-item-plain.component.scss'],
})
export class FilterItemPlainComponent {
  @Input() icon?: string;
  @Input() text?: string;
  @Input() count?: number;

  constructor() {
  }
}

@NgModule({
  declarations: [FilterItemPlainComponent],
  exports: [FilterItemPlainComponent],
  imports: [CommonModule, MatIconModule, TranslateModule],
})
export class FilterItemPlainModule {}
