import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {ITheme} from './theme';
import {EMARKETING_THEME} from './themes/emarketing/theme';

@Injectable({
  providedIn: 'root',
})
export class WhiteLabelService {
  private readonly _subject = of(EMARKETING_THEME);

  constructor() {}

  observable(): Observable<ITheme> {
    return this._subject;
  }
}
