/* tslint:disable */
/**
* This file was automatically generated.
* DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
* and run the generate-code script again.
*/
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {GetDashboardKpiReq} from '../types/request-objects/performance/get-dashboard-kpi';
import {GetDashboardKpiResp} from '../types/view-models/performance/get-dashboard-kpi';
import {GetGooglePricesStatsReq} from '../types/request-objects/performance/get-google-prices-stats';
import {GetGooglePricesStatsResp} from '../types/view-models/performance/get-google-prices-stats';

@Injectable({
  providedIn: 'root',
})
export class PerformanceGateway {
  constructor(private _http: HttpClient) {}

  getDashboardKpi(params: GetDashboardKpiReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<GetDashboardKpiResp> {
    const request = params as any as { [param: string]: string | string[]; };
    return this._http.get<GetDashboardKpiResp>('/emarketing_api/performance/dashboard_kpi', {...options, params: request});
  }

  getGooglePricesStats(params: GetGooglePricesStatsReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<GetGooglePricesStatsResp> {
    const request = params as any as { [param: string]: string | string[]; };
    return this._http.get<GetGooglePricesStatsResp>('/emarketing_api/performance/google_prices_stats', {...options, params: request});
  }
}
