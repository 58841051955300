import {CommonModule} from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Input,
  NgModule,
  QueryList,
} from '@angular/core';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {EmSetupStepComponent} from '../setup-step/setup-step.component';
import {EmButtonModule} from '../button/button.module';

@Component({
  selector: 'em-setup-stepper',
  templateUrl: './setup-stepper.component.html',
  styleUrls: ['./setup-stepper.component.scss'],
})
export class EmSetupStepperComponent implements AfterViewInit {
  @Input() set defaultSelectedIndex(value: number | null | undefined) {
    this.selectedIndex = value || 0;
  }
  @ContentChildren(EmSetupStepComponent)
  steps!: QueryList<EmSetupStepComponent>;

  selectedIndex = 0;

  constructor(private readonly _cd: ChangeDetectorRef) {}

  goToStep(stepIndex: number) {
    this.selectedIndex = stepIndex;
  }

  ngAfterViewInit(): void {
    this._cd.detectChanges();
  }
}

@NgModule({
  declarations: [EmSetupStepperComponent, EmSetupStepComponent],
  exports: [EmSetupStepperComponent, EmSetupStepComponent],
  imports: [CommonModule, MatIconModule, MatExpansionModule, EmButtonModule],
})
export class EmSetupStepperModule {}
