<em-icon
  class="inline-block text-success-500"
  [name]="'check'"
  [size]="'sm'"
  *ngIf="showCheckmark$ | async; else copyUlr"
  data-testid="success-btn"
></em-icon>
<ng-template #copyUlr>
  <em-button
    [type]="'icon'"
    [color]="'basic'"
    [icon]="'content_copy'"
    [size]="'sm'"
    (click)="copyToClipboard()"
    data-testid="copy-btn"
  ></em-button>
</ng-template>
