import {SchedulSupportedSourceId} from '@em/market-insights/api-interface';
import {PriceCrawl} from '@em/subscription/api-interface';
import {uniq} from 'lodash';

export function getUniqueSources(
  crawlJobs: PriceCrawl[],
): SchedulSupportedSourceId[] {
  return uniq(
    crawlJobs?.reduce(
      (accumulator, currentValue) => [...accumulator, ...currentValue.sources],
      [] as SchedulSupportedSourceId[],
    ) || [],
  );
}
