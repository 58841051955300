import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {map, startWith} from 'rxjs/operators';

@Component({
  selector: 'em-onboarding-container',
  templateUrl: './onboarding-container.component.html',
  styleUrls: ['./onboarding-container.component.scss'],
})
export class OnboardingContainerComponent {
  readonly availableLangs = ['en', 'de'];
  readonly defaultLanguage = 'en';

  currentLanguage$ = this._translateService.onLangChange.pipe(
    map((ev) => ev.lang),
    startWith('en'),
  );
  constructor(private readonly _translateService: TranslateService) {
    this._translateService.setDefaultLang(this.defaultLanguage);

    const browserLang = this._translateService.getBrowserLang();
    if (browserLang && this.availableLangs.indexOf(browserLang) > -1) {
      this._translateService.use(browserLang);
    } else {
      this._translateService.use(this.defaultLanguage);
    }
  }

  changeLanguage(lang: string) {
    this._translateService.use(lang);
  }
}
