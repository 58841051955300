import {CommonModule} from '@angular/common';
import {
  Component,
  Input,
  NgModule,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {ApexOptions} from 'ng-apexcharts';
import {
  ApexAxisChartSeries,
  ApexNonAxisChartSeries,
  NgApexchartsModule,
} from 'ng-apexcharts';
import {lineChartOptions} from '../../utils/apex-charts.helper';

@Component({
  selector: 'em-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
})
export class LineChartComponent implements OnChanges {
  @Input() series: ApexAxisChartSeries | ApexNonAxisChartSeries | undefined;
  @Input() config: ApexOptions | undefined;

  chartOptions = lineChartOptions;
  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['config']?.currentValue) {
      this.chartOptions = {
        ...lineChartOptions,
        ...changes['config']?.currentValue,
      };
    }
  }
}

@NgModule({
  declarations: [LineChartComponent],
  exports: [LineChartComponent],
  imports: [CommonModule, NgApexchartsModule],
})
export class LineChartModule {}
