import {Injectable} from '@angular/core';
import {NotificationsResp, WorkflowType} from '@em/shared/api-interface';
import {GetResp as GetWorkflowsResp} from '@em/shared/api-interface/lib/types/view-models/workflows/get';
import {IWorkflowEntry, WorkflowIcon} from './workflow-entry.model';
import {workflowStatus} from './types';

type workflowFactory = (
  uuid: string,
  type: WorkflowType,
  status: workflowStatus,
  notifications: NotificationsResp,
  createdAt?: Date,
  failedAt?: Date,
  finishedAt?: Date,
) => IWorkflowEntry;

@Injectable({
  providedIn: 'root',
})
export class WorkflowActivityFactory {
  buildActivity(workflow: GetWorkflowsResp[0]): IWorkflowEntry {
    const {uuid, type, status, notifications} = workflow;
    const createdAt = new Date(workflow.created_at);
    const failedAt = workflow.failed_at
      ? new Date(workflow.failed_at)
      : undefined;
    const finishedAt = workflow.finished_at
      ? new Date(workflow.finished_at)
      : undefined;

    const factory = this._getFactory(type);
    return factory(
      uuid,
      type,
      status,
      notifications,
      createdAt,
      failedAt,
      finishedAt,
    );
  }

  private _getFactory(type: WorkflowType) {
    return (
      {
        'UseCases::Workflows::ProductPipelinesWorkflow':
          CommonWorkflow.buildEntity,
        'UseCases::Workflows::EgressFeedWorkflow': CommonWorkflow.buildEntity,
        'UseCases::Workflows::SupplementalDataWorkflow':
          CommonWorkflow.buildEntity,
        'External::RepricingWorkflow': CommonWorkflow.buildEntity,
        'UseCases::Workflows::FullThrottleWorkflow': CommonWorkflow.buildEntity,
        'UseCases::Workflows::DatafeedSetupWorkflow':
          CommonWorkflow.buildEntity,
        'UseCases::Workflows::ScheduledRefreshWorkflow':
          CommonWorkflow.buildEntity,
        'UseCases::Workflows::AuthorizationWorkflow':
          CommonWorkflow.buildEntity,
        'UseCases::Workflows::DeletionWorkflow': CommonWorkflow.buildEntity,

        // These are handled differently
        'UseCases::Workflows::AutomaticCampaignWorkflow':
          AutomaticCampaignWorkflow.buildEntity,
        'External::PriceApiWorkflow': PriceApiWorkflow.buildEntity,
        'UseCases::Workflows::DatafeedWorkflow': DatafeedWorkflow.buildEntity,
        'External::BingWorkflow': CommonWorkflow.buildEntity,
      } as {[k: string]: workflowFactory}
    )[type];
  }
}

export class CommonWorkflow {
  static buildEntity(
    uuid: string,
    type: WorkflowType,
    status: workflowStatus,
    notifications: NotificationsResp,
    createdAt: Date,
    failedAt?: Date,
    finishedAt?: Date,
  ): IWorkflowEntry {
    const label = type.replace(/::/g, '_').toUpperCase();

    return {
      uuid,
      type,
      status,
      createdAt,
      icon: CommonWorkflow.buildIcon(type),
      label,
      description: {
        i18nKey: 'WORKFLOW_COMMON_DESCRIPTION',
        interpolateParams: {value: notifications.length},
      },
      dateRange: {
        i18nKey: `ACTIVITY_WORKFLOW_STATUS_${status.toUpperCase()}`,
        date: failedAt || finishedAt || createdAt,
      },
      notifications,
    };
  }

  static buildIcon(type: WorkflowType): WorkflowIcon {
    return (
      (
        {
          'UseCases::Workflows::FullThrottleWorkflow': {
            type: 'svg',
            name: 'data-fetch',
          },
          'UseCases::Workflows::DatafeedSetupWorkflow': {
            type: 'svg',
            name: 'feed-setup',
          },
          'UseCases::Workflows::ScheduledRefreshWorkflow': {
            type: 'svg',
            name: 'premium-update',
          },
          'UseCases::Workflows::AuthorizationWorkflow': {
            type: 'svg',
            name: 'authorization',
          },
          'UseCases::Workflows::DeletionWorkflow': {
            type: 'svg',
            name: 'account-reset',
          },
          'UseCases::Workflows::ProductPipelinesWorkflow': {
            type: 'svg',
            name: 'data-fetch',
          },
          'UseCases::Workflows::EgressFeedWorkflow': {
            type: 'svg',
            name: 'feed-process',
          },
          'UseCases::Workflows::SupplementalDataWorkflow': {
            type: 'svg',
            name: 'supplemental-data',
          },
          'External::RepricingWorkflow': {type: 'svg', name: 'repricing-icon'},
          'External::BingWorkflow': {type: 'svg', name: 'microsoft-logo'},
        } as {[k: string]: WorkflowIcon}
      )[type] || {type: 'svg', name: 'default'}
    );
  }
}

export class AutomaticCampaignWorkflow {
  static buildEntity(
    uuid: string,
    type: WorkflowType,
    status: workflowStatus,
    notifications: NotificationsResp,
    createdAt: Date,
    failedAt?: Date,
    finishedAt?: Date,
  ): IWorkflowEntry {
    const label = type.replace(/::/g, '_').toUpperCase();
    const campaignUpdatedNotifications = notifications.filter(
      ({key}) => key === 'UseCases::Notifications::CampaignUpdated',
    );

    return {
      uuid,
      type,
      status,
      createdAt,
      icon: AutomaticCampaignWorkflow.buildIcon(campaignUpdatedNotifications),
      label,
      description: {
        i18nKey: campaignUpdatedNotifications.length
          ? `${label}_DESCRIPTION`
          : 'WORKFLOW_COMMON_DESCRIPTION',
        interpolateParams: {
          value: campaignUpdatedNotifications.length || notifications.length,
        },
      },
      dateRange: {
        i18nKey: `ACTIVITY_WORKFLOW_STATUS_${status.toUpperCase()}`,
        date: failedAt || finishedAt || createdAt,
      },
      notifications,
    };
  }

  static buildIcon(notifications: NotificationsResp): WorkflowIcon {
    if (!notifications.length) return {type: 'default', name: 'update'};

    const hasGoogle = !!notifications.find(
      (n) =>
        (n.payload as {platform: 'google-shopping' | 'facebook' | 'bing'})
          .platform === 'google-shopping',
    );
    const hasFacebook = !!notifications.find(
      (n) =>
        (n.payload as {platform: 'google-shopping' | 'facebook' | 'bing'})
          .platform === 'facebook',
    );
    const hasBing = !!notifications.find(
      (n) =>
        (n.payload as {platform: 'google-shopping' | 'facebook' | 'bing'})
          .platform === 'bing',
    );
    if (hasGoogle && hasFacebook) {
      return {type: 'svg', name: 'combined-platforms'};
    }
    if (hasGoogle && !hasFacebook) {
      return {type: 'svg', name: 'google-icon-colored'};
    }
    if (hasBing) {
      return {type: 'svg', name: 'microsoft-logo'};
    }
    return {type: 'svg', name: 'facebook-icon'};
  }
}

export class PriceApiWorkflow {
  static buildEntity(
    uuid: string,
    type: WorkflowType,
    status: workflowStatus,
    notifications: NotificationsResp,
    createdAt: Date,
    failedAt?: Date,
    finishedAt?: Date,
  ): IWorkflowEntry {
    const label = type.replace(/::/g, '_').toUpperCase();
    const pricesFetchedNotifications = notifications.filter(
      ({key}) => key === 'MarketInsights::Domain::Notifications::PricesFetched',
    );

    return {
      uuid,
      type,
      status,
      createdAt,
      icon: {
        type: 'svg',
        name: 'price-fetch',
      },
      label,
      description: {
        i18nKey: pricesFetchedNotifications.length
          ? `${label}_DESCRIPTION`
          : 'WORKFLOW_COMMON_DESCRIPTION',
        interpolateParams: {
          value: pricesFetchedNotifications.length || notifications.length,
        },
      },
      dateRange: {
        i18nKey: `ACTIVITY_WORKFLOW_STATUS_${status.toUpperCase()}`,
        date: failedAt || finishedAt || createdAt,
      },
      notifications,
    };
  }
}

export class DatafeedWorkflow {
  static buildEntity(
    uuid: string,
    type: WorkflowType,
    status: workflowStatus,
    notifications: NotificationsResp,
    createdAt: Date,
    failedAt?: Date,
    finishedAt?: Date,
  ): IWorkflowEntry {
    const label = type.replace(/::/g, '_').toUpperCase();
    const categoryCount = notifications.filter(
      ({key}) => key === 'Datafeed::Domain::Notifications::CategoriesFetched',
    ).length;
    const productCount = notifications.filter(
      ({key}) => key === 'Datafeed::Domain::Notifications::ProductsFetched',
    ).length;

    return {
      uuid,
      type,
      status,
      createdAt,
      icon: {
        type: 'svg',
        name: 'feed-process',
      },
      label,
      description: {
        i18nKey:
          categoryCount + productCount === 0
            ? 'WORKFLOW_COMMON_DESCRIPTION'
            : `${label}_DESCRIPTION`,
        interpolateParams: DatafeedWorkflow.interpolateParams(
          categoryCount,
          productCount,
          notifications.length,
        ),
      },
      dateRange: {
        i18nKey: `ACTIVITY_WORKFLOW_STATUS_${status.toUpperCase()}`,
        date: failedAt || finishedAt || createdAt,
      },
      notifications,
    };
  }

  static interpolateParams(
    categoryCount: number,
    productCount: number,
    value: number,
  ): {[k: string]: number} {
    return categoryCount + productCount === 0
      ? {value}
      : {
          categoryCount,
          productCount,
        };
  }
}
