import {ColDef} from 'ag-grid-community';

import {inject} from '@angular/core';
import {CurrencyPipe, DecimalPipe, PercentPipe} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {format} from '@em/shared/util-date-time';
import {getStringFilterParams} from '../filter/filter-types/text-filter/gets-text-filter-params/gets-text-filter-params';
import {getNumberFilterParams} from '../filter/filter-types/number-filter/get-number-filter-params/get-number-filter-params';
import {getBooleanFilterParams} from '../filter/filter-types/boolean-filter/get-boolean-filter-params/get-boolean-filter-params';
import {getDateFilterParams} from '../filter/filter-types/date-filter/get-date-filter-params/get-date-filter-params';
import {getFetchStatusFilterParams} from '../filter/filter-types/fetch-status-filter/get-fetch-status-filter-params/get-fetch-status-filter-params';

export type ColumnType =
  | 'text'
  | 'number'
  | 'percentage'
  | 'money'
  | 'stringDate'
  | 'boolean'
  | 'object'
  | 'fetchStatus';

export type ColumnTypeName = `${ColumnType}Column`;

export function createColumnsTypes(): {
  [key in ColumnTypeName]?: ColDef;
} {
  const decimalPipe = inject(DecimalPipe);
  const currencyPipe = inject(CurrencyPipe);
  const percentPipe = inject(PercentPipe);
  const translateService = inject(TranslateService);

  return {
    textColumn: {
      filterParams: getStringFilterParams(),
      valueFormatter: (params) => (params.value == null ? '-' : params.value),
    },
    numberColumn: {
      filter: 'agNumberColumnFilter',
      filterParams: getNumberFilterParams(),
      valueFormatter: (params) => {
        const value = params.value;
        return value == null ? '-' : decimalPipe.transform(value) || '-';
      },
    },
    booleanColumn: {
      filter: 'agNumberColumnFilter',
      filterParams: getBooleanFilterParams(),
      valueFormatter: (params) =>
        params.value == null
          ? '-'
          : params.value
          ? translateService.instant('SHARED_LABEL_YES')
          : translateService.instant('SHARED_LABEL_NO'),
    },
    stringDateColumn: {
      filter: 'agDateColumnFilter',
      filterParams: getDateFilterParams(),
      valueFormatter: (params) =>
        // convert to `dd/mm/yyyy`
        params.value == null ? '-' : format(new Date(params.value), 'P p'),
    },
    moneyColumn: {
      filter: 'agNumberColumnFilter',
      filterParams: getNumberFilterParams(),
      valueFormatter: (params) => {
        const moneyObject = params.value;
        const value = moneyObject?.value;
        const currency = moneyObject?.currency || '';

        return value == null
          ? '-'
          : currency
          ? currencyPipe.transform(value, currency)
          : value;
      },
    },
    percentageColumn: {
      filter: 'agNumberColumnFilter',
      filterParams: getNumberFilterParams(),
      valueFormatter: (params) => {
        const value = params.value;

        return value == null
          ? '-'
          : percentPipe.transform(value / 100, '1.0-2') || '-';
      },
    },
    objectColumn: {
      valueFormatter: (params) => {
        let finalVal = '';
        if (params.value) {
          for (const key in params.value) {
            if (Object.prototype.hasOwnProperty.call(params.value, key)) {
              const value = params.value[key];
              finalVal += (finalVal ? ', ' : '') + key + ': ' + value;
            }
          }
        }
        return finalVal;
      },
    },
    fetchStatusColumn: {
      filterParams: getFetchStatusFilterParams(),
      valueFormatter: (params) =>
        params.value == null
          ? '-'
          : translateService.instant(
              'SHARED_LABEL_PRICE_' + (params.value as string).toUpperCase(),
            ),
    },
  };
}
