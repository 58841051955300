import {NgModule, Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {CountryCode} from '@em/shared/util-types';

@Pipe({
  name: 'translateCountry',
})
export class TranslateCountryPipe implements PipeTransform {
  constructor(private readonly _translateService: TranslateService) {}

  transform(country: CountryCode | undefined | null): string {
    if (!country) return '';
    return this._translateService.instant(`COUNTRY_${country.toUpperCase()}`);
  }
}

@NgModule({
  declarations: [TranslateCountryPipe],
  exports: [TranslateCountryPipe],
})
export class TranslateCountryPipeModule {}
