<div class="px-8 py-6">
  <em-spinner class="m-16" *ngIf="isLoading"></em-spinner>
  <em-icon-button
    class="close-btn"
    [icon]="'close'"
    [color]="''"
    (btnClick)="close()"
  ></em-icon-button>

  <div *ngIf="showSubscriptionUpdated">
    <div class="text-center">
      <em-icon
        [size]="'xl'"
        class="inline-block text-success-500"
        name="task_alt"
      ></em-icon>
    </div>
    <div class="text-center font-bold text-lg mt-1 mb-6">
      {{ 'SHARED_PAYMENT_SUCCESS_TITLE' | translate }}
    </div>
    <div class="text-center mt-1 mb-8">
      {{ 'SHARED_PAYMENT_SUCCESS_DESC' | translate }}
    </div>
  </div>

  <div *ngIf="showSubscriptionUpdatedFailed">
    <div class="text-center">
      <em-icon
        [size]="'xl'"
        class="inline-block text-danger-500"
        name="highlight_off"
      ></em-icon>
    </div>
    <div class="text-center font-bold text-lg mt-1 mb-2">
      {{ 'SHARED_PAYMENT_FAILED_TITLE' | translate }}
    </div>
    <div class="text-center mt-1 mb-8">
      {{ 'SHARED_PAYMENT_FAILED_DESC' | translate }}
    </div>
  </div>
</div>
