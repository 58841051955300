import {registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localeDeExtra from '@angular/common/locales/extra/de';
import localeEnExtra from '@angular/common/locales/extra/en';
import localeEsExtra from '@angular/common/locales/extra/es';
import localeFrExtra from '@angular/common/locales/extra/fr';
import localeItExtra from '@angular/common/locales/extra/it';
import localeNlExtra from '@angular/common/locales/extra/nl';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import localeNl from '@angular/common/locales/nl';
import {registerLocales} from '@em/shared/util-date-time';

import {enUS, de, es, nl, it, fr} from 'date-fns/locale';

registerLocaleData(localeEn, 'en-GB', localeEnExtra);
registerLocaleData(localeDe, 'de-DE', localeDeExtra);
registerLocaleData(localeEs, 'es-ES', localeEsExtra);
registerLocaleData(localeNl, 'nl-NL', localeNlExtra);
registerLocaleData(localeIt, 'it-IT', localeItExtra);
registerLocaleData(localeFr, 'fr-FR', localeFrExtra);

export const registeredLocales = [
  'en-GB',
  'de-DE',
  'es-ES',
  'nl-NL',
  'it-IT',
  'fr-FR',
];

// Default to EN - (british by chance)
export const LOCALE_SETTING = {
  current: 'en-GB',
};

// If set by user in local storage -> override
const userSetLocale = window.localStorage.getItem('locale');
if (userSetLocale) {
  LOCALE_SETTING.current = userSetLocale;
}

// If set by query param -> override
const urlParams = new URLSearchParams(window.location.search);
const urlLocale = urlParams.get('locale');
if (urlLocale && registeredLocales.includes(urlLocale)) {
  LOCALE_SETTING.current = urlLocale;
  window.localStorage.setItem('locale', urlLocale);
}

registerLocales({
  en: enUS,
  de,
  fr,
  es,
  it,
  nl,
});
