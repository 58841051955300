import {
  ProductsListFilter,
  ProductsListDateFilterCols,
} from '@em/shared/api-interface';
import {DateFilterModel} from 'ag-grid-community';
import {GRID_TO_BACKEND_DATE_OPERATOR} from '../../../consts/operator-maps';
import {isNullaryOperator} from '../../../is-nullary-operator/is-nullary-operator';

export function addDateFilter(
  filter: NonNullable<ProductsListFilter>,
  key: ProductsListDateFilterCols,
  filterModel: DateFilterModel,
) {
  if (filterModel.type) {
    const backendOperator = GRID_TO_BACKEND_DATE_OPERATOR[filterModel.type];
    if (backendOperator) {
      if (isNullaryOperator(backendOperator)) {
        filter[key] = {
          operator: backendOperator,
        };
      } else if (filterModel.dateFrom !== null) {
        filter[key] = {
          operator: backendOperator,
          value: filterModel.dateFrom,
        };
      }
    }
  }
}
