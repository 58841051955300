<ng-container [ngSwitch]="type">
  <ng-container *ngSwitchCase="'primary'">
    <button
      mat-flat-button
      [color]="color"
      [ngClass]="{
        'btn-size-md': size === 'md',
        'btn-size-sm': size === 'sm',
        'btn-size-xs': size === 'xs',
        'btn-size-lg': size === 'lg',
        'btn-size-full': size === 'full'
      }"
      [disabled]="disabled"
      [type]="htmlType"
      data-testid="primary-button"
      (click)="btnClicked($event)"
    >
      <ng-template [ngTemplateOutlet]="content"></ng-template>
    </button>
  </ng-container>
  <ng-container *ngSwitchCase="'secondary'">
    <button
      mat-stroked-button
      [color]="color"
      [ngClass]="{
        'btn-size-md': size === 'md',
        'btn-size-sm': size === 'sm',
        'btn-size-xs': size === 'xs',
        'btn-size-lg': size === 'lg',
        'btn-size-full': size === 'full'
      }"
      [disabled]="disabled"
      [type]="htmlType"
      data-testid="secondary-button"
      (click)="btnClicked($event)"
    >
      <ng-template [ngTemplateOutlet]="content"></ng-template>
    </button>
  </ng-container>
  <ng-container *ngSwitchCase="'icon'">
    <button
      mat-icon-button
      [color]="color"
      [ngClass]="{
        'btn-size-md': size === 'md',
        'btn-size-sm': size === 'sm',
        'btn-size-xs': size === 'xs',
        'btn-size-lg': size === 'lg',
        'btn-size-full': size === 'full'
      }"
      [disabled]="disabled"
      [type]="htmlType"
      data-testid="icon-button"
      (click)="btnClicked($event)"
    >
      <ng-template [ngTemplateOutlet]="content"></ng-template>
    </button>
  </ng-container>
  <ng-container *ngSwitchCase="'mini-fab'">
    <button
      mat-mini-fab
      [color]="color"
      [ngClass]="{
        'btn-size-md': size === 'md',
        'btn-size-sm': size === 'sm',
        'btn-size-xs': size === 'xs',
        'btn-size-lg': size === 'lg',
        'btn-size-full': size === 'full'
      }"
      [disabled]="disabled"
      [type]="htmlType"
      data-testid="icon-button"
      (click)="btnClicked($event)"
    >
      <ng-template [ngTemplateOutlet]="content"></ng-template>
    </button>
  </ng-container>
  <ng-container *ngSwitchCase="'link'">
    <a
      mat-flat-button
      [color]="color"
      [disabled]="disabled"
      [ngClass]="{
        'btn-size-md': size === 'md',
        'btn-size-sm': size === 'sm',
        'btn-size-xs': size === 'xs',
        'btn-size-lg': size === 'lg',
        'btn-size-full': size === 'full'
      }"
      [href]="linkUrl"
      [target]="linkTarget"
      data-testid="link-button"
      ><ng-template [ngTemplateOutlet]="content"></ng-template
    ></a>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <button
      mat-button
      [color]="color"
      [ngClass]="{
        'btn-size-md': size === 'md',
        'btn-size-sm': size === 'sm',
        'btn-size-xs': size === 'xs',
        'btn-size-lg': size === 'lg',
        'btn-size-full': size === 'full'
      }"
      [disabled]="disabled"
      [type]="htmlType"
      data-testid="text-button"
      (click)="btnClicked($event)"
    >
      <ng-template [ngTemplateOutlet]="content"></ng-template>
    </button>
  </ng-container>
</ng-container>

<ng-template #content>
  <span class="flex items-center">
    <mat-icon *ngIf="icon">{{ icon }}</mat-icon>
    <ng-content></ng-content>
  </span>
</ng-template>
