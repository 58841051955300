import {Component} from '@angular/core';
import {EmIconModule} from '../icon/icon.component';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'em-success-box',
  templateUrl: './success-box.component.html',
  styleUrls: ['./success-box.component.scss'],
  standalone: true,
  imports: [CommonModule, EmIconModule],
})
export class EmSuccessBoxComponent {
  constructor() {}
}
