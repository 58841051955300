import {
  Directive,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
} from '@angular/core';
import {Subscription} from 'rxjs';
import {ChangesObj, ICommand} from '@em/shared/util-types';
import {LoggerService} from '../logger/logger.service';

@Directive({
  standalone: true,
  selector: '[emBindCommand]',
})
export class EmBindCommandDirective<T> implements OnChanges {
  @Input('emBindCommand') command?: ICommand<T>;
  private _subscription?: Subscription;

  constructor(private readonly _logger: LoggerService) {}

  private _disabled = true;

  @HostBinding('disabled') get disabled() {
    return this._disabled;
  }

  @HostBinding('class.mat-button-disabled') get addDisabledClass() {
    return this._disabled;
  }

  @HostListener('click') onClick() {
    if (!this.command) return;

    try {
      this.command.perform().subscribe(
        () => {},
        (error: unknown) => {
          this._logger.error(error);
        },
      );
    } catch (e) {
      this._logger.error(e);
      throw e;
    }
  }

  ngOnChanges(changes: ChangesObj<EmBindCommandDirective<T>, 'command'>): void {
    if (changes.command) {
      if (this._subscription) {
        this._subscription.unsubscribe();
      }

      if (this.command) {
        this._subscription = this.command.canPerform().subscribe((enabled) => {
          this._disabled = !enabled;
        });
      } else {
        this._disabled = true;
      }
    }
  }
}
