import {IOptionDefinition} from './option-definition';

export type inputType = 'text' | 'number' | 'boolean';

export function getOptionType(option: IOptionDefinition): inputType {
  switch (option.type) {
    case 'boolean':
      return 'boolean';
    case 'string':
      return 'text';
    case 'integer':
    case 'number':
      return 'number';
    default:
      return 'text';
  }
}
