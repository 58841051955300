import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export function oneServiceAtLeast(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const groupValue = control.value as {
      [key: string]: {service?: boolean; subServices: boolean[]};
    };

    let hasValue = false;
    for (const key in groupValue) {
      if (Object.prototype.hasOwnProperty.call(groupValue, key)) {
        const service = groupValue[key];
        if (service.service || !!service.subServices?.find((e) => !!e)) {
          hasValue = true;
          break;
        }
      }
    }

    if (!hasValue) {
      return {noValue: true};
    }
    return null;
  };
}
