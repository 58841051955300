import {TokenStorageService} from '@em/auth/data-access';

export class JwtOptions {
  constructor(private readonly _tokenStorage: TokenStorageService) {}

  get tokenGetter() {
    return () => this._tokenStorage.getToken();
  }

  get disallowedRoutes() {
    return [
      '/emarketing_api/merchants/exchange_login',
      '/emarketing_api/merchants/login',
      '/emarketing_api/enterprise/performance',
      '/emarketing_api/enterprise/signup',
      '/emarketing_api/enterprise/social_login',
      /^\/configuration_api\//,
      /^\/locales\//,
      /^\/assets\//,
    ];
  }
}
