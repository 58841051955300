import {Pipe, PipeTransform, inject} from '@angular/core';
import {isTemplateGroup} from '@em/data-feed/data-access-products';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'productGroupName',
  standalone: true,
})
export class EmProductGroupNamePipe implements PipeTransform {
  private readonly _translateService = inject(TranslateService);

  transform(value: string | null | undefined): string {
    if (!value) {
      return '';
    } else if (isTemplateGroup(value)) {
      return this._translateService.instant(value.toUpperCase());
    } else {
      return value;
    }
  }
}
