/* tslint:disable */
/**
* This file was automatically generated.
* DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
* and run the generate-code script again.
*/
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {GetCountReq} from '../types/request-objects/products/get-count';
import {GetCountResp} from '../types/view-models/products/get-count';
import {GetCustomLabelsResp} from '../types/view-models/products/get-custom-labels';
import {PostCsvReq} from '../types/request-objects/products/post-csv';
import {PostSearchReq} from '../types/request-objects/products/post-search';
import {PostSearchResp} from '../types/view-models/products/post-search';
import {PostSearchProductsOnlyReq} from '../types/request-objects/products/post-search-products-only';
import {PostSearchProductsOnlyResp} from '../types/view-models/products/post-search-products-only';

@Injectable({
  providedIn: 'root',
})
export class ProductsGateway {
  constructor(private _http: HttpClient) {}

  getCount(params: GetCountReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<GetCountResp> {
    const request = params as any as { [param: string]: string | string[]; };
    return this._http.get<GetCountResp>('/emarketing_api/products/count', {...options, params: request});
  }

  getCustomLabels(options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<GetCustomLabelsResp> {
    return this._http.get<GetCustomLabelsResp>('/emarketing_api/products/custom_labels', options);
  }

  postCsv(params: PostCsvReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<undefined> {
    return this._http.post<undefined>('/emarketing_api/products/csv', params, options);
  }

  postSearch(params: PostSearchReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<PostSearchResp> {
    return this._http.post<PostSearchResp>('/emarketing_api/products/search', params, options);
  }

  postSearchProductsOnly(params: PostSearchProductsOnlyReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<PostSearchProductsOnlyResp> {
    return this._http.post<PostSearchProductsOnlyResp>('/emarketing_api/products/search_products_only', params, options);
  }
}
