import {eachDayOfInterval} from 'date-fns';
import {endOfWeek, startOfWeek} from 'date-fns';
import {default as format} from './format';

export function getWeekDays(): Array<{name: string; day: number}> {
  const now = new Date();
  const arr = eachDayOfInterval({start: startOfWeek(now), end: endOfWeek(now)});
  return arr.reduce<Array<{name: string; day: number}>>((a, d) => {
    a.push({name: format(d, 'eeee'), day: d.getDay()});
    return a;
  }, []);
}
