import {CdkStepperModule, StepperSelectionEvent} from '@angular/cdk/stepper';
import {CommonModule} from '@angular/common';
import {Component, OnInit, inject} from '@angular/core';
import {ActivatedRoute, Router, RouterLink} from '@angular/router';
import {ManagedCampaignsStore} from '@em/campaigns/data-access';
import {ProductGroupType} from '@em/data-feed/data-access-products';
import {
  EmFabButtonComponent,
  EmPrimaryButtonComponent,
  EmStepperComponent,
  EmWarningBoxModule,
} from '@em/shared/ui';
import {NamedUrl} from '@em/shared/util-types';
import {
  EmSuggestedPlanComponent,
  SuggestedPlanService,
} from '@em/subscription/feature';
import {TranslateModule} from '@ngx-translate/core';
import {combineLatest} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {ManageGroupsService} from '../../../services/manage-groups/manage-groups.service';
import {EmProductGroupsListComponent} from '../../product-groups-list/product-groups-list.component';

const PageUrls: NamedUrl[] = [
  {name: 'competitors-radar', url: '/products/groups/competitors-radar'},
  {name: 'custom', url: '/products/groups/custom'},
  {name: 'template', url: '/products/groups/template'},
];

@Component({
  selector: 'em-product-groups-page',
  templateUrl: './product-groups-page.component.html',
  styleUrls: ['./product-groups-page.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    CdkStepperModule,
    RouterLink,
    EmProductGroupsListComponent,
    EmStepperComponent,
    EmFabButtonComponent,
    EmWarningBoxModule,
    EmPrimaryButtonComponent,
    EmSuggestedPlanComponent,
  ],
  providers: [SuggestedPlanService],
})
export class EmProductGroupsPageComponent implements OnInit {
  private readonly _suggestedPlanService = inject(SuggestedPlanService);
  private readonly _manageGroupsService = inject(ManageGroupsService);
  private readonly _manageCampaigns = inject(ManagedCampaignsStore);
  private readonly _activatedRoute = inject(ActivatedRoute);
  private readonly _router = inject(Router);

  readonly showSuggestedPlan$ = combineLatest([
    this._suggestedPlanService.isUpgradeNeeded$,
    this._suggestedPlanService.isDowngradeNeeded$,
  ]).pipe(map(([u, d]) => u || d));
  readonly productGroups$ = this._manageGroupsService.getGroups();

  readonly competitorsRadarGroups$ = this.productGroups$.pipe(
    map((groupList) => groupList.filter((g) => g.is_price_crawl === true)),
  );
  readonly customGroups$ = this.productGroups$.pipe(
    map((groupList) =>
      groupList.filter(
        (g) => g.type === 'custom' && g.is_price_crawl === false,
      ),
    ),
  );
  readonly templateGroups$ = this.productGroups$.pipe(
    map((groupList) =>
      groupList.filter(
        (g) => g.type === 'template' && g.is_price_crawl === false,
      ),
    ),
  );
  protected selectedStepIndex = 0;

  // Lifecycle events
  ngOnInit(): void {
    this._manageCampaigns.loadAllCampaigns(true);
    this._manageGroupsService.forceLoadGroups();

    // link router to the correct stepper step
    this._activatedRoute.paramMap
      .pipe(
        tap((paramMap) => {
          const step = paramMap.get('type');
          this.selectedStepIndex = this.getStepIndex(step);
        }),
      )
      .subscribe();
  }

  addNewGroup(type: ProductGroupType) {
    this._manageGroupsService.openAddGroupAndSave(type).subscribe();
  }

  getStepIndex(stepName: string | null): number {
    const step = PageUrls.find((s) => s.name === stepName);

    return step ? PageUrls.indexOf(step) : 0;
  }

  selectedStepChanged(event: StepperSelectionEvent) {
    this._router.navigateByUrl(PageUrls[event.selectedIndex].url);
  }
}
