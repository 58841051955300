import {Injectable, inject} from '@angular/core';
import {ProductsGateway} from '@em/shared/api-interface/lib/gateways/products.gateway';
import {Observable, combineLatest} from 'rxjs';
import {map, shareReplay, switchMap} from 'rxjs/operators';
import {CountryService} from '@em/user-account/data-access';
import {FetchProductsStore} from '../fetch-products/fetch-products.store';

@Injectable({
  providedIn: 'root',
})
export class ProductsCountService {
  private readonly _productsGateway = inject(ProductsGateway);
  private readonly _countyService = inject(CountryService);
  private readonly _fetchProductsStore = inject(FetchProductsStore);

  readonly productFetched$ = this._fetchProductsStore.uploadFinished$.pipe();
  productsInfo$ = combineLatest([
    this.productFetched$,
    this._countyService.observable(),
  ]).pipe(
    switchMap(([, countryCode]) =>
      this._productsGateway.getCount({country: countryCode}),
    ),
    shareReplay({refCount: false, bufferSize: 1}),
  );

  productsCount$: Observable<number> = this.productsInfo$.pipe(
    map((respons) => respons.product_count || 0),
    shareReplay({refCount: false, bufferSize: 1}),
  );

  allProductsCount$: Observable<number> = this.productFetched$.pipe(
    switchMap(() => this._productsGateway.getCount({})),
    map((respons) => respons.product_count || 0),
    shareReplay({refCount: false, bufferSize: 1}),
  );
}
