import {Action, createReducer, on} from '@ngrx/store';
import {hideAppBlocker, jwtValid, showAppBlocker} from './actions';

export interface IState {
  appBlockerActive: boolean;
}

export const initialState: IState = {
  appBlockerActive: true,
};

export const appStateReducers = createReducer(
  initialState,
  on(
    showAppBlocker,
    (state): IState => ({
      ...state,
      appBlockerActive: true,
    }),
  ),
  on(
    hideAppBlocker,
    jwtValid,
    (state): IState => ({
      ...state,
      appBlockerActive: false,
    }),
  ),
);

export function reducer(state: IState | undefined, action: Action) {
  return appStateReducers(state, action);
}
