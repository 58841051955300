import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {TranslateWithErrorReportingModule} from '@em/shared/util-configuration';
import {CommonWizardModule} from './common-wizard/common-wizard.module';
import {OnboardingPageComponent} from './onboarding-page/onboarding-page.component';
import {OnboardingRoutingModule} from './onboarding.routing.module';
import {OnboardingEffects, reducers} from '@em/onboarding/data-access';
import {OnboardingCompletedGuard} from './onboarding-completed.guard';
import {
  EmCardModule,
  EmPersonalAdvisorModule,
  EmSpinnerModule,
} from '@em/shared/ui';
import {CommonModule} from '@angular/common';

const COMPONENTS = [OnboardingPageComponent];

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('wizard', reducers),
    EffectsModule.forFeature([OnboardingEffects]),
    OnboardingRoutingModule,
    RouterModule,
    CommonWizardModule,
    TranslateWithErrorReportingModule,
    EmCardModule,
    EmPersonalAdvisorModule,
    EmSpinnerModule,
  ],
  declarations: [...COMPONENTS],
  providers: [OnboardingCompletedGuard],
})
export class OnboardingModule {}
