import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {EmCountryFlagComponent} from './country-flag.component';

@NgModule({
  imports: [CommonModule],
  declarations: [EmCountryFlagComponent],
  exports: [EmCountryFlagComponent],
})
export class EmCountryFlagModule {}
