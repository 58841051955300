import {ComponentType} from '@angular/cdk/portal';
import {Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {OperatorFunction, pipe, throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private readonly _defaultDuration = 3000;
  private readonly _defaultOptions: MatSnackBarConfig = {
    horizontalPosition: 'right',
    verticalPosition: 'top',
  };

  constructor(
    private readonly _matSnackBar: MatSnackBar,
    private readonly _translate: TranslateService,
  ) {}

  show(
    messageKey: string,
    hideDelay?: number,
    interpolateParams: {[k: string]: string} = {},
  ) {
    if (messageKey && messageKey !== '') {
      const message = this._translate.instant(messageKey, interpolateParams);

      this._matSnackBar.open(message, undefined, {
        ...this._defaultOptions,
        duration: hideDelay || this._defaultDuration,
      });
    }
  }

  tapShow<T>(
    messagePrefix: string,
    interpolateParams?: {[k: string]: string},
  ): OperatorFunction<T, T> {
    return pipe(
      tap((success) =>
        this.success(`${messagePrefix}_SUCCESS`, interpolateParams),
      ),
      catchError((error: unknown) => {
        this.error(`${messagePrefix}_FAILED`, interpolateParams);
        return throwError(error);
      }),
    );
  }

  success(messageKey: string, interpolateParams?: {[k: string]: string}) {
    this.show(messageKey, this._defaultDuration, interpolateParams);
  }

  error(
    messageKey: string,
    interpolateParams?: {[k: string]: string},
    hideDelay?: number,
  ) {
    this.show(
      messageKey,
      hideDelay || this._defaultDuration,
      interpolateParams,
    );
  }

  showButton(messageKey: string, buttonKey: string, hideDelay?: number) {
    const message = this._translate.instant(messageKey);
    const button = this._translate.instant(buttonKey);
    this._matSnackBar.open(message, button, {
      ...this._defaultOptions,
      duration: hideDelay || this._defaultDuration,
    });
  }

  custom<TC, T>(component: ComponentType<TC>, data: T) {
    this._matSnackBar.openFromComponent(component, {
      horizontalPosition: 'left',
      verticalPosition: 'bottom',
      duration: 6000,
      data,
      panelClass: 'notification-toast',
    });
  }
}
