/* tslint:disable */
/**
* This file was automatically generated.
* DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
* and run the generate-code script again.
*/
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {GetReq as GetContractsReq} from '../types/request-objects/contracts/get';
import {GetResp as GetContractsResp} from '../types/view-models/contracts/get';
import {PostPreviewReq} from '../types/request-objects/contracts/post-preview';
import {PostPreviewResp} from '../types/view-models/contracts/post-preview';
import {PutReq as PutContractsReq} from '../types/request-objects/contracts/put';
import {PutResp as PutContractsResp} from '../types/view-models/contracts/put';

@Injectable({
  providedIn: 'root',
})
export class ContractsGateway {
  constructor(private _http: HttpClient) {}

  getContracts(params: GetContractsReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<GetContractsResp> {
    const request = params as any as { [param: string]: string | string[]; };
    return this._http.get<GetContractsResp>('/payment_api/contracts/', {...options, params: request});
  }

  postPreview(params: PostPreviewReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<PostPreviewResp> {
    return this._http.post<PostPreviewResp>('/payment_api/contracts/preview', params, options);
  }

  putContracts(params: PutContractsReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<PutContractsResp> {
    return this._http.put<PutContractsResp>('/payment_api/contracts/', params, options);
  }
}
