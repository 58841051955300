<div class="title-wrapper" *ngIf="inModal">
  <div class="title" translate>ACCOUNT_USER_PAYMENT_SECTION_TITLE</div>
  <em-icon name="close" class="close-icon" (click)="cancel()"></em-icon>
</div>

<form [formGroup]="form" (ngSubmit)="submit()">
  <div class="grid grid-cols-1 xl:grid-cols-2 gap-6">
    <em-select
      class="w-full"
      formControlName="salutation"
      [label]="'USER_PAYMENT_FORM_SALUTATION' | translate"
    >
      <em-option *ngFor="let salutation of salutations" [value]="salutation">
        {{
          'USER_PAYMENT_FORM_SALUTATION_' + salutation?.toUpperCase()
            | translate
        }}
      </em-option>

      <em-error>{{ 'VALIDATION_FIELD_REQUIRED' | translate }}</em-error>
    </em-select>

    <em-input
      class="w-full"
      formControlName="firstName"
      [label]="'USER_PAYMENT_FORM_FIRSTNAME' | translate"
    >
      <em-error>{{ 'VALIDATION_FIELD_REQUIRED' | translate }}</em-error>
    </em-input>

    <em-input
      class="w-full"
      formControlName="lastName"
      [label]="'USER_PAYMENT_FORM_LASTNAME' | translate"
    >
      <em-error>{{ 'VALIDATION_FIELD_REQUIRED' | translate }}</em-error>
    </em-input>

    <em-input
      class="w-full"
      formControlName="company"
      [label]="'USER_PAYMENT_FORM_COMPANY' | translate"
    >
    </em-input>

    <em-input
      class="w-full"
      formControlName="job"
      [label]="'USER_PAYMENT_FORM_JOB' | translate"
    >
    </em-input>

    <em-input
      class="w-full"
      formControlName="street"
      [label]="'USER_PAYMENT_FORM_STREET' | translate"
    >
      <em-error>{{ 'VALIDATION_FIELD_REQUIRED' | translate }}</em-error>
    </em-input>

    <em-input
      class="w-full"
      formControlName="zip"
      [label]="'USER_PAYMENT_FORM_ZIP' | translate"
    >
      <em-error>{{ 'VALIDATION_FIELD_REQUIRED' | translate }}</em-error>
    </em-input>

    <em-input
      class="w-full"
      formControlName="location"
      [label]="'USER_PAYMENT_FORM_LOCATION' | translate"
    >
      <em-error>{{ 'VALIDATION_FIELD_REQUIRED' | translate }}</em-error>
    </em-input>

    <em-input
      class="w-full"
      formControlName="state"
      [label]="'USER_PAYMENT_FORM_STATE' | translate"
    >
      <em-error>{{ 'VALIDATION_FIELD_REQUIRED' | translate }}</em-error>
    </em-input>

    <em-input
      class="w-full"
      formControlName="country"
      [label]="'USER_PAYMENT_FORM_COUNTRY' | translate"
    >
      <em-error>{{ 'VALIDATION_FIELD_REQUIRED' | translate }}</em-error>
    </em-input>

    <em-input
      class="w-full"
      formControlName="phoneNumber"
      [label]="'USER_PAYMENT_FORM_PHONE_NUMBER' | translate"
    >
      <em-error>{{ 'VALIDATION_FIELD_REQUIRED' | translate }}</em-error>
    </em-input>

    <em-input
      class="w-full"
      formControlName="vatId"
      [label]="'USER_PAYMENT_FORM_VATID' | translate"
    >
    </em-input>

    <em-input
      class="w-full"
      formControlName="accountingEmail"
      [label]="'USER_PAYMENT_FORM_ACCOUNTING_EMAIL' | translate"
    >
      <em-error>
        {{ 'VALIDATION_EMAIL_PATTERN_WRONG' | translate }}
      </em-error>
    </em-input>

    <em-select
      class="w-full"
      formControlName="invoiceRecipient"
      [label]="'USER_PAYMENT_FORM_INVOICE_RECIPIENT' | translate"
    >
      <em-option
        *ngFor="let invoiceRecipient of invoiceRecipients"
        [value]="invoiceRecipient"
      >
        {{
          'USER_PAYMENT_FORM_INVOICE_RECIPIENT_' +
            invoiceRecipient?.toUpperCase() | translate
        }}
      </em-option>
    </em-select>

    <em-input
      class="w-full"
      formControlName="url"
      [label]="'USER_PAYMENT_FORM_URL' | translate"
    >
      <em-error>
        <ng-container *ngIf="form?.get('url')?.hasError('required')">
          {{ 'VALIDATION_FIELD_REQUIRED' | translate }}
        </ng-container>
        <ng-container *ngIf="form?.get('url')?.hasError('pattern')">
          {{ 'VALIDATION_PATTERN_WRONG' | translate }}
        </ng-container>
      </em-error>
    </em-input>
  </div>

  <p class="info">
    <em-icon name="info"></em-icon>
    <span translate>USER_PAYMENT_FORM_INVOICE_HINT</span>
  </p>

  <div class="flex mt-4">
    <em-primary-button class="ml-auto" type="submit">
      <span translate>USER_PAYMENT_FORM_SUBMIT</span>
    </em-primary-button>
  </div>
</form>
