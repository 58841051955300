import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {EnterpriseGateway} from '@em/shared/api-interface/lib/gateways/enterprise.gateway';
import {CurrencyCode} from '@em/shared/util-types';
import {PostSignupReq} from '@em/shared/api-interface/lib/types/request-objects/enterprise/post-signup';
import {LanguageGuesserService} from '@em/shared/util-configuration';

@Injectable({
  providedIn: 'root',
})
export class EnterpriseService {
  constructor(
    private readonly _gateway: EnterpriseGateway,
    private readonly _languageGuesser: LanguageGuesserService,
  ) {}

  postSignup(
    email: string,
    accessToken: string,
    adwordsId: number,
    currencyCode: CurrencyCode,
    code: string | null,
    loginCustomerId: number,
    recaptcha_response: string,
  ): Observable<string> {
    const params: PostSignupReq = {
      email,
      language: this._languageGuesser.language(),
      adwords_id: adwordsId,
      currency_code: currencyCode,
      code,
      login_customer_id: loginCustomerId,
    };

    const options = {...this._buildAuthHeader(accessToken)};

    return this._gateway
      .postSignup(params, options)
      .pipe(map((resp) => resp.jwt));
  }

  getPerformance(
    accessToken: string,
    adwordsId: number,
    loginCustomerId: number,
  ) {
    return this._gateway.getPerformance(
      {adwords_id: `${adwordsId}`, login_customer_id: `${loginCustomerId}`},
      {...this._buildAuthHeader(accessToken)},
    );
  }

  postSocialLogin(accessToken: string) {
    return this._gateway.postSocialLogin(
      {},
      {...this._buildAuthHeader(accessToken)},
    );
  }

  private _buildAuthHeader(accessToken: string) {
    return {
      headers: {
        Authorization: `${accessToken}`,
      },
    };
  }
}
