import {Injectable, inject} from '@angular/core';
import {PlatformId} from '@em/shared/platforms/util';
import {TranslateService} from '@ngx-translate/core';
import {ExportJob, NotificationsResp} from '@em/shared/api-interface';
import {Observable, combineLatest, map, of} from 'rxjs';
import {NotificationSupplDataService} from './notifications-suppl-data.service';
import {workflowStatus} from './types';

export interface INotificationBuilder {
  buildNotification: (
    notifications: Notification,
    status: workflowStatus,
  ) => Observable<INotification | INotification[]>;
}
export interface IMappedCampaignsData {
  [k: string]: {
    name: string | undefined;
    campaignConfigurationUuid: string;
  };
}

export interface IInterpolatedTranslations {
  isHtml?: boolean;
  i18nKey: string;
  interpolatedParams: {[k: string]: number | string | undefined};
}

export interface INotification {
  workflowUuid: string;
  type: string;
  title?: IInterpolatedTranslations;
  status: 'finished' | 'failed' | 'requested';
  description: IInterpolatedTranslations[];
  createdAt: Date;
  icon?: {
    name: string;
    type: 'svg' | 'default';
  };
}

export type WorkflowCompleted = NotificationsResp[0] & {
  key: 'UseCases::Workflows::Notifications::WorkflowCompleted';
};
export type BatchCompleted = NotificationsResp[0] & {
  key: 'UseCases::Workflows::Notifications::BatchCompleted';
};
export type ProductsFetched = NotificationsResp[0] & {
  key: 'Datafeed::Domain::Notifications::ProductsFetched';
};
export type CategoriesFetched = NotificationsResp[0] & {
  key: 'Datafeed::Domain::Notifications::CategoriesFetched';
};
export type PricesFetched = NotificationsResp[0] & {
  key: 'MarketInsights::Domain::Notifications::PricesFetched';
};
export type ProductsCsvCreated = NotificationsResp[0] & {
  key: 'UseCases::Notifications::ProductsCsvCreated';
};
export type CompetitorsCsvCreated = NotificationsResp[0] & {
  key: 'UseCases::Notifications::CompetitorsCsvCreated';
};
export type CampaignUpdated = NotificationsResp[0] & {
  key: 'UseCases::Notifications::CampaignUpdated';
};
export type PriceChanged = NotificationsResp[0] & {
  key: 'Repricing::Domain::Notifications::PriceChanged';
};
export type BingProductsUploaded = NotificationsResp[0] & {
  key: 'Bing::Domain::Notifications::ProductUpload';
};
export type Notification =
  | WorkflowCompleted
  | BatchCompleted
  | ProductsFetched
  | CategoriesFetched
  | PricesFetched
  | ProductsCsvCreated
  | CompetitorsCsvCreated
  | CampaignUpdated
  | PriceChanged
  | BingProductsUploaded;

@Injectable({providedIn: 'root'})
export class WorkflowCompletedNotification implements INotificationBuilder {
  private readonly _translateService = inject(TranslateService);
  buildNotification(
    untypedNotification: Notification,
  ): Observable<INotification> {
    const notification = untypedNotification as WorkflowCompleted;
    return of({
      workflowUuid: notification.workflow_uuid,
      type: notification.key,
      status: notification.payload.failed ? 'failed' : 'finished',
      description: [
        {
          i18nKey: notification.payload.failed
            ? 'NOTIFICATION_WORKFLOW_COMPLETED_FAILED_MESSAGE'
            : 'NOTIFICATION_WORKFLOW_COMPLETED_SUCCESS_MESSAGE',
          interpolatedParams: {
            workflow_type: this._translateService.instant(
              notification.payload.workflow_type
                .replace(/::/g, '_')
                .toUpperCase(),
            ),
          },
          isHtml: true,
        },
      ],
      createdAt: notification.created_at
        ? new Date(notification.created_at)
        : new Date(),
    });
  }
}

@Injectable()
export class BatchCompletedNotification implements INotificationBuilder {
  private readonly _suppleData = inject(NotificationSupplDataService);
  private readonly _translateService = inject(TranslateService);

  buildNotification(
    untypedNotification: Notification,
  ): Observable<INotification[]> {
    const notification = untypedNotification as BatchCompleted;
    return combineLatest([
      this._suppleData.allCampaignsHashed$,
      this._suppleData.exportJobs$,
    ]).pipe(
      map(([campaigns, exportJobs]) =>
        notification.payload.workers.map((worker) => {
          let description: IInterpolatedTranslations[];
          if (
            worker.key ===
            'UseCases::Workflows::EgressFeed::ExportProductsWorker'
          ) {
            description = [
              {
                i18nKey: notification.payload.failed
                  ? 'NOTIFICATION_WORKFLOW_EXPORT_PRODUCTS_FAILED_MESSAGE'
                  : 'NOTIFICATION_WORKFLOW_EXPORT_PRODUCTS_SUCCESS_MESSAGE',
                interpolatedParams: this.buildExportParams(worker, exportJobs),
                isHtml: true,
              },
            ];
          } else {
            description = [
              {
                i18nKey: notification.payload.failed
                  ? 'NOTIFICATION_WORKFLOW_BATCHCOMPLETED_FAILED_MESSAGE'
                  : 'NOTIFICATION_WORKFLOW_BATCHCOMPLETED_SUCCESS_MESSAGE',
                interpolatedParams: this.buildInterpolatedParams(
                  this._translateService,
                  worker,
                  campaigns,
                ),
                isHtml: true,
              },
            ];
          }

          return {
            workflowUuid: notification.workflow_uuid,
            type: notification.key,
            status: notification.payload.failed ? 'failed' : 'finished',
            description,
            createdAt: notification.created_at
              ? new Date(notification.created_at)
              : new Date(),
          };
        }),
      ),
    );
  }

  buildInterpolatedParams(
    translate: TranslateService,
    worker: BatchCompleted['payload']['workers'][0],
    campaigns: IMappedCampaignsData,
  ): IInterpolatedTranslations['interpolatedParams'] {
    if (
      worker.key ===
      'UseCases::Workflows::AutomaticCampaign::CheckFilterChangesWorker'
    ) {
      const campaign =
        campaigns[(worker.payload as string[])[0]]?.name ||
        translate.instant('ACTIVITY_CAMPAIGN_DELETED');
      return {
        workflow_type: translate.instant(
          worker.key.replace(/::/g, '_').toUpperCase(),
          {campaign},
        ),
      };
    }
    return {
      workflow_type: translate.instant(
        worker.key.replace(/::/g, '_').toUpperCase(),
        worker.payload,
      ),
    };
  }

  buildExportParams(
    worker: BatchCompleted['payload']['workers'][0],
    exportJobs?: ExportJob[],
  ): IInterpolatedTranslations['interpolatedParams'] {
    const exportJob = exportJobs?.find((j) => j.uuid === worker.payload[1]);

    return {
      jobName: exportJob?.name || '',
    };
  }
}

@Injectable()
export class BingDatafeeddNotification implements INotificationBuilder {
  buildNotification(
    untypedNotification: Notification,
  ): Observable<INotification> {
    const notification = untypedNotification as BatchCompleted;

    return of({
      workflowUuid: notification.workflow_uuid,
      type: notification.key,
      status: notification.payload.failed ? 'failed' : 'finished',
      description: [
        {
          i18nKey: notification.payload.failed
            ? 'BING_WORKFLOW_PRODUCTS_UPLOAD_FAILED_MESSAGE'
            : 'BING_WORKFLOW_PRODUCTS_UPLOAD_SUCCESS_MESSAGE',
          interpolatedParams: {},
          isHtml: true,
        },
      ],
      createdAt: notification.created_at
        ? new Date(notification.created_at)
        : new Date(),
    });
  }
}

@Injectable()
export class ProductsFetchedNotification implements INotificationBuilder {
  buildNotification(
    untypedNotification: Notification,
    status: workflowStatus,
  ): Observable<INotification> {
    const notification = untypedNotification as ProductsFetched;

    return of({
      workflowUuid: notification.workflow_uuid,
      type: notification.key,
      status,
      description: [
        {
          i18nKey: `${notification.key
            .replace(/::/g, '_')
            .toUpperCase()}_DESCRIPTION`,
          interpolatedParams: notification.payload,
          isHtml: true,
        },
      ],
      createdAt: notification.created_at
        ? new Date(notification.created_at)
        : new Date(),
    });
  }
}

@Injectable()
export class CategoriesFetchedNotification implements INotificationBuilder {
  buildNotification(
    untypedNotification: Notification,
    status: workflowStatus,
  ): Observable<INotification> {
    const notification = untypedNotification as CategoriesFetched;

    return of({
      workflowUuid: notification.workflow_uuid,
      type: notification.key,
      status,
      description: [
        {
          i18nKey: `${notification.key
            .replace(/::/g, '_')
            .toUpperCase()}_DESCRIPTION`,
          interpolatedParams: notification.payload,
          isHtml: true,
        },
      ],
      createdAt: notification.created_at
        ? new Date(notification.created_at)
        : new Date(),
    });
  }
}

@Injectable()
export class PricesFetchedNotification implements INotificationBuilder {
  buildNotification(
    untypedNotification: Notification,
    status: workflowStatus,
  ): Observable<INotification> {
    const notification = untypedNotification as PricesFetched;

    return of({
      workflowUuid: notification.workflow_uuid,
      type: notification.key,
      status,
      description: [
        {
          i18nKey: 'NOTIFICATION_PRICE_FETCH_MESSAGE',
          interpolatedParams: notification.payload,
          isHtml: true,
        },
      ],
      createdAt: notification.created_at
        ? new Date(notification.created_at)
        : new Date(),
    });
  }
}

@Injectable()
export class ProductsCsvCreatedNotification implements INotificationBuilder {
  buildNotification(
    untypedNotification: Notification,
    status: workflowStatus,
  ): Observable<INotification> {
    const notification = untypedNotification as ProductsCsvCreated;

    return of({
      workflowUuid: notification.workflow_uuid,
      type: notification.key,
      status,
      description: [
        {
          i18nKey: 'NOTIFICATION_PRODUCT_CSV_MESSAGE',
          interpolatedParams: notification.payload,
          isHtml: true,
        },
      ],
      createdAt: notification.created_at
        ? new Date(notification.created_at)
        : new Date(),
    });
  }
}

@Injectable()
export class CompetitorsCsvCreatedNotification implements INotificationBuilder {
  buildNotification(
    untypedNotification: Notification,
    status: workflowStatus,
  ): Observable<INotification> {
    const notification = untypedNotification as CompetitorsCsvCreated;

    return of({
      workflowUuid: notification.workflow_uuid,
      type: notification.key,
      status,
      description: [
        {
          i18nKey: 'NOTIFICATION_COMPETITOR_CSV_MESSAGE',
          interpolatedParams: notification.payload,
          isHtml: true,
        },
      ],
      createdAt: notification.created_at
        ? new Date(notification.created_at)
        : new Date(),
    });
  }
}

@Injectable()
export class PriceChangedNotification implements INotificationBuilder {
  buildNotification(
    untypedNotification: Notification,
    status: workflowStatus,
  ): Observable<INotification> {
    const notification = untypedNotification as PriceChanged;

    return of({
      workflowUuid: notification.workflow_uuid,
      type: notification.key,
      status,
      description: [
        {
          i18nKey: `${notification.key
            .replace(/::/g, '_')
            .toUpperCase()}_DESCRIPTION`,
          interpolatedParams: {
            ...notification.payload,
            old_price: notification.payload.old_price.toFixed(2),
            new_price: notification.payload.new_price.toFixed(2),
          },
          isHtml: true,
        },
      ],
      createdAt: notification.created_at
        ? new Date(notification.created_at)
        : new Date(),
    });
  }
}

@Injectable()
export class CampaignUpdatedNotification implements INotificationBuilder {
  private readonly _suppleData = inject(NotificationSupplDataService);
  private readonly _translateService = inject(TranslateService);

  buildNotification(
    untypedNotification: Notification,
    status: workflowStatus,
  ): Observable<INotification> {
    const notification = untypedNotification as CampaignUpdated;
    return this._suppleData.allCampaignsHashed$.pipe(
      map((campaigns) => {
        const i18nBase = notification.key.replace(/::/g, '_').toUpperCase();
        const delta = notification.payload.delta;
        const set = notification.payload.set;
        const description: IInterpolatedTranslations[] = [];
        const icon = this.buildIcon(notification.payload.platform);
        const title = this.buildTitle(
          this._translateService,
          notification.payload.platform,
          notification.payload.automatic_campaign_uuid,
          campaigns,
        );

        if (delta !== null) {
          const removedCount = delta.offer_ids_to_remove.length;
          const addedCount = delta.offer_ids_to_add.length;

          if (removedCount === 0 && addedCount === 0) {
            description.push({
              i18nKey: `${i18nBase}_DELTA_EMPTY`,
              interpolatedParams: {},
            });
          } else {
            description.push({
              i18nKey: `${i18nBase}_DELTA`,
              interpolatedParams: {
                removed_count: removedCount,
                added_count: addedCount,
              },
              isHtml: true,
            });
          }
        }

        if (set !== null) {
          let counter = 1;
          Object.entries(set.individual).forEach(([, value]) => {
            description.push({
              i18nKey: `${i18nBase}_SET_INDIVIDUAL`,
              interpolatedParams: {
                filter_key: counter,
                individual_count: value.length,
              },
              isHtml: true,
            });
            counter++;
          });

          description.push({
            i18nKey: `${i18nBase}_SET_INTERSECTION`,
            interpolatedParams: {
              intersection_count: set.intersection.length,
            },
            isHtml: true,
          });
        }

        if (delta === null && set === null) {
          description.push({
            i18nKey: `${i18nBase}_NO_CHANGES`,
            interpolatedParams: {},
          });
        }

        return {
          workflowUuid: notification.workflow_uuid,
          type: notification.key,
          title,
          status,
          description,
          createdAt: notification.created_at
            ? new Date(notification.created_at)
            : new Date(),
          icon,
        };
      }),
    );
  }

  buildTitle(
    translate: TranslateService,
    platform: PlatformId,
    campaignUuid: string,
    campaigns: IMappedCampaignsData,
  ): IInterpolatedTranslations {
    const campaign = campaigns[campaignUuid];
    if (campaign) {
      return {
        isHtml: true,
        i18nKey: 'ACTIVITIES_CAMPAIGN_LINK',
        interpolatedParams: {
          platform,
          uuid: campaign.campaignConfigurationUuid,
          name: campaign.name || translate.instant('ACTIVITY_CAMPAIGN_DELETED'),
        },
      };
    }
    return {
      i18nKey: 'ACTIVITY_CAMPAIGN_DELETED',
      interpolatedParams: {},
    };
  }

  buildIcon(platform: PlatformId): INotification['icon'] {
    let key: string;
    switch (platform) {
      case 'google-shopping':
        key = 'google-icon-colored';
        break;
      case 'bing':
        key = 'microsoft-logo';
        break;
      default:
        key = 'facebook-icon';
        break;
    }

    return {type: 'svg', name: key};
  }
}
