<div class="flex flex-col items-center">
  <em-warning-box class="mb-4" *ngIf="showProductsMissing$ | async">
    <ng-container title>{{
      'SHARED_WARNING_NO_PRODUCTS_TITLE' | translate
    }}</ng-container>
    <ng-container description>
      {{ 'SHARED_WARNING_NO_PRODUCTS' | translate }}
    </ng-container>
  </em-warning-box>

  <ng-container *ngIf="showSuggestedPlan$ | async">
    <em-warning-box
      *ngIf="noPlanExist$ | async; else upgradeOrDowngrade"
      class="mb-8"
    >
      <ng-container title>{{
        'SHARED_WARNING_PLAN_MISSING_TITLE' | translate
      }}</ng-container>
      <ng-container description>
        {{ 'SHARED_WARNING_PLAN_MISSING' | translate }}
      </ng-container>
    </em-warning-box>
    <ng-template #upgradeOrDowngrade>
      <em-warning-box class="mb-4" *ngIf="showUpgradeWarning$ | async">
        <ng-container description>{{
          'SHARED_WARNING_PLAN_UPGRADE_NEEDED' | translate
        }}</ng-container>
      </em-warning-box>

      <em-info-box class="mb-4" *ngIf="showDowngradeTip$ | async">
        <ng-container description>{{
          'SHARED_TIP_PLAN_DOWNGRADE_NEEDED' | translate
        }}</ng-container>
      </em-info-box>
    </ng-template>

    <em-plan-box
      *ngIf="suggestedPlan$ | async as suggestedPlan"
      class="mt-4"
      [contract]="suggestedPlan"
      [highlightDiff]="liveContractWithPrice$ | async"
      [recommended]="true"
      [CTA]="'SHARED_LABEL_DETAILS'"
      (subscribe)="upgrade(suggestedPlan)"
    ></em-plan-box>
  </ng-container>

  <ng-container *ngIf="showLivePlan$ | async">
    <em-plan-box
      *ngIf="liveContractWithPrice$ | async as liveContract"
      class="mt-4"
      [contract]="liveContract"
      [current]="true"
    ></em-plan-box>
  </ng-container>
</div>
