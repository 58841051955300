import {Injectable, inject} from '@angular/core';
import {DeepPartial} from '@em/shared/util-types';
import {Contract} from '@em/subscription/api-interface';
import {
  BehaviorSubject,
  combineLatest,
  map,
  shareReplay,
  switchMap,
} from 'rxjs';
import {ContractService} from '@em/subscription/data-access';

@Injectable()
export class SuggestedPlanService {
  private readonly _contractService = inject(ContractService);

  upgradeNeededIf: ((c: Contract) => boolean) | undefined;
  readonly missingFeatures$ = new BehaviorSubject<
    DeepPartial<Contract> | undefined
  >(undefined);

  // Get a suggested plan for the user, include on:
  // - the existing pending features
  // - the features selected
  readonly suggestedPlan$ = this.missingFeatures$.pipe(
    switchMap((subContract) =>
      this._contractService.getSuggestedContract(subContract as Contract),
    ),
    shareReplay({bufferSize: 1, refCount: true}),
  );

  // user need to upgrade in one of the following cases:
  // - No Live Contract Exist
  // - suggested plan credits exceed the user live credits
  // - Specific condition need to passed (upgradeNeededIf), this can be used to explicit define needed features
  readonly isUpgradeNeeded$ = combineLatest([
    this.suggestedPlan$,
    this._contractService.liveContractWitCredits$,
  ]).pipe(
    map(
      ([suggestedPlan, liveWithCredits]) =>
        !liveWithCredits ||
        (suggestedPlan?.pricePreview.credits || 0) > liveWithCredits.credits ||
        (!!this.upgradeNeededIf &&
          !!liveWithCredits &&
          this.upgradeNeededIf(liveWithCredits.details)),
    ),
  );

  // user need to downgrade if the suggested plan credits is lower than live credits
  readonly isDowngradeNeeded$ = combineLatest([
    this.suggestedPlan$,
    this._contractService.liveContractWitCredits$,
  ]).pipe(
    map(
      ([suggestedPlan, liveWithCredits]) =>
        !!suggestedPlan &&
        !!liveWithCredits &&
        suggestedPlan.pricePreview.credits < liveWithCredits.credits,
    ),
  );

  //#region Show/Hide flags

  readonly showSuggestedPlan$ = combineLatest([
    this.isUpgradeNeeded$,
    this.isDowngradeNeeded$,
  ]).pipe(
    map(
      ([isUpgradeNeeded, isDowngradeNeeded]) =>
        isUpgradeNeeded || isDowngradeNeeded,
    ),
  );

  readonly showLivePlan$ = combineLatest([
    this.showSuggestedPlan$,
    this._contractService.liveContract$,
  ]).pipe(
    map(([suggestedPlan, liveContract]) => !suggestedPlan && !!liveContract),
  );

  readonly noPlanExist$ = this._contractService.noPlanExist$;

  //#endregion
}
