import {DecimalPipe} from '@angular/common';
import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  standalone: true,
  name: 'numberWithUnit',
})
export class EmNumberWithUnitPipe implements PipeTransform {
  constructor(private readonly _decimalPipe: DecimalPipe) {}

  transform(
    value: string | number | null | undefined,
    unit?: string | null,
    fractionSize?: number,
  ): string | null {
    if (value === null || value === undefined) return '-';

    if (unit) {
      if (fractionSize === undefined) fractionSize = 2;
      return (
        this._decimalPipe.transform(value, `.${fractionSize}-${fractionSize}`) +
        ` ${unit}`
      );
    }

    if (fractionSize === undefined) {
      return this._decimalPipe.transform(value);
    }

    return this._decimalPipe.transform(
      value,
      `.${fractionSize}-${fractionSize}`,
    );
  }
}
