import {Injectable} from '@angular/core';
import {WorkFlowBackendResp, WorkflowType} from '@em/shared/api-interface';
import {WorkflowsGateway} from '@em/shared/api-interface/lib/gateways/workflows.gateway';
import {NEVER, Observable, merge, of} from 'rxjs';
import {map, shareReplay, startWith, switchMap} from 'rxjs/operators';
import {WorkflowActivityFactory} from './workflow-activity.factory';
import {IWorkflowEntry} from './workflow-entry.model';
import {NotificationsService} from '@em/server-notifications';

export interface IPaginationParams {
  page: number | undefined;
  count: number | undefined;
  workflowType?: WorkflowType;
}

@Injectable({
  providedIn: 'root',
})
export class WorkflowsService {
  constructor(
    private readonly _workflowActivity: WorkflowActivityFactory,
    private readonly _workflowsGateway: WorkflowsGateway,
    private readonly _notificationsService: NotificationsService,
  ) {}

  getWorkflows(params?: IPaginationParams): Observable<IWorkflowEntry[]> {
    const mappedParams = this._removeUndefined({
      page: params?.page ? `${params.page}` : undefined,
      count: params?.count ? `${params.count}` : undefined,
      workflow_type: params?.workflowType,
    });
    return this._workflowsGateway.getWorkflows(mappedParams).pipe(
      map((workflows) =>
        workflows.map((wf) => this._workflowActivity.buildActivity(wf)),
      ),
      shareReplay({refCount: false, bufferSize: 1}),
    );
  }

  getFilteredWorkflow(
    predicate?: (workflow: WorkFlowBackendResp) => boolean,
    params?: IPaginationParams,
  ) {
    const mappedParams = this._removeUndefined({
      page: params?.page ? `${params.page}` : undefined,
      count: params?.count ? `${params.count}` : undefined,
      workflow_type: params?.workflowType,
    });
    return this._workflowsGateway.getWorkflows(mappedParams).pipe(
      map((workflows) =>
        workflows
          .filter((wf) => !predicate || predicate(wf))
          .map((wf) => this._workflowActivity.buildActivity(wf)),
      ),
      shareReplay({refCount: false, bufferSize: 1}),
    );
  }

  getAndMonitorWorkflow(
    workflowType: WorkflowType,
    filterWorkFlows?: (workFlows: WorkFlowBackendResp) => boolean,
    refresh$?: Observable<void>,
    uuidToMonitor$?: Observable<string>,
  ): Observable<{
    inProgress?: WorkFlowBackendResp;
    lastSuccess?: WorkFlowBackendResp;
    lastFail?: WorkFlowBackendResp;
    justFinished?: boolean;
  }> {
    let trigger$: Observable<void | boolean>;
    if (refresh$) {
      trigger$ = refresh$;
    } else {
      trigger$ = of(true);
    }

    return trigger$.pipe(
      switchMap(() =>
        this._workflowsGateway.getWorkflows({
          workflow_type: workflowType,
          count: '20',
        }),
      ),
      map((workflows) =>
        workflows.filter((workflow) =>
          filterWorkFlows ? filterWorkFlows(workflow) : true,
        ),
      ),
      switchMap((workflows) => {
        const {lastSuccess, lastFail, inProgress} =
          this._getLatestWFs(workflows);

        if (inProgress || uuidToMonitor$) {
          // if workflow still in progress monitor the complete notification
          return merge(
            inProgress ? of(inProgress.uuid) : NEVER,
            uuidToMonitor$ ?? NEVER,
          ).pipe(
            switchMap((uuid) =>
              this._notificationsService.workflowCompletedNotification(uuid),
            ),
            map((finishedWorkflow) => ({
              inProgress: undefined,
              lastFail: finishedWorkflow?.failed
                ? ({
                    ...inProgress,
                    failed_at: new Date().toISOString(),
                    finished_at: new Date().toISOString(),
                  } as WorkFlowBackendResp)
                : undefined,
              lastSuccess: finishedWorkflow?.failed
                ? lastSuccess
                : ({
                    ...inProgress,
                    finished_at: new Date().toISOString(),
                  } as WorkFlowBackendResp),
              justFinished: true,
            })),
            startWith({lastSuccess, lastFail, inProgress, justFinished: false}),
          );
        } else {
          return of({lastSuccess, lastFail, inProgress, justFinished: false});
        }
      }),
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _removeUndefined(params: {[k: string]: any}) {
    for (const key in params) {
      if (params[key] === undefined) {
        delete params[key];
      }
    }
    return params;
  }

  private _getLatestWFs(workFlows: WorkFlowBackendResp[]): {
    inProgress?: WorkFlowBackendResp;
    lastSuccess?: WorkFlowBackendResp;
    lastFail?: WorkFlowBackendResp;
  } {
    let lastSuccess: WorkFlowBackendResp | undefined,
      lastFail: WorkFlowBackendResp | undefined,
      inProgress: WorkFlowBackendResp | undefined;

    for (const workflow of workFlows) {
      if (!workflow.failed_at && !workflow.finished_at) {
        inProgress = workflow;
      } else if (workflow.failed_at && !lastFail) {
        lastFail = workflow;
      } else if (workflow.finished_at && !workflow.failed_at) {
        lastSuccess = workflow;
        break;
      }
    }

    return {lastSuccess, lastFail, inProgress};
  }
}
