<div *ngIf="item" class="filter-connect">
  <mat-radio-group
    (change)="changeLogicalOperator($event)"
    [value]="value"
    aria-label="Select an option"
  >
    <mat-radio-button color="primary" value="and">{{
      'AND' | translate
    }}</mat-radio-button>
    <mat-radio-button color="primary" value="or">{{
      'OR' | translate
    }}</mat-radio-button>
  </mat-radio-group>

  <button (click)="doAction()" class="button-connect">
    <img [src]="icon" alt="Connect" />
  </button>
</div>
