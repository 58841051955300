import {Contract} from '@em/subscription/api-interface';

export function isMicrosoftCampaignsEnabled(contract: Contract): boolean {
  return !!contract?.advertising?.microsoft_campaigns;
}

export function isMicrosoftCampaignsDisabled(contract: Contract): boolean {
  return !contract?.advertising?.microsoft_campaigns;
}

export function isMicrosoftUploadEnabled(contract: Contract): boolean {
  return !!contract?.datafeed?.microsoft_upload;
}

export function isMicrosoftUploadDisabled(contract: Contract): boolean {
  return !contract?.datafeed?.microsoft_upload;
}
