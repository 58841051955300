import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {LocalUserDataService} from '@em/auth/data-access';
import {LocationUrlService} from '@em/shared/util-configuration';
import {LoggerService} from '@em/shared/util-configuration';
import {SessionService} from '@em/auth/data-access';
import {NotificationService} from '@em/shared/util-web';
import {TranslateService} from '@ngx-translate/core';

const UNAUTHORIZED_ERROR = 401;
const MISSING_CONNECTION_ERROR = 409;

const ERROR_KEY_MAP: {[key: string]: string | undefined} = {
  'UseCases::ErrorHandlers::AdwordsErrorHandler': 'adwords-connection',
  'UseCases::ErrorHandlers::OauthErrorHandler': 'google-oauth',
  'UseCases::ErrorHandlers::FacebookErrorHandler': 'facebook',
  'UseCases::ErrorHandlers::BingErrorHandler': 'bing',
  'Services::Facebook::FacebookCampaignCreationPrerequisitesError':
    'facebook-campaign-creation-prerequisites',
};

/**
 * Captures all 401 and 409 Http Errors and redirects the user to the error page, if possible with a mapped i18n-key for
 * certain errors defined in `ERROR_KEY_MAP`.
 * If the status code is expected but should not redirect to an error page, add the request URL to `_excludedPaths`.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
@Injectable()
export class HttpErrorMessageInterceptor implements HttpInterceptor {
  private readonly _excludedPaths = [
    '/emarketing_api/merchants/login',
    '/emarketing_api/enterprise/performance',
    '/emarketing_api/enterprise/signup',
    '/emarketing_api/enterprise/social_login',
  ];
  private readonly _statusCodes = [
    UNAUTHORIZED_ERROR,
    MISSING_CONNECTION_ERROR,
  ];

  constructor(
    private readonly _logger: LoggerService,
    private readonly _locationUrl: LocationUrlService,
    private readonly _localUserData: LocalUserDataService,
    private readonly _router: Router,
    private readonly _session: SessionService,
    private readonly _matDialog: MatDialog,
    private readonly _notificationService: NotificationService,
    private readonly _translateService: TranslateService,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next
      .handle(req)
      .pipe(
        catchError((err: unknown) => this._checkForPayloadErrorMessage(err)),
      );
  }

  private _checkForPayloadErrorMessage(event: any): Observable<never> {
    if (event instanceof HttpErrorResponse) {
      const response = event as HttpErrorResponse;

      if (
        this._statusCodes.includes(response.status) &&
        !this._isExcludedUrl(response.url)
      ) {
        this._matDialog.closeAll();

        if (response.status === UNAUTHORIZED_ERROR) {
          this._logger.warn('Merchant was not found, clearing session.');

          // Ignore hanging requests that run parallel to URL change
          if (!this._session.isChangingUrl) {
            this._localUserData.clear();
            this._locationUrl.forceChange('/');
          }

          return throwError(new Error('Unauthorized merchant (401)'));
        } else if (response.status === MISSING_CONNECTION_ERROR) {
          const errorKey = this._getErrorKey(response.error);
          this._notificationService.error(
            this._translateService.instant(
              `APP_ERROR_${
                errorKey ? errorKey.replace(/-/g, '_').toUpperCase() : 'UNKNOWN'
              }_DESCRIPTION`,
            ),
            undefined,
            5000,
          );
          this._localUserData.clear();
          this._locationUrl.forceChange('/');
          return throwError(new Error('Invalid merchant (409)'));
        }
      }
    }

    return throwError(event);
  }

  private _isExcludedUrl(url: string | null) {
    if (!url) return false;
    return this._excludedPaths.some((path) => url.endsWith(path));
  }

  private _getErrorKey(response: {error: string}) {
    if (!response) return 'unknown-error';
    return ERROR_KEY_MAP[response.error] || 'unknown-error';
  }
}
