import {CommonModule, NgClass} from '@angular/common';
import {Component} from '@angular/core';
import {EmIconModule} from '@em/shared/ui';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';
@Component({
  selector: 'em-value-diff-renderer',
  standalone: true,
  imports: [CommonModule, EmIconModule, NgClass],
  templateUrl: './value-diff-renderer.component.html',
  styleUrls: ['./value-diff-renderer.component.scss'],
  styles: [],
})
export class CustomValueDiffRendererComponent
  implements ICellRendererAngularComp
{
  displayValue: string | undefined | null;
  value: number | undefined;

  // gets called once before the renderer is used
  agInit(params: ICellRendererParams): void {
    this.refreshView(params);
  }

  // gets called whenever the cell refreshes
  refresh(params: ICellRendererParams): boolean {
    // set value into cell again
    this.refreshView(params);
    return true;
  }

  refreshView(params: ICellRendererParams) {
    this.value = params.value;
    this.displayValue = params.valueFormatted;
  }
}
