import {IPlatform} from '../types/platform';

export const MICROSOFT: Readonly<IPlatform> = {
  id: 'bing',
  name: 'Microsoft',
  i18nKey: 'BING',
  colors: {
    charts: '#008575',
  },
  logos: {
    general: 'assets/images/bing/microsoft-logo.svg',
    mono: 'assets/images/bing/microsoft-advertising.png',
    campaign: 'assets/images/bing/microsoft-logo.svg',
  },
  supportsKpis: true,
  supportsMultiCampaignTypes: true,
};
