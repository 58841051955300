import {inject} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {IFilterOptionDef, INumberFilterParams} from 'ag-grid-community';
import {getBasicFilterParams} from '../../../get-basic-filter-params/get-basic-filter-params';

export function getFetchStatusFilterParams(): INumberFilterParams {
  const translateService = inject(TranslateService);

  return {
    ...getBasicFilterParams(),
    filterOptions: [
      'empty',
      {
        displayKey: 'eq_found',
        displayName: translateService.instant('SHARED_LABEL_PRICE_FOUND'),
        predicate: (_, cellValue) => cellValue === 'found',
        numberOfInputs: 0,
      },
      {
        displayKey: 'eq_not_found',
        displayName: translateService.instant('SHARED_LABEL_PRICE_NOT_FOUND'),
        predicate: (_, cellValue) => cellValue === 'not_found',
        numberOfInputs: 0,
      },
      {
        displayKey: 'eq_error',
        displayName: translateService.instant('SHARED_LABEL_PRICE_ERROR'),
        predicate: (_, cellValue) => cellValue === 'error',
        numberOfInputs: 0,
      },
      {
        displayKey: 'fetch_status_blank',
        displayName: translateService.instant('SHARED_LABEL_BLANK'),
        predicate: (_, cellValue) =>
          cellValue === null || cellValue === undefined,
        numberOfInputs: 0,
      },
      {
        displayKey: 'fetch_status_notBlank',
        displayName: translateService.instant('SHARED_LABEL_NOT_BLANK'),
        predicate: (_, cellValue) =>
          cellValue !== null && cellValue !== undefined,
        numberOfInputs: 0,
      },
    ] as IFilterOptionDef[],
  };
}
