import {createAction, props} from '@ngrx/store';
import {IMerchantModel} from '../merchant.model';

export const loadMerchantSuccess = createAction(
  '[Merchant] Load Merchant Success',
  props<{data: IMerchantModel}>(),
);

export const basicDataConfirmed = createAction(
  '[Merchant] Basic Data Confirmed',
  props<{isConfirmed: boolean}>(),
);
