import {Component, OnDestroy} from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {MerchantsGateway} from '@em/shared/api-interface/lib/gateways/merchants.gateway';
import {expandCollapseAnimation} from '@em/shared/ui';
import {Observable, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {IService} from './IService';
import {oneServiceAtLeast} from './services-list.validator';

type RequestSource = 'emarketing' | 'epwr' | 'intellitracking';

const dataFeed: IService = {
  name: 'datafeed',
  subServices: [
    {name: 'google'},
    {name: 'facebook'},
    {name: 'amazon'},
    {name: 'bing'},
    {name: 'otto', soon: true},
    {name: 'zalando', soon: true},
  ],
  checked: false,
};

const campaign: IService = {
  name: 'campaign',
  subServices: [
    {name: 'google'},
    {name: 'facebook'},
    {name: 'amazon'},
    {name: 'bing'},
    {name: 'otto', soon: true},
    {name: 'zalando', soon: true},
  ],
  checked: false,
};

const repricing: IService = {
  name: 'repricing',
  subServices: [{name: 'google'}, {name: 'competitors'}],
  checked: false,
};

const journeyAnalysis: IService = {
  name: 'journeyAnalysis',
  checked: false,
};

const amazonAds: IService = {
  name: 'amazonAds',
  checked: false,
};

@Component({
  selector: 'em-services-list',
  templateUrl: './services-list.component.html',
  styleUrls: ['./services-list.component.scss'],
  animations: [expandCollapseAnimation],
})
export class ServicesListComponent implements OnDestroy {
  readonly subscriptions = new Subscription();
  readonly source: Observable<RequestSource> =
    this._activatedRoute.queryParamMap.pipe(
      map(
        (queryParamMap) =>
          (queryParamMap.get('source') ?? 'emarketing') as RequestSource,
      ),
    );
  readonly servicesGroup: UntypedFormGroup;
  readonly setupGroup: UntypedFormGroup;
  readonly startGroup: UntypedFormGroup;
  services: IService[] = [];
  appointmentSet = false;
  isLoading = false;

  get shopType(): AbstractControl {
    return this.setupGroup.controls.shopType;
  }
  get shopUrl(): AbstractControl {
    return this.setupGroup.controls.shopUrl;
  }
  get agencyUrl(): AbstractControl {
    return this.setupGroup.controls.agencyUrl;
  }
  get whoIAm(): AbstractControl {
    return this.setupGroup.controls.whoIAm;
  }
  get registerType(): AbstractControl {
    return this.startGroup.controls.registerType;
  }
  get name(): AbstractControl {
    return this.startGroup.controls.name;
  }
  get phone(): AbstractControl {
    return this.startGroup.controls.phone;
  }
  get notes(): AbstractControl {
    return this.startGroup.controls.notes;
  }
  get website(): AbstractControl {
    return this.startGroup.controls.website;
  }
  get withPlugin(): AbstractControl {
    return this.startGroup.controls.withPlugin;
  }
  get email(): AbstractControl {
    return this.startGroup.controls.email;
  }

  constructor(
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _merchantsGateway: MerchantsGateway,
    _formBuilder: UntypedFormBuilder,
  ) {
    this.servicesGroup = new UntypedFormGroup({}, oneServiceAtLeast());
    this.subscriptions.add(
      this.source.subscribe((source) => {
        this.adjustServices(source);
        this.fillServicesGroup();
      }),
    );

    this.setupGroup = _formBuilder.group({
      whoIAm: [''],
      shopType: [''],
      shopUrl: [''],
      agencyUrl: [''],
      googleAds: [],
      googleMC: [],
      facebookAds: [],
      facebookCM: [],
      marketPlace: [],
      amazon: [],
      otto: [],
      zalando: [],
      other: [],
      webshop: [],
      eCommerce: [],
      digitalCommerce: [],
    });
    this.addSetupValidators();

    this.startGroup = _formBuilder.group({
      email: ['', [Validators.email, Validators.required]],
      registerType: ['', Validators.required],
      withPlugin: [null],
      name: [''],
      phone: [''],
      website: [''],
      notes: [''],
    });
    this.addStartValidators();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  submitForm() {
    if (this.startGroup.valid) {
      this.sendOnboardingEmail();
      if (this.registerType.value === 'directly') {
        if (this.withPlugin.value === 'yes') {
          window.location.href = 'https://emarketing.com/plugins';
        } else {
          window.location.href =
            'https://app.emarketing.com/auth/create-account?email=' +
            this.email.value;
        }
      }
    }
  }

  adjustServices(source: RequestSource) {
    if (source === 'intellitracking') {
      this.services = [
        journeyAnalysis,
        dataFeed,
        campaign,
        repricing,
        amazonAds,
      ];
    }
    if (source === 'epwr') {
      this.services = [
        amazonAds,
        dataFeed,
        campaign,
        repricing,
        journeyAnalysis,
      ];
    } else {
      this.services = [
        dataFeed,
        campaign,
        repricing,
        journeyAnalysis,
        amazonAds,
      ];
    }
  }

  private sendOnboardingEmail() {
    this.isLoading = true;
    this._merchantsGateway
      .postOnboardingRequestEmail({
        params: this.getEmailTemplate(),
      })
      .subscribe(
        () => {
          this.appointmentSet = true;
          this.isLoading = false;
        },
        () => (this.isLoading = false),
      );
  }

  private fillServicesGroup() {
    this.services.forEach((serviceGroup) => {
      if (serviceGroup.subServices?.length) {
        const formArray = new UntypedFormArray(
          serviceGroup.subServices.map(() => new UntypedFormControl(false)),
        );
        const formGroup = new UntypedFormGroup({
          subServices: formArray,
          service: new UntypedFormControl(false),
        });
        this.servicesGroup.addControl(serviceGroup.name, formGroup);
      } else {
        const formGroup = new UntypedFormGroup({
          service: new UntypedFormControl(false),
        });
        this.servicesGroup.addControl(serviceGroup.name, formGroup);
      }
    });
  }

  private addSetupValidators() {
    this.subscriptions.add(
      this.whoIAm.valueChanges.subscribe((newValue) => {
        if (newValue === 'onlineRetailer') {
          this.shopUrl.addValidators(Validators.required);
          this.shopType.addValidators(Validators.required);

          this.shopUrl.updateValueAndValidity();
          this.shopType.updateValueAndValidity();
        } else {
          this.shopUrl.removeValidators(Validators.required);
          this.shopType.removeValidators(Validators.required);

          this.shopUrl.updateValueAndValidity();
          this.shopType.updateValueAndValidity();
        }

        if (newValue === 'isAgent') {
          this.agencyUrl.addValidators(Validators.required);

          this.agencyUrl.updateValueAndValidity();
        } else {
          this.agencyUrl.removeValidators(Validators.required);

          this.agencyUrl.updateValueAndValidity();
        }
      }),
    );

    this.subscriptions.add(
      this.setupGroup.controls.marketPlace.valueChanges.subscribe(
        (newValue) => {
          if (!newValue) {
            this.setupGroup.controls.amazon.setValue(null);
            this.setupGroup.controls.otto.setValue(null);
            this.setupGroup.controls.zalando.setValue(null);
            this.setupGroup.controls.other.setValue(null);
          }
        },
      ),
    );

    this.subscriptions.add(
      this.setupGroup.controls.webshop.valueChanges.subscribe((newValue) => {
        if (!newValue) {
          this.setupGroup.controls.eCommerce.setValue(null);
          this.setupGroup.controls.digitalCommerce.setValue(null);
        }
      }),
    );
  }

  private addStartValidators() {
    this.subscriptions.add(
      this.registerType.valueChanges.subscribe((newValue) => {
        if (newValue === 'appointment') {
          this.name.addValidators(Validators.required);
          this.phone.addValidators(Validators.required);
          this.withPlugin.removeValidators(Validators.required);

          this.withPlugin.setValue(null);

          this.name.updateValueAndValidity();
          this.phone.updateValueAndValidity();
          this.withPlugin.updateValueAndValidity();
        } else {
          this.name.removeValidators(Validators.required);
          this.phone.removeValidators(Validators.required);
          this.withPlugin.addValidators(Validators.required);

          this.name.setValue(null);
          this.phone.setValue(null);

          this.name.updateValueAndValidity();
          this.phone.updateValueAndValidity();
          this.withPlugin.updateValueAndValidity();
        }
      }),
    );
  }

  private getYesNo(value: string | boolean) {
    return value ? 'yes' : 'no';
  }

  private getServiceYesNo(serviceName: string, subServiceName?: string) {
    const service = this.servicesGroup.value[serviceName] as {
      service: boolean;
      subServices: boolean[];
    };

    if (service) {
      if (!subServiceName) {
        return service.service ? 'yes' : 'no';
      } else if (service.subServices?.length) {
        const serviceObject = this.services.find((s) => s.name === serviceName);
        const subServiceIndex = serviceObject?.subServices?.findIndex(
          (ss) => ss.name === subServiceName,
        );

        if (subServiceIndex === 0 || subServiceIndex) {
          return service.subServices[subServiceIndex] ? 'yes' : 'no';
        }
      }
    }

    return 'no';
  }

  private getFieldValue(value: string) {
    return value ?? 'unknown';
  }

  private getEmailTemplate() {
    return `
Email(${this.getFieldValue(this.email.value)})
Name(${this.getFieldValue(this.name.value)})
Phone(${this.getFieldValue(this.phone.value)})
Notes(${this.getFieldValue(this.notes.value)})
Website(${this.getFieldValue(this.website.value)})
RegisterPlugin(${this.getYesNo(this.withPlugin.value === 'yes')})
RegisterNoPlugin(${this.getYesNo(this.withPlugin.value === 'no')})
BookAppointment(${this.getYesNo(this.registerType.value === 'appointment')})
IsAgent(${this.getYesNo(this.whoIAm.value === 'isAgent')})
AgencyURL(${this.getFieldValue(this.agencyUrl.value)})
UseAgent(${this.getYesNo(this.whoIAm.value === 'useAgent')})
IsOnlineRetailer(${this.getYesNo(this.whoIAm.value === 'onlineRetailer')})
ShopURL(${this.getFieldValue(this.shopUrl.value)})
ShopSystem(${this.getFieldValue(this.shopType.value)})
WebShop(${this.getYesNo(this.setupGroup.controls.other.value)})
E-Commerce(${this.getYesNo(this.setupGroup.controls.eCommerce.value)})
DigitalCommerce(${this.getYesNo(
      this.setupGroup.controls.digitalCommerce.value,
    )})
Marketplace(${this.getYesNo(this.setupGroup.controls.marketPlace.value)})
Amazon(${this.getYesNo(this.setupGroup.controls.amazon.value)})
OTTO(${this.getYesNo(this.setupGroup.controls.otto.value)})
Amazon(${this.getYesNo(this.setupGroup.controls.zalando.value)})
Other(${this.getYesNo(this.setupGroup.controls.other.value)})
AdvertisingOnGoogle(${this.getYesNo(this.setupGroup.controls.googleAds.value)})
HasGoogleMerchantCenter(${this.getYesNo(
      this.setupGroup.controls.googleMC.value,
    )})
AdvertisingOnFacebook(${this.getYesNo(
      this.setupGroup.controls.facebookAds.value,
    )})
HasFacebookCommerceManager(${this.getYesNo(
      this.setupGroup.controls.facebookCM.value,
    )})
DatafeedManagement(${this.getServiceYesNo('datafeed')})
DatafeedGoogle(${this.getServiceYesNo('datafeed', 'google')})
DatafeedFacebook(${this.getServiceYesNo('datafeed', 'facebook')})
DatafeedAmazon(${this.getServiceYesNo('datafeed', 'amazon')})
DatafeedBing(${this.getServiceYesNo('datafeed', 'bing')})
DatafeedOTTO(${this.getServiceYesNo('datafeed', 'otto')})
DatafeedZalando(${this.getServiceYesNo('datafeed', 'zalando')})
CampaignManagement(${this.getServiceYesNo('campaign')})
CampaignManagementGoogle(${this.getServiceYesNo('campaign', 'google')})
CampaignManagementFacebook(${this.getServiceYesNo('campaign', 'facebook')})
CampaignManagementAmazon(${this.getServiceYesNo('campaign', 'amazon')})
CampaignManagementBing(${this.getServiceYesNo('campaign', 'bing')})
CampaignManagementOTTO(${this.getServiceYesNo('campaign', 'otto')})
CampaignManagementZalando(${this.getServiceYesNo('campaign', 'zalando')})
Repricing(${this.getServiceYesNo('repricing')})
RepricingGoogle(${this.getServiceYesNo('repricing', 'google')})
RepricingCompetitors(${this.getServiceYesNo('repricing', 'competitors')})
CustomerJourneyAnalysis(${this.getServiceYesNo('journeyAnalysis')})
AmazonAds(${this.getServiceYesNo('amazonAds')})
`;
  }
}
