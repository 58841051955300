import {CommonModule} from '@angular/common';
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SchedulSupportedSourceId} from '@em/market-insights/api-interface';
import {FACEBOOK, GOOGLE, MICROSOFT} from '@em/shared/platforms/util';
import {
  EmBoxModule,
  EmIconModule,
  EmPrimaryButtonComponent,
  EmSecondaryButtonComponent,
} from '@em/shared/ui';
import {ContractWithPrice} from '@em/subscription/api-interface';
import {
  getHigherFrequency,
  getSumJobsProducts,
  getUniqueSources,
} from '@em/subscription/data-access';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'em-plan-box',
  templateUrl: './plan-box.component.html',
  styleUrls: ['./plan-box.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    EmPrimaryButtonComponent,
    EmSecondaryButtonComponent,
    EmBoxModule,
    EmIconModule,
  ],
})
export class EmPlanBoxComponent {
  @Input({required: true}) contract?: ContractWithPrice | null;
  @Input() highlightDiff?: ContractWithPrice | null;
  @Input() CTA?: string | null;
  @Input() recommended: boolean | undefined;
  @Input() current: boolean | undefined;
  @Input() disabled: boolean | undefined;

  @Output() subscribe = new EventEmitter<ContractWithPrice>();

  readonly Google = GOOGLE;
  readonly facebook = FACEBOOK;
  readonly microsoft = MICROSOFT;

  constructor() {}

  get crawlEnabled(): boolean {
    return !!this.contract?.contract.price_crawls?.find((c) => c.enabled);
  }

  get crawlProducts(): number {
    return getSumJobsProducts(this.contract?.contract.price_crawls || []);
  }

  get sources(): SchedulSupportedSourceId[] {
    return getUniqueSources(this.contract?.contract.price_crawls || []);
  }

  get higherFrequency() {
    return getHigherFrequency(this.contract?.contract.price_crawls || []) || '';
  }

  get diffHigherFrequency() {
    return (
      getHigherFrequency(this.highlightDiff?.contract.price_crawls || []) || ''
    );
  }

  get diffCrawlProducts(): number {
    return getSumJobsProducts(this.highlightDiff?.contract.price_crawls || []);
  }

  get diffSources(): SchedulSupportedSourceId[] {
    return getUniqueSources(this.highlightDiff?.contract.price_crawls || []);
  }
}
