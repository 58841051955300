import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

@Injectable()
export class AdblockInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (!req.url.startsWith('/emarketing_api/')) {
      return next.handle(req);
    }

    return next.handle(
      req.clone({
        // See corresponding backend part in '/interfaces/emarketing_api/helpers/adblock_helper.rb'
        url: req.url.replace('/adwords/', '/thatwords/'),
      }),
    );
  }
}
