import {CommonModule} from '@angular/common';
import {Component, OnInit, inject} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LocalUserDataService} from '@em/auth/data-access';
import {
  EmBoxModule,
  EmIconModule,
  EmPrimaryButtonComponent,
  EmSpinnerModule,
} from '@em/shared/ui';
import {TranslateModule} from '@ngx-translate/core';
import {ShopifyPaymentStatusStore} from '@em/subscription/data-access';
import {Observable, combineLatest, map, take} from 'rxjs';

@Component({
  selector: 'em-shopify-payment-status-page',
  templateUrl: './shopify-payment-status-page.component.html',
  styleUrls: ['./shopify-payment-status-page.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    EmSpinnerModule,
    EmBoxModule,
    EmIconModule,
    EmPrimaryButtonComponent,
  ],
  providers: [ShopifyPaymentStatusStore],
})
export class EmShopifyPaymentStatusPageComponent implements OnInit {
  private readonly _activatedRoute = inject(ActivatedRoute);
  private readonly _router = inject(Router);
  private readonly _localStorage = inject(LocalUserDataService);
  private readonly _statusStore = inject(ShopifyPaymentStatusStore);

  protected readonly isLoading$ = this._statusStore.isLoading$;
  protected readonly paymentSuccess$ = this._statusStore.paymentSuccess$;
  protected readonly paymentFailed$ = combineLatest([
    this.isLoading$,
    this.paymentSuccess$,
  ]).pipe(map(([isLoading, success]) => !isLoading && !success));

  private readonly _sessionId$: Observable<string> =
    this._activatedRoute.queryParamMap.pipe(
      map((paramMap) => paramMap.get('subscription_id') || ''),
      take(1),
    );

  ngOnInit(): void {
    const subscriptionId = this._localStorage.get('shopify_subscription_id');
    if (subscriptionId) {
      this._statusStore.pollSubscriptionStatus(subscriptionId);
    }
  }

  continue() {
    const redirectUrl = this._localStorage.get('payment_redirect_url');
    if (redirectUrl) {
      this._router.navigateByUrl(redirectUrl, {replaceUrl: true});
      this._localStorage.remove('payment_redirect_url');
    } else {
      this._router.navigateByUrl('/payment/subscription', {replaceUrl: true});
    }
  }

  tryAgain() {
    const redirectUrl = this._localStorage.get('payment_redirect_url');
    this._router.navigateByUrl(redirectUrl || '/payment/subscription', {
      replaceUrl: true,
    });
  }
}
