<mat-radio-group
  color="primary"
  [ngClass]="orientation === 'horizontal' ? 'horizontal' : 'vertical'"
  [ngModel]="controlValue"
  [disabled]="isDisabled"
  (change)="selectionChange.emit($event.value)"
  (ngModelChange)="controlValueChanged($event); controlTouched()"
>
  <div class="radio-button-wrapper" *ngFor="let radio of radios; let i = index">
    <mat-radio-button [value]="radio.value" [disabled]="radio.disabled">
      <ng-container *ngIf="radio.templateRef">
        <ng-template [ngTemplateOutlet]="radio.templateRef"></ng-template>
      </ng-container>
    </mat-radio-button>
    <div
      class="ml-16 mb-3"
      [ngClass]="radio.disabled ? 'disabled-content' : ''"
      *ngIf="radio.subContentDir && radio.subContent"
    >
      <ng-template [ngTemplateOutlet]="radio.subContent"></ng-template>
    </div>
  </div>
</mat-radio-group>

<div
  class="text-red-700 text-xs"
  *ngIf="error && errorMatcher.isErrorState(null, parentFromGroup)"
>
  <ng-content select="em-error"></ng-content>
</div>
