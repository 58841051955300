<em-product-filter-overlay
  overlayTitle="PRODUCT_FILTER_MANUAL_PRODUCT_SELECTION"
  [isCloseable]="false"
  class="second-level no-padding"
>
  <ng-container data-slot-type="action">
    <em-button
      icon="arrow_back"
      color="basic"
      type="text"
      (btnClick)="openEditFilterSet()"
    >
      <span translate>FILTER_BACK_OVERVIEW</span>
    </em-button>
  </ng-container>
  <div data-slot-type="content">
    <div class="list-header fixed">
      <div class="search-input">
        <em-search-input
          class="product-filter"
          (inputChange)="searchProducts($event)"
        ></em-search-input>
      </div>
    </div>
    <div class="list-pane available-products">
      <div class="spaced" *ngIf="!productLimit">
        <em-button
          icon="add_circle"
          type="text"
          color="basic"
          (btnClick)="addAllFilteredToList('manuallyAdded')"
        >
          <span translate>ADD_ALL_PRODUCTS_TO_FILTER</span>
        </em-button>

        <em-button
          icon="remove_circle"
          type="text"
          color="basic"
          (btnClick)="addAllFilteredToList('manuallyRemoved')"
        >
          <span translate>EXCLUDE_ALL_PRODUCTS_FROM_FILTER</span>
        </em-button>
      </div>

      <div
        class="item-wrapper"
        [ngClass]="
          costOfGoodsRequired && !product.costOfGoods ? 'fade-away' : ''
        "
        *ngFor="let product of availableProducts"
      >
        <em-product-item
          class="spaced"
          (productSelected)="onAdded($event, product)"
          [product]="product"
          [showIncludeIcon]="true"
          [showExcludeIcon]="!productLimit"
          [disabled]="product.selected || limitReached"
        >
        </em-product-item>
        <div
          class="warning-overlay"
          *ngIf="costOfGoodsRequired && !product.costOfGoods"
        >
          <mat-icon>warning</mat-icon>
          {{ 'SHARED_LABEL_COST_OF_GOODS_MISSING' | translate }}
        </div>
      </div>
      <em-button
        class="block text-center mb-2"
        *ngIf="
          availableProducts &&
          totalProducts &&
          totalProducts > availableProducts?.length &&
          !isLoadingProducts
        "
        [type]="'text'"
        [icon]="'autorenew'"
        (btnClick)="loadMoreProducts()"
        >{{ 'ACTIVITIES_ACTION_LOAD_MORE' | translate }}</em-button
      >
    </div>
  </div>
</em-product-filter-overlay>

<em-product-filter-overlay
  overlayTitle="PRODUCT_FILTER_SELECTED_PRODUCTS"
  [isCloseable]="false"
  (closed)="openEditFilterSet()"
  class="no-padding embedded-content"
>
  <div data-slot-type="content">
    <div class="list-header">
      {{ getShownProductCount() }}
      {{
        'CAMPAIGN_PERFORMANCE_FILTER_OFFER_IDS_SELECTABLE_PRODUCTS' | translate
      }}
    </div>
    <div class="list-panel">
      <div class="filter-group-header">
        <div class="filter-group-headline added">
          <span translate>FILTER_MANUAL_INCLUDED</span>
          ({{ productsFromList('manuallyAdded').length }})
        </div>
        <em-button
          class="remove-all"
          type="text"
          color="basic"
          icon="remove_circle"
          [disabled]="!productsFromList('manuallyAdded').length"
          (btnClick)="removeAllFromList('manuallyAdded')"
        >
          <span translate>REMOVE_ALL_PRODUCTS_FROM_FILTER_LIST</span>
        </em-button>
      </div>
      <div class="list">
        <div
          *ngFor="let product of productsFromList('manuallyAdded')"
          role="listitem"
        >
          <em-product-item
            class="result"
            (click)="remove(product)"
            [product]="product"
          >
            <div class="delete-action">
              <mat-icon class="align-right delete" aria-label="delete">
                delete
              </mat-icon>
            </div>
          </em-product-item>
        </div>
      </div>

      <ng-container *ngIf="!productLimit">
        <div class="filter-group-header">
          <div class="filter-group-headline excluded">
            <span translate>FILTER_MANUAL_EXCLUDED</span>
            ({{ productsFromList('manuallyRemoved').length }})
          </div>
          <em-button
            class="remove-all"
            type="text"
            color="basic"
            icon="remove_circle"
            [disabled]="!productsFromList('manuallyRemoved').length"
            (btnClick)="removeAllFromList('manuallyRemoved')"
          >
            <span translate>REMOVE_ALL_PRODUCTS_FROM_FILTER_LIST</span>
          </em-button>
        </div>
        <div class="list">
          <div
            *ngFor="let product of productsFromList('manuallyRemoved')"
            role="listitem"
          >
            <em-product-item
              class="result"
              (click)="remove(product)"
              [product]="product"
            >
              <div class="delete-action">
                <mat-icon class="align-right delete" aria-label="delete">
                  delete
                </mat-icon>
              </div>
            </em-product-item>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</em-product-filter-overlay>
