import {PriceCrawl} from '@em/subscription/api-interface';

export function getSumJobsProducts(crawlJobs: PriceCrawl[]): number {
  return (
    crawlJobs.reduce(
      (accumulator, currentValue) => currentValue.total_products + accumulator,
      0,
    ) || 0
  );
}
