import {UntypedFormGroup} from '@angular/forms';
import {combineLatest, Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {ProgressCommand} from './command';

export abstract class FormCommand<R> extends ProgressCommand<R> {
  protected constructor(protected readonly _form: UntypedFormGroup) {
    super();
  }

  canPerform(): Observable<boolean> {
    return combineLatest([
      this.isIdle(),
      this._form.statusChanges.pipe(startWith([undefined])),
      this._form.valueChanges.pipe(startWith([undefined])),
    ]).pipe(
      map(([inProgress]) => inProgress && this._form.dirty && this._form.valid),
    );
  }
}
