<em-select
  class="w-full"
  [label]="label"
  [hint]="hint"
  [placeholder]="placeholder"
  [floatLabel]="floatLabel"
  [errorMatcher]="errorMatcher"
  [disabled]="!!isDisabled"
  [(ngModel)]="controlValue"
  [subscriptSizing]="'dynamic'"
  (selectionChange)="selectionChange.emit($event)"
  (blur)="controlTouched(); blur.emit()"
  (ngModelChange)="controlValueChanged($event)"
>
  <ng-container *ngIf="controlValue && controlValue !== 'ALL'" emPrefix>
    <em-country-flag
      [countryCode]="controlValue || ''"
      size="sm"
      class="ml-4 mr-4"
    ></em-country-flag>
  </ng-container>

  <ng-container emSelectTrigger>
    <ng-container *ngIf="controlValue">
      <ng-container *ngIf="controlValue !== 'ALL'">
        {{ 'COUNTRY_' + controlValue | translate }}
      </ng-container>
      <ng-container *ngIf="controlValue === 'ALL'">
        {{ 'SHARED_LABEL_ALL_COUNTRIES' | translate }}
      </ng-container>
    </ng-container>
  </ng-container>

  <em-option [value]="'ALL'" *ngIf="addAllOption"> All </em-option>

  <em-option *ngFor="let country of availableCountries" [value]="country">
    <div class="flex items-center">
      <em-country-flag [countryCode]="country" class="mr-4"></em-country-flag>
      <span>{{ 'COUNTRY_' + country | translate }}</span>
    </div>
  </em-option>

  <em-error>
    <ng-template [ngTemplateOutlet]="errors"></ng-template>
  </em-error>
</em-select>

<ng-template #errors>
  <ng-content select="em-error"></ng-content>
</ng-template>
