import {CommonModule} from '@angular/common';
import {Component} from '@angular/core';
import {ProductsListComponent} from '../products-list-page/products-list/products-list.component';

@Component({
  selector: 'em-frontend-data-feed-feature-products',
  standalone: true,
  imports: [CommonModule, ProductsListComponent],
  templateUrl: './data-feed-feature-products.component.html',
  styleUrls: ['./data-feed-feature-products.component.scss'],
})
export class DataFeedFeatureProductsComponent {}
