<span class="label" [translate]="performanceLabel || ''"></span>
<span
  class="value"
  *ngIf="
    performanceValue !== null && performanceValue !== undefined;
    else notAvailable
  "
  >{{ performanceValue | numberWithUnit: 'x':1 }}</span
>

<ng-template #notAvailable>
  <span class="value no-data" translate>PERFORMANCE_KPI_VALUE_NA</span>
</ng-template>
