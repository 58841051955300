import {Injectable, inject} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {AutomaticFiltersGateway} from '@em/shared/api-interface';
import {isNotNullOrUndefined} from '@em/shared/util-rxjs';
import {BehaviorSubject, Observable, Subject, combineLatest} from 'rxjs';
import {
  filter,
  finalize,
  shareReplay,
  startWith,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import {FetchProductsStore} from '../fetch-products/fetch-products.store';
import {ProductGroup} from '../types/product-group';
import {ProductGroupsStore} from './product-groups.store';

const MAX_PARALLEL_REQUESTS = 5;

@Injectable({providedIn: 'root'})
export class GroupsProductsService {
  private readonly _autmaticFilterGateway = inject(AutomaticFiltersGateway);
  private readonly _productGroupsStore = inject(ProductGroupsStore);
  private readonly _fetchProductsStore = inject(FetchProductsStore);

  private readonly _responseByGroup = new Map<string, Observable<string[]>>();
  private readonly _loadingFlags = new Map<string, BehaviorSubject<boolean>>();
  private readonly _refreshSignalByGroup = new Map<string, Subject<void>>();
  private readonly _reqQueue$ = new BehaviorSubject<string[]>([]);

  constructor() {
    this._productGroupsStore.groupSaved$
      .pipe(
        tap((group) => this._remoevCache(group)),
        takeUntilDestroyed(),
      )
      .subscribe();
  }

  getProductsIds(group: ProductGroup) {
    const existObs$ = this._responseByGroup.get(group.uuid);

    if (!existObs$) {
      const newResp$ = combineLatest([
        this._fetchProductsStore.uploadFinished$,
        this._getRefreshSignal(group.uuid).pipe(startWith('')),
      ]).pipe(
        switchMap(() =>
          this._productGroupsStore
            .getGroup(group.uuid, group.country)
            .pipe(isNotNullOrUndefined(), take(1)),
        ),
        switchMap((g) => this.fetchProductIdsIds(g)),
        // refCount: false, result will alwasy be cached
        // refCount: true, result will be removed when all ref are unsbuscribed
        shareReplay({bufferSize: 1, refCount: false}),
      );

      this._responseByGroup.set(group.uuid, newResp$);
      return newResp$;
    }

    return existObs$;
  }

  fetchProductIdsIds(group: ProductGroup): Observable<string[]> {
    this._getLoadingFlag(group.uuid).next(true);

    // Add request to the Queue
    this._addRequest(group.uuid);
    return this._reqQueue$.pipe(
      // Make sure only MAX_PARALLEL_REQUESTS of HTTP request are parallel
      filter((running) => running.indexOf(group.uuid) < MAX_PARALLEL_REQUESTS),
      take(1),
      switchMap(() =>
        this._autmaticFilterGateway.postFilterPreview({
          links: group.links,
          country: group.country,
          manually_added_products: group.manually_added_products,
          manually_removed_products: group.manually_removed_products,
          selected_filters: group.selected_filters,
          excluded_set_uuid: group.excluded_set_uuid,
        }),
      ),

      tap(() => {
        this._getLoadingFlag(group.uuid).next(false);
        this._removeRequest(group.uuid);
      }),
      finalize(() => {
        this._getLoadingFlag(group.uuid).next(false);
        this._removeRequest(group.uuid);
      }),
    );
  }

  isLoading(groupId: string) {
    return this._getLoadingFlag(groupId);
  }

  private _getRefreshSignal(groupId: string) {
    const exist = this._refreshSignalByGroup.get(groupId);

    if (exist) {
      return exist;
    } else {
      const newRefreshSignal = new Subject<void>();
      this._refreshSignalByGroup.set(groupId, newRefreshSignal);
      return newRefreshSignal;
    }
  }

  private _getLoadingFlag(groupId: string) {
    const exist = this._loadingFlags.get(groupId);

    if (exist) {
      return exist;
    } else {
      const newLoadingFlag = new BehaviorSubject<boolean>(false);
      this._loadingFlags.set(groupId, newLoadingFlag);
      return newLoadingFlag;
    }
  }

  private _remoevCache(groupId: string) {
    const refreshSignal = this._getRefreshSignal(groupId);
    refreshSignal.next();
  }

  private _addRequest(uuid: string) {
    this._reqQueue$.next([...this._reqQueue$.value, uuid]);
  }

  private _removeRequest(uuid: string) {
    this._reqQueue$.next(this._reqQueue$.value.filter((i) => i !== uuid));
  }
}
