/* eslint-disable */
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {LanguageCode} from '@em/shared/util-types';
import {EnvVarKey, configurationHelper} from '@em/shared/util-configuration';
import {Observable} from 'rxjs';

type SignupPartners = 'emarketing' | 'webstollen';
const PARTNER_GATEWAY_ENV_MAPPER: {[k in SignupPartners]: EnvVarKey} = {
  emarketing: 'SIGNUP_GATEWAY_URL',
  webstollen: 'WEBSTOLLEN_SIGNUP_GATEWAY_URL',
};

export interface IPostRegistrationReq {
  email: string;
  language: LanguageCode;
  name?: string;
  recaptcha_response: string;
}

export interface IPostRegistrationResp {
  jwt: string;
}

@Injectable({
  providedIn: 'root',
})
export class RegistrationGateway {
  constructor(private _http: HttpClient) {}

  register(
    params: IPostRegistrationReq,
    partnerName = 'emarketing',
  ): Observable<IPostRegistrationResp> {
    const partner = partnerName as SignupPartners;
    const partnerEnv =
      PARTNER_GATEWAY_ENV_MAPPER[partner] ||
      PARTNER_GATEWAY_ENV_MAPPER['emarketing'];

    const registrationUrl = configurationHelper.needConfig(partnerEnv);
    return this._http.post<IPostRegistrationResp>(registrationUrl, params);
  }
}
