import {ValidatorFn} from '@angular/forms';

export const validateEmail: ValidatorFn = (control) => {
  if (!control.value) return null;

  const EMAIL_REGEXP = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i;

  return EMAIL_REGEXP.test(control.value)
    ? null
    : {
        validateEmail: {
          valid: false,
        },
      };
};
