import {Injectable, inject} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {
  ActiveSubscription,
  Contract,
  OneTimeFetchData,
  SubscriptionGateway,
} from '@em/subscription/api-interface';
import {EmUpgradePlanModalComponent} from '../../upgrade-plan-modal/upgrade-plan-modal.component';
import {ContractService} from '@em/subscription/data-access';
import {
  EMPTY,
  Observable,
  catchError,
  filter,
  map,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';
import {EmStripePaymentModalComponent} from '../../stripe/stripe-payment-modal/stripe-payment-modal.component';
import {EmUpdateSubscriptionModalComponent} from '../../update-subscription-modal/update-subscription-modal.component';
import {DialogService} from '@em/shared/ui';
import {EmCancelSubscriptionModalComponent} from '../../cancel-subscription-modal/cancel-subscription-modal.component';
import {EmShopifyPaymentModalComponent} from '../../shopify/shopify-payment-modal/shopify-payment-modal.component';
import {DatafeedStore} from '@em/data-feed/data-access-setup';
import {LocalUserDataService} from '@em/auth/data-access';

export type PaymentStatus = 'cancel' | 'in-progress' | 'success';
@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  private readonly _matDialog = inject(MatDialog);
  private readonly _contractService = inject(ContractService);
  private readonly _dialogService = inject(DialogService);
  private readonly _subscriptionGateway = inject(SubscriptionGateway);
  private readonly _datafeedStore = inject(DatafeedStore);
  private readonly _localStorage = inject(LocalUserDataService);

  upgradePlan(contract: Contract) {
    this._matDialog.open(EmUpgradePlanModalComponent, {
      data: {contract},
      maxHeight: '95vh',
    });
  }

  includeAndUpgradePlan(
    contract: Partial<Contract>,
  ): Observable<PaymentStatus> {
    return this._contractService.combineWithExisting(contract).pipe(
      switchMap((mergedContract) => {
        this._matDialog.open(EmUpgradePlanModalComponent, {
          data: {contract: mergedContract},
          maxHeight: '95vh',
          autoFocus: false,
        });

        const status: PaymentStatus = 'success';
        return of(status);
      }),
    );
  }

  openCancelSubscriptionModal() {
    this._matDialog.open(EmCancelSubscriptionModalComponent, {maxWidth: 500});
  }

  // emit true value if the user has valid subscription,
  // otherwize trigger the payment and emit true if user finish the payment
  // will Complete without emitting if user didn't finish the payment
  mustHavePlan(): Observable<boolean> {
    return this._contractService.hasPlan$.pipe(
      take(1),
      switchMap((hasPlan) => {
        if (hasPlan) {
          return of(true);
        } else {
          return this.includeAndUpgradePlan({}).pipe(
            switchMap(() => of(false)),
          );
        }
      }),
    );
  }

  startPayment(contract: Contract, redirectUrl?: string) {
    this._contractService.activeSubscription$
      .pipe(
        switchMap((activePlan) => {
          // Save redirect url till after payment
          this._localStorage.set('payment_redirect_url', redirectUrl);
          this._localStorage.set('payment_is_contract', 'true');

          if (activePlan?.processor === 'stripe') {
            this._payWithStripe(contract);
            return of(true);
          } else {
            this._datafeedStore.loadPlugin(true);
            return this._datafeedStore.isLoading$.pipe(
              filter((isLoading) => !isLoading),
              switchMap(() => this._datafeedStore.pluginName$),
              tap((pluginName) => {
                if (pluginName === 'shopify') {
                  this._payWithShopify(contract);
                } else {
                  this._payWithStripe(contract);
                }
              }),
            );
          }
        }),
        take(1),
      )
      .subscribe();
  }

  startOneTimePayment(oneTimeFetch: OneTimeFetchData, redirectUrl?: string) {
    // Save redirect url till after payment
    this._localStorage.set('payment_redirect_url', redirectUrl);
    this._localStorage.set('payment_is_contract', undefined);
    this._payOneTimeWithStripe(oneTimeFetch);
  }

  private _payWithStripe(contract: Contract) {
    this._contractService.activeSubscription$
      .pipe(
        switchMap((activatedSubscription) => {
          // Cancel Paypal Subscription First
          if (activatedSubscription?.processor === 'paypal') {
            return this._cancelPaypal(activatedSubscription);
          }
          return of(activatedSubscription);
        }),
        tap((activeSubscription) => {
          // Open Either Payment Method Selection or Upgrade subscription
          if (!activeSubscription) {
            this._matDialog.open(EmStripePaymentModalComponent, {
              data: {
                contract,
              },
              maxHeight: '95vh',
              disableClose: true,
              autoFocus: false,
            });
          } else {
            this._dialogService
              .confirm({
                description: 'UPDATE_SUBSCRIPTION_DESC',
                title: 'UPDATE_SUBSCRIPTION_TITLE',
              })
              .pipe(
                take(1),
                tap((response) => {
                  if (response) {
                    this._matDialog.open(EmUpdateSubscriptionModalComponent, {
                      data: {
                        contract,
                      },
                      maxHeight: '95vh',
                      disableClose: true,
                      autoFocus: false,
                    });
                  }
                }),
              )
              .subscribe();
          }
        }),
        take(1),
      )
      .subscribe();
  }

  private _payOneTimeWithStripe(oneTimeFetch: OneTimeFetchData) {
    this._matDialog.open(EmStripePaymentModalComponent, {
      data: {
        oneTimeFetch,
      },
      maxHeight: '95vh',
      disableClose: true,
      autoFocus: false,
    });
  }

  private _payWithShopify(contract: Contract) {
    this._contractService.activeSubscription$
      .pipe(
        switchMap((activatedSubscription) => {
          // Cancel Paypal Subscription First
          if (activatedSubscription?.processor === 'paypal') {
            return this._cancelPaypal(activatedSubscription);
          }
          return of(activatedSubscription);
        }),
        tap((activatedSubscription) => {
          let obs$: Observable<boolean | undefined>;
          if (activatedSubscription) {
            obs$ = this._dialogService
              .confirm({
                description: 'UPDATE_SUBSCRIPTION_DESC',
                title: 'UPDATE_SUBSCRIPTION_TITLE',
              })
              .pipe(take(1));
          } else {
            obs$ = of(true);
          }

          obs$
            .pipe(
              tap((response) => {
                if (response) {
                  this._matDialog.open(EmShopifyPaymentModalComponent, {
                    data: {
                      contract,
                    },
                    maxHeight: '95vh',
                    disableClose: true,
                    autoFocus: false,
                  });
                }
              }),
            )
            .subscribe();
        }),
        take(1),
      )
      .subscribe();
  }

  cancelSubscription(reason: string | null): Observable<boolean> {
    return this._subscriptionGateway.postCancel({reason}).pipe(
      map(() => true),
      catchError(() => of(false)),
    );
  }

  private _cancelPaypal(activeSubscription: ActiveSubscription) {
    return this._dialogService
      .confirm({
        description: 'PAPYAL_USER_SUB_CANCEL',
        confirmLabel: 'SHARED_LABEL_CONTINUE',
      })
      .pipe(
        switchMap((response) =>
          response
            ? this.cancelSubscription('Cancel Paypal Before Using Stripe').pipe(
                switchMap((resp) => (resp ? of(activeSubscription) : EMPTY)),
              )
            : EMPTY,
        ),
      );
  }
}
