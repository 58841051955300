import {INumberFilterParams} from 'ag-grid-community';
import {getBasicFilterParams} from '../../../get-basic-filter-params/get-basic-filter-params';
import {BACKEND_TO_GRID_DATE_OPERATOR} from '../../../consts/operator-maps';

// Filter types

export function getDateFilterParams(): INumberFilterParams {
  return {
    ...getBasicFilterParams(),
    filterOptions: Object.values(BACKEND_TO_GRID_DATE_OPERATOR),
  };
}
