import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {EmBoxComponent} from './box.component';

@NgModule({
  imports: [CommonModule],
  declarations: [EmBoxComponent],
  exports: [EmBoxComponent],
})
export class EmBoxModule {}
