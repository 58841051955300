import {ICollection, ICollectionObject, initialCollection} from './store-util';
import {CurrencyCode} from '@em/shared/util-types';
import {ActionTypes, EnterpriseActions} from './enterprise.actions';

export interface IAdwordsAccount {
  id: number;
  name: string | null;
  jwt: string | boolean | null;
  currencyCode: CurrencyCode;
  isManager: boolean;
  loginCustomerId: number;
  managedById?: number;
  managedByName?: string | null;
}

export interface IPerformanceData {
  costs: number | null;
  conversions: number | null;
  clicks: number | null;
  revenue: number | null;
}

export interface IPerformanceCollection {
  [k: number]: ICollectionObject<IPerformanceData> | undefined;
}

export interface EnterpriseState {
  adwordsAccounts: ICollection<IAdwordsAccount>;
  performanceData: IPerformanceCollection;
  hasOauthToken?: boolean;
  sdk: {
    ready: boolean;
    loading: boolean;
  };
  oauth: {
    state: 'unauthorized' | 'in-progress' | 'done';
    accessToken?: string;
  };
  authorization: {
    state: 'unauthorized' | 'in-progress' | 'authorized' | 'done';
    code: string | null;
  };
}

export const initialState: EnterpriseState = {
  adwordsAccounts: initialCollection<IAdwordsAccount>(),
  performanceData: {},
  hasOauthToken: undefined,
  sdk: {
    ready: false,
    loading: false,
  },
  oauth: {
    state: 'unauthorized',
    accessToken: undefined,
  },
  authorization: {
    state: 'unauthorized',
    code: null,
  },
};

export function reducer(
  state = initialState,
  action: EnterpriseActions,
): EnterpriseState {
  switch (action.type) {
    case ActionTypes.LOAD_GOOGLE_SDK:
      return {
        ...state,
        sdk: {
          ...state.sdk,
          loading: true,
        },
      };
    case ActionTypes.SDK_READY: {
      return {
        ...state,
        sdk: {
          ...state.sdk,
          ready: true,
          loading: false,
        },
      };
    }
    case ActionTypes.OAUTH_SIGN_IN: {
      return {
        ...state,
        oauth: {
          ...state.oauth,
          state: 'in-progress',
        },
      };
    }
    case ActionTypes.OAUTH_SIGN_IN_SUCCESS: {
      return {
        ...state,
        oauth: {
          ...state.oauth,
          state: 'done',
          accessToken: action.payload.accessToken,
        },
      };
    }
    case ActionTypes.OAUTH_SIGN_IN_FAILED: {
      return {
        ...state,
        oauth: {
          ...state.oauth,
          state: 'unauthorized',
        },
      };
    }
    case ActionTypes.LOAD_ADWORDS_ACCOUNTS:
      return {
        ...state,
        adwordsAccounts: {
          ...state.adwordsAccounts,
          loading: true,
        },
      };
    case ActionTypes.LOAD_ADWORDS_ACCOUNTS_SUCCESS:
      return {
        ...state,
        hasOauthToken: action.payload.hasOauthToken,
        adwordsAccounts: {
          ...state.adwordsAccounts,
          loading: false,
          loaded: true,
          list: action.payload.adwordsAccounts,
        },
      };
    case ActionTypes.AUTH_STARTED: {
      return {
        ...state,
        authorization: {
          ...state.authorization,
          state: 'in-progress',
        },
      };
    }
    case ActionTypes.AUTH_COMPLETED: {
      return {
        ...state,
        authorization: {
          ...state.authorization,
          state: 'authorized',
          code: action.payload.code,
        },
      };
    }
    case ActionTypes.AUTH_FAILED: {
      return {
        ...state,
        authorization: {
          ...state.authorization,
          state: 'unauthorized',
          code: null,
        },
      };
    }
    case ActionTypes.ACCOUNT_CREATION_SUCCESS: {
      const {adwordsId, jwt} = action.payload;
      const updatedList = state.adwordsAccounts.list.map((x) => {
        if (x.id === adwordsId) {
          return {
            ...x,
            jwt,
          };
        }

        return x;
      });

      return {
        ...state,
        hasOauthToken: true,
        adwordsAccounts: {
          ...state.adwordsAccounts,
          list: updatedList,
        },
        authorization: {
          ...state.authorization,
          state: 'unauthorized',
        },
      };
    }
    case ActionTypes.ACCOUNT_CREATION_FAILED: {
      return {
        ...state,
        authorization: {
          ...state.authorization,
          state: 'unauthorized',
        },
      };
    }
    case ActionTypes.LOAD_PERFORMANCE_DATA: {
      const performanceData = {...state.performanceData};
      performanceData[action.payload.adwordsId] = {
        loading: true,
        loaded: false,
        data: undefined,
      };

      return {
        ...state,
        performanceData,
      };
    }
    case ActionTypes.LOAD_PERFORMANCE_DATA_SUCCESS: {
      const performanceData = {...state.performanceData};
      performanceData[action.payload.adwordsId] = {
        loading: false,
        loaded: true,
        data: {...action.payload.performanceData},
      };

      return {
        ...state,
        performanceData,
      };
    }
    default:
      return state;
  }
}
