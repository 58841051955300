import {CommonModule} from '@angular/common';
import {Component, Inject, inject} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
} from '@angular/material/dialog';
import {EmIconButtonComponent} from '@em/shared/ui';
import {Contract, OneTimeFetchData} from '@em/subscription/api-interface';
import {EmStripePaymentComponent} from '../stripe-payment/stripe-payment.component';
import {EmStripeOneTimvePaymentComponent} from '../stripe-one-time-payment/stripe-one-time-payment.component';

@Component({
  selector: 'em-stripe-payment-modal',
  templateUrl: './stripe-payment-modal.component.html',
  styleUrls: ['./stripe-payment-modal.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    EmStripePaymentComponent,
    EmStripeOneTimvePaymentComponent,
    MatDialogModule,
    EmIconButtonComponent,
  ],
})
export class EmStripePaymentModalComponent {
  private readonly _matDialog = inject(MatDialog);

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      contract?: Contract;
      oneTimeFetch?: OneTimeFetchData;
    },
  ) {}

  close() {
    this._matDialog.closeAll();
  }
}
