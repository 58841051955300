import {Inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {exhaustMap, map, mapTo, switchMap, tap} from 'rxjs/operators';

import {
  hideAppBlocker,
  initAngularRouting,
  jwtInvalid,
  jwtValid,
  redirectToLogin,
  startAngularRouting,
  validateJwt,
} from './actions';
import {WINDOW} from '@em/shared/util-web';
import {isIndependentRoute} from '@em/shared/util-configuration';
import {SessionService} from '../services/session/session.service';

@Injectable({
  providedIn: 'root',
})
export class AppEffects {
  validateJwt$ = createEffect(() =>
    this._actions$.pipe(
      ofType(validateJwt),
      map(() => {
        if (!this._session.isLoggedIn()) return redirectToLogin();

        return this._session.isJwtValid() ? jwtValid() : jwtInvalid();
      }),
    ),
  );

  logout$ = createEffect(() =>
    this._actions$.pipe(
      ofType(jwtInvalid),
      switchMap(() => this._session.logout()),
      map(() => redirectToLogin()),
    ),
  );

  redirectToLogin$ = createEffect(() =>
    this._actions$.pipe(
      ofType(redirectToLogin),
      switchMap(() => {
        if (this._isRouteHandledSeparately() || this._isJwtExchangeInUrl()) {
          return of(true);
        } else {
          this._router.initialNavigation();
          return of(false);
        }
      }),
      exhaustMap((dispatchActions) =>
        dispatchActions
          ? of(initAngularRouting(), hideAppBlocker())
          : of(hideAppBlocker()),
      ),
    ),
  );

  startAngularRouting$ = createEffect(() =>
    this._actions$.pipe(
      ofType(jwtValid, initAngularRouting),
      tap(() => this._router.initialNavigation()),
      mapTo(startAngularRouting()),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _session: SessionService,
    private readonly _router: Router,
    @Inject(WINDOW) private readonly _window: Window,
  ) {}

  private _isRouteHandledSeparately(): boolean {
    return isIndependentRoute(this._window.location.pathname);
  }

  private _isJwtExchangeInUrl(): boolean {
    return this._window.location.search.includes('?jwt=');
  }
}
