import {CommonModule} from '@angular/common';
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {TranslateModule} from '@ngx-translate/core';
import {IOptionViewModel} from '../filter-item-options/filter-item-options.component';

@Component({
  selector: 'em-filter-item-option-input',
  templateUrl: './filter-item-option-input.component.html',
  styleUrls: ['./filter-item-option-input.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
  ],
})
export class EmFilterItemOptionInputComponent<T> {
  @Input({required: true}) filterOption?: IOptionViewModel;
  @Input({required: true}) value?: T;
  @Input({required: true}) parentFilterKey?: string;
  @Input() showPlaceholder = true;
  @Output() valueChanged = new EventEmitter<T>();
  @Output() touched = new EventEmitter<boolean>();

  get showDropDown() {
    return (
      !!this.filterOption?.definition?.selectableValues?.length ||
      this.filterOption?.definition.type === 'array'
    );
  }

  get showToggle() {
    return this.filterOption?.definition.type === 'boolean';
  }

  get showTextInput() {
    return !this.showDropDown && !this.showToggle;
  }

  get multipleValues() {
    return this.filterOption?.definition.type === 'array';
  }

  get placeholder() {
    return `G_FILTER_${this.parentFilterKey}_${this.filterOption?.key}_INPUT_PLACEHOLDER`.toUpperCase();
  }

  getValueLabel(valeu: string) {
    return `G_FILTER_${valeu}_VALUE`.toUpperCase();
  }
}
