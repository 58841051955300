import {Component} from '@angular/core';
import {Store} from '@ngrx/store';
import {selectIsLoading} from '@em/onboarding/data-access';

@Component({
  selector: 'em-onboarding-page',
  templateUrl: './onboarding-page.component.html',
  styleUrls: ['./onboarding-page.component.scss'],
})
export class OnboardingPageComponent {
  readonly isLoading = this._store.select(selectIsLoading);

  constructor(private readonly _store: Store) {}
}
