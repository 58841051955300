import {Duration, formatAsDayMonth, sub} from '@em/shared/util-date-time';
import zipWith from 'lodash-es/zipWith';

export interface ITimeDatapoint {
  readonly hasValue?: boolean;
  readonly x: string;
  readonly y: number;
}

export class TimeSeries {
  constructor(readonly data: ITimeDatapoint[], readonly name?: string) {}

  static aggregateTo(
    values: Array<number | null>,
    desiredValues: number,
    lastTimestamp: Date,
    interval: Duration,
    sumBatches = true,
    seriesName?: string,
  ) {
    const numericValues: number[] = values.map((val) => val || 0) as number[];
    const aggregatedValues: number[] = [];
    const valuesPerBatch = values.length / desiredValues;
    const divider = sumBatches ? 1 : valuesPerBatch;

    [...Array(desiredValues).keys()].forEach(() => {
      aggregatedValues.push(
        numericValues.splice(0, valuesPerBatch).reduce((a, b) => a + b, 0) /
          divider,
      );
    });

    return TimeSeries.create(
      aggregatedValues,
      lastTimestamp,
      interval,
      seriesName,
    );
  }

  static aggregateByWeek(
    values: Array<number | null>,
    lastTimestamp: Date,
    divider = 1,
  ): TimeSeries {
    const aggregatedValues: number[] = [];
    const aggregatedDuration: Duration = {weeks: 1};
    const expectedValueCount = 4;

    // Remove first N values that do not fit into "7 values per week"
    values.splice(0, values.length % expectedValueCount);

    [...Array(expectedValueCount).keys()].forEach(() => {
      aggregatedValues.push(
        ((values.splice(0, 7).reduce((a, b) => (a || 0) + (b || 0), 0) || 0) /
          divider) as number,
      );
    });

    return TimeSeries.create(
      aggregatedValues,
      lastTimestamp,
      aggregatedDuration,
    );
  }

  static create(
    values: number[],
    lastTimestamp: Date,
    interval: Duration,
    seriesName?: string,
  ): TimeSeries {
    // If `hasValue`-calculation turns out to be error-prone, we need to switch from `number[]`
    // to e.g. `{initiallyNull: boolean, value: number}[]` to mark the condition as `hasValue: !item.initiallyNull`
    return new TimeSeries(
      zipWith(
        values,
        TimeSeries.createTimestamps(values.length, lastTimestamp, interval),
        (value, date) => ({
          y: value,
          x: formatAsDayMonth(date),
          hasValue: value !== 0,
        }),
      ),
      seriesName,
    );
  }

  static createTimestamps(
    n: number,
    lastTimestamp: Date,
    interval: Duration,
  ): Date[] {
    const result: Date[] = [];

    while (n-- > 0) {
      result.push(lastTimestamp);

      lastTimestamp = sub(lastTimestamp, interval);
    }

    return result.reverse();
  }

  isEmpty() {
    return !this.data.filter((x) => x.hasValue).length;
  }
}
