import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MATERIAL_SANITY_CHECKS} from '@angular/material/core';
import {EmErrorDirective} from './error.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [EmErrorDirective],
  exports: [EmErrorDirective],
  providers: [{provide: MATERIAL_SANITY_CHECKS, useValue: false}],
})
export class EmErrorModule {}
