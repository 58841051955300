import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  NgModule,
} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'em-onboarding-status-done',
  templateUrl: './onboarding-status-done.component.html',
  styleUrls: ['./onboarding-status-done.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingStatusDoneComponent {
  @Input() content?: string;

  constructor() {}
}

@NgModule({
  declarations: [OnboardingStatusDoneComponent],
  exports: [OnboardingStatusDoneComponent],
  imports: [CommonModule, MatIconModule, TranslateModule],
})
export class EmOnboardingStatusDoneModule {}
