import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {IMoney} from '@em/shared/util-types';
import {TranslateModule} from '@ngx-translate/core';
import {EmWithCurrencyPipe} from '../../../pipes/with-currency/with-currency.pipe';

@Component({
  selector: 'em-money-kpi',
  standalone: true,
  imports: [CommonModule, EmWithCurrencyPipe, TranslateModule],
  templateUrl: './money-kpi.component.html',
  styleUrls: ['./money-kpi.component.scss', '../kpi.shared.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmMoneyKpiComponent {
  @Input() performanceLabel?: string;
  @Input() performanceValue?: IMoney | null;

  constructor() {}
}
