import {ITextFilterParams} from 'ag-grid-community';
import {getBasicFilterParams} from '../../../get-basic-filter-params/get-basic-filter-params';
import {BACKEND_TO_GRID_TEXT_OPERATOR} from '../../../consts/operator-maps';

export function getStringFilterParams(): ITextFilterParams {
  return {
    ...getBasicFilterParams(),
    filterOptions: Object.values(BACKEND_TO_GRID_TEXT_OPERATOR),
  };
}
