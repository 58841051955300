import {
  Component,
  EventEmitter,
  Input,
  Optional,
  Output,
  Self,
} from '@angular/core';
import {NgControl} from '@angular/forms';
import {EmMaterialWrapperComponent} from '../material-wrapper/material-wrapper.component';

type CheckboxColor = 'primary' | 'warn';

@Component({
  selector: 'em-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class EmCheckboxComponent extends EmMaterialWrapperComponent<boolean> {
  @Input()
  get checked(): boolean {
    return !!this.controlValue;
  }
  set checked(value: boolean | string | undefined | null) {
    this.controlValue = !!value;
  }
  @Input() color: CheckboxColor = 'primary';
  @Input() indeterminate: boolean | undefined = false;
  @Output() valueChange: EventEmitter<boolean> = new EventEmitter();

  protected isChecked = false;
  constructor(@Optional() @Self() public override ngControl: NgControl) {
    super(ngControl);
  }
}
