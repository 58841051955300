/* tslint:disable */
/**
* This file was automatically generated.
* DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
* and run the generate-code script again.
*/
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DeleteReq as DeleteAdwordsReq} from '../types/request-objects/adwords/delete';
import {DeleteResp as DeleteAdwordsResp} from '../types/view-models/adwords/delete';
import {GetAccountsResp} from '../types/view-models/adwords/get-accounts';
import {GetSettingsResp} from '../types/view-models/adwords/get-settings';
import {PostSettingsReq} from '../types/request-objects/adwords/post-settings';
import {PutSettingsReq} from '../types/request-objects/adwords/put-settings';

@Injectable({
  providedIn: 'root',
})
export class AdwordsGateway {
  constructor(private _http: HttpClient) {}

  deleteAdwords(params: DeleteAdwordsReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<DeleteAdwordsResp> {
    const request = params as any as { [param: string]: string | string[]; };
    return this._http.delete<DeleteAdwordsResp>('/emarketing_api/adwords/', {...options, params: request});
  }

  getAccounts(options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<GetAccountsResp> {
    return this._http.get<GetAccountsResp>('/emarketing_api/adwords/accounts', options);
  }

  getSettings(options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<GetSettingsResp> {
    return this._http.get<GetSettingsResp>('/emarketing_api/adwords/settings', options);
  }

  postSettings(params: PostSettingsReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<undefined> {
    return this._http.post<undefined>('/emarketing_api/adwords/settings', params, options);
  }

  putSettings(params: PutSettingsReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<undefined> {
    return this._http.put<undefined>('/emarketing_api/adwords/settings', params, options);
  }
}
