import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MATERIAL_SANITY_CHECKS} from '@angular/material/core';
import {EmOptionModule} from '../option/option.module';
import {EmOptionGroupComponent} from './option-group.component';

@NgModule({
  imports: [CommonModule, EmOptionModule],
  declarations: [EmOptionGroupComponent],
  exports: [EmOptionGroupComponent],
  providers: [{provide: MATERIAL_SANITY_CHECKS, useValue: false}],
})
export class EmOptionGroupModule {}
