import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EmButtonBaseComponent} from '../base-button/button-base.component';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';

@Component({
  selector: 'em-icon-button',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule],
  templateUrl: './icon-button.component.html',
  styleUrls: [
    './icon-button.component.scss',
    '../base-button/button-base.component.scss',
  ],
})
export class EmIconButtonComponent extends EmButtonBaseComponent {}
