import {Component} from '@angular/core';

@Component({
  selector: 'em-info-box',
  templateUrl: './info-box.component.html',
  styleUrls: ['./info-box.component.scss'],
})
export class EmInfoBoxComponent {
  constructor() {}
}
