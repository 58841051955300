<span *ngIf="filter">
  <span *ngIf="hasOptions()"
        [ngSwitch]="filter.key">
    <span *ngSwitchCase="'category'">
      {{ getI18nKey(filter.key) | translate }}:
      <span class="filter-option-value">
        {{ getCategoriesLength() }}
      </span>
    </span>
    <span *ngSwitchCase="'best_price'">
      {{ getI18nKey(filter.key) | translate }}
    </span>
    <span *ngSwitchCase="'offer_ids'">
      {{ getI18nKey(filter.key) | translate }}
      <span class="offer-ids-list">{{ getOfferIdsList() | translate }}</span>
    </span>
    <span *ngSwitchDefault>
      {{ getI18nKey(filter.key, '1') | translate }}
      <span class="filter-option-value">
        {{ getFirstOptionValue() }}
      </span>
      {{ getI18nKey(filter.key, '2') | translate }}
    </span>
  </span>
  <span *ngIf="!hasOptions()">
    {{ getI18nKey(filter.key) | translate }}
  </span>
</span>
