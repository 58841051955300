import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {
  EmCountryFlagModule,
  EmErrorBoxModule,
  EmInputModule,
  EmPrimaryButtonComponent,
  EmSelectModule,
} from '@em/shared/ui';
import {TranslateModule} from '@ngx-translate/core';
import {ChangeCountryComponent} from './change-country/change-country.component';
import {ChangeEmailComponent} from './change-email/change-email.component';
import {ChangeLanguageComponent} from './change-language/change-language.component';
import {CommonWizardComponent} from './common-wizard.component';

@NgModule({
  declarations: [
    CommonWizardComponent,
    ChangeEmailComponent,
    ChangeCountryComponent,
    ChangeLanguageComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    EmCountryFlagModule,
    EmErrorBoxModule,
    EmInputModule,
    EmSelectModule,
    EmPrimaryButtonComponent,
  ],
})
export class CommonWizardModule {}
