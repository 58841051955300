import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';
import {ComponentBase} from '../component-base/component-base';

@Component({
  selector: 'em-search-input',
  standalone: true,
  imports: [CommonModule, MatIconModule, TranslateModule, FormsModule],
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
})
export class EmSearchInputComponent extends ComponentBase implements OnInit {
  searchText?: string;
  @Input() debounceTime?: number;
  @Input() placeholderKey = '';
  @Output() inputChange = new EventEmitter<string | undefined>();
  private readonly _searchTextChange = new Subject<string | undefined>();

  constructor() {
    super();
  }

  ngOnInit() {
    this._subscribe(
      this._searchTextChange.pipe(debounceTime(this.debounceTime || 300)),
      (val) => this.inputChange.emit(val),
    );
  }

  searchTextChanged() {
    this._searchTextChange.next(this._normalizeValue(this.searchText));
  }

  private _normalizeValue(val: string | undefined): string | undefined {
    if (val !== undefined && val.trim() === '') val = undefined;
    return val;
  }
}
