import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ValidSessionGuard} from '@em/auth/data-access';
import {commonRoutes} from './common-wizard/routes';
import {OnboardingPageComponent} from './onboarding-page/onboarding-page.component';

export const routes: Routes = [
  {
    path: '',
    canActivate: [ValidSessionGuard],
    component: OnboardingPageComponent,
    children: [
      ...commonRoutes,
      {
        path: '',
        redirectTo: 'common',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OnboardingRoutingModule {}
