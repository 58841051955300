import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class QueryParamsService {

  constructor(private readonly _router: Router) {
  }

  getValue(url: string, key: string): string | undefined {
    const urlTree = this._router.parseUrl(url);

    return urlTree.queryParams[key];
  }

  hasParam(url: string, key: string): boolean {
    const urlTree = this._router.parseUrl(url);

    return key in urlTree.queryParams;
  }
}
