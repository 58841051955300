import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {HttpStatusCode} from '@em/shared/util-types';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {PaymentService} from '../services/payment-service/payment.service';

@Injectable()
export class PaymentInterceptor implements HttpInterceptor {
  constructor(private readonly _paymentService: PaymentService) {}

  /* eslint-disable @typescript-eslint/no-explicit-any */
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(
        () => {},
        (error: unknown) => {
          if (
            (error as HttpErrorResponse).status ===
            HttpStatusCode.PaymentRequired
          ) {
            this._paymentService.includeAndUpgradePlan({}).subscribe(() => {});
          }
        },
      ),
    );
  }
}
