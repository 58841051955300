import {fromJS, Map} from 'immutable';
import {IGenericFilterSetting} from './filtering-types';

export class FilterGroupItem {
  data: Map<string, string | undefined>;

  constructor(data: IGenericFilterSetting) {
    this.data = fromJS(data);
  }

  get uuid(): string | undefined {
    return this.data.get('uuid');
  }

  get key(): string | undefined {
    return this.data.get('key');
  }

  get options() {
    return this.data.get('options');
  }

  get parentUuid(): string | undefined {
    return this.data.get('parent_uuid');
  }

  set parentUuid(value: string | undefined) {
    this.data = this.data.set('parent_uuid', value);
  }
}
