import {BehaviorSubject, NEVER, Observable, of, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

/**
 * Represents an executable unit of work, and determines if it can be executed
 * at the moment. Based on the standard MVVM command pattern.
 * See: https://msdn.microsoft.com/en-us/magazine/dn237302.aspx
 */
export interface ICommand<R = void> {
  canPerform(): Observable<boolean>;

  perform(): Observable<R>;
}

export class NoopCommand<R = void> implements ICommand<R> {
  canPerform(): Observable<boolean> {
    return of(false);
  }

  perform(): Observable<R> {
    return NEVER;
  }
}

export type ProgressState = 'idle' | 'inProgress' | 'failed';

export abstract class ProgressCommand<R = void> implements ICommand<R> {
  protected readonly _state = new BehaviorSubject<ProgressState>('idle');

  protected constructor(private readonly _stayInProgress = false) {}

  abstract canPerform(): Observable<boolean>;

  isIdle(): Observable<boolean> {
    return this._state.pipe(map((x) => x === 'idle'));
  }

  perform(): Observable<R> {
    this._state.next('inProgress');
    return this._perform().pipe(
      tap(() => {
        if (!this._stayInProgress) this._state.next('idle');
      }),
      catchError((error: unknown) => {
        this._state.next('failed');
        return throwError(error);
      }),
    );
  }

  protected abstract _perform(): Observable<R>;
}
