import {CdkStepper, CdkStepperModule} from '@angular/cdk/stepper';
import {CommonModule, NgTemplateOutlet} from '@angular/common';
import {Component, Input} from '@angular/core';
import {EmIconModule} from '../icon/icon.component';
import {EmBoxModule} from '../box/box.module';

@Component({
  selector: 'em-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  standalone: true,
  providers: [{provide: CdkStepper, useExisting: EmStepperComponent}],
  imports: [
    CommonModule,
    NgTemplateOutlet,
    CdkStepperModule,
    EmIconModule,
    EmBoxModule,
  ],
})
export class EmStepperComponent extends CdkStepper {
  @Input() showIcons = true;

  selectStepByIndex(index: number): void {
    this.selectedIndex = index;
  }
}
