import {inject} from '@angular/core';
import {ProductsListItem} from '@em/shared/api-interface';
import {TranslateService} from '@ngx-translate/core';
import {ColDef, ColGroupDef} from 'ag-grid-community';
import {CustomHeaderGroupComponent} from '../custom-header-group/custom-header-group.component';
import {isFilterableColumn} from '../filter/is-filterable-column/is-filterable-column';
import {COLUMNS_GROUPS, Column, ColumnGroup} from './columns-groups';
import {ColumnTypeName, createColumnsTypes} from './columns-types';
import {getBasicFilterParams} from '../filter/get-basic-filter-params/get-basic-filter-params';
import {isSortableColumn} from '../sorting/is-sortable-column/is-sortable-column';

export function createProductsListColumnsDefAndTypes(): {
  columnsDef: Array<ColDef<ProductsListItem> | ColGroupDef>;
  columnsTypes: {[key in ColumnTypeName]?: ColDef};
} {
  const columnsTypes = createColumnsTypes();

  const columnsDef = createProductsListColumnsDef();

  return {
    columnsDef,
    columnsTypes,
  };
}

function createProductsListColumnsDef(): Array<
  ColDef<ProductsListItem> | ColGroupDef
> {
  const translateService = inject(TranslateService);

  return COLUMNS_GROUPS.map((group) => {
    const groupDef: ColGroupDef = {
      groupId: group.groupId,
      headerName: translateService.instant(
        'PRODUCTS_LIST_GROUP_' + group.groupId.toUpperCase(),
      ),
      headerClass: group.groupId + '-group-header',

      children: group.children.map((column) => buildColumnDef(column, group)),
    };

    // add logo/icon to the header
    if (group.logo || group.icon) {
      groupDef.headerGroupComponent = CustomHeaderGroupComponent;
      groupDef.headerGroupComponentParams = {
        logo: group.logo,
        icon: group.icon,
      };
    }

    return groupDef;
  });
}

function buildColumnDef(
  column: Column,
  group: ColumnGroup,
): ColDef<ProductsListItem> {
  const colDef: ColDef = {
    ...getBasicColumn(column),
    type: [column.type || 'textColumn'],
    valueGetter: column.valueGetter || undefined,
    cellRenderer: column.cellRenderer || undefined,
  };

  colDef.cellClass = group.groupId + '-cell';
  colDef.headerClass = group.groupId + '-cell-header';

  return colDef;
}

function getBasicColumn(column: Column): ColDef {
  return {
    field: column.field,
    colId: column.field,
    headerName: getHeaderName(column),
    sortable: isSortableColumn(column.field),
    filterParams: getBasicFilterParams(),
    menuTabs: isFilterableColumn(column.field) ? ['filterMenuTab'] : [],
    hide: !column.showByDefault,
    pinned: column.isPinned,
    width: column.width,
    wrapHeaderText: true,
    autoHeaderHeight: true,
  };
}

function getHeaderName(column: Column) {
  const translateService = inject(TranslateService);
  return translateService.instant(
    'COLUMN_' + (column.headerTitle || column.field).toUpperCase(),
  );
}
