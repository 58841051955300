import {CommonModule} from '@angular/common';
import {Component, Input} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {EmNumberWithUnitPipe} from '../../../legacy/number-with-unit/number-with-unit.pipe';

@Component({
  selector: 'em-multiplier-kpi',
  standalone: true,
  imports: [CommonModule, EmNumberWithUnitPipe, TranslateModule],
  templateUrl: './multiplier-kpi.component.html',
  styleUrls: ['./multiplier-kpi.component.scss', '../kpi.shared.scss'],
})
export class EmMultiplierKpiComponent {
  @Input() performanceLabel?: string;
  @Input() performanceValue?: number | null;

  constructor() {}
}
