<div class="options">
  <div class="option">
    <mat-slide-toggle (change)="setOptionValue('tolerance_enabled', $event.checked, 'boolean')"
                      [checked]="getOptionValue('tolerance_enabled')"
                      #toggler color="primary">
      <span translate>CAMPAIGN_PERFORMANCE_FILTER_BEST_PRICE_TOLERANCE_1</span>
    </mat-slide-toggle>
    <textarea matInput hidden></textarea>
  </div>
  <div class="description">{{i18nToleranceDescription() | translate}}</div>
  <div class="option" *ngIf="getOptionValue('tolerance_enabled')">
    <mat-form-field appearance="fill">
      <input matInput
             [disabled]="!getOptionValue('tolerance_enabled')"
             [ngModel]="getOptionValue('tolerance_percent')"
             (ngModelChange)="setOptionValue('tolerance_percent', $event, 'number')"
             [min]="0"
             [max]="100"
             [step]="1"
             [required]="getOptionValue('tolerance_enabled')"
             (blur)="onTouched()"
             type="number">
    </mat-form-field>
  </div>
  <div class="option">
    <mat-slide-toggle
      (change)="setOptionValue('shipping_costs_excluded', $event.checked, 'boolean')"
      [checked]="getOptionValue('shipping_costs_excluded')"
      #toggler color="primary">
      <span translate>CAMPAIGN_PERFORMANCE_FILTER_BEST_PRICE_EXCLUDE_SHIPPING</span>
    </mat-slide-toggle>
    <textarea matInput hidden></textarea>
  </div>

  <div class="option">
    <mat-slide-toggle (change)="setOptionValue('exclude_products', $event.checked, 'boolean')"
                      [checked]="getOptionValue('exclude_products')"
                      color="primary">
      <span>{{ 'CAMPAIGN_PERFORMANCE_FILTER_EXCLUDE_PRODUCTS_LABEL' | translate }}</span>
    </mat-slide-toggle>
  </div>

  <textarea hidden matInput></textarea>
</div>
