import {Component, HostBinding, Input} from '@angular/core';

@Component({
  selector: 'em-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class EmSpinnerComponent {
  @Input() label: string | undefined;
  @HostBinding('class.center-container')
  @Input()
  centerContainer: boolean | null | undefined;

  @HostBinding('class.over-content')
  @Input()
  overContent: boolean | null | undefined;
  constructor() {}
}
