import {ActionReducerMap} from '@ngrx/store';
import {appStateReducers, initialState, IState} from '@em/auth/data-access';
import {
  EnterpriseState,
  initialState as enterpriseInitialState,
} from '@em/enterprise';

export interface IApplicationState {
  appState: IState;
  enterprise: EnterpriseState;
}

export function defaultReducer<T>(state: T) {
  return state;
}

export const initialReducerMap = {
  appState: appStateReducers,
  enterprise: defaultReducer,
} as ActionReducerMap<IApplicationState>;

export function getInitialState() {
  return {
    appState: initialState,
    enterprise: enterpriseInitialState,
  } as IApplicationState;
}
