import {configurationHelper} from '@em/shared/util-configuration';
import * as Sentry from '@sentry/angular-ivy';

export const sentryConfig = (
  release?: string | null,
): Sentry.BrowserOptions => {
  const env = configurationHelper.isDevelopment()
    ? 'development'
    : configurationHelper.isSandbox()
    ? 'staging'
    : 'production';
  release = release ? release.substring(0, 8) : 'development';

  const ignoredStagingUrls = configurationHelper.isSandbox()
    ? [/intercom\.emarketing\.com/i, /app\.intercom\.com/i]
    : [];

  // eslint-disable-next-line no-restricted-syntax, no-console
  console.debug(`Identifying to Sentry as ${env}@${release}`);

  return {
    release,
    environment: env,
    ignoreErrors: [
      // Our very own error when using a deleted merchant instance
      'Error: Invalid merchant (409)',
      'Error: Unauthorized merchant (401)',
      // Lazy-Load errors when browsing with obsolete, cached sources
      /Loading chunk \d+ failed/,
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error. html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
      // Firefox specifics
      'NS_ERROR_FILE_NO_DEVICE_SPACE',
      'NS_ERROR_NOT_INITIALIZED',
      'NS_ERROR_FAILURE',
    ],
    denyUrls: [
      ...ignoredStagingUrls,
      // Intercom internals
      /js\.intercomcdn\.com/i,
      // Facebook flakiness
      /graph\.facebook\.com/i,
      /connect\.facebook\.net/i,
      // Paypal
      /paypal\.com\/sdk\/js/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
      // Firefox extensions
      /^resource:\/\//i,
      // Ignore Google flakiness
      /\/(gtm|ga|analytics)\.js/i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
  };
};
