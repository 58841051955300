import {Contract} from '@em/subscription/api-interface';

export function isMetaCampaignsEnabled(contract: Contract): boolean {
  return !!contract?.advertising?.meta_campaigns;
}

export function isMetaCampaignsDisabled(contract: Contract): boolean {
  return !contract?.advertising?.meta_campaigns;
}

export function isMetaUploadEnabled(contract: Contract): boolean {
  return !!contract?.datafeed?.meta_upload;
}

export function isMetaUploadDisabled(contract: Contract): boolean {
  return !contract?.datafeed?.meta_upload;
}
