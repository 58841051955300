import {Locale} from 'date-fns';

let registeredLocals: {[key: string]: Locale} = {};

export let SELECTED_LOCALE: Locale | undefined;

export function registerLocales(locales: {[key: string]: Locale}) {
  registeredLocals = locales;
}

export function setLocaleByName(localeName: string) {
  SELECTED_LOCALE = registeredLocals[localeName];
}

export function setLocale(local: Locale) {
  SELECTED_LOCALE = local;
}

export function getSelectedLocale() {
  return SELECTED_LOCALE;
}
