import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoadingBarRouterModule} from '@ngx-loading-bar/router';
import {AppErrorComponent} from './app-error.component';
import {authAreaPluggable} from '@em/auth/feature';
import {ExchangeJwtGuard} from '@em/auth/data-access';
import {ValidSessionGuard} from '@em/auth/data-access';
import {OnboardingCompletedGuard} from '@em/onboarding/feature';
import {DebugToolsGuard} from './guards/debug-tools.guard';

const appRoutes: Routes = [
  {
    path: 'onboarding',
    loadChildren: () =>
      import('@em/onboarding/feature').then((m) => m.OnboardingFeatureModule),
  },
  {
    path: '',
    canActivate: [DebugToolsGuard, ExchangeJwtGuard],
    children: [
      {
        path: 'setup',
        loadChildren: () =>
          import('@em/onboarding/feature').then((m) => m.OnboardingModule),
      },
      {
        path: 'account',
        loadChildren: () =>
          import('@em/user-account/feature').then((m) => m.AccountAreaModule),
        canActivate: [ValidSessionGuard, OnboardingCompletedGuard],
      },
      {
        path: 'payment',
        loadChildren: () =>
          import('@em/subscription/feature').then((m) => m.SubscriptionModule),
        canActivate: [OnboardingCompletedGuard, ValidSessionGuard],
      },
      {
        path: 'overview',
        loadChildren: () =>
          import('@em/dashboard').then((m) => m.OverviewAreaModule),
        canActivate: [ValidSessionGuard, OnboardingCompletedGuard],
      },
      {
        path: 'campaigns',
        loadChildren: () =>
          import('@em/campaigns/feature-main').then(
            (m) => m.CampaignsAreaModule,
          ),
        canActivate: [ValidSessionGuard, OnboardingCompletedGuard],
      },
      ...authAreaPluggable.lazyRoutes,
      {
        path: 'enterprise',
        loadChildren: () =>
          import('@em/enterprise').then((m) => m.EnterpriseAreaModule),
      },
      {
        path: 'activities',
        loadChildren: () =>
          import('@em/activities/feature').then(
            (m) => m.activitiesFeatureRoutes,
          ),
        canActivate: [ValidSessionGuard, OnboardingCompletedGuard],
      },
      {
        path: 'products',
        loadChildren: () =>
          import('@em/data-feed/feature-products').then(
            (m) => m.dataFeedFeatureProductsRoutes,
          ),
        canActivate: [ValidSessionGuard, OnboardingCompletedGuard],
      },
      {
        path: 'google',
        loadChildren: () =>
          import('@em/google/shell').then((m) => m.GoogleShellModule),
        canActivate: [ValidSessionGuard, OnboardingCompletedGuard],
      },
      {
        path: 'facebook',
        loadChildren: () =>
          import('@em/facebook/shell').then((m) => m.FacebookShellModule),
        canActivate: [ValidSessionGuard, OnboardingCompletedGuard],
      },
      {
        path: 'pipelines',
        loadChildren: () =>
          import('@em/data-feed/feature-setup').then(
            (m) => m.DataFeedFeatureSetupModule,
          ),
        canActivate: [ValidSessionGuard, OnboardingCompletedGuard],
      },
      {
        path: 'export-products',
        loadChildren: () =>
          import('@em/data-feed/feature-export').then(
            (m) => m.dataFeedFeatureExportRoutes,
          ),
        canActivate: [ValidSessionGuard, OnboardingCompletedGuard],
      },
      {
        path: 'repricing',
        loadChildren: () =>
          import('@em/repricing/feature').then((m) => m.FeatureRepricingModule),
        canActivate: [ValidSessionGuard, OnboardingCompletedGuard],
      },
      {
        path: 'bing',
        loadChildren: () =>
          import('@em/bing/shell').then((m) => m.BingShellModule),
        canActivate: [ValidSessionGuard, OnboardingCompletedGuard],
      },
      {
        path: 'insights',
        loadChildren: () =>
          import('@em/market-insights/feature').then(
            (m) => m.marketInsightsFeatureRoutes,
          ),
        canActivate: [ValidSessionGuard, OnboardingCompletedGuard],
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'overview',
      },
      {
        path: 'error',
        component: AppErrorComponent,
      },
      {
        path: '**',
        redirectTo: 'overview',
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      enableTracing: false,
      onSameUrlNavigation: 'reload',
      initialNavigation: 'disabled',
      bindToComponentInputs: true,
    }),
    LoadingBarRouterModule,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
