import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {EmPrefixDirective} from './prefix.directive';
import {EmSuffixDirective} from './suffix.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [EmSuffixDirective, EmPrefixDirective],
  exports: [EmSuffixDirective, EmPrefixDirective],
})
export class EmFormFieldModule {}
