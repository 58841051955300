import {v4 as uuidv4} from 'uuid';
import {IFilterSet, IGenericFilterSetting} from '../../types/filtering-types';

export interface IProductGroupTemplate {
  name: string;
  selectedFilters: IGenericFilterSetting[];
  isAdded?: boolean;
  isPriceCrawl?: boolean;
}

export const WITH_GOOGLE_CONVERSIONS = {
  name: 'AUTOMATIC_FILTER_PRODUCTS_WITH_GOOGLE_CONVERSIONS',
  selectedFilters: [
    {
      key: 'with_conversions',
      options: {value: 0},
      uuid: uuidv4(),
      parent_uuid: uuidv4(),
    },
  ],
};

export const PRODUCTS_WITH_COMPEITORS_PRICES: IProductGroupTemplate = {
  name: 'AUTOMATIC_FILTER_PRODUCTS_WITH_COMPETITORS',
  selectedFilters: [
    {
      key: 'with_competitors',
      options: {value: 0},
      uuid: uuidv4(),
      parent_uuid: uuidv4(),
    },
  ],
};

export const PRODUCTS_WITH_MORE_THAN_3_COMPETITORS: IProductGroupTemplate = {
  name: 'AUTOMATIC_FILTER_PRODUCTS_WITH_MORE_THAN_3_COMPETITORS',
  selectedFilters: [
    {
      key: 'with_competitors',
      options: {value: 3, operator: 'gt'},
      uuid: uuidv4(),
      parent_uuid: uuidv4(),
    },
  ],
};

export const PRODUCTS_WITH_MORE_THAN_3_GOOGLE_COMPETITORS: IProductGroupTemplate =
  {
    name: 'AUTOMATIC_FILTER_PRODUCTS_WITH_MORE_THAN_3_GOOGLE_COMPETITORS',
    selectedFilters: [
      {
        key: 'with_competitors',
        options: {value: 3, operator: 'gt', source: 'google-shopping'},
        uuid: uuidv4(),
        parent_uuid: uuidv4(),
      },
    ],
  };

export const PRODUCTS_WITH_MORE_THAN_3_IDEALO_COMPETITORS: IProductGroupTemplate =
  {
    name: 'AUTOMATIC_FILTER_PRODUCTS_WITH_MORE_THAN_3_IDEALO_COMPETITORS',
    selectedFilters: [
      {
        key: 'with_competitors',
        options: {value: 3, operator: 'gt', source: 'idealo'},
        uuid: uuidv4(),
        parent_uuid: uuidv4(),
      },
    ],
  };

export const PRODUCTS_WITH_GOOGLE_CONVERSIONS: IProductGroupTemplate = {
  name: 'AUTOMATIC_FILTER_PRODUCTS_WITH_GOOGLE_CONVERSIONS',
  selectedFilters: [
    {
      key: 'with_conversions',
      options: {value: 0},
      uuid: uuidv4(),
      parent_uuid: uuidv4(),
    },
  ],
};

export const PRODUCTS_WITH_GOOGLE_CONVERSIONS_3_COMPETITORS: IProductGroupTemplate =
  {
    name: 'AUTOMATIC_FILTER_PRODUCTS_WITH_GOOGLE_CONVERSIONS_AND_3_COMPETITORS',
    selectedFilters: [
      {
        key: 'with_conversions',
        options: {value: 0},
        uuid: uuidv4(),
        parent_uuid: uuidv4(),
      },
      {
        key: 'with_competitors',
        options: {value: 3, operator: 'gt'},
        uuid: uuidv4(),
        parent_uuid: uuidv4(),
      },
    ],
  };

export const PRODUCTS_PRICE_FOUND_GOOGLE: IProductGroupTemplate = {
  name: 'AUTOMATIC_PRODUCTS_PRICE_FOUND_GOOGLE',
  selectedFilters: [
    {
      key: 'with_competitors',
      options: {source: 'google-shopping'},
      uuid: uuidv4(),
      parent_uuid: uuidv4(),
    },
    {
      key: 'fetch_status',
      options: {operator: 'eq', value: 'found'},
      uuid: uuidv4(),
      parent_uuid: uuidv4(),
    },
  ],
};

export const PRODUCTS_PRICE_FOUND_IDEALO: IProductGroupTemplate = {
  name: 'AUTOMATIC_PRODUCTS_PRICE_FOUND_IDEALO',
  selectedFilters: [
    {
      key: 'with_competitors',
      options: {source: 'idealo'},
      uuid: uuidv4(),
      parent_uuid: uuidv4(),
    },
    {
      key: 'fetch_status',
      options: {operator: 'eq', value: 'found'},
      uuid: uuidv4(),
      parent_uuid: uuidv4(),
    },
  ],
};
export const PRODUCTS_PRICE_FETCH_NOT_BLANK: IProductGroupTemplate = {
  name: 'AUTOMATIC_PRODUCTS_PRICE_FOUND_IDEALO',
  selectedFilters: [
    {
      key: 'fetch_status',
      options: {operator: 'not_empty'},
      uuid: uuidv4(),
      parent_uuid: uuidv4(),
    },
  ],
};

export const PRODUCTS_GROUP_TEMPLATES: IProductGroupTemplate[] = [
  PRODUCTS_WITH_COMPEITORS_PRICES,
  PRODUCTS_WITH_MORE_THAN_3_COMPETITORS,
  PRODUCTS_WITH_MORE_THAN_3_GOOGLE_COMPETITORS,
  PRODUCTS_WITH_MORE_THAN_3_IDEALO_COMPETITORS,
  PRODUCTS_WITH_GOOGLE_CONVERSIONS,
  PRODUCTS_WITH_GOOGLE_CONVERSIONS_3_COMPETITORS,
  {
    name: 'AUTOMATIC_FILTER_BEST_PRICE',
    selectedFilters: [
      {
        key: 'best_price',
        options: {},
        uuid: uuidv4(),
        parent_uuid: uuidv4(),
      },
    ],
  },
  {
    name: 'AUTOMATIC_FILTER_PRODUCTS_PRICED_ATTRACTIVE',
    selectedFilters: [
      {
        key: 'mode_price_v2',
        options: {range_mode: 'below'},
        uuid: uuidv4(),
        parent_uuid: uuidv4(),
      },
    ],
  },
  {
    name: 'AUTOMATIC_FILTER_PRODUCTS_PRICED_FAIR',
    selectedFilters: [
      {
        key: 'mode_price_v2',
        options: {range_mode: 'within'},
        uuid: uuidv4(),
        parent_uuid: uuidv4(),
      },
    ],
  },
  {
    name: 'AUTOMATIC_FILTER_PRODUCTS_PRICED_EXPENSIVE',
    selectedFilters: [
      {
        key: 'mode_price_v2',
        options: {range_mode: 'above'},
        uuid: uuidv4(),
        parent_uuid: uuidv4(),
      },
    ],
  },
  {
    name: 'AUTOMATIC_FILTER_PRODUCTS_PRICED_WRONG',
    selectedFilters: [
      {
        key: 'outliers',
        options: {},
        uuid: uuidv4(),
        parent_uuid: uuidv4(),
      },
    ],
  },

  {
    name: 'AUTOMATIC_FILTER_PRODUCTS_WITH_COSTS_NO_CONVERSION',
    selectedFilters: [
      {
        key: 'with_costs',
        options: {value: 0},
        uuid: uuidv4(),
        parent_uuid: uuidv4(),
      },
      {
        key: 'with_conversions',
        options: {value: 0},
        uuid: uuidv4(),
        parent_uuid: uuidv4(),
      },
    ],
  },
];

export const OLD_PRODUCTS_GROUP_TEMPLATES: IProductGroupTemplate[] = [
  {
    name: 'AUTOMATIC_FILTER_HIGH_FREQUENCY',
    selectedFilters: [],
  },
  {
    name: 'AUTOMATIC_FILTER_REPRICING_1',
    selectedFilters: [],
  },
  {
    name: 'AUTOMATIC_FILTER_REPRICING_2',
    selectedFilters: [],
  },
  {
    name: 'AUTOMATIC_FILTER_REPRICING_3',
    selectedFilters: [],
  },
];

export function isTemplateGroup(name: string): boolean {
  return (
    name === ONET_TIME_FETCH_TEMPLATE.name ||
    !!PRODUCTS_GROUP_TEMPLATES.find(
      (template) => template.name === name.toUpperCase(),
    ) ||
    !!OLD_PRODUCTS_GROUP_TEMPLATES.find(
      (template) => template.name === name.toUpperCase(),
    )
  );
}

export const ONET_TIME_FETCH_TEMPLATE: IFilterSet = {
  name: 'AUTOMATIC_FILTER_ONE_TIME_FETCH',
  selectedFilters: [
    {
      key: 'gtin',
      options: {},
      uuid: uuidv4(),
      parent_uuid: uuidv4(),
    },
  ],
  categoryFilter: {
    inverted: false,
    categories: [],
  },
  manuallyAddedProducts: [],
  manuallyRemovedProducts: [],
  productCount: null,
  offerIds: [],
  appliedCampaigns: [],
  type: 'template',
  links: [],
  isGoogleSPD: false,
  isPriceCrawl: true,
  excludeSetId: null,
};

export function generatePriceCrawlTemplate(
  existingNames: string[],
): IFilterSet {
  const defaultName = 'Competitor Radar Product Group';
  let name = defaultName;
  if (existingNames.length >= 0) {
    let counter = 0;
    let isUnique = false;
    // find unique name
    while (!isUnique) {
      counter++;
      name = defaultName + '-' + counter;
      isUnique = !existingNames.find((n) => n === name);
    }
  }

  return {
    name,
    selectedFilters: [
      {
        key: 'gtin',
        options: {},
        uuid: uuidv4(),
        parent_uuid: uuidv4(),
      },
    ],
    categoryFilter: {
      inverted: false,
      categories: [],
    },
    manuallyAddedProducts: [],
    manuallyRemovedProducts: [],
    productCount: null,
    offerIds: [],
    appliedCampaigns: [],
    type: 'custom',
    links: [],
    isGoogleSPD: false,
    isPriceCrawl: true,
    excludeSetId: null,
  };
}
