<em-spinner *ngIf="isAdding$ | async" [overContent]="true"></em-spinner>

<div class="flex items-center p-5 text-xl" data-testid="header">
  <em-icon name="filter_alt" class="text-primary-500 mr-2"></em-icon>
  <span>{{ 'PRODUCT_FILTER_ADD_FILTERSET' | translate }}</span>
</div>

<div mat-dialog-content class="mt-6 ml-6">
  <em-radio-group [(ngModel)]="groupType">
    <em-radio-button [value]="'custom'">
      <span class="flex items-center" data-testid="custom-option">
        <em-icon name="tune" class="mr-2"></em-icon>
        {{ 'PRODUCT_GROUP_CREATE_CUSTOM' | translate }}
      </span>
    </em-radio-button>
    <em-radio-button [value]="'template'">
      <span class="flex items-center" data-testid="template-option">
        <em-icon name="backup_table" class="mr-2"></em-icon>
        {{ 'PRODUCT_GROUP_CREATE_FROM_TEMPLATE' | translate }}
      </span>

      <div emRadioSubContent *ngIf="groupType === 'template'">
        <em-select
          [label]="'template name'"
          [(ngModel)]="template"
          [subscriptSizing]="'dynamic'"
          (selectionChange)="missingTemplate = false"
          class="w-full mt-2"
          data-testid="template-select"
        >
          <ng-container emSelectTrigger>
            {{ template | translate }}
          </ng-container>
          <em-option
            [value]="groupTemplate.name"
            [disabled]="groupTemplate.isAdded"
            *ngFor="let groupTemplate of groupTemplates$ | async"
          >
            <div class="flex items-center">
              <em-icon
                [name]="groupTemplate.isAdded ? 'check' : 'add_circle_outline'"
                [ngClass]="groupTemplate.isAdded ? 'text-success-500' : ''"
              ></em-icon>
              <span>{{ groupTemplate.name | translate }}</span>
            </div>
          </em-option>
        </em-select>

        <div *ngIf="missingTemplate" class="text-danger-500 text-sm">
          {{ 'SHARED_REUIRED_FIELD' | translate }}
        </div>

        <div *ngIf="allTemplatesAdded$ | async" class="mt-2 flex items-center">
          <em-icon name="done_all" class="mr-2 text-success-500"></em-icon>
          <span>{{ 'PRODUCT_GROUP_TEMPLATES_ALL_ADDED' | translate }}</span>
        </div>
      </div>
    </em-radio-button>
  </em-radio-group>
</div>
<div mat-dialog-actions align="end">
  <em-text-button class="mr-2" [mat-dialog-close]="false" cdkFocusInitial>
    {{ 'ACTIONS_CANCEL' | translate }}
  </em-text-button>

  <em-primary-button (btnClick)="submit()">
    <ng-container *ngIf="groupType === 'custom'">
      {{ 'SHARED_LABEL_CONTINUE' | translate }}
    </ng-container>
    <ng-container *ngIf="groupType === 'template'">
      {{ 'SHARED_SAVE' | translate }}
    </ng-container>
  </em-primary-button>
</div>
