import {CommonModule} from '@angular/common';
import {Component, inject} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatDialog, MatDialogModule} from '@angular/material/dialog';
import {
  EmErrorBoxModule,
  EmIconModule,
  EmInputModule,
  EmPrimaryButtonComponent,
  EmSecondaryButtonComponent,
  EmSpinnerModule,
  EmSuccessBoxComponent,
} from '@em/shared/ui';
import {TranslateModule} from '@ngx-translate/core';
import {tap} from 'rxjs';
import {ContractService} from '@em/subscription/data-access';
import {PaymentService} from '../services/payment-service/payment.service';

@Component({
  selector: 'em-cancel-subscription-modal',
  templateUrl: './cancel-subscription-modal.component.html',
  styleUrls: ['./cancel-subscription-modal.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    MatDialogModule,
    EmSpinnerModule,
    EmInputModule,
    EmPrimaryButtonComponent,
    EmSecondaryButtonComponent,
    EmIconModule,
    EmErrorBoxModule,
    EmSuccessBoxComponent,
  ],
})
export class EmCancelSubscriptionModalComponent {
  private readonly _contractService = inject(ContractService);
  private readonly _paymentSubscription = inject(PaymentService);
  private readonly _matDialog = inject(MatDialog);

  protected cancelReason = '';
  protected showError = false;
  protected showSuccess = false;
  protected isLoading = false;

  confirmCancelation() {
    this.isLoading = true;
    this._paymentSubscription
      .cancelSubscription(this.cancelReason || null)
      .pipe(
        tap((response) => {
          if (response) {
            this._contractService.reloadContracts();
            this.showSuccess = true;
          } else {
            this.showError = true;
          }
          this.isLoading = false;
        }),
      )
      .subscribe();
  }

  cancel() {
    this._matDialog.closeAll();
  }
}
