import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

type ButtonType =
  | 'primary'
  | 'secondary'
  | 'text'
  | 'link'
  | 'icon'
  | 'mini-fab';
type ButtonColor = 'primary' | 'basic' | 'warn';
type LinkTarget = '_blank' | '_self' | '_parent' | '_top';

@Component({
  selector: 'em-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmButtonComponent {
  @Input() type: ButtonType = 'primary';
  @Input() htmlType: 'button' | 'submit' | 'reset' = 'button';
  @Input() color: ButtonColor = 'primary';
  @Input() size: 'xs' | 'sm' | 'md' | 'lg' | 'full' = 'md';
  @Input() disabled: boolean | null | undefined = false;
  @Input() icon?: string;
  @Input() linkUrl?: string;
  @Input() linkTarget?: LinkTarget = '_self';

  @Output() btnClick: EventEmitter<MouseEvent> = new EventEmitter();

  constructor() {}

  btnClicked(event: MouseEvent) {
    if (!this.disabled) {
      this.btnClick.emit(event);
    }
  }
}
