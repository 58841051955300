import {Injectable, inject} from '@angular/core';
import {ProductsCountService} from '@em/data-feed/data-access-products';
import {Contract, ContractWithPrice} from '@em/subscription/api-interface';

import {cloneDeep, merge} from 'lodash';
import {Observable, combineLatest, of} from 'rxjs';
import {filter, map, switchMap, take} from 'rxjs/operators';

import {ContractStore} from './contract.store';
import {RepricingProductsCountStore} from '../repricing-products-count/repricing-products-count.store';
import {roundProductsCount} from '../helpers/round-products-count';

const DEFAULT_CURRENCY = 'EUR';

@Injectable({
  providedIn: 'root',
})
export class ContractService {
  private readonly _contractStore = inject(ContractStore);
  private readonly _productsCountService = inject(ProductsCountService);
  private readonly _repricingProductsCoutnStore = inject(
    RepricingProductsCountStore,
  );

  readonly editableContract$ = this._waitTillInitialization(
    this._contractStore.editableContract$,
  );
  readonly liveContract$ = this._waitTillInitialization(
    this._contractStore.liveContract$,
  );
  readonly activeContract$ = this._waitTillInitialization(
    this._contractStore.activeContract$,
  );
  readonly liveContractWitCredits$ = this._waitTillInitialization(
    this._contractStore.liveContractWithCredits$,
  );
  readonly pendingContract$ = this._waitTillInitialization(
    this._contractStore.pendingContract$,
  );
  readonly activeSubscription$ = this._waitTillInitialization(
    this._contractStore.activeSubscription$,
  );
  readonly currency$ = this.activeSubscription$.pipe(
    map((sub) => sub?.monthly_payment?.currency || DEFAULT_CURRENCY),
  );

  readonly hasPlan$ = this.liveContract$.pipe(map((contract) => !!contract));
  readonly noPlanExist$ = this.liveContract$.pipe(map((contract) => !contract));

  readonly liveContractWithPrice$: Observable<ContractWithPrice | null> =
    combineLatest([
      this.liveContractWitCredits$,
      this.activeSubscription$,
    ]).pipe(
      map(([liveContract, activeSubscription]) =>
        liveContract
          ? {
              contract: liveContract.details,
              pricePreview: {
                credits: liveContract.credits,
                currency:
                  activeSubscription?.monthly_payment?.currency ||
                  DEFAULT_CURRENCY,
                price: activeSubscription?.monthly_payment?.price || 0,
              },
            }
          : null,
      ),
    );

  readonly repricingMissing$ = combineLatest([
    this.liveContract$,
    this._repricingProductsCoutnStore.repricingProductsCount$,
  ]).pipe(
    map(
      ([contract, productsCount]) =>
        !contract?.repricing?.enabled ||
        (contract?.repricing?.total_products || 0) <
          roundProductsCount(productsCount),
    ),
  );

  readonly hasLivePriceCrawl$ = this.activeContract$.pipe(
    map((liveContract) => !!liveContract?.price_crawls?.find((c) => c.enabled)),
  );

  loadContract(observableOrValue?: void | Observable<void> | undefined) {
    return this._contractStore.loadContract(observableOrValue);
  }

  saveContract(contractChanges: Partial<Contract>) {
    if (contractChanges.product_management?.total_products) {
      this._contractStore.saveContract(contractChanges);
    } else {
      // Make sure to include the product_management when saving if not exist
      // Could happen when the first time user save a contract (price crawl)
      this._contractStore.saveContract(
        combineLatest([
          of(contractChanges),
          this._productsCountService.allProductsCount$,
        ]).pipe(
          take(1),
          map(
            ([changes, productsCount]) =>
              ({
                ...changes,
                product_management: {total_products: productsCount},
              } as Contract),
          ),
        ),
      );
    }
  }

  reloadContracts() {
    this._contractStore.loadContract();
  }

  getContractPreview(contract: Contract): Observable<ContractWithPrice | null> {
    return this.currency$.pipe(
      switchMap((currency) => {
        // Don't allow sending total_products = 0, backend doesn't accept
        let cont: Contract = contract;
        if (contract.repricing?.total_products === 0) {
          cont = {
            ...contract,
            repricing: {...cont.repricing, total_products: 1},
          };
        }
        return this._contractStore.getContractPreview(cont, currency);
      }),
    );
  }

  getSuggestedContract(
    additionalFeatures?: Partial<Contract>,
  ): Observable<ContractWithPrice | null> {
    return this.combineWithExisting(additionalFeatures).pipe(
      switchMap((contract) => this.getContractPreview({...contract})),
    );
  }

  combineWithExisting(
    additionalFeatures: Partial<Contract> | undefined,
  ): Observable<Contract> {
    return combineLatest([
      this.editableContract$,
      this._productsCountService.allProductsCount$,
      this._repricingProductsCoutnStore.repricingProductsCount$,
    ]).pipe(
      map(([contract, productsCount, repricingProductsCount]) => {
        let mergedContract = merge(
          cloneDeep(contract),
          additionalFeatures,
        ) as Contract;

        // ToDO: is this necessary
        if (!contract?.price_crawls?.length) {
          mergedContract = {...mergedContract, price_crawls: []};
        }

        // Include total number of products if not exist
        if (productsCount || !mergedContract.product_management) {
          mergedContract = {
            ...mergedContract,
            ...{
              product_management: {
                total_products: productsCount || 1,
              },
            },
          };
        }

        // Include total number of repricing products if missing
        if (mergedContract?.repricing) {
          mergedContract = {
            ...mergedContract,
            ...{
              repricing: {
                ...mergedContract.repricing,
                total_products: roundProductsCount(repricingProductsCount),
              },
            },
          };
        }

        return mergedContract as Contract;
      }),
    );
  }

  private _waitTillInitialization<T>(source: Observable<T>) {
    return combineLatest([this._contractStore.isInitialized$, source]).pipe(
      filter(([isInitialized]) => !!isInitialized),
      map(([, data]) => data),
    );
  }
}
