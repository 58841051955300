import {LanguageCode} from '@em/shared/util-types';

export const AVAILABLE_LANGUAGES: LanguageCode[] = ['en', 'de'];

export const LANGUAGE_LOCALE_MAP = {
  en: 'en-GB',
  de: 'de-DE',
  fr: 'fr-FR',
  es: 'es-ES',
  it: 'it-IT',
  nl: 'nl-NL',
};
