import {ISetupStatus} from '@em/auth/data-access';
import {PipelineListResp} from '@em/shared/api-interface';
import {GOOGLE} from '@em/shared/platforms/util';
import {Flatten} from '@em/shared/util-types';
import {getPluginLogo, PluginName} from '@em/shop-system/data-access-settings';
import {combineLatest, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {DatafeedStore} from '../datafeed-store/datafeed.store';
import {PipelineListItem} from '../types/pipeline-list-item';
import {PipelineSourceType} from '../types/pipeline-source-type';

export class PipelineListFactory {
  static buildPipelinesList(
    pipelines: PipelineListResp,
    setupStatus$: Observable<ISetupStatus>,
    datafeedStore: DatafeedStore,
  ): Observable<PipelineListItem[]> {
    return combineLatest([
      this.getPluginNameIfNeeded(pipelines, datafeedStore),
      setupStatus$,
    ]).pipe(
      map(([pluginName, setupStatus]) =>
        pipelines
          .map((pipeline) =>
            this.buildPipelineListItem(pipeline, pluginName, setupStatus),
          )
          .sort((a, b) => (a.uuid > b.uuid ? 1 : -1)),
      ),
    );
  }

  static getPluginNameIfNeeded(
    pipelines: PipelineListResp,
    datafeedStore: Pick<DatafeedStore, 'loadPlugin' | 'pluginName$'>,
  ): Observable<PluginName | undefined> {
    let pluginName$: Observable<PluginName | undefined>;
    const dataFeedPipeline = pipelines.find((p) => p.source === 'datafeed_api');
    if (dataFeedPipeline) {
      datafeedStore.loadPlugin();
      pluginName$ = datafeedStore.pluginName$;
    } else {
      pluginName$ = of(undefined);
    }

    return pluginName$;
  }

  static buildPipelineListItem(
    pipeline: Flatten<PipelineListResp>,
    pluginName: PluginName | undefined,
    setupStatus: ISetupStatus,
  ): PipelineListItem {
    let isSetupComplete = false;
    const pluginDetails: Pick<PipelineListItem, 'logoUrl' | 'sourceDesc'> = {};

    switch (pipeline.source as PipelineSourceType) {
      case 'datafeed_api':
        if (pluginName) {
          pluginDetails.logoUrl = getPluginLogo(pluginName);
          pluginDetails.sourceDesc = pluginName;
        }
        isSetupComplete = setupStatus.datafeed?.isSetup;
        break;
      case 'merchant_center':
        pluginDetails.logoUrl = GOOGLE.logos['merchantCenter'];
        pluginDetails.sourceDesc = 'ONBOARDING_GOOGLE_MERCHANT_CENTER_TITLE';
        isSetupComplete = setupStatus?.merchant?.hasFullSetup;
        break;
      case 'csv':
        pluginDetails.logoUrl = 'assets/images/shared/csv-file.png';
        pluginDetails.sourceDesc = 'CSV File';
        isSetupComplete = !!pipeline.source_options?.['url'];
        break;
      default:
        break;
    }

    return {
      isActive: pipeline.active,
      uuid: pipeline.uuid,
      source: pipeline.source as PipelineSourceType,
      isSetupComplete,
      ...pluginDetails,
    };
  }

  static getPiplineLogo(
    type: PipelineSourceType,
    pluginName?: PluginName,
  ): string {
    switch (type) {
      case 'datafeed_api':
        if (pluginName) {
          return getPluginLogo(pluginName) || '';
        }
        return '';
        break;
      case 'merchant_center':
        return GOOGLE.logos['merchantCenter'];
      case 'csv':
        return 'assets/images/shared/csv-file.png';

        break;
      default:
        return '';
    }
  }

  static getPiplineDesc(
    type: PipelineSourceType,
    pluginName?: PluginName,
  ): string {
    switch (type) {
      case 'datafeed_api':
        if (pluginName) {
          return pluginName;
        }
        return '';
        break;
      case 'merchant_center':
        return 'ONBOARDING_GOOGLE_MERCHANT_CENTER_TITLE';
      case 'csv':
        return 'CSV File';

        break;
      default:
        return '';
    }
  }
}
