/* tslint:disable */
/**
* This file was automatically generated.
* DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
* and run the generate-code script again.
*/
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DeleteAdAccountResp} from '../types/view-models/bing/delete-ad-account';
import {DeleteResp as DeleteBingResp} from '../types/view-models/bing/delete';
import {DeleteMerchantCenterResp} from '../types/view-models/bing/delete-merchant-center';
import {GetAdAccountReq} from '../types/request-objects/bing/get-ad-account';
import {GetAdAccountResp} from '../types/view-models/bing/get-ad-account';
import {GetAdAccountsReq} from '../types/request-objects/bing/get-ad-accounts';
import {GetAdAccountsResp} from '../types/view-models/bing/get-ad-accounts';
import {GetResp as GetBingResp} from '../types/view-models/bing/get';
import {GetCatalogStatusReq} from '../types/request-objects/bing/get-catalog-status';
import {GetCatalogStatusResp} from '../types/view-models/bing/get-catalog-status';
import {GetCatalogsResp} from '../types/view-models/bing/get-catalogs';
import {GetMerchantCentersResp} from '../types/view-models/bing/get-merchant-centers';
import {GetProductsStatusesReq} from '../types/request-objects/bing/get-products-statuses';
import {GetProductsStatusesResp} from '../types/view-models/bing/get-products-statuses';
import {GetProductsSummaryResp} from '../types/view-models/bing/get-products-summary';
import {PostAdAccountsReq} from '../types/request-objects/bing/post-ad-accounts';
import {PostAdAccountsResp} from '../types/view-models/bing/post-ad-accounts';
import {PostAutomaticUploadReq} from '../types/request-objects/bing/post-automatic-upload';
import {PostMerchantCentersReq} from '../types/request-objects/bing/post-merchant-centers';
import {PostMerchantCentersResp} from '../types/view-models/bing/post-merchant-centers';
import {PostSignupReq} from '../types/request-objects/bing/post-signup';
import {PostSignupResp} from '../types/view-models/bing/post-signup';

@Injectable({
  providedIn: 'root',
})
export class BingGateway {
  constructor(private _http: HttpClient) {}

  deleteAdAccount(options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<DeleteAdAccountResp> {
    return this._http.delete<DeleteAdAccountResp>('/emarketing_api/bing/ad_account', options);
  }

  deleteBing(options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<DeleteBingResp> {
    return this._http.delete<DeleteBingResp>('/emarketing_api/bing/', options);
  }

  deleteMerchantCenter(options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<DeleteMerchantCenterResp> {
    return this._http.delete<DeleteMerchantCenterResp>('/emarketing_api/bing/merchant_center', options);
  }

  getAdAccount(params: GetAdAccountReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<GetAdAccountResp> {
    const request = params as any as { [param: string]: string | string[]; };
    return this._http.get<GetAdAccountResp>('/emarketing_api/bing/ad_account', {...options, params: request});
  }

  getAdAccounts(params: GetAdAccountsReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<GetAdAccountsResp> {
    const request = params as any as { [param: string]: string | string[]; };
    return this._http.get<GetAdAccountsResp>('/emarketing_api/bing/ad_accounts', {...options, params: request});
  }

  getBing(options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<GetBingResp> {
    return this._http.get<GetBingResp>('/emarketing_api/bing/', options);
  }

  getCatalogStatus(params: GetCatalogStatusReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<GetCatalogStatusResp> {
    const request = params as any as { [param: string]: string | string[]; };
    return this._http.get<GetCatalogStatusResp>('/emarketing_api/bing/catalog_status', {...options, params: request});
  }

  getCatalogs(options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<GetCatalogsResp> {
    return this._http.get<GetCatalogsResp>('/emarketing_api/bing/catalogs', options);
  }

  getMerchantCenters(options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<GetMerchantCentersResp> {
    return this._http.get<GetMerchantCentersResp>('/emarketing_api/bing/merchant_centers', options);
  }

  getProductsStatuses(params: GetProductsStatusesReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<GetProductsStatusesResp> {
    const request = params as any as { [param: string]: string | string[]; };
    return this._http.get<GetProductsStatusesResp>('/emarketing_api/bing/products_statuses', {...options, params: request});
  }

  getProductsSummary(options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<GetProductsSummaryResp> {
    return this._http.get<GetProductsSummaryResp>('/emarketing_api/bing/products_summary', options);
  }

  postAdAccounts(params: PostAdAccountsReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<PostAdAccountsResp> {
    return this._http.post<PostAdAccountsResp>('/emarketing_api/bing/ad_accounts', params, options);
  }

  postAutomaticUpload(params: PostAutomaticUploadReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<undefined> {
    return this._http.post<undefined>('/emarketing_api/bing/automatic_upload', params, options);
  }

  postMerchantCenters(params: PostMerchantCentersReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<PostMerchantCentersResp> {
    return this._http.post<PostMerchantCentersResp>('/emarketing_api/bing/merchant_centers', params, options);
  }

  postSignup(params: PostSignupReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<PostSignupResp> {
    return this._http.post<PostSignupResp>('/emarketing_api/bing/signup', params, options);
  }
}
