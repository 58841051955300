import {Component, ContentChild, Directive, Input} from '@angular/core';

@Directive({
  selector: '[emCardTitle], em-card-title',
})
export class EmCardTitleDirective {}

@Directive({
  selector: '[emCardSubtitle], em-card-subtitle',
})
export class EmCardSubTitleDirective {}

@Directive({
  selector: '[emCardActions], em-card-actions',
})
export class EmCardActionsDirective {}

@Component({
  selector: 'em-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class EmCardComponent {
  @Input() padding = true;
  @ContentChild(EmCardTitleDirective) cardTitle!: EmCardTitleDirective;
  @ContentChild(EmCardSubTitleDirective) cardSubtitle!: EmCardSubTitleDirective;
  @ContentChild(EmCardActionsDirective) cardActions!: EmCardActionsDirective;
  constructor() {}
}
