/* eslint-disable @typescript-eslint/no-explicit-any */

export function undefinedAsNull<T>(value: T): T | null {
  return value === undefined ? null : value;
}

export function stripUndefinedOrNull(value: {[key: string]: any}): {
  [key: string]: any;
} {
  return pickBy(
    value,
    (v) => v !== undefined && v !== null && !Number.isNaN(v),
  );
}

export function stripUndefined(value: {[key: string]: any}): {
  [key: string]: any;
} {
  return pickBy(value, (v) => v !== undefined);
}

export function pickBy(
  object: {[key: string]: unknown},
  predator?: (v: unknown) => boolean,
) {
  const obj: {[key: string]: unknown} = {};

  for (const key in object) {
    if (predator && predator(object[key])) {
      obj[key] = object[key];
    } else if (object[key]) {
      obj[key] = 1;
    }
  }
  return obj;
}
