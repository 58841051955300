import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {NotificationService} from '@em/shared/util-web';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

/**
 * Captures any 5xx Http Error and redirects the user to the general error page.
 * 4xx errors are defined to be expected and should be handled where the request
 * is made.
 */
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private readonly _router: Router,
    private readonly _matDialog: MatDialog,
    private readonly _notificationService: NotificationService,
    private readonly _translateService: TranslateService,
  ) {}

  /* eslint-disable @typescript-eslint/no-explicit-any */
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(
        () => {},
        (err: unknown) => this._handleError(err),
      ),
    );
  }

  private _handleError(err: any): void {
    if (!(err instanceof HttpErrorResponse)) return;
    if (!err.status || !this._errorStatusCode(err.status)) return;
    this._matDialog.closeAll();
    // show notification error instead of redirect the user to the error page
    this._notificationService.error(
      this._translateService.instant('APP_ERROR_UNKNOWN_DESCRIPTION'),
      undefined,
      5000,
    );
    // this._router.navigateByUrl('/error?error=unknown-error');
  }

  private _errorStatusCode(status: number): boolean {
    // Note on non-standard 10XX status codes:
    // https://support.cloudflare.com/hc/en-us/sections/200820298-Error-Pages
    return (status >= 500 && status <= 599) || status >= 1000;
  }
}
