<div class="chart-wrapper">
  <div class="no-data-indicator" *ngIf="!hasData()">
    <span class="mat-title" translate>CHART_NO_DATA</span>
  </div>
  <ng-container *ngIf="config">
    <apx-chart [series]="result"
               [chart]="config.chart"
               [labels]="config.labels"
               [plotOptions]="config.plotOptions"
               [colors]="config.colors"
               [states]="config.states"
               [tooltip]="config.tooltip"
               [stroke]="config.stroke"
               [xaxis]="config.xaxis"
               [yaxis]="config.yaxis"
               [dataLabels]="config.dataLabels"
               [legend]="config.legend"
               [responsive]="config.responsive">
    </apx-chart>
  </ng-container>
</div>
