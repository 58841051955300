<em-box class="relative shadow-sm" size="sm">
  <em-spinner
    [overContent]="true"
    *ngIf="inProgress$ | async"
    class="em-spinner-mini"
    style="height: 100%"
    [label]="('PROGRESS_ACTION_RUNNING' | translate) + '...'"
  ></em-spinner>

  <div class="flex gap-4 items-center">
    <div class="flex flex-col gap-1">
      <div *ngIf="neverFetched$ | async" class="flex items-centerß">
        <em-icon
          size="sm"
          name="warning_amber"
          class="text-warn-500 mr-1"
        ></em-icon>
        {{ 'DATA_NEVER_FETCHED' | translate }}
      </div>

      <ng-container *ngIf="lastFail$ | async as lastFail; else lastSuccess">
        <div class="flex items-center">
          <em-icon
            size="sm"
            name="error_outline"
            class="text-danger-500 mr-1"
          ></em-icon>
          <span class="capitalize"
            >{{ 'SHARED_LABEL_FAILED' | translate }}:</span
          >
          <span class="font-bold ml-1">{{ formatRelative(lastFail) }}</span>
        </div>
        <ng-container [ngTemplateOutlet]="lastSuccess"></ng-container>
      </ng-container>
    </div>

    <em-secondary-button (btnClick)="triggerFetch()" icon="update">
      {{ 'PROGRESS_STATE_IDLE' | translate }}
    </em-secondary-button>
  </div>
</em-box>

<ng-template #lastSuccess>
  <div class="flex items-center" *ngIf="lastSuccess$ | async as lasSuccess">
    <em-icon size="sm" name="done" class="text-success-500 mr-1"></em-icon>
    <span class="capitalize">{{ 'SHARED_LABEL_SUCCESSFUL' | translate }}:</span>
    <span class="font-bold ml-1">{{ formatRelative(lasSuccess) }}</span>
  </div>
</ng-template>
