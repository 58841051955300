import {Component} from '@angular/core';

@Component({
  selector: 'em-warning-box',
  templateUrl: './warning-box.component.html',
  styleUrls: ['./warning-box.component.scss'],
})
export class EmWarningBoxComponent {
  constructor() {}
}
