import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MATERIAL_SANITY_CHECKS} from '@angular/material/core';
import {MatRadioModule} from '@angular/material/radio';
import {EmErrorDirective} from '../../directives/error/error.directive';
import {EmErrorModule} from '../../directives/error/error.module';
import {EmRadioSubContentDirective} from './radio-button-sub-content.directive';
import {EmRadioButtonComponent} from './radio-button.component';
import {EmRadioGroupComponent} from './radio-group.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    EmErrorModule,
    MatRadioModule,
  ],
  declarations: [
    EmRadioGroupComponent,
    EmRadioButtonComponent,
    EmRadioSubContentDirective,
  ],
  exports: [
    EmRadioGroupComponent,
    EmRadioButtonComponent,
    EmErrorDirective,
    EmRadioSubContentDirective,
  ],
  providers: [{provide: MATERIAL_SANITY_CHECKS, useValue: false}],
})
export class EmRadioModule {}
