import {Injectable} from '@angular/core';
import {merge, Observable, of, Subject} from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  startWith,
} from 'rxjs/operators';
import {CountryCode, IInitializable} from '@em/shared/util-types';
import {SessionService} from '@em/auth/data-access';
import {
  SetupStatusService,
  ISetupStatus,
  LocalUserDataAccessor,
  LocalUserDataService,
} from '@em/auth/data-access';
import {ISimpleModelService} from '../types/simple-model-service';
import {MerchantsService} from '../merchant/merchants.service';

const COUNTRY_KEY = 'merchant_country';

@Injectable({
  providedIn: 'root',
})
export class CountryService
  implements ISimpleModelService<CountryCode, CountryCode>, IInitializable
{
  readonly name = 'CountryService';
  private readonly _countryAccessor: LocalUserDataAccessor;
  private readonly _countryChange = new Subject<CountryCode>();
  private readonly _observable: Observable<CountryCode>;

  constructor(
    private readonly _session: SessionService,
    private readonly _localUserData: LocalUserDataService,
    private readonly _merchants: MerchantsService,
    private readonly _setupStatus: SetupStatusService,
  ) {
    this._countryAccessor = this._localUserData.createAccessor(COUNTRY_KEY);

    this._observable = merge(
      this._session.loginResponse.pipe(map((resp) => resp.country)),
      this._merchants.observable().pipe(
        filter((merchant) => !!merchant.countryCode),
        map((merchant) => merchant.countryCode as CountryCode),
      ),
      this._countryChange,
    ).pipe(
      startWith(...this._fromLocalUserData()),
      distinctUntilChanged(),
      shareReplay({refCount: false, bufferSize: 1}),
    );
  }

  initialize(): Observable<void> {
    this._countryChange.subscribe((countryCode) => {
      this._updateMerchant(countryCode);
    });

    this._observable.subscribe((countryCode) => {
      this._storeCountry(countryCode);
    });

    return of(undefined);
  }

  observable(): Observable<CountryCode> {
    return this._observable;
  }

  availableCountries(): Observable<CountryCode[]> {
    return this._setupStatus
      .observable()
      .pipe(
        map((status: ISetupStatus) => status.merchantCenter.availableCountries),
      );
  }

  change(key: CountryCode): void {
    this._countryChange.next(key);
  }

  private _storeCountry(countryCode: CountryCode) {
    this._countryAccessor.value = countryCode;
  }

  private _updateMerchant(countryCode: CountryCode) {
    this._merchants.update({
      countryCode,
    });
  }

  private _fromLocalUserData(): CountryCode[] {
    const value = this._countryAccessor.value;
    if (value) return [value as CountryCode];
    return [];
  }
}
