import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MATERIAL_SANITY_CHECKS} from '@angular/material/core';
import {MatIconModule} from '@angular/material/icon';
import {EmExternalLinkComponent} from './external-link.component';

@NgModule({
  imports: [CommonModule, MatIconModule],
  declarations: [EmExternalLinkComponent],
  exports: [EmExternalLinkComponent],
  providers: [{provide: MATERIAL_SANITY_CHECKS, useValue: false}],
})
export class EmExternalLinkModule {}
