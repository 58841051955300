<ng-container *ngIf="componentState | async as compState">
  <em-block-ui *ngIf="compState.loading || dataLoading"></em-block-ui>
  <div [ngSwitch]="compState.uiState">
    <div *ngSwitchCase="'enter-email'" class="enter-email">
      <div class="title">
        {{ 'ENTERPRISE_ACCOUNT_CREATION_ENTER_EMAIL_TITLE' | translate }}
      </div>
      <div class="description">
        {{ 'ENTERPRISE_ACCOUNT_CREATION_ENTER_EMAIL_DESCRIPTION' | translate }}
      </div>

      <form class="form" [formGroup]="form">
        <mat-form-field>
          <input
            name="email"
            type="email"
            matInput
            [placeholder]="'ACCOUNT_CREATION_EMAIL' | translate"
            formControlName="email"
          />
          <mat-error *ngIf="form?.controls.email.hasError('required')">
            {{ 'VALIDATION_FIELD_REQUIRED' | translate }}
          </mat-error>
          <mat-error *ngIf="form?.controls.email.hasError('validateEmail')">
            {{ 'VALIDATION_EMAIL_PATTERN_WRONG' | translate }}
          </mat-error>
        </mat-form-field>
        <button
          mat-flat-button
          color="primary"
          [disabled]="dataLoading || !form.valid"
          (click)="captchaRef.execute()"
        >
          {{ 'ENTERPRISE_ACCOUNT_CREATION_CREATE_ACCOUNT' | translate }}
        </button>
        <re-captcha
          #captchaRef="reCaptcha"
          size="invisible"
          badge="bottomleft"
          (resolved)="submit($event, captchaRef)"
        ></re-captcha>
      </form>
    </div>
    <div *ngSwitchCase="'needs-authorization'" class="needs-authorization">
      <div class="title">
        {{ 'ENTERPRISE_ACCOUNT_CREATION_START_AUTH_TITLE' | translate }}
      </div>
      <div class="description">
        {{ 'ENTERPRISE_ACCOUNT_CREATION_START_AUTH_DESCRIPTION' | translate }}
      </div>

      <button mat-flat-button color="primary" (click)="startAuthorization()">
        {{ 'ENTERPRISE_ACCOUNT_CREATION_START_AUTH' | translate }}
      </button>
    </div>
    <div
      *ngSwitchCase="'authorization-progress'"
      class="authorization-progress"
    >
      <div class="title">
        {{ 'ENTERPRISE_ACCOUNT_CREATION_AUTH_IN_PROGRESS' | translate }}
      </div>
    </div>
  </div>
</ng-container>
