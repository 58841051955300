import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {SingleFilterRowComponent} from './single-filter-row/single-filter-row.component';
import {FilterGroupConnectComponent} from './filter-group-connect/filter-group-connect.component';
import {FilterConnectComponent} from './filter-connect/filter-connect.component';
import {MatRadioModule} from '@angular/material/radio';
import {TranslateWithErrorReportingModule} from '@em/shared/util-configuration';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateWithErrorReportingModule,
    MatRadioModule,
  ],
  declarations: [
    SingleFilterRowComponent,
    FilterGroupConnectComponent,
    FilterConnectComponent,
  ],
  exports: [
    SingleFilterRowComponent,
    FilterConnectComponent,
    FilterGroupConnectComponent,
  ],
})
export class ProductFilteringFeatureModule {}
