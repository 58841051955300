import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';

import {createSelector, Store} from '@ngrx/store';
import {map, switchMap, take} from 'rxjs/operators';
import {validateEmail} from '@em/shared/ui';
import {CurrencyCode} from '@em/shared/util-types';
import {NotificationService} from '@em/shared/util-web';
import {GapiAuthService} from '@em/auth/data-access';
import {AuthCompleted, AuthFailed, OauthStarted} from '../enterprise.actions';
import {
  getAuthorizationState,
  getEnterpriseState,
} from '../enterprise.selectors';
import {EnterpriseService} from '../enterprise.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {RecaptchaComponent} from 'ng-recaptcha';

export const getUiState = createSelector(getEnterpriseState, (state) => {
  const {state: authState} = state.authorization;

  if (state.hasOauthToken) return 'enter-email';
  if (authState === 'unauthorized') return 'needs-authorization';
  if (authState === 'authorized') return 'enter-email';

  return 'authorization-progress';
});

export const componentState = createSelector(
  getEnterpriseState,
  getAuthorizationState,
  getUiState,
  (state, auth, uiState) => ({
    code: state.authorization.code,
    uiState,
    loading: auth === 'in-progress',
  }),
);

export interface IAccountCreationData {
  adwordsId: number;
  accessToken: string;
  hasOauthToken: boolean;
  currencyCode: CurrencyCode;
  loginCustomerId: number;
}

@Component({
  selector: 'em-create-account',
  templateUrl: './account-creation.component.html',
  styleUrls: ['./account-creation.component.scss'],
})
export class AccountCreationComponent implements OnInit {
  readonly componentState = this._store.select(componentState);
  dataLoading = false;
  form: UntypedFormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private readonly _data: IAccountCreationData,
    private readonly _matDialogRef: MatDialogRef<AccountCreationComponent>,
    private readonly _fb: UntypedFormBuilder,
    private readonly _enterprise: EnterpriseService,
    private readonly _gapiAuth: GapiAuthService,
    private readonly _store: Store,
    private readonly _notification: NotificationService,
  ) {
    this.form = _fb.group({
      email: [undefined, [Validators.required, validateEmail]],
    });
  }

  ngOnInit(): void {
    if (!this._data.hasOauthToken) {
      this.startAuthorization();
    }
  }

  startAuthorization() {
    this._store.dispatch(new OauthStarted());
    this._gapiAuth.authorizeUser().subscribe(
      (code) => {
        this._store.dispatch(new AuthCompleted({code}));
      },
      (error: unknown) => {
        this._store.dispatch(new AuthFailed({}));
      },
    );
  }

  submit(recaptcha_response: string, reCaptcha?: RecaptchaComponent) {
    if (recaptcha_response) {
      this.form.controls['email'].disable();
      this.dataLoading = true;

      const email = this.form.value.email;
      const accessToken = this._data.accessToken;
      const adwordsId = this._data.adwordsId;
      const currencyCode = this._data.currencyCode;
      const loginCustomerId = this._data.loginCustomerId;

      this.componentState
        .pipe(
          map(({code}) => code),
          take(1),
          switchMap((code) =>
            this._enterprise.postSignup(
              email,
              accessToken,
              adwordsId,
              currencyCode,
              code,
              loginCustomerId,
              recaptcha_response,
            ),
          ),
        )
        .subscribe(
          (jwt) => {
            this._notification.show('ACCOUNT_CREATION_SUCCESSFUL');
            this.dataLoading = false;
            this._matDialogRef.close(jwt);
          },
          (err: unknown) => {
            reCaptcha?.reset();
            this._handleError(err);
          },
        );
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _handleError(err: any) {
    const duration = 10000;
    this.dataLoading = false;
    this.form.controls['email'].enable();

    if (err?.error?.error === 'InvalidMerchantParametersError') {
      // Allow to change email-address, no need to re-do authentication
      this._notification.error(
        'ERROR_INVALID_MERCHANT_PARAMETERS',
        undefined,
        duration,
      );
    } else if (err?.error?.error === 'GoogleOauthMismatch') {
      // Reset authentication
      this._notification.error(
        'ERROR_GOOGLE_OAUTH_MISMATCH',
        undefined,
        duration,
      );
      this._store.dispatch(new AuthFailed({}));
    } else {
      // Close modal and stop process. Something's foobar with the "to be linked" adwords account.
      this._notification.error(
        'ERROR_ACCOUNT_CREATION',
        {code: err?.error?.error},
        duration,
      );
      this._matDialogRef.close(null);
    }
  }
}
