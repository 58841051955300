<mat-form-field
  class="w-full"
  appearance="outline"
  [subscriptSizing]="subscriptSizing"
  [floatLabel]="floatLabel"
>
  <mat-label *ngIf="label"
    >{{ label }} <span *ngIf="required">*</span></mat-label
  >
  <input
    matInput
    [type]="type"
    [min]="min"
    [max]="max"
    [step]="step"
    [placeholder]="placeholder"
    [(ngModel)]="controlValue"
    [disabled]="isDisabled"
    [errorStateMatcher]="errorMatcher"
    (blur)="controlTouched(); blur.emit()"
    (focus)="focus.emit()"
    (ngModelChange)="controlValueChanged($event)"
  />
  <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
  <mat-error>
    <ng-template [ngTemplateOutlet]="errors"></ng-template>
  </mat-error>
  <ng-container matSuffix *ngIf="emSuffix">
    <ng-content select="[emSuffix]"></ng-content>
  </ng-container>
  <ng-container matPrefix *ngIf="emPrefix">
    <ng-content select="[emPrefix]"></ng-content>
  </ng-container>
</mat-form-field>

<ng-template #errors>
  <ng-content select="em-error"></ng-content>
</ng-template>
