import {IOptionDefinition} from './option-definition';
import {IProductFilterDefinition} from './product-filter-definition';

export function getProductFilterErrorKey(error: IProductFilterError) {
  return `${error.definition.key}_${error.option.name}`.toUpperCase();
}

export interface IProductFilterError {
  definition: IProductFilterDefinition;
  error: string;
  option: IOptionDefinition;
}
