import {NgModule} from '@angular/core';
import {Route, RouterModule} from '@angular/router';
import {OnboardingContainerComponent} from './container/onboarding-container.component';
import {OnboardingContainerModule} from './container/onboarding-container.module';
import {ServicesListComponent} from './services-list/services-list.component';
import {ServicesListModule} from './services-list/services-list.module';

const onboardingServicesRoutes: Route[] = [
  {
    path: '',
    component: OnboardingContainerComponent,
    children: [
      {
        path: '',
        component: ServicesListComponent,
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(onboardingServicesRoutes),
    ServicesListModule,
    OnboardingContainerModule,
  ],
  declarations: [],
})
export class OnboardingFeatureModule {}
