<em-box class="bg-white m-auto w-[350px] mt-20">
  <ng-container *ngIf="isLoading$ | async; else statusMessages">
    <div>
      <em-spinner class="m-auto"></em-spinner>
      <div class="text-center mt-6">
        We are finalizing your payment, please wait...
      </div>
    </div>
  </ng-container>

  <ng-template #statusMessages>
    <div *ngIf="paymentSuccess$ | async">
      <div class="text-center">
        <em-icon
          [size]="'xl'"
          class="inline-block text-success-500"
          name="task_alt"
        ></em-icon>
      </div>
      <div class="text-center font-bold text-lg mt-1 mb-6">
        {{ 'SHARED_PAYMENT_SUCCESS_TITLE' | translate }}
      </div>
      <div class="text-center mt-1 mb-8">
        {{ 'SHARED_PAYMENT_SUCCESS_DESC' | translate }}
      </div>
      <div class="text-center">
        <em-primary-button (btnClick)="continue()">
          {{ 'SHARED_LABEL_CONTINUE' | translate }}
        </em-primary-button>
      </div>
    </div>

    <div *ngIf="paymentFailed$ | async">
      <div class="text-center">
        <em-icon
          [size]="'xl'"
          class="inline-block text-danger-500"
          name="highlight_off"
        ></em-icon>
      </div>
      <div class="text-center font-bold text-lg mt-1 mb-2">
        {{ 'SHARED_PAYMENT_FAILED_TITLE' | translate }}
      </div>
      <div class="text-center mt-1 mb-8">
        {{ 'SHARED_PAYMENT_FAILED_DESC' | translate }}
      </div>
      <div class="text-center">
        <em-primary-button (btnClick)="tryAgain()">
          {{ 'SHARED_LABEL_TRY_AGAIN' | translate }}
        </em-primary-button>
      </div>
    </div>
  </ng-template>
</em-box>
