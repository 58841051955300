import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MATERIAL_SANITY_CHECKS} from '@angular/material/core';
import {EmOptionComponent} from './option.component';

@NgModule({
  imports: [CommonModule],
  declarations: [EmOptionComponent],
  exports: [EmOptionComponent],
  providers: [{provide: MATERIAL_SANITY_CHECKS, useValue: false}],
})
export class EmOptionModule {}
