/* tslint:disable */
/**
* This file was automatically generated.
* DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
* and run the generate-code script again.
*/
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DeleteResp as DeleteRepricingResp} from '../types/view-models/repricing/delete';
import {GetSettingsResp} from '../types/view-models/repricing/get-settings';
import {PutSettingsReq} from '../types/request-objects/repricing/put-settings';
import {PutSettingsResp} from '../types/view-models/repricing/put-settings';

@Injectable({
  providedIn: 'root',
})
export class RepricingGateway {
  constructor(private _http: HttpClient) {}

  deleteRepricing(options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<DeleteRepricingResp> {
    return this._http.delete<DeleteRepricingResp>('/emarketing_api/repricing/', options);
  }

  getSettings(options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<GetSettingsResp> {
    return this._http.get<GetSettingsResp>('/emarketing_api/repricing/settings', options);
  }

  putSettings(params: PutSettingsReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<PutSettingsResp> {
    return this._http.put<PutSettingsResp>('/emarketing_api/repricing/settings', params, options);
  }
}
