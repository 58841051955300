import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {
  IBlob,
  IGetPluginSettingsResponse,
  IGoogleCountriesResponse,
} from './types';

@Injectable({
  providedIn: 'root',
})
export class PluginSettingsGateway {
  constructor(private readonly _http: HttpClient) {}

  getGoogleCountries(shopToken: string): Observable<IGoogleCountriesResponse> {
    return this._http.get<IGoogleCountriesResponse>(
      '/configuration_api/data/google-countries',
      this._requestOptions(shopToken),
    );
  }

  get<ReferenceType, BlobType>(
    shopToken: string,
  ): Observable<IGetPluginSettingsResponse<ReferenceType, BlobType>> {
    return this._http.get<IGetPluginSettingsResponse<ReferenceType, BlobType>>(
      '/configuration_api/blob/emx-shopsystem-target-countries',
      this._requestOptions(shopToken),
    );
  }

  put<BlobType>(blob: IBlob<BlobType>, shopToken: string) {
    return this._http.put(
      '/configuration_api/blob/emx-shopsystem-target-countries',
      {
        blob,
      },
      this._requestOptions(shopToken),
    );
  }

  private _requestOptions(shopToken: string) {
    return {
      headers: new HttpHeaders().set('Authorization', shopToken),
    };
  }
}
