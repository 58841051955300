import {createReducer, on} from '@ngrx/store';
import {
  finishCommonWizard,
  finishCommonWizardSuccess,
  loadOnboardingData,
  loadOnboardingDataFailure,
  loadOnboardingDataSuccess,
  saveMerchantSettingsFailure,
} from './actions';

export interface IOnboardingState {
  loading: boolean;
  loaded: boolean;
  commonWizard: {
    email: string | undefined;
    country: string | undefined;
    language: string | undefined;
    error: string | undefined;
  };
}

export type OnboardingData = Omit<IOnboardingState, 'loaded' | 'loading'>;

export const initialState: IOnboardingState = {
  loading: false,
  loaded: false,
  commonWizard: {
    email: undefined,
    country: undefined,
    language: undefined,
    error: undefined,
  },
};

export const reducers = createReducer<IOnboardingState>(
  initialState,

  on(
    loadOnboardingData,
    (state): IOnboardingState => ({
      ...state,
      loading: true,
      loaded: false,
    }),
  ),
  on(
    loadOnboardingDataSuccess,
    (state, props): IOnboardingState => ({
      ...JSON.parse(JSON.stringify(props.data)),
      loading: false,
      loaded: true,
    }),
  ),
  on(
    loadOnboardingDataFailure,
    (state): IOnboardingState => ({
      ...state,
      loading: false,
      loaded: false,
    }),
  ),
  on(
    finishCommonWizard,
    (state, {data}): IOnboardingState => ({
      ...state,
      loading: true,
      commonWizard: {
        ...state.commonWizard,
        email: data.email,
        country: data.country,
        language: data.language,
        error: undefined,
      },
    }),
  ),
  on(
    finishCommonWizardSuccess,
    (state, {data}): IOnboardingState => ({
      ...state,
      loading: false,
      commonWizard: {
        ...state.commonWizard,
        email: data.email,
        country: data.country,
        language: data.language,
        error: undefined,
      },
    }),
  ),
  on(
    saveMerchantSettingsFailure,
    (state, {error}): IOnboardingState => ({
      ...state,
      commonWizard: {
        ...state.commonWizard,
        error: error.error[0],
      },
    }),
  ),
);
