export class FullTextStore<T> {
  total = 0;
  private items: T[];
  private readonly invertedIndex: { [key: string]: T[] };
  private readonly fieldsToSearch: Array<keyof T> | null;

  constructor(...fieldsToSearch: Array<keyof T>) {
    this.fieldsToSearch = fieldsToSearch.length ? fieldsToSearch : null;
    this.items = [];
    this.invertedIndex = {};
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private tokenize(data: any): string[] {
    if (typeof data !== 'string') return [`${data}`];
    const token: string[] = data.toLowerCase().match(/\S{2,}/g) || [];
    token.push(`${data}`);
    return token;
  }

  private indexItems(items: T[]): void {
    this.total = items.length;
    for (const item of items) {
      for (const field of this.fieldsToSearch ||
        Object.getOwnPropertyNames(item)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        for (const token of this.tokenize((item as any)[field])) {
          if (!this.invertedIndex[token]) this.invertedIndex[token] = [];
          if (!this.invertedIndex[token].includes(item)) {
            this.invertedIndex[token].push(item);
          }
        }
      }
    }
  }

  add(items: T | T[]): FullTextStore<T> {
    if (!Array.isArray(items)) items = [items];
    this.items = this.items.concat(items);
    this.indexItems(items);
    return this;
  }

  search(query?: string | number, emptyLimit = this.items.length): T[] {
    if (!query || query === '') {
      return this.items.slice(0, emptyLimit);
    }
    query = query.toString().toLowerCase();
    const result: T[] = [];
    for (const token of Object.keys(this.invertedIndex)) {
      if (token.toLowerCase().indexOf(query) === -1) continue;
      for (const item of this.invertedIndex[token]) {
        if (!result.includes(item)) result.push(item);
      }
    }
    return result;
  }
}
