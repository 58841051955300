import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {MerchantsGateway} from '@em/shared/api-interface/lib/gateways/merchants.gateway';
import {Actions, concatLatestFrom, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {of} from 'rxjs';
import {
  catchError,
  exhaustMap,
  map,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import {MerchantsService} from '@em/user-account/data-access';
import {ChallengeService} from '../challenge/challenge-service';
import {
  finishCommonWizard,
  finishCommonWizardSuccess,
  loadOnboardingData,
  loadOnboardingDataFailure,
  loadOnboardingDataSuccess,
  noop,
  saveMerchantSettingsFailure,
} from './actions';
import {selectOnboardingCompleted} from './selectors';
import {OnboardingData} from './reducers';
import {RedirectAfterOnboardingService} from '../routing/redirect-after-onboarding';

@Injectable({
  providedIn: 'root',
})
export class OnboardingEffects {
  loadOnboardingData$ = createEffect(() =>
    this._actions$.pipe(
      ofType(loadOnboardingData),
      switchMap(() =>
        this._merchantService.observable().pipe(
          take(1),
          map((merchant) => {
            const data: OnboardingData = {
              commonWizard: {
                email: merchant.email ?? undefined,
                country: merchant.countryCode ?? undefined,
                language: merchant.language,
                error: undefined,
              },
            };
            return loadOnboardingDataSuccess({data});
          }),
          catchError((error: unknown) =>
            of(loadOnboardingDataFailure({error: error as HttpErrorResponse})),
          ),
        ),
      ),
    ),
  );

  finishCommonWizard$ = createEffect(() =>
    this._actions$.pipe(
      ofType(finishCommonWizard),
      exhaustMap(({data}) =>
        this._merchantsGateway
          .patchSettings({
            email: data.email,
            language: data.language,
            country: data.country,
          })
          .pipe(
            map(() => finishCommonWizardSuccess({data})),

            catchError((error: unknown) =>
              of(
                saveMerchantSettingsFailure({
                  error: error as HttpErrorResponse,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  finishCommonWizardSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(finishCommonWizardSuccess),
      switchMap(() => this._challengeService.processChallenges(true)),
      switchMap((processResult) =>
        this._redirectService.redirect(processResult),
      ),
      tap((redirected) => {
        if (!redirected) {
          this._router.navigateByUrl('/pipelines/add?source=datafeed_api');
        }
      }),
      map(() => noop()),
    ),
  );

  loadOnboardingDataSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(loadOnboardingDataSuccess),
      concatLatestFrom(() => this._store.select(selectOnboardingCompleted)),
      map(([, isOnboardingCompleted]) => {
        if (!isOnboardingCompleted) {
          this._router.navigateByUrl('setup/common');
        }
        return noop();
      }),
    ),
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _store: Store,
    private readonly _router: Router,
    private readonly _merchantsGateway: MerchantsGateway,
    private readonly _merchantService: MerchantsService,
    private readonly _challengeService: ChallengeService,
    private readonly _redirectService: RedirectAfterOnboardingService,
  ) {}
}
