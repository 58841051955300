import {FACEBOOK, GOOGLE} from '@em/shared/platforms/util';
import {IMenuItem} from './nav-menu-items-types';

export const MainItems: IMenuItem[] = [
  // {
  //   title: 'NAV_WELCOME',
  //   items: [],
  //   iconName: 'outlined_flag',
  //   targetPath: '/welcome',
  //   activePaths: ['/welcome'],
  // },
  {
    title: 'NAV_PRODUCT_OVERVIEW',
    iconName: 'shopping_cart',
    items: [
      {
        title: 'SHARED_LABEL_DATA_SOURCE',
        pageTitle: 'SHARED_LABEL_PRODUCTS_DATA_SOURCES',
        iconName: 'view_in_ar',
        activePaths: [
          '/pipelines/list',
          '/pipelines/add',
          '/pipelines/edit/:id',
        ],
        targetPath: '/pipelines/list',
      },
      {
        title: 'SHARED_LABEL_PRODUCT_LIST',
        iconName: 'format_list_bulleted',
        activePaths: ['/products/list'],
        targetPath: '/products/list',
      },
      {
        title: 'NAV_EXPORT_CSV',
        iconImage: 'assets/images/shared/csv-file.png',
        activePaths: [
          '/export-products/list',
          '/export-products/add',
          '/export-products/edit',
        ],
        targetPath: '/export-products/list',
      },
    ],
  },
  {
    title: 'NAV_PRODUCT_FILTER_SETS_OVERVIEW',
    iconName: 'filter_alt',
    activePaths: [
      '/products/groups/custom',
      '/products/groups/template',
      '/products/groups/competitors-radar',
    ],
    targetPath: '/products/groups/custom',
  },
  {
    title: 'SHARED_LABEL_GOOGLE_PRICES',
    iconImage: GOOGLE.logos.googlePrices,
    targetPath: '/insights/google-prices/settings/status',
    activePaths: [
      '/insights/google-prices/settings/status',
      '/insights/google-prices/settings/steps',
      '/insights/google-prices/settings/source',
    ],
  },
  {
    title: 'NAV_COMPETITORS_RADAR',
    iconName: 'radar',
    items: [
      {
        title: 'NAV_RADAR_SETTINGS',
        pageTitle: 'PAGE_TITLE_RADAR_SETTINGS',
        iconName: 'saved_search',
        activePaths: ['/insights/settings'],
        targetPath: '/insights/settings',
      },
      {
        title: 'NAV_ANALYSIS',
        pageTitle: 'NAV_COMPETITORS_RADAR',
        iconName: 'bar_chart',
        activePaths: ['/insights/analysis'],
        targetPath: '/insights/analysis',
      },
      {
        title: 'NAV_ACTIVITIES',
        pageTitle: 'MARKET_INSIGHTS_LOG_HEADLINE',
        iconName: 'notifications',
        activePaths: ['/insights/activities'],
        targetPath: '/insights/activities',
      },
    ],
  },
  {
    title: 'NAV_REPRICING',
    iconName: 'local_offer',
    items: [
      {
        title: 'NAV_REPRICING_CHECKLIST',
        pageTitle: 'SHARED_DYNAMIC_REPRICING_CHECKLIST',
        iconName: 'checklist',
        activePaths: [
          '/repricing/checklist/source',
          '/repricing/checklist/screening',
          '/repricing/checklist/product-groups',
          '/repricing/checklist/price-sync',
          '/repricing/checklist/repricing-jobs',
          '/repricing/checklist/subscription',
        ],
        targetPath: '/repricing/checklist',
      },
      {
        title: 'REPRICING_JOBS_TITLE',
        pageTitle: 'REPRICING_JOBS_TITLE',
        iconName: 'local_offer',
        activePaths: ['/repricing/jobs/list', '/repricing/products'],
        targetPath: '/repricing/jobs/list',
      },
      {
        title: 'NAV_ANALYSIS',
        pageTitle: 'NAV_DASHBOARD',
        iconName: 'bar_chart',
        activePaths: ['/repricing/dashboard'],
        targetPath: '/repricing/dashboard',
      },
      {
        title: 'NAV_ACTIVITIES',
        pageTitle: 'REPRICING_ACTIVITIES_TITLE',
        iconName: 'notifications',
        activePaths: ['/repricing/activities'],
        targetPath: '/repricing/activities',
      },
      {
        title: 'SHARED_LABEL_SETUP',
        pageTitle: 'BING_SETUP_TITLE',
        iconName: 'settings',
        activePaths: ['/repricing/setup'],
        targetPath: '/repricing/setup',
      },
    ],
  },
  {
    title: 'NAV_GOOGLE_SHOPPING',
    iconImage: GOOGLE.logos.general,
    items: [
      {
        title: 'SHARED_LABEL_DATAFEED',
        pageTitle: 'GOOGLE_DATAFEED_TITLE',
        iconImage: GOOGLE.logos.merchantCenter,
        activePaths: ['/google/datafeed'],
        targetPath: '/google/datafeed',
      },
      {
        title: 'SHARED_LABEL_STATS',
        pageTitle: 'GOOGLE_STATS_TITLE',
        iconName: 'signal_cellular_alt',
        iconImage: GOOGLE.logos.general,
        activePaths: ['/google/stats'],
        targetPath: '/google/stats',
      },
      {
        title: 'NAV_SHARED_CAMPAIGNS',
        pageTitle: 'GOOGLE_SHOPPING_TITLE',
        iconImage: GOOGLE.logos.campaign,
        activePaths: ['/campaigns/google-shopping'],
        targetPath: '/campaigns/google-shopping',
      },
      {
        title: 'SHARED_LABEL_SETUP',
        pageTitle: 'GOOGLE_SETUP_TITLE',
        iconName: 'settings',
        iconImage: GOOGLE.logos.general,
        activePaths: ['/google/setup'],
        targetPath: '/google/setup',
      },
    ],
  },
  {
    title: 'NAV_FACEBOOK',
    iconImage: FACEBOOK.logos.withInstagram,
    items: [
      {
        title: 'NAV_SHARED_CAMPAIGNS',
        iconImage: FACEBOOK.logos.withInstagram,
        activePaths: ['/campaigns/facebook'],
        targetPath: '/campaigns/facebook',
      },
      {
        title: 'SHARED_LABEL_SETUP',
        pageTitle: 'FACEBOOK_SETUP_TITLE',
        iconName: 'settings',
        iconImage: FACEBOOK.logos.withInstagram,
        activePaths: ['/facebook/setup'],
        targetPath: '/facebook/setup',
      },
    ],
  },
  {
    title: 'NAV_BING',
    iconImage: 'assets/images/bing/microsoft-logo.svg',
    items: [
      {
        title: 'SHARED_LABEL_DATAFEED',
        pageTitle: 'BING_DATAFEED_TITLE',
        iconImage: 'assets/images/bing/microsoft-logo.svg',
        activePaths: ['/bing/datafeed'],
        targetPath: '/bing/datafeed',
      },
      {
        title: 'NAV_SHARED_CAMPAIGNS',
        pageTitle: 'BING_CAMPAIGNS_TITLE',
        iconImage: 'assets/images/bing/microsoft-logo.svg',
        activePaths: ['/bing/campaigns'],
        targetPath: '/bing/campaigns',
      },
      {
        title: 'SHARED_LABEL_SETUP',
        pageTitle: 'BING_SETUP_TITLE',
        iconName: 'settings',
        iconImage: 'assets/images/bing/microsoft-logo.svg',
        activePaths: ['/bing/setup'],
        targetPath: '/bing/setup',
      },
    ],
  },
  {
    title: 'SHARED_LABEL_ADS_DASHBOARD',
    items: [],
    iconName: 'space_dashboard',
    targetPath: '/overview',
    activePaths: ['/overview'],
  },
  {
    title: 'NAV_ACTIVITIES',
    pageTitle: 'ACTIVITIES_TITLE',
    targetPath: '/activities',
    iconName: 'notifications',
    activePaths: ['/activities'],
    items: [],
  },
];

export const UserMenuItems: IMenuItem[] = [
  {
    title: 'NAV_ACCOUNT',
    pageTitle: 'MY_ACCOUNT_TITLE',
    targetPath: '/account',
    activePaths: ['/account'],
  },
  {
    title: 'NAV_INVOICES',
    targetPath: '/payment/invoices',
    queryParams: {
      openedTab: 'invoices',
    },
    activePaths: ['/payment/invoices'],
  },
  {
    title: 'NAV_PAYMENT',
    targetPath: '/payment/subscription',
    activePaths: ['/payment/subscription'],
  },
  {
    title: 'NAV_ACTIVITIES',
    pageTitle: 'ACTIVITIES_TITLE',
    targetPath: '/activities',
    iconName: 'notifications',
    activePaths: ['/activities'],
    items: [],
  },
];
