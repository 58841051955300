<div>
  <div *ngFor="let vm of vms; trackBy: trackByFn">
    <mat-form-field
      class="form-field-sm w-full mb-2"
      subscriptSizing="dynamic"
      *ngIf="vm.inputType !== 'boolean'; else booleanInput"
    >
      <input
        matInput
        [disabled]="disabled"
        [ngModel]="getOptionValue(vm.key)"
        (ngModelChange)="setOptionValue(vm.key, $event, vm.definition.type)"
        [min]="vm.definition.minimum"
        [max]="vm.definition.maximum"
        [step]="vm.definition.step"
        [required]="vm.definition.required"
        (blur)="onTouched()"
        [placeholder]="getPlaceholder(vm) | translate"
        [type]="vm.inputType"
      />
    </mat-form-field>

    <ng-template #booleanInput>
      <mat-slide-toggle
        (change)="setOptionValue(vm.key, $event.checked, vm.inputType)"
        [checked]="getOptionValue(vm.key)"
        [ngClass]="{'two-lined-toggle': hasLongText(vm.key)}"
        color="primary"
      >
        <span>{{
          'CAMPAIGN_PERFORMANCE_FILTER_' + vm.key.toUpperCase() + '_LABEL'
            | translate
        }}</span>
      </mat-slide-toggle>
      <textarea matInput hidden></textarea>
    </ng-template>
  </div>
</div>
