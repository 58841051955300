<em-spinner class="m-auto" *ngIf="isLoading"></em-spinner>

<div class="block m-6" *ngIf="pagedInvoices$ | async as invoices">
  <div class="text-lg font-semibold">
    {{ 'SHARED_LABEL_OLD_INVOICES' | translate }}
  </div>
  <table
    mat-table
    [dataSource]="invoices"
    class="products-table"
    data-testid="products-table"
  >
    <!-- Product Column -->
    <ng-container matColumnDef="product">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'SHARED_LABEL_NAME' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row.product }}
      </td>
    </ng-container>

    <!-- Date Column -->
    <ng-container matColumnDef="created_at">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'SHARED_LABEL_DATE' | translate }}
      </th>
      <td class="col-md" mat-cell *matCellDef="let row">
        {{ row.created_at | date }}
      </td>
    </ng-container>

    <!-- Downlaod Column -->
    <ng-container matColumnDef="url">
      <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
      <td class="col-sm w-32" mat-cell *matCellDef="let row">
        <a [href]="row.url" target="_blank">
          <em-primary-button>{{
            'SHARED_LABEL_DOWNLOAD' | translate
          }}</em-primary-button></a
        >
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <hr />
  <mat-paginator
    [pageIndex]="currentPage$ | async"
    [length]="(allInvoices$ | async)?.length"
    [pageSize]="pageSize$ | async"
    [pageSizeOptions]="[10, 50, 100]"
    (page)="goToPage($event)"
    data-testid="products-pagination"
  ></mat-paginator>
</div>
