import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LANGUAGE_LOCALE_MAP} from '../constants/languages';
import {LanguageCode} from '@em/shared/util-types';

@Injectable({
  providedIn: 'root',
})
export class LanguageGuesserService {
  constructor(private readonly _translate: TranslateService) {}

  language(): LanguageCode {
    return (
      this._translate.getBrowserCultureLang() || LANGUAGE_LOCALE_MAP['en']
    ).split('-')[0] as LanguageCode;
  }
}
