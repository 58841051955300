<div class="grid grid-cols-2 gap-1">
  <!-- Operator -->
  <div
    *ngIf="operatorOption"
    [ngClass]="getOptionValue(operatorOption.key) !== 'eq' ? 'col-span-2' : ''"
  >
    <em-filter-item-option-input
      [filterOption]="operatorOption"
      [value]="getOptionValue(operatorOption.key)"
      [parentFilterKey]="parentFilterKey"
      [showPlaceholder]="false"
      (valueChanged)="
        OperatorChanged(
          operatorOption.key,
          $event,
          operatorOption.definition.type
        )
      "
    ></em-filter-item-option-input>
  </div>

  <!-- Value/Times -->
  <div
    *ngIf="
      valueOption &&
      operatorOption &&
      getOptionValue(operatorOption.key) === 'eq'
    "
  >
    <em-filter-item-option-input
      [filterOption]="valueOption"
      [value]="getOptionValue(valueOption.key)"
      [parentFilterKey]="parentFilterKey"
      [showPlaceholder]="false"
      (valueChanged)="
        setOptionValue(valueOption.key, $event, valueOption.definition.type)
      "
    ></em-filter-item-option-input>
  </div>
</div>
