import {Pipe, PipeTransform} from '@angular/core';

// fallback to a specific charecter if number is null or undefined, do not change Zero number
@Pipe({
  name: 'allowZero',
  standalone: true,
})
export class EmAllowZeroPipe implements PipeTransform {
  transform(
    value: number | string | null | undefined,
    fallback = '-',
  ): string | number {
    if (value === null || value === undefined) return fallback;

    return value;
  }
}
