/* tslint:disable */
/**
* This file was automatically generated.
* DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
* and run the generate-code script again.
*/
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {PostExportProductsReq} from '../types/request-objects/accelerator/post-export-products';
import {PostExportProductsResp} from '../types/view-models/accelerator/post-export-products';
import {PostProductFetchResp} from '../types/view-models/accelerator/post-product-fetch';

@Injectable({
  providedIn: 'root',
})
export class AcceleratorGateway {
  constructor(private _http: HttpClient) {}

  postExportProducts(params: PostExportProductsReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<PostExportProductsResp> {
    return this._http.post<PostExportProductsResp>('/emarketing_api/accelerator/export_products', params, options);
  }

  postProductFetch(options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<PostProductFetchResp> {
    return this._http.post<PostProductFetchResp>('/emarketing_api/accelerator/product_fetch', {}, options);
  }
}
