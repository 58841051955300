import {Directive, HostBinding, HostListener} from '@angular/core';

@Directive({
  standalone: true,
  selector: '[emSingleUse]',
})
export class EmDisabledDirective {
  private _disabled = false;

  constructor() {}

  @HostBinding('disabled') get disabled() {
    return this._disabled;
  }

  @HostBinding('class.mat-button-disabled') get addDisabledClass() {
    return this._disabled;
  }

  @HostListener('click', ['$event']) onClick(e: Event) {
    if (this._disabled) {
      e.preventDefault();
      return;
    }

    this._disabled = true;
  }
}
