import {CommonModule} from '@angular/common';
import {Component, NgModule} from '@angular/core';
import {MatDividerModule} from '@angular/material/divider';
import {MatMenuModule} from '@angular/material/menu';
import {EmButtonModule, EmCountryFlagModule, EmIconModule} from '@em/shared/ui';
import {OrderByTranslatedPipeModule} from '@em/shared/util-i18n';
import {CountryCode} from '@em/shared/util-types';
import {CountryService} from '@em/user-account/data-access';
import {TranslateModule} from '@ngx-translate/core';
import {combineLatest, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'em-country-selection',
  templateUrl: './country-selection.component.html',
  styleUrls: ['./country-selection.component.scss'],
})
export class CountrySelectionComponent {
  countries: Observable<CountryCode[]>;
  currentCountry: Observable<CountryCode>;

  constructor(private readonly _country: CountryService) {
    this.currentCountry = this._country.observable();
    this.countries = combineLatest([
      this._country.observable(),
      this._country.availableCountries(),
    ]).pipe(
      map(([currentCountry, availableCountries]) =>
        availableCountries.filter((c) => currentCountry !== c),
      ),
    );
  }

  select(countryCode: CountryCode) {
    this._country.change(countryCode);
  }

  i18nKey(countryCode: CountryCode): string {
    return `COUNTRY_${countryCode}`;
  }
}

@NgModule({
  declarations: [CountrySelectionComponent],
  exports: [CountrySelectionComponent],
  imports: [
    CommonModule,
    MatMenuModule,
    MatDividerModule,
    EmCountryFlagModule,
    TranslateModule,
    EmButtonModule,
    EmIconModule,
    OrderByTranslatedPipeModule,
  ],
})
export class CountrySelectionModule {}
