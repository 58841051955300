import {Component, ContentChildren, Input, QueryList} from '@angular/core';
import {EmOptionComponent} from '../option/option.component';

@Component({
  selector: '[emOptionGroup], em-option-group',
  template: '<ng-template #templateRef><ng-content></ng-content></ng-template>',
})
export class EmOptionGroupComponent {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() label: any | undefined;
  @Input() disabled: boolean | undefined;
  @ContentChildren(EmOptionComponent)
  options!: QueryList<EmOptionComponent>;

  constructor() {}
}
