import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {EmIconModule} from '../icon/icon.component';
import {EmWarningBoxComponent} from './warning-box.component';

@NgModule({
  imports: [CommonModule, EmIconModule],
  declarations: [EmWarningBoxComponent],
  exports: [EmWarningBoxComponent],
})
export class EmWarningBoxModule {}
