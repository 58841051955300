import {Inject, Injectable, InjectionToken} from '@angular/core';
import {IStorageDriver} from '../storage-drivers/storage-driver';

export class LocalUserDataAccessor {
  constructor(
    private readonly _dataService: LocalUserDataService,
    private readonly _key: string,
  ) {}

  get value(): string | undefined {
    return this._dataService.get(this._key) || undefined;
  }

  set value(newVal: string | undefined) {
    this._dataService.set(this._key, newVal);
  }

  remove() {
    this._dataService.remove(this._key);
  }
}

export const STORAGE_DRIVER = new InjectionToken('STORAGE_DRIVER');

@Injectable({
  providedIn: 'root',
})
export class LocalUserDataService {
  constructor(
    @Inject(STORAGE_DRIVER) private readonly _localStorage: IStorageDriver,
  ) {}

  clear() {
    this._localStorage.clear();
  }

  createAccessor(key: string) {
    return new LocalUserDataAccessor(this, key);
  }

  get(key: string): string | undefined {
    return this._localStorage.getItem(key);
  }

  set(key: string, value: string | undefined) {
    if (value === undefined) {
      this.remove(key);
    } else {
      this._localStorage.setItem(key, value);
    }
  }

  remove(key: string) {
    this._localStorage.removeItem(key);
  }

  setObj(key: string, val: unknown) {
    this._localStorage.setItem(key, JSON.stringify(val));
  }

  getObj<T>(key: string): T | null {
    const item = this.get(key);
    if (!item) {
      return null;
    }
    return JSON.parse(item);
  }
}
