import {Component, Input} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {availableCountryCodes} from '@em/shared/util-types';

@Component({
  selector: 'em-change-country',
  templateUrl: './change-country.component.html',
  styleUrls: ['./change-country.component.scss'],
})
export class ChangeCountryComponent {
  @Input() form?: UntypedFormGroup;
  readonly countries = [...availableCountryCodes];

  constructor() {}
}
