import {CommonModule} from '@angular/common';
import {Component, NgModule} from '@angular/core';
import {MATERIAL_SANITY_CHECKS} from '@angular/material/core';
import {MatIconModule} from '@angular/material/icon';

@Component({
  selector: 'em-premium-lock',
  templateUrl: './premium-lock.component.html',
  styleUrls: ['./premium-lock.component.scss'],
})
export class EmPremiumLockComponent {
  constructor() {}
}

@NgModule({
  imports: [CommonModule, MatIconModule],
  declarations: [EmPremiumLockComponent],
  exports: [EmPremiumLockComponent],
  providers: [{provide: MATERIAL_SANITY_CHECKS, useValue: false}],
})
export class EmPremiumLockModule {}
