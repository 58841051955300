import {Component, ContentChild, Input, Optional, Self} from '@angular/core';
import {NgControl} from '@angular/forms';
import {EmMaterialWrapperComponent} from '../material-wrapper/material-wrapper.component';
import {FloatLabelType} from '@angular/material/form-field';
import {EmSuffixDirective} from '../../directives/form-field/suffix.directive';
import {EmPrefixDirective} from '../../directives/form-field/prefix.directive';

@Component({
  selector: 'em-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class EmInputComponent extends EmMaterialWrapperComponent<
  string | number
> {
  @Input() label = '';
  @Input() placeholder = '';
  @Input() hint = '';
  @Input() type: 'text' | 'number' | 'password' = 'text';
  @Input() min: number | undefined;
  @Input() max: number | undefined;
  @Input() step: number | undefined;
  @Input() floatLabel: FloatLabelType = 'auto';
  @ContentChild(EmSuffixDirective) emSuffix: EmSuffixDirective | undefined;
  @ContentChild(EmPrefixDirective) emPrefix: EmPrefixDirective | undefined;

  constructor(@Optional() @Self() public override ngControl: NgControl) {
    super(ngControl);
  }

  override controlValueChanged(newValue: string | number) {
    const trueTypeValue = this.type === 'number' ? +newValue : newValue;

    if (this.onChange) {
      this.onChange(trueTypeValue);
    } else {
      this.controlValue = trueTypeValue;
    }
  }
}
