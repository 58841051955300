import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  NgModule,
} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';

export type EmIconType = 'svg' | 'default';

export type EmIconSize = 'sm' | 'md' | 'lg' | 'xl';

export type EmIconColor = 'primary' | '' | 'warn' | 'basic';

export interface EmIcon {
  name: string;
  type: 'svg' | 'default';
}

@Component({
  selector: 'em-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmIconComponent {
  @Input() size: EmIconSize = 'md';
  @Input() color: EmIconColor = '';
  @Input() set name(value: string | undefined | null) {
    if (value) {
      this.iconName = value;
    }
  }
  @Input() set type(value: EmIconType | undefined | null) {
    if (value) {
      this.iconType = value;
    }
  }
  @Input() set icon(value: EmIcon | undefined | null) {
    if (value) {
      this.iconName = value.name;
      this.iconType = value.type || 'default';
    }
  }

  @HostBinding('class') get sizeClass() {
    return 'size-' + this.size;
  }

  protected iconName: string | undefined | null;
  protected iconType: EmIconType = 'default';

  constructor() {}
}

@NgModule({
  imports: [CommonModule, MatIconModule],
  declarations: [EmIconComponent],
  exports: [EmIconComponent],
})
export class EmIconModule {}
