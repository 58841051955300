import {CommonModule} from '@angular/common';
import {Component, inject} from '@angular/core';
import {MatTableModule} from '@angular/material/table';
import {EmPrimaryButtonComponent} from '@em/shared/ui';
import {
  FeaturesGateway,
  InsightsStripeGateway,
} from '@em/subscription/api-interface';
import {TranslateModule} from '@ngx-translate/core';
import {tap} from 'rxjs';

@Component({
  selector: 'em-one-time-payments',
  templateUrl: './one-time-payments.component.html',
  styleUrls: ['./one-time-payments.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatTableModule,
    EmPrimaryButtonComponent,
  ],
})
export class EmOneTimePaymentsComponent {
  private readonly _insightsStripeGateway = inject(InsightsStripeGateway);
  private readonly _featuresGateway = inject(FeaturesGateway);

  protected readonly purchase$ = this._featuresGateway.getPurchases({});
  protected readonly displayedColumns = ['key', 'created_at', 'url'];

  openStripePortal(payment_id: string) {
    this._insightsStripeGateway
      .getPortal({payment_id})
      .pipe(tap((response) => (window.location.href = response.stripe.url)))
      .subscribe();
  }
}
