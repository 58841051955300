<div class="options">
  <div class="option" *ngFor="let vm of vms; trackBy: trackByFn">
    <mat-form-field
      class="form-field-sm w-full"
      *ngIf="vm.definition.selectableValues.length > 0"
    >
      <mat-select
        [ngModel]="getOptionValue(vm.key)"
        (ngModelChange)="setOptionValue(vm.key, $event, vm.inputType)"
        [required]="vm.definition.required"
      >
        <mat-option
          *ngFor="let val of vm.definition.selectableValues"
          [value]="val"
        >
          {{ i18nDropdownKey(val) | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="option">
    <mat-slide-toggle
      (change)="
        setOptionValue('shipping_costs_excluded', $event.checked, 'boolean')
      "
      [checked]="getOptionValue('shipping_costs_excluded')"
      color="primary"
    >
      <span translate
        >CAMPAIGN_PERFORMANCE_FILTER_MODE_PRICE_EXCLUDE_SHIPPING</span
      >
    </mat-slide-toggle>
    <textarea hidden matInput></textarea>
  </div>

  <div class="option">
    <mat-slide-toggle
      (change)="setOptionValue('exclude_products', $event.checked, 'boolean')"
      [checked]="getOptionValue('exclude_products')"
      class="two-lined-toggle"
      color="primary"
    >
      <span>{{
        'CAMPAIGN_PERFORMANCE_FILTER_EXCLUDE_PRODUCTS_LABEL' | translate
      }}</span>
    </mat-slide-toggle>
  </div>
</div>
