import {Injectable} from '@angular/core';
import Pusher, {Channel} from 'pusher-js';
import {Observable, of, Subject} from 'rxjs';
import {take} from 'rxjs/operators';
import {NotificationKey} from '@em/shared/api-interface';
import {IInitializable} from '@em/shared/util-types';
import {SessionService} from '@em/auth/data-access';
import {TokenStorageService} from '@em/auth/data-access';
import {PusherFactory} from './pusher.factory';

const PUSHER_EVENTS = {
  notificationEvent: 'new-notification',
};

export interface IPusherNotification {
  key: NotificationKey;
  owner_uuid: string;
  workflow_uuid: string;
  failed?: boolean;

  [key: string]: unknown | string | boolean | undefined;
}

@Injectable({
  providedIn: 'root',
})
export class PusherService implements IInitializable {
  readonly name = 'PusherService';
  private _socket?: Pusher;
  private _channel?: Channel;
  private readonly _notifications = new Subject<IPusherNotification>();

  constructor(
    private readonly _session: SessionService,
    private readonly _tokenStorage: TokenStorageService,
    private readonly _pusherFactory: PusherFactory,
  ) {}

  initialize(): Observable<void> {
    this._session
      .observable()
      .pipe(take(1))
      .subscribe(() => {
        this._initializePusher();
        this._subscribeChannel();
        this._bindEvents();
      });

    return of(undefined);
  }

  notifications(): Observable<IPusherNotification> {
    return this._notifications;
  }

  private _initializePusher() {
    this._socket = this._pusherFactory.build();
  }

  private _subscribeChannel() {
    const decodedToken = this._tokenStorage.decodedToken;
    if (!decodedToken || !this._socket) return;

    const channelName = `private-${decodedToken.merchant_uuid}`;
    this._channel = this._socket.subscribe(channelName);
  }

  private _bindEvents() {
    if (!this._channel) return;

    this._channel.bind(
      PUSHER_EVENTS.notificationEvent,
      (data: IPusherNotification) => {
        this._notifications.next(data);
      },
    );
  }
}
