import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MATERIAL_SANITY_CHECKS} from '@angular/material/core';
import {MatCheckboxModule as MatCheckboxModule} from '@angular/material/checkbox';
import {EmCheckboxComponent} from './checkbox.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

@NgModule({
  imports: [CommonModule, MatCheckboxModule, FormsModule, ReactiveFormsModule],
  declarations: [EmCheckboxComponent],
  exports: [EmCheckboxComponent],
  providers: [{provide: MATERIAL_SANITY_CHECKS, useValue: false}],
})
export class EmCheckboxModule {}
