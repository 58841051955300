import {CommonModule} from '@angular/common';
import {Component} from '@angular/core';
import {EmIconModule} from '@em/shared/ui';
import {IHeaderGroupAngularComp} from 'ag-grid-angular';
import {IHeaderGroupParams} from 'ag-grid-community';

@Component({
  selector: 'em-custom-header-group',
  standalone: true,
  imports: [CommonModule, EmIconModule],
  templateUrl: './custom-header-group.component.html',
  styles: [],
})
export class CustomHeaderGroupComponent implements IHeaderGroupAngularComp {
  params!: IHeaderGroupParams & {logo?: string; icon?: string};
  expandState!: string;

  agInit(params: IHeaderGroupParams): void {
    this.params = params;
  }
}
