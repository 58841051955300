import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  standalone: true,
  name: 'formatAdwordsId',
})
export class EmFormatAdwordsIdPipe implements PipeTransform {
  transform(value: number | null | undefined): string {
    if (value === undefined || value === null) {
      return '';
    }

    const parts = value.toString().match(/(\d\d\d)(\d\d\d)(\d\d\d\d)/);

    if (!parts) {
      return `${value}`;
    }

    return `${parts[1]}-${parts[2]}-${parts[3]}`;
  }
}
