import {AddProductGroupReq} from '@em/shared/api-interface';
import {CountryCode} from '@em/shared/util-types';
import {IProductGroupTemplate} from '../group-templates/group-templates';
import {
  ConnectItemType,
  ConnectLogicalOperator,
} from '../../types/filtering-types';

export function buildGroupFromTemplate(
  country: CountryCode,
  template: IProductGroupTemplate,
): AddProductGroupReq {
  return {
    country,
    name: template.name,
    selected_filters: template.selectedFilters,
    manually_added_products: [],
    manually_removed_products: [],
    links: [
      {
        parent_uuid: null,
        operator: ConnectLogicalOperator.AND,
        type: ConnectItemType.GROUP,
      },
    ],
    is_google_spd: false,
    is_price_crawl: template.isPriceCrawl || false,
  } as AddProductGroupReq;
}
