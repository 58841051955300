/* tslint:disable */
/**
* This file was automatically generated.
* DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
* and run the generate-code script again.
*/
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DeleteReq as DeleteProductExportsReq} from '../types/request-objects/product-exports/delete';
import {GetColumnsReq} from '../types/request-objects/product-exports/get-columns';
import {GetColumnsResp} from '../types/view-models/product-exports/get-columns';
import {GetListReq} from '../types/request-objects/product-exports/get-list';
import {GetListResp} from '../types/view-models/product-exports/get-list';
import {GetReq as GetProductExportsReq} from '../types/request-objects/product-exports/get';
import {GetResp as GetProductExportsResp} from '../types/view-models/product-exports/get';
import {PostReq as PostProductExportsReq} from '../types/request-objects/product-exports/post';
import {PostResp as PostProductExportsResp} from '../types/view-models/product-exports/post';

@Injectable({
  providedIn: 'root',
})
export class ProductExportsGateway {
  constructor(private _http: HttpClient) {}

  deleteProductExports(params: DeleteProductExportsReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<undefined> {
    const request = params as any as { [param: string]: string | string[]; };
    return this._http.delete<undefined>('/emarketing_api/product_exports/', {...options, params: request});
  }

  getColumns(params: GetColumnsReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<GetColumnsResp> {
    const request = params as any as { [param: string]: string | string[]; };
    return this._http.get<GetColumnsResp>('/emarketing_api/product_exports/columns', {...options, params: request});
  }

  getList(params: GetListReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<GetListResp> {
    const request = params as any as { [param: string]: string | string[]; };
    return this._http.get<GetListResp>('/emarketing_api/product_exports/list', {...options, params: request});
  }

  getProductExports(params: GetProductExportsReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<GetProductExportsResp> {
    const request = params as any as { [param: string]: string | string[]; };
    return this._http.get<GetProductExportsResp>('/emarketing_api/product_exports/', {...options, params: request});
  }

  postProductExports(params: PostProductExportsReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<PostProductExportsResp> {
    return this._http.post<PostProductExportsResp>('/emarketing_api/product_exports/', params, options);
  }
}
