import {configurationHelper} from '@em/shared/util-configuration';
import * as Sentry from '@sentry/angular-ivy';

import {sentryConfig} from './sentry-config';

export function initializeSentry() {
  Sentry.init({
    dsn: configurationHelper.getConfig('PUBLIC_SENTRY_DSN') as string,
    ...sentryConfig(configurationHelper.getConfig('SOURCE_VERSION')),
  });
}
