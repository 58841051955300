import {NumberFilterModel} from 'ag-grid-community';
import {FilterModel} from '../../../filter-model';
import {BooleanFilterType} from '../boolean-filter-type';
import {ISimpleFilterModelType} from 'ag-grid-community/dist/lib/filter/provided/simpleFilter';

export function isBooleanFilter(
  filterMode: FilterModel<ISimpleFilterModelType | BooleanFilterType>,
): filterMode is NumberFilterModel {
  return (
    filterMode.type === 'eq_true' ||
    filterMode.type === 'eq_false' ||
    filterMode.type === 'boolean_blank' ||
    filterMode.type === 'boolean_notBlank'
  );
}
