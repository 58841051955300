import {CommonModule} from '@angular/common';
import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {CategoriesGateway} from '@em/shared/api-interface/lib/gateways/categories.gateway';
import {EmButtonModule} from '@em/shared/ui';
import {CountryCode, ITypedControlValueAccessor} from '@em/shared/util-types';
import {MillerColumnsComponent} from '../miller-columns/miller-columns.component';
import {ProductCategoryModel} from '../miller-columns/product-category.model';
import {ProductFilterOverlayComponent} from '../product-filter-overlay/product-filter-overlay.component';
import {TranslateModule} from '@ngx-translate/core';
import {ChangesObj} from '@em/shared/util-types';

@Component({
  selector: 'em-category-selection',
  templateUrl: './category-selection.component.html',
  styleUrls: ['./category-selection.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ProductFilterOverlayComponent,
    MillerColumnsComponent,
    EmButtonModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CategorySelectionComponent),
      multi: true,
    },
  ],
})
export class CategorySelectionComponent
  implements ITypedControlValueAccessor<string[]>, OnChanges
{
  @Input() countryCode?: CountryCode | null;
  @Output() backClick = new EventEmitter<void>();
  disabled = false;
  availableCategories: ProductCategoryModel[] = [];
  private _currentValues: string[] = [];
  private _onChangeCallback?: (val: string[] | null | undefined) => void;
  private _onTouchedCallback?: () => void;

  constructor(private readonly _categoriesGateway: CategoriesGateway) {}

  private _selectedCategories: ProductCategoryModel[] = [];

  get selectedCategories(): ProductCategoryModel[] {
    return this._selectedCategories;
  }

  set selectedCategories(val: ProductCategoryModel[]) {
    this._selectedCategories = val;
    this._currentValues = val.map((c) => c.name);

    this.onTouched();
    if (this._onChangeCallback) this._onChangeCallback(this._currentValues);
  }

  ngOnChanges(
    changes: ChangesObj<CategorySelectionComponent, 'countryCode'>,
  ): void {
    if (changes.countryCode) {
      if (changes.countryCode.currentValue) {
        this._loadCategories(changes.countryCode.currentValue);
      } else {
        this.availableCategories = [];
      }
    }
  }

  writeValue(obj: string[] | null | undefined): void {
    this._currentValues = obj || [];

    this._updateFromPatchValue();
  }

  registerOnChange(fn: (val: string[] | null | undefined) => void): void {
    this._onChangeCallback = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouchedCallback = fn;
  }

  onTouched() {
    if (this._onTouchedCallback) this._onTouchedCallback();
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  openEditFilterSet() {
    this.backClick.emit();
  }

  private _loadCategories(country: CountryCode) {
    this._categoriesGateway.getCategories({country}).subscribe((resp) => {
      this.availableCategories = resp.map(
        (itm) =>
          new ProductCategoryModel(
            itm.category,
            itm.conversions || 0,
            itm.product_count,
          ),
      );

      this._updateFromPatchValue();
    });
  }

  private _updateFromPatchValue() {
    if (
      !this.countryCode ||
      !this.availableCategories.length ||
      !this._currentValues.length
    ) {
      return;
    }

    const preselectedCategories = this._currentValues;
    this._selectedCategories = this.availableCategories.filter((c) =>
      preselectedCategories.includes(c.name),
    );
  }
}
