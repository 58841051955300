<div class="m-8" *ngIf="isStripe$ | async; else checkShopify">
  <em-error-box class="mb-4" *ngIf="showNoStripeAccount">
    <ng-container description
      >You don't have stipe account with us yet</ng-container
    >
  </em-error-box>

  <div class="text-center mb-8">
    {{ 'STRIPE_PORTAL_ENTRY_DESC' | translate }}:
  </div>

  <div class="text-center">
    <em-primary-button *ngIf="!isLoading" (btnClick)="openStripePortal()">{{
      'STRIPE_PORTAT_ENTLRY_BTN_LABEL' | translate
    }}</em-primary-button>

    <em-spinner *ngIf="isLoading" class="m-auto"></em-spinner>
  </div>
</div>

<ng-template #checkShopify>
  <div class="m-8" *ngIf="isShopify$ | async">
    <div class="text-center m-auto mb-8 max-w-3xl">
      {{ 'SHOPIFY_PORTAL_ENTRY_DESC' | translate }}
    </div>

    <div class="text-center">
      <a href="https://admin.shopify.com/">
        <em-primary-button>{{
          'SHOPIFY_PORTAL_ENTRY_BTN_LABEL' | translate
        }}</em-primary-button>
      </a>
    </div>
  </div>
</ng-template>
