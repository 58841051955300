import {CommonModule, DecimalPipe} from '@angular/common';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatDateFnsModule} from '@angular/material-date-fns-adapter';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatMenuModule} from '@angular/material/menu';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {
  ErrorInterceptor,
  RetryForStatusCodesInterceptor,
} from '@em/shared/util-error-handling';
import {
  TranslateCountryPipe,
  TranslateCountryPipeModule,
} from '@em/shared/util-i18n';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';

import {SessionService} from '@em/auth/data-access';

import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import {
  initialSetupState,
  setupReducer,
  SetupStatusEffects,
} from '@em/auth/data-access';
import {AdblockInterceptor} from '@em/shared/api-interface';
import {EmAllowZeroPipe} from '@em/shared/ui';
import {
  DistanceFromNowModule,
  DistanceFromNowPipe,
  getSelectedLocale,
  RelativeToNowModule,
  RelativeToNowPipe,
} from '@em/shared/util-date-time';
import {PaymentInterceptor} from '@em/subscription/feature';
import {APIDomainInterceptor} from '@em/shared/util-configuration';
import {INITIALIZABLE} from '@em/shared/util-types';
import {IntercomService} from '../support/intercom.service';
import {
  CountryService,
  LanguageService,
  merchantReducer,
  initialMerchantState,
} from '@em/user-account/data-access';
import {NotificationsService} from '@em/server-notifications';
import {HttpErrorMessageInterceptor} from '../error-handling/http-error-message.interceptor';
@NgModule({
  imports: [
    CommonModule,
    MatSnackBarModule,
    MatMenuModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    FormsModule,
    ReactiveFormsModule,
    StoreModule.forFeature('merchant', merchantReducer, {
      initialState: initialMerchantState,
    }),
    StoreModule.forFeature('setup', setupReducer, {
      initialState: initialSetupState,
    }),
    EffectsModule.forFeature([SetupStatusEffects]),
    TranslateCountryPipeModule,
    EmAllowZeroPipe,
    DistanceFromNowModule,
    RelativeToNowModule,
    MatDateFnsModule,
  ],
  exports: [
    CommonModule,
    EmAllowZeroPipe,
    TranslateCountryPipe,
    RelativeToNowPipe,
    DistanceFromNowPipe,
  ],
  providers: [DecimalPipe],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AdblockInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          multi: true,
          useClass: ErrorInterceptor,
        },
        {
          provide: HTTP_INTERCEPTORS,
          multi: true,
          useClass: HttpErrorMessageInterceptor,
        },
        {
          provide: HTTP_INTERCEPTORS,
          multi: true,
          useClass: PaymentInterceptor,
        },
        {
          provide: HTTP_INTERCEPTORS,
          multi: true,
          useClass: APIDomainInterceptor,
        },
        {
          provide: HTTP_INTERCEPTORS,
          multi: true,
          useValue: new RetryForStatusCodesInterceptor({retryCount: 2}),
        },
        {
          provide: INITIALIZABLE,
          multi: true,
          useExisting: SessionService,
        },
        {
          provide: INITIALIZABLE,
          multi: true,
          useExisting: LanguageService,
        },
        {
          provide: INITIALIZABLE,
          multi: true,
          useExisting: CountryService,
        },
        {
          provide: INITIALIZABLE,
          multi: true,
          useExisting: IntercomService,
        },
        {
          provide: INITIALIZABLE,
          multi: true,
          useExisting: NotificationsService,
        },
        {
          provide: MAT_DATE_LOCALE,
          useFactory: getSelectedLocale,
        },
      ],
    };
  }
}
