import {DecimalPipe} from '@angular/common';
import {Pipe, PipeTransform} from '@angular/core';
import {CURRENCY_SYMBOLS, IMoney} from '@em/shared/util-types';

@Pipe({
  standalone: true,
  name: 'withCurrency',
})
export class EmWithCurrencyPipe implements PipeTransform {
  constructor(private readonly _decimalPipe: DecimalPipe) {}

  transform(
    value: undefined | null | IMoney,
    fractionSize?: null | number | string,
  ): string {
    if (value === undefined || value === null) {
      return '-';
    }

    const currencySymbol = CURRENCY_SYMBOLS[value.currencyCode] || '';

    if (
      fractionSize === undefined ||
      fractionSize === null ||
      fractionSize === ''
    ) {
      fractionSize = 2;
    }

    // \u00A0 => non-breaking space. prevents wrapping between value & unit
    return `${this._decimalPipe.transform(
      value.value,
      `.${fractionSize}-${fractionSize}`,
    )}\u00A0${currencySymbol}`;
  }
}
