import {GetSetupStatusResp} from '@em/shared/api-interface/lib/types/view-models/merchants/get-setup-status';
import {CountryCode} from '@em/shared/util-types';
import {Contract} from '@em/subscription/api-interface';

export interface ISetupStatus {
  readonly loaded: boolean;
  readonly hasAutomaticCampaigns: boolean;
  readonly merchantCenter: IMerchantCenterStatus;
  readonly datafeed: IDatafeedStatus;
  readonly repricing: IRepricingStatus;
  readonly merchant: IMerchantStatus;
  readonly contract: Contract | null;
  readonly hasGoogleSPD: boolean;
  readonly facebookSetup: IFacebookSetupStastus;
}

export interface ISubscriptionStatus {
  hasValidSubscription: boolean;
  planDetails: IPlanDetails;
}

export interface IPlanDetails {
  type: string;
  name: string;
  productLimit: number;
  frequency: number;
  includesCampaigns?: boolean;
  includesRepricing?: boolean;
}

export interface IMerchantStatus {
  hasEmail: boolean;
  hasGoogleOAuth: boolean;
  hasAdwordsConnection: boolean;
  hasMerchantCenter: boolean;
  hasFullSetup: boolean;
}

export interface IDatafeedProductsCounts {
  total: number;
  approved: number;
  pending: number;
  product: number;
  disapproved: number;
}

export interface IRepricingStatus extends IFeatureStatus {
  canConnect: boolean;
}

export interface IDatafeedStatus extends IFeatureStatus {
  canConnect: boolean;
  hasProductFetch: boolean;
}

export interface IFeatureStatus {
  isSetup: boolean;
}

export interface IFacebookSetupStastus {
  hasFacebookAccount: boolean;
  hasFacebookAuth: boolean;
  hasFullSetup: boolean;
}

export interface IMerchantCenterStatus {
  availableCountries: CountryCode[];
}

export class SetupStatusBuilder {
  static build(response: GetSetupStatusResp): ISetupStatus {
    return {
      loaded: true,
      contract: response.emarketing_plan_details as Contract,
      merchant: {
        hasEmail: response.has_email,
        hasGoogleOAuth: response.has_google_oauth,
        hasAdwordsConnection: response.has_adwords_connection,
        hasMerchantCenter: !!response.has_merchant_center_connection,
        hasFullSetup:
          !!response.has_google_oauth &&
          !!response.has_adwords_connection &&
          !!response.has_merchant_center_connection,
      },

      merchantCenter: {
        availableCountries: Array.from(response.available_countries).sort(),
      },

      hasAutomaticCampaigns: response.automatic_campaign_count > 0,

      // DataFeedSetup now has 3 states:
      // null  -> No `Connection` is available. Everything is fresh
      // false -> `Connection` has been created. DataFeed can communicate to us with ShopToken (which is now available).
      // true  -> Everything is completely set up.
      datafeed: {
        isSetup: response.has_datafeed_setup === true,
        canConnect: response.has_datafeed_setup !== null,
        hasProductFetch: response.has_product_fetch,
      },

      // Repricing now has 3 states:
      // null  -> No `Connection` is available. Everything is fresh
      // false -> `Connection` has been created. Repricing can communicate to us with ShopToken (which is now available).
      // true  -> Everything is completely set up.
      repricing: {
        isSetup: response.has_repricing_setup === true,
        canConnect: response.has_repricing_setup !== null,
      },

      hasGoogleSPD: response.has_google_spd || false,
      facebookSetup: {
        hasFacebookAccount: response.has_facebook_account || false,
        hasFacebookAuth: response.has_facebook_setup || false,
        hasFullSetup:
          !!response.has_facebook_account && !!response.has_facebook_setup,
      },
    };
  }
}
