/* tslint:disable */
/**
* This file was automatically generated.
* DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
* and run the generate-code script again.
*/
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {GetCategoriesReq} from '../types/request-objects/get-categories';
import {GetCategoriesResp} from '../types/view-models/get-categories';

@Injectable({
  providedIn: 'root',
})
export class CategoriesGateway {
  constructor(private _http: HttpClient) {}

  getCategories(params: GetCategoriesReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<GetCategoriesResp> {
    const request = params as any as { [param: string]: string | string[]; };
    return this._http.get<GetCategoriesResp>('/emarketing_api/categories', {...options, params: request});
  }
}
