import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {EmSubscriptionPageComponent} from './subscription-page/subscription-page.component';
import {EmStripePaymentStatusPageComponent} from './stripe/stripe-payment-status-page/stripe-payment-status-page.component';
import {EmShopifyPaymentStatusPageComponent} from './shopify/shopify-payment-status-page/shopify-payment-status-page.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'subscription',
  },
  {
    path: 'stripe/status',
    component: EmStripePaymentStatusPageComponent,
  },
  {
    path: 'shopify/status',
    component: EmShopifyPaymentStatusPageComponent,
  },
  {
    path: ':tab',
    component: EmSubscriptionPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SubscriptionRoutingModule {}
