import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {PipelineSetupStore} from '../pipeline-setup/pipeline-setup.store';
import {
  CsvColSeparator,
  CsvQuote,
  CsvRowSeparator,
} from '@em/shared/api-interface';

export type CsvColSeparatorForSource = CsvColSeparator | 'auto';
export interface CsvSourceOptions {
  url: string;
  col_sep: CsvColSeparatorForSource;
  row_sep: CsvRowSeparator;
  quote_char: CsvQuote;
}
@Injectable()
export class CsvSourceSetupStore {
  readonly CsvSourceOptions$: Observable<CsvSourceOptions | undefined> =
    this._pipelineSetupStore.pipelineOptions$.pipe(
      map((options) => options as unknown as CsvSourceOptions | undefined),
    );

  readonly saved$ = this._pipelineSetupStore.pipelineSaved$;

  constructor(private readonly _pipelineSetupStore: PipelineSetupStore) {}

  saveOptions(options: Partial<CsvSourceOptions>) {
    this._pipelineSetupStore.updatePipelineOptions({...options});
    this._pipelineSetupStore.savePipeline();
  }
}
