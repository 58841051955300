import {
  UntypedFormControl,
  FormGroupDirective,
  NgForm,
  AbstractControl,
  NgControl,
} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';

export class EmInputErroStateMatcher implements ErrorStateMatcher {
  constructor(private readonly inputComponent: {ngControl?: NgControl}) {}
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null,
  ): boolean {
    const isSubmitted = form && form.submitted;
    const componentControl: AbstractControl | null | undefined =
      this.inputComponent.ngControl?.control;
    return !!(
      componentControl &&
      componentControl.invalid &&
      (componentControl.touched || isSubmitted)
    );
  }
}
