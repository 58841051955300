import {NotificationKey, NotificationRespType} from '@em/shared/api-interface';
import {NotificationModel} from '../notification.model';
import {IPusherNotification} from '../pusher-service/pusher.service';

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type payload = {name: string; competitor_count: number; source: string};
const sourceNames: {[key: string]: string} = {
  'google-shopping': 'Google Shopping',
  amazon: 'Amazon',
  idealo: 'Idealo',
};

export class PriceFetchNotification extends NotificationModel {
  static key: NotificationKey =
    'MarketInsights::Domain::Notifications::PricesFetched';
  readonly name: string;
  readonly competitorCount: number;
  readonly source: string;

  constructor(
    createdAt: Date,
    workflowUuid: string,
    name: string,
    competitorCount: number,
    source: string,
  ) {
    super(createdAt, PriceFetchNotification.key, workflowUuid);
    this.name = name;
    this.competitorCount = competitorCount;
    this.source = sourceNames[source] || '';
  }

  static priceFetchFromPusher(
    resp: IPusherNotification,
  ): PriceFetchNotification {
    return new PriceFetchNotification(
      resp['created_at'] ? new Date(resp['created_at'] as string) : new Date(),
      resp['workflow_uuid'],
      resp['name'] as string,
      Number.parseInt(resp['competitor_count'] as string, 10),
      resp['source'] as string,
    );
  }

  static priceFetchFromResp(
    resp: NotificationRespType,
  ): PriceFetchNotification {
    return new PriceFetchNotification(
      resp['created_at'] ? new Date(resp['created_at'] as string) : new Date(),
      resp.workflow_uuid,
      (resp.payload as payload).name,
      (resp.payload as payload).competitor_count,
      (resp.payload as payload).source,
    );
  }
}
