import {CountryCode} from './countries';

export type CurrencyCode =
  | 'AED'
  | 'ARS'
  | 'AUD'
  | 'BGN'
  | 'BND'
  | 'BOB'
  | 'BRL'
  | 'CAD'
  | 'CHF'
  | 'CLP'
  | 'CNY'
  | 'COP'
  | 'CSD'
  | 'CZK'
  | 'DEM'
  | 'DKK'
  | 'EEK'
  | 'EGP'
  | 'EUR'
  | 'FJD'
  | 'FRF'
  | 'GBP'
  | 'HKD'
  | 'HRK'
  | 'HUF'
  | 'IDR'
  | 'ILS'
  | 'INR'
  | 'JOD'
  | 'JPY'
  | 'KES'
  | 'KRW'
  | 'LKR'
  | 'LTL'
  | 'MAD'
  | 'MTL'
  | 'MXN'
  | 'MYR'
  | 'NGN'
  | 'NOK'
  | 'NZD'
  | 'PEN'
  | 'PHP'
  | 'PKR'
  | 'PLN'
  | 'ROL'
  | 'RON'
  | 'RSD'
  | 'RUB'
  | 'SAR'
  | 'SEK'
  | 'SGD'
  | 'SIT'
  | 'SKK'
  | 'THB'
  | 'TND'
  | 'TRL'
  | 'TRY'
  | 'TWD'
  | 'UAH'
  | 'USD'
  | 'UYU'
  | 'VEB'
  | 'VEF'
  | 'VND'
  | 'ZAR';

export const CURRENCY_SYMBOLS: {[P in CurrencyCode]?: string} = {
  EUR: '€',
  GBP: '£',
  USD: '$',
  AED: 'د.إ',
  ARS: 'AR$',
  AUD: '$',
  BGN: 'лв',
  BND: '$',
  BOB: 'Bs.',
  BRL: 'R$',
  CAD: '$',
  CHF: 'Fr.',
  CLP: '$',
  CNY: '¥',
  COP: '$',
  CSD: undefined,
  CZK: 'Kč',
  DEM: undefined,
  DKK: 'kr',
  EEK: 'kr',
  EGP: '£',
  FJD: '$',
  FRF: undefined,
  HKD: '$',
  HRK: 'kn',
  HUF: 'Ft',
  IDR: 'Rp',
  ILS: '₪',
  INR: '₹',
  JOD: 'JD',
  JPY: '¥',
  KES: 'KSh',
  KRW: '₩',
  LKR: '₨',
  LTL: 'Lt',
  MAD: 'MAD',
  MTL: undefined,
  MXN: '$',
  MYR: 'RM',
  NGN: '₦',
  NOK: 'kr',
  NZD: '$',
  PEN: 'S/.',
  PHP: '₱',
  PKR: '₨',
  PLN: 'zł',
  ROL: undefined,
  RON: 'lei',
  RSD: 'Дин.',
  RUB: '₽',
  SAR: '﷼',
  SEK: 'kr',
  SGD: '$',
  SIT: undefined,
  SKK: undefined,
  THB: '฿',
  TND: 'د.ت',
  TRL: '₤',
  TRY: '₺',
  TWD: 'NT$',
  UAH: '₴',
  UYU: '$U',
  VEB: undefined,
  VEF: 'Bs',
  VND: '₫',
  ZAR: 'R',
};

export const CURRENCY_NAMES: {[P in CurrencyCode]?: string} = {
  EUR: 'Euros',
  GBP: 'British Pounds Sterling',
  USD: 'US Dollars',
  AED: 'United Arab Emirates Dirham',
  ARS: 'Argentine Peso',
  AUD: 'Australian Dollars',
  BGN: 'Bulgarian Lev',
  BND: 'Brunei Dollar',
  BOB: 'Bolivian Boliviano',
  BRL: 'Brazilian Real',
  CAD: 'Canadian Dollars',
  CHF: 'Swiss Francs',
  CLP: 'Chilean Peso',
  CNY: 'Yuan Renminbi',
  COP: 'Colombian Peso',
  CSD: 'Old Serbian Dinar',
  CZK: 'Czech Koruna',
  DEM: 'Deutsche Marks',
  DKK: 'Denmark Kroner',
  EEK: 'Estonian Kroon',
  EGP: 'Egyptian Pound',
  FJD: 'Fiji Dollar',
  FRF: 'French Franks',
  HKD: 'Hong Kong Dollars',
  HRK: 'Croatian Kuna',
  HUF: 'Hungarian Forint',
  IDR: 'Indonesian Rupiah',
  ILS: 'Israeli Shekel',
  INR: 'Indian Rupee',
  JOD: 'Jordanian Dinar',
  JPY: 'Japanese Yen',
  KES: 'Kenyan Shilling',
  KRW: 'South Korean Won',
  LKR: 'Sri Lankan Rupee',
  LTL: 'Lithuanian Litas',
  MAD: 'Moroccan Dirham',
  MTL: 'Maltese Lira',
  MXN: 'Mexico Peso',
  MYR: 'Malaysian Ringgit',
  NGN: 'Nigerian Naira',
  NOK: 'Norway Kroner',
  NZD: 'New Zealand Dollars',
  PEN: 'Peruvian Nuevo Sol',
  PHP: 'Philippine Peso',
  PKR: 'Pakistan Rupee',
  PLN: 'Polish New Zloty',
  ROL: 'Romanian Leu',
  RON: 'New Romanian Leu',
  RSD: 'Serbian Dinar',
  RUB: 'Russian Rouble',
  SAR: 'Saudi Riyal',
  SEK: 'Sweden Kronor',
  SGD: 'Singapore Dollars',
  SIT: 'Slovenian Tolar',
  SKK: 'Slovak Koruna',
  THB: 'Thai Baht',
  TND: 'Tunisian Dinar',
  TRL: 'Turkish Lira',
  TRY: 'New Turkish Lira',
  TWD: 'New Taiwan Dollar',
  UAH: 'Ukrainian Hryvnia',
  UYU: 'Uruguayan peso',
  VEB: 'Venezuela Bolivar',
  VEF: 'Venezuela Bolivar Fuerte',
  VND: 'Vietnamese Dong',
  ZAR: 'South African Rand',
};

export const currencyCodeByCountryCode: {[countryCode: string]: CurrencyCode} =
  {
    BE: 'EUR',
    BG: 'BGN',
    BL: 'EUR',
    BN: 'BND',
    BO: 'BOB',
    BV: 'NOK',
    BQ: 'USD',
    BR: 'BRL',
    JE: 'GBP',
    RU: 'RUB',
    RS: 'RSD',
    TL: 'USD',
    RE: 'EUR',
    RO: 'RON',
    TK: 'NZD',
    GU: 'USD',
    GS: 'GBP',
    GR: 'EUR',
    GP: 'EUR',
    JP: 'JPY',
    GG: 'GBP',
    GF: 'EUR',
    GB: 'GBP',
    SV: 'USD',
    GL: 'DKK',
    TN: 'TND',
    JO: 'JOD',
    HR: 'HRK',
    HU: 'HUF',
    HK: 'HKD',
    HM: 'AUD',
    VE: 'VEF',
    PR: 'USD',
    PS: 'ILS',
    PW: 'USD',
    PT: 'EUR',
    SJ: 'NOK',
    PE: 'PEN',
    PK: 'PKR',
    PH: 'PHP',
    PN: 'NZD',
    PL: 'PLN',
    PM: 'EUR',
    EH: 'MAD',
    EE: 'EUR',
    EG: 'EGP',
    ZA: 'ZAR',
    EC: 'USD',
    IT: 'EUR',
    VN: 'VND',
    SA: 'SAR',
    ES: 'EUR',
    ME: 'EUR',
    MF: 'EUR',
    MA: 'MAD',
    MC: 'EUR',
    MH: 'USD',
    MT: 'EUR',
    MQ: 'EUR',
    MP: 'USD',
    IM: 'GBP',
    MY: 'MYR',
    MX: 'MXN',
    IL: 'ILS',
    FR: 'EUR',
    IO: 'USD',
    FI: 'EUR',
    FJ: 'FJD',
    FM: 'USD',
    FO: 'DKK',
    NL: 'EUR',
    NO: 'NOK',
    NF: 'AUD',
    NG: 'NGN',
    NZ: 'NZD',
    NR: 'AUD',
    NU: 'NZD',
    CK: 'NZD',
    XK: 'EUR',
    CH: 'CHF',
    CO: 'COP',
    CN: 'CNY',
    CL: 'CLP',
    CC: 'AUD',
    CA: 'CAD',
    CZ: 'CZK',
    CY: 'EUR',
    CX: 'AUD',
    KE: 'KES',
    KI: 'AUD',
    SK: 'EUR',
    KR: 'KRW',
    SI: 'EUR',
    SM: 'EUR',
    SG: 'SGD',
    SE: 'SEK',
    DK: 'DKK',
    VG: 'USD',
    DE: 'EUR',
    US: 'USD',
    UY: 'UYU',
    YT: 'EUR',
    UM: 'USD',
    TV: 'AUD',
    TW: 'TWD',
    TR: 'TRY',
    LK: 'LKR',
    LI: 'CHF',
    LV: 'EUR',
    LT: 'LTL',
    LU: 'EUR',
    TH: 'THB',
    TF: 'EUR',
    TC: 'USD',
    VA: 'EUR',
    AE: 'AED',
    AD: 'EUR',
    VI: 'USD',
    AS: 'USD',
    AR: 'ARS',
    AU: 'AUD',
    AT: 'EUR',
    IN: 'INR',
    AX: 'EUR',
    IE: 'EUR',
    ID: 'IDR',
    UA: 'UAH',
  };

export function currencyForCountry(countryCode: CountryCode): CurrencyCode {
  return currencyCodeByCountryCode[countryCode];
}
