import {CommonModule} from '@angular/common';
import {Component, Input, NgModule} from '@angular/core';
import {EmCardModule} from '../card/card.module';
import {EmSpinnerModule} from '../spinner/spinner.module';

@Component({
  selector: 'em-dashboard-kpi-card',
  templateUrl: './dashboard-kpi-card.component.html',
  styleUrls: ['./dashboard-kpi-card.component.scss'],
})
export class DashboardKpiCardComponent {
  @Input() isLoading: boolean | null | undefined;

  constructor() {}
}

@NgModule({
  declarations: [DashboardKpiCardComponent],
  exports: [DashboardKpiCardComponent],
  imports: [CommonModule, EmCardModule, EmSpinnerModule],
})
export class DashboardKpiCardModule {}
