import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {configurationHelper} from '@em/shared/util-configuration';
import {combineLatest, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MicrosoftLinkGeneratorService {
  constructor() {}

  getLoginLink(): string {
    const baseUrl =
      'https://login.microsoftonline.com/common/oauth2/v2.0/authorize';

    const params = new HttpParams()
      .set(
        'client_id',
        configurationHelper.getConfig('BING_CLIENT_ID') as string,
      )
      .set('response_type', 'code')
      .set(
        'redirect_uri',
        configurationHelper.getConfig('BING_REDIRECT_URL') as string,
      )
      .set('response_mode', 'query')
      .set(
        'scope',
        configurationHelper.getConfig('BING_CLIENT_SCOPE') as string,
      );

    return baseUrl + '?' + params.toString();
  }

  getMerchantCenterLink(
    customerId$: Observable<string | number | null | undefined>,
    accountId$: Observable<string | number | null | undefined>,
  ): Observable<string>;
  getMerchantCenterLink(
    customerId: string | number,
    merchantCenterId: string | number,
  ): string;
  getMerchantCenterLink(
    customerIdSource: unknown,
    merchantCenterIdSource: unknown,
  ) {
    if (
      customerIdSource instanceof Observable &&
      merchantCenterIdSource instanceof Observable
    ) {
      return combineLatest([customerIdSource, merchantCenterIdSource]).pipe(
        map(([customerId, merchantCenterId]: Array<string | number>) => {
          if (customerId && merchantCenterId) {
            return this.getMerchantCenterLink(customerId, merchantCenterId);
          }
          return '';
        }),
      );
    } else {
      return (
        'https://ui.ads.microsoft.com/campaign/vnext/merchantcenter/store-summary/productissues?storeId=' +
        merchantCenterIdSource +
        '&cid=' +
        customerIdSource
      );
    }
  }

  getMicrosoftAdsAccountLink(
    customerId: Observable<string | number | null | undefined>,
    accountId: Observable<string | number | null | undefined>,
  ): Observable<string>;
  getMicrosoftAdsAccountLink(
    customerId: string | number | null | undefined,
    accountId: string | number | null | undefined,
  ): string;
  getMicrosoftAdsAccountLink(
    customerIdSource: unknown,
    accountIdSource: unknown,
  ) {
    if (
      customerIdSource instanceof Observable &&
      accountIdSource instanceof Observable
    ) {
      return combineLatest([customerIdSource, accountIdSource]).pipe(
        map(([customerId, accountId]: Array<string | number>) => {
          if (customerId && accountId) {
            return this.getMicrosoftAdsAccountLink(customerId, accountId);
          }
          return '';
        }),
      );
    } else {
      return (
        'https://ui.ads.microsoft.com/campaign/vnext/campaigns?aid=' +
        accountIdSource +
        '&cid=' +
        customerIdSource
      );
    }
  }

  getAddMerchantCenterLink(
    customerId$: Observable<string | number | null | undefined>,
    accountId$: Observable<string | number | null | undefined>,
  ): Observable<string>;
  getAddMerchantCenterLink(
    customerId: string | number,
    accountId: string | number,
  ): string;
  getAddMerchantCenterLink(
    customerIdSource: unknown,
    accountIdSource: unknown,
  ) {
    if (
      customerIdSource instanceof Observable &&
      accountIdSource instanceof Observable
    ) {
      return combineLatest([customerIdSource, accountIdSource]).pipe(
        map(([customerId, accountId]: Array<string | number>) => {
          if (customerId && accountId) {
            return this.getAddMerchantCenterLink(customerId, accountId);
          }
          return '';
        }),
      );
    } else {
      return (
        'https://ui.ads.microsoft.com/campaign/vnext/merchantcenter/stores?aid=' +
        accountIdSource +
        '&cid=' +
        customerIdSource
      );
    }
  }

  getUETLink(
    customerId: Observable<string | number | null | undefined>,
    accountId: Observable<string | number | null | undefined>,
    tagId: Observable<string | number | null | undefined>,
  ): Observable<string>;
  getUETLink(
    customerId: string | number | null | undefined,
    accountId: string | number | null | undefined,
    tagId: string | number | null | undefined,
  ): string;
  getUETLink(
    customerIdSource: unknown,
    accountIdSource: unknown,
    tagIdSource: unknown,
  ) {
    if (
      customerIdSource instanceof Observable &&
      accountIdSource instanceof Observable &&
      tagIdSource instanceof Observable
    ) {
      return combineLatest([
        customerIdSource,
        accountIdSource,
        tagIdSource,
      ]).pipe(
        map(([customerId, accountId, tagId]: Array<string | number>) => {
          if (customerId && accountId && tagId) {
            return this.getUETLink(customerId, accountId, tagId);
          }
          return '';
        }),
      );
    } else {
      return (
        'https://ui.ads.microsoft.com/campaign/vnext/uettags/edit?uetTagId=' +
        tagIdSource +
        '&aid=' +
        accountIdSource +
        '&cid=' +
        customerIdSource
      );
    }
  }

  getUETCreationLink(
    customerId$: Observable<string | number | null | undefined>,
    accountId$: Observable<string | number | null | undefined>,
  ): Observable<string>;
  getUETCreationLink(
    customerId: string | number,
    accountId: string | number,
  ): string;
  getUETCreationLink(customerIdSource: unknown, accountIdSource: unknown) {
    if (
      customerIdSource instanceof Observable &&
      accountIdSource instanceof Observable
    ) {
      return combineLatest([customerIdSource, accountIdSource]).pipe(
        map(([customerId, accountId]: Array<string | number>) => {
          if (customerId && accountId) {
            return this.getUETCreationLink(customerId, accountId);
          }
          return '';
        }),
      );
    } else {
      return (
        'https://ui.ads.microsoft.com/campaign/vnext/uettag?' +
        'aid=' +
        accountIdSource +
        '&cid=' +
        customerIdSource
      );
    }
  }
}
