<img
  class="block m-auto mb-6"
  style="max-width: 620px; width: 100%"
  src="assets/images/emarketing/emarketing-brands.png"
/>

<mat-stepper *ngIf="!appointmentSet && !isLoading" [linear]="true" #stepper>
  <mat-step [stepControl]="servicesGroup" state="services">
    <form [formGroup]="servicesGroup">
      <ng-template matStepLabel>{{
        'ONBOARDING_SERVICES_STEP_TITLE' | translate
      }}</ng-template>

      <h2 class="section-title">
        {{ 'ONBOARDING_SERVICES_TITLE' | translate }}
      </h2>

      <section class="section-body">
        <ng-container *ngFor="let service of services; let i = index">
          <ng-container [formGroupName]="service.name">
            <em-checkbox class="block" formControlName="service">
              {{
                'ONBOARDING_SERVICE_' + service.name.toUpperCase() | translate
              }}
            </em-checkbox>
            <div
              class="ml-8 mb-6"
              formArrayName="subServices"
              *ngIf="
                service.subServices?.length &&
                servicesGroup.get(service.name + '.service')?.value
              "
            >
              <em-checkbox
                class="block"
                *ngFor="let subService of service.subServices; let i = index"
                [formControlName]="i"
              >
                {{
                  'ONBOARDING_SERVICE_' +
                    service.name.toUpperCase() +
                    '_' +
                    subService.name.toUpperCase() | translate
                }}

                <ng-container *ngIf="subService.soon">
                  ({{ 'SHARED_LABEL_SOON' | translate }})
                </ng-container>
              </em-checkbox>
            </div>
          </ng-container>
        </ng-container>
      </section>

      <div class="flex mt-16 justify-end">
        <em-button
          [disabled]="servicesGroup.invalid"
          (btnClick)="stepper.next()"
          >{{ 'SHARED_LABEL_NEXT' | translate }}</em-button
        >
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="setupGroup" state="setup">
    <form [formGroup]="setupGroup">
      <ng-template matStepLabel>{{
        'ONBOARDING_SETUP_STEP_TITLE' | translate
      }}</ng-template>

      <h2 class="section-title">{{ 'ONBOARDING_SETUP_TITLE' | translate }}</h2>

      <section class="section-body">
        <em-radio-group class="block mb-8" formControlName="whoIAm">
          <em-radio-button value="isAgent">
            {{ 'ONBOARDING_SERVICE_IS_AGENT' | translate }}
            <div emRadioSubContent>
              <div
                class="ml-8 flex flex-col"
                *ngIf="whoIAm.value === 'isAgent'"
                [@expandCollapse]
              >
                <em-input
                  class="mb-2 max-w-xs"
                  formControlName="agencyUrl"
                  [hint]="'ONBOARDING_SERVICE_SHOPURL_HINT' | translate"
                  [label]="'ONBOARDING_SERVICE_AGENCYURL_LABEL' | translate"
                >
                  <em-error>{{
                    'ONBOARDING_SERVICE_SHOPURL_REQUIRED' | translate
                  }}</em-error>
                  <mat-icon emSuffix>link</mat-icon>
                </em-input>
              </div>
            </div>
          </em-radio-button>
          <em-radio-button value="useAgent">{{
            'ONBOARDING_SERVICE_USE_AGENT' | translate
          }}</em-radio-button>
          <em-radio-button value="onlineRetailer"
            >{{ 'ONBOARDING_SERVICE_ONLINE_RETAILER' | translate }}
            <div emRadioSubContent>
              <div
                class="ml-8 flex flex-col"
                *ngIf="whoIAm.value === 'onlineRetailer'"
                [@expandCollapse]
              >
                <em-input
                  class="mb-2 max-w-xs"
                  formControlName="shopUrl"
                  [hint]="'ONBOARDING_SERVICE_SHOPURL_HINT' | translate"
                  [label]="'ONBOARDING_SERVICE_SHOPURL_LABEL' | translate"
                >
                  <em-error>{{
                    'ONBOARDING_SERVICE_SHOPURL_REQUIRED' | translate
                  }}</em-error>
                  <mat-icon emSuffix>link</mat-icon>
                </em-input>

                <em-select
                  class="max-w-xs"
                  formControlName="shopType"
                  [label]="'ONBOARDING_SERVICE_SHOPSYSTEM_LABEL' | translate"
                >
                  <em-option value="Shopify">Shopify</em-option>
                  <em-option value="Shopware">Shopware</em-option>
                  <em-option value="Plentymarkets">Plentymarkets</em-option>
                  <em-option value="JTL">JTL</em-option>
                  <em-option value="Magento">Magento</em-option>
                  <em-option value="Prestashop">Prestashop</em-option>
                  <em-option value="other">Other</em-option>

                  <em-error>{{
                    'ONBOARDING_SERVICE_SHOPSYSTEM_REQUIRED' | translate
                  }}</em-error>
                </em-select>
              </div>
            </div>
          </em-radio-button>
        </em-radio-group>

        <em-checkbox class="block" formControlName="webshop">
          {{ 'ONBOARDING_SERVICE_WEBSHOP' | translate }}
        </em-checkbox>

        <div
          class="mb-2 ml-8"
          *ngIf="setupGroup.controls.webshop.value"
          [@expandCollapse]
        >
          <em-checkbox class="block" formControlName="eCommerce">
            E-Commerce
          </em-checkbox>
          <em-checkbox class="block pb-4" formControlName="digitalCommerce">
            Digital Commerce
          </em-checkbox>
        </div>

        <em-checkbox class="block" formControlName="marketPlace">
          {{ 'ONBOARDING_SERVICE_MARKETPLACE' | translate }}
        </em-checkbox>

        <div
          class="mb-2 ml-8"
          *ngIf="setupGroup.controls.marketPlace.value"
          [@expandCollapse]
        >
          <em-checkbox class="block" formControlName="amazon">
            Amazon
          </em-checkbox>
          <em-checkbox class="block" formControlName="otto"> OTTO </em-checkbox>
          <em-checkbox class="block" formControlName="zalando">
            Zalando
          </em-checkbox>
          <em-checkbox class="block pb-4" formControlName="other">
            Other
          </em-checkbox>
        </div>

        <em-checkbox class="block" formControlName="googleAds">
          {{ 'ONBOARDING_SERVICE_GOOGLE_ADS' | translate }}
        </em-checkbox>

        <div
          class="mb-2 ml-8"
          *ngIf="setupGroup.controls.googleAds.value"
          [@expandCollapse]
        >
          <em-checkbox class="block pb-4" formControlName="googleMC">
            {{ 'ONBOARDING_SERVICE_GOOGLE_MC' | translate }}
          </em-checkbox>
        </div>

        <em-checkbox class="block" formControlName="facebookAds">
          {{ 'ONBOARDING_SERVICE_FACEBOOK_ADS' | translate }}
        </em-checkbox>

        <div
          class="mb-2 ml-8"
          *ngIf="setupGroup.controls.facebookAds.value"
          [@expandCollapse]
        >
          <em-checkbox class="block pb-4" formControlName="facebookCM">
            {{ 'ONBOARDING_SERVICE_FACEBOOK_CM' | translate }}
          </em-checkbox>
        </div>
      </section>

      <div class="section-actions">
        <em-button
          [type]="'secondary'"
          [icon]="'chevron_left'"
          (btnClick)="stepper.previous()"
          >{{ 'SHARED_LABEL_PREVIOUS' | translate }}</em-button
        >
        <em-button (btnClick)="stepper.next()" [htmlType]="'submit'">{{
          'SHARED_LABEL_NEXT' | translate
        }}</em-button>
      </div>
    </form>
  </mat-step>
  <mat-step>
    <form [formGroup]="startGroup">
      <ng-template matStepLabel>
        <div [innerHTML]="'ONBOARDING_LETS_START_STEP_TITLE' | translate"></div>
      </ng-template>

      <h2 class="section-title">
        {{ 'ONBOARDING_LETS_START_TITLE' | translate }}
      </h2>

      <section class="section-body">
        <em-input
          class="block mb-2 w-full max-w-xs"
          formControlName="email"
          [label]="'SHARED_LABEL_EMAIL' | translate"
        >
          <em-error>{{
            'ONBOARDING_SERVICE_EMAIL_REQUIRED' | translate
          }}</em-error>
          <mat-icon emSuffix>email</mat-icon>
        </em-input>

        <em-radio-group formControlName="registerType">
          <em-radio-button value="directly">
            {{ 'ONBOARDING_SERVICE_DIRECT_REGISTRATION' | translate }}

            <div
              class="mb-4"
              emRadioSubContent
              *ngIf="registerType.value === 'directly'"
            >
              <em-radio-group formControlName="withPlugin">
                <em-radio-button value="yes">{{
                  'ONBOARDING_SERVICE_INSTALL_PLUGIN' | translate
                }}</em-radio-button>
                <em-radio-button value="no">{{
                  'ONBOARDING_SERVICE_WITHOUT_PLUGIN' | translate
                }}</em-radio-button>
              </em-radio-group>
            </div>
          </em-radio-button>

          <em-radio-button value="appointment">
            {{ 'ONBOARDING_SERVICE_APPOINTMENT_REGISTRATION' | translate }}

            <div
              class="flex flex-col"
              emRadioSubContent
              *ngIf="registerType.value === 'appointment'"
            >
              <em-input
                class="block mb-2 max-w-xs"
                formControlName="name"
                [label]="'ONBOARDING_SERVICE_NAME_LABEL' | translate"
              >
                <mat-icon emSuffix>person</mat-icon>
                <em-error>{{ 'SHARED_REUIRED_FIELD' | translate }}</em-error>
              </em-input>

              <em-input
                class="block mb-2 max-w-xs"
                formControlName="phone"
                [label]="'ONBOARDING_SERVICE_PHONE_LABEL' | translate"
              >
                <mat-icon emSuffix>phone</mat-icon>
                <em-error>{{ 'SHARED_REUIRED_FIELD' | translate }}</em-error>
              </em-input>

              <em-input
                class="block mb-2 max-w-xs"
                formControlName="website"
                [label]="
                  ('ONBOARDING_SERVICE_BUSINESS_WEBSITE' | translate) +
                  ' (' +
                  ('SHARED_LABEL_OPTIONAL' | translate) +
                  ')'
                "
              >
                <mat-icon emSuffix>phone</mat-icon>
              </em-input>

              <em-text-area
                class="block mb-2 max-w-xs"
                formControlName="notes"
                [label]="
                  ('ONBOARDING_SERVICE_ADDITIONAL_LABEL' | translate) +
                  ' (' +
                  ('SHARED_LABEL_OPTIONAL' | translate) +
                  ')'
                "
              ></em-text-area>
            </div>
          </em-radio-button>
        </em-radio-group>
      </section>

      <div class="section-actions">
        <em-button
          [type]="'secondary'"
          [icon]="'chevron_left'"
          (btnClick)="stepper.previous()"
          >{{ 'SHARED_LABEL_PREVIOUS' | translate }}</em-button
        >
        <em-button (btnClick)="submitForm()" [htmlType]="'submit'">{{
          'SHARED_LABEL_FINISH' | translate
        }}</em-button>
      </div>
    </form>
  </mat-step>

  <ng-template matStepperIcon="edit">
    <mat-icon>check</mat-icon>
  </ng-template>
</mat-stepper>

<div *ngIf="appointmentSet">
  <div class="flex justify-center">
    <mat-icon [color]="'primary'">check_circle</mat-icon>
    <span>{{ 'ONBOARDING_APPOINTMENT_SET' | translate }}</span>
  </div>
</div>

<div *ngIf="isLoading">
  <em-spinner class="m-auto"></em-spinner>
</div>
