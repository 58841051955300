import {ErrorHandler, Injectable, NgModule} from '@angular/core';
import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
  TranslateModule,
  TranslateParser,
} from '@ngx-translate/core';
import {configurationHelper} from '../configuration';

export class MissingTranslationError extends Error {
  constructor(key: string) {
    super(`Missing translation key ${key}`);
  }
}

@Injectable({
  providedIn: 'root',
})
export class ReportMissingTranslationHandler
  implements MissingTranslationHandler
{
  private readonly _reportedKeys: string[] = [];

  constructor(
    private readonly _errorHandler: ErrorHandler,
    private readonly _translateParser: TranslateParser,
  ) {}

  handle(params: MissingTranslationHandlerParams): string {
    const key = params.key;
    const lang = params.translateService.currentLang;
    const defaultLang = params.translateService.defaultLang;
    const translations = params.translateService.translations;
    // Check if it is really a key. There may be glitches in code or from ngx-translate.
    if (key.toUpperCase() !== key || !/^[A-Z0-9]/.test(key)) return key;
    // Check if translations are available for the default language.
    if (!translations[defaultLang]) return key;
    // Only report missing translations for the default language.
    if (lang !== defaultLang) return this._fallback(params);
    // Only report the same key once in a session. Change detector may call frequently.
    if (this._reportedKeys.includes(key)) return key;
    this._reportedKeys.push(key);

    const error = new MissingTranslationError(key);
    configurationHelper.isSandbox()
      ? console.error(error)
      : this._errorHandler.handleError(error);
    return key;
  }

  private _fallback(params: MissingTranslationHandlerParams): string {
    const defaultLang = params.translateService.defaultLang;
    const translations = params.translateService.translations[defaultLang];
    return (
      this._translateParser.interpolate(
        translations[params.key],
        params.interpolateParams,
      ) || params.key
    );
  }
}

const translateModuleParams = {
  missingTranslationHandler: {
    provide: MissingTranslationHandler,
    useClass: ReportMissingTranslationHandler,
  },
  useDefaultLang: false,
};

@NgModule({
  imports: [TranslateModule.forChild(translateModuleParams)],
  exports: [TranslateModule],
})
export class TranslateWithErrorReportingModule {}
