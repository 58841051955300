import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Observable} from 'rxjs';
import {map, pluck} from 'rxjs/operators';
import {SessionService} from '@em/auth/data-access';
import {IntercomService} from './support/intercom.service';

@Component({
  selector: 'em-app-error',
  templateUrl: './app-error.component.html',
  styleUrls: ['./app-error.component.scss'],
})
export class AppErrorComponent {
  constructor(
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _session: SessionService,
    private readonly _intercom: IntercomService,
  ) {}

  getSupport() {
    return this._intercom.show();
  }

  titleKey(): Observable<string> {
    return this._errorKey().pipe(
      map((errorKey) => (errorKey ? `${errorKey}_TITLE` : '')),
    );
  }

  descriptionKey(): Observable<string> {
    return this._errorKey().pipe(
      map((errorKey) => (errorKey ? `${errorKey}_DESCRIPTION` : '')),
    );
  }

  merchantUuid(): Observable<string> {
    return this._session.observable().pipe(pluck('uuid'));
  }

  //TO Do: remove this, this is only for testing Sentry on Production
  throwError() {
    throw new Error('Test Sentry Error Message');
  }

  private _errorKey(): Observable<string | null> {
    return this._activatedRoute.queryParamMap.pipe(
      map((queryParamMap) => queryParamMap.get('error')),
      map(
        (errorQueryParam) =>
          errorQueryParam &&
          `APP_ERROR_${errorQueryParam.replace(/-/g, '_').toUpperCase()}`,
      ),
    );
  }
}
