<div class="wrapper">
  <em-spinner [overContent]="true" *ngIf="isLoading"></em-spinner>

  <ng-container
    *ngIf="titlePosition === 'above'"
    [ngTemplateOutlet]="titleTemp"
  ></ng-container>

  <div class="chart-wrapper" [ngClass]="size">
    <div class="number-indicator" *ngIf="hasData()">
      {{ data | numberWithUnit: '%':0 }}
    </div>
    <div class="no-data-indicator" *ngIf="!hasData()">
      <span translate>DASHBOARD_CHART_NO_DATA</span>
    </div>
    <apx-chart
      [series]="[valueOrZero, fillerValue]"
      [labels]="['PLATFORM_CHART_CONVERTING_PRODUCTS', '']"
      [chart]="chartOptions.chart"
      [labels]="chartOptions.labels"
      [plotOptions]="chartOptions.plotOptions"
      [colors]="chartOptions.colors"
      [states]="chartOptions.states"
      [tooltip]="chartOptions.tooltip"
      [stroke]="chartOptions.stroke"
      [dataLabels]="chartOptions.dataLabels"
      [legend]="chartOptions.legend"
      [responsive]="chartOptions.responsive"
    >
    </apx-chart>
  </div>

  <ng-container
    *ngIf="titlePosition === 'below'"
    [ngTemplateOutlet]="titleTemp"
  ></ng-container>
</div>

<ng-template #titleTemp>
  <div
    class="text-wrapper"
    [ngClass]="titlePosition === 'above' ? '' : '!-mt-4'"
    *ngIf="title"
  >
    <div class="header">{{ title }}</div>
  </div>
</ng-template>
