import {animate, state, style, transition, trigger} from '@angular/animations';

// Expand/Collapse base on ngIf directive, Item remove entirly from the view when collapsed
export const expandCollapseAnimation = trigger('expandCollapse', [
  transition(':enter', [
    style({height: 0, overflow: 'hidden'}),
    animate('200ms', style({height: '*'})),
  ]),

  transition(':leave', [
    style({height: '*', overflow: 'hidden'}),
    animate('200ms', style({height: 0})),
  ]),
]);

// Expand/Collapse based on a condition, item still exist in the view
export const expandCollapseVisibilityAnimation = trigger('expandCollapse', [
  state('true', style({height: '*'})),
  state('false', style({height: 0, overflow: 'hidden'})),
  transition('true => false', animate('200ms ease-in')),
  transition('false => true', animate('200ms ease-out')),
]);
