import {Directive, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {
  Observable,
  OperatorFunction,
  ReplaySubject,
  Subject,
  Subscription,
} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class ComponentBase implements OnDestroy, OnChanges {
  protected _destroyed = new Subject<void>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private readonly _bindingSubjects: Array<{
    key: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    subject: Subject<any>;
  }> = [];

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();

    for (const item of this._bindingSubjects) {
      item.subject.complete();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const item of this._bindingSubjects) {
      if (changes[item.key]) {
        item.subject.next(changes[item.key].currentValue);
      }
    }
  }

  protected _untilDestroyed<T>(): OperatorFunction<T, T> {
    return takeUntil(this._destroyed);
  }

  /**
   * Subscribe to an observable and automatically terminate the subscription
   * on component destruction.
   * @param observable
   * @param next
   * @param error
   * @param complete
   * @private
   */
  protected _subscribe<T>(
    observable: Observable<T>,
    next: (value: T) => void,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error?: (error: any) => void,
    complete?: () => void,
  ): Subscription {
    return observable
      .pipe(takeUntil(this._destroyed))
      .subscribe(next, error, complete);
  }

  /**
   * Create an observable that emits a value everytime the input binding is changed.
   * Relies on `ngOnChanges`, that means, if you overwrite `ngOnChanges`, be sure to
   * call the super method as well.
   * When the component gets destroyed, the observable will complete (and therefore
   * close all subscriptions).
   * Note: because an input binding can always be set to undefined, the value is always
   * "undefined-able".
   * @param key name of the input binding
   * @private
   */
  protected _getInputChange<T, R>(key: keyof T): Observable<R | undefined> {
    const subject = new ReplaySubject<R | undefined>(1);
    this._bindingSubjects.push({key: key as string, subject});
    return subject;
  }
}
