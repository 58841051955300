import {Inject, Injectable} from '@angular/core';
import Pusher from 'pusher-js';
import {configurationHelper} from '@em/shared/util-configuration';
import {TokenStorageService} from '@em/auth/data-access';
import {PUSHER_AUTH_ENDPOINT} from '../tokens';

@Injectable({
  providedIn: 'root',
})
export class PusherFactory {
  constructor(
    private readonly _tokenStorage: TokenStorageService,
    @Inject(PUSHER_AUTH_ENDPOINT) private readonly authEndpoint: string,
  ) {}

  build(): Pusher | undefined {
    const appKey = configurationHelper.getConfig('PUSHER_APP_KEY');
    const jwt = this._tokenStorage.getToken();

    if (!appKey || !this.authEndpoint) return undefined;

    return new Pusher(appKey, {
      cluster: 'eu',
      authEndpoint: this.authEndpoint,
      auth: {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      },
    });
  }
}
