import {NotificationKey} from '@em/shared/api-interface';

export abstract class NotificationModel {
  protected constructor(
    readonly createdAt: Date,
    readonly key: NotificationKey,
    readonly workflowUuid: string,
  ) {}

  get namespace(): string {
    return this.key.split('::')[0];
  }
}
