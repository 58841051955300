<mat-form-field
  class="w-full"
  appearance="outline"
  [subscriptSizing]="subscriptSizing"
  [floatLabel]="floatLabel"
  [floatLabel]="'always'"
>
  <mat-label>{{ label }}</mat-label>
  <input
    matInput
    type="number"
    step="0.01"
    min="0.01"
    [formControl]="valueControl"
    [placeholder]="placeholder"
    [errorStateMatcher]="errorMatcher"
    (change)="onValueChange($event)"
    (keyup)="onValueChange($event)"
    (blur)="controlTouched()"
  />
  <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
  <mat-error>
    <ng-template [ngTemplateOutlet]="errors"></ng-template>
  </mat-error>
  <ng-container matSuffix *ngIf="emSuffix">
    <ng-content select="[emSuffix]"></ng-content>
  </ng-container>
  <ng-container matPrefix>
    <span class="p-2">{{ currencyCode | currencySymbol }}</span>
  </ng-container>
</mat-form-field>

<ng-template #errors>
  <ng-content select="em-error"></ng-content>
</ng-template>
