import {Injectable} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {
  LocalUserDataAccessor,
  LocalUserDataService,
} from '../local-user-data/local-user-data.service';
import {IMerchantJwt} from '../setup-status/merchant-jwt';

const tokenKey = 'ecom_auth_token';

@Injectable({
  providedIn: 'root',
})
export class TokenStorageService {
  private readonly _jwtHelper: JwtHelperService;
  private readonly _jwtAccessor: LocalUserDataAccessor;

  constructor(localUserData: LocalUserDataService) {
    this._jwtHelper = new JwtHelperService();
    this._jwtAccessor = localUserData.createAccessor(tokenKey);
  }

  get decodedToken(): IMerchantJwt | undefined | null {
    const jwt = this.getToken();
    if (!jwt) return undefined;
    try {
      return this._jwtHelper.decodeToken(jwt);
    } catch (e) {
      return undefined;
    }
  }

  hasToken(): boolean {
    return !!this._jwtAccessor.value;
  }

  clearToken() {
    this._jwtAccessor.remove();
  }

  setToken(jwt: string) {
    this._jwtAccessor.value = jwt;
  }

  getToken(): string | undefined {
    return this._jwtAccessor.value;
  }
}
