<em-box class="bg-white h-full" size="sm">
  <em-pie-chart
    [title]="title"
    [data]="percentage$ | async"
    [isLoading]="isLoading$ | async"
    [titlePosition]="'below'"
    size="sm"
  >
  </em-pie-chart>
</em-box>
