<button
  mat-flat-button
  [color]="finalColor"
  [class.w-full]="fullWidth"
  [disabled]="disabled"
  [type]="type"
  [tabIndex]="tabindex"
  (click)="btnClicked($event)"
>
  <mat-icon *ngIf="icon">{{ icon }}</mat-icon>
  <ng-content></ng-content>
</button>
