import {Component, EventEmitter, Input, Output} from '@angular/core';

export type ButtonColor = 'primary' | '' | 'warn' | 'danger';
type MaterialSupportedColors = 'primary' | 'warn' | '';

@Component({
  selector: 'em-button-base',
  template: '',
})
export abstract class EmButtonBaseComponent {
  @Input() type: 'button' | 'submit' | 'reset' = 'button';
  @Input() fullWidth = false;
  @Input() disabled: boolean | null | undefined = false;
  @Input() icon?: string;
  @Input() tabindex = 0;

  @Output() btnClick: EventEmitter<MouseEvent> = new EventEmitter();

  finalColor: MaterialSupportedColors = 'primary';

  constructor() {}

  @Input() set color(color: ButtonColor) {
    if (color === 'danger') {
      this.finalColor = 'warn';
    } else {
      this.finalColor = color;
    }
  }

  btnClicked(event: MouseEvent) {
    if (!this.disabled) {
      this.btnClick.emit(event);
    }
  }
}
