/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {HttpStatusCode} from '@em/shared/util-types';
import {Inject, Injectable, InjectionToken, Optional} from '@angular/core';
import {Observable} from 'rxjs';
import {retryWhen, scan, takeWhile} from 'rxjs/operators';

export interface RetryRequestConfig {
  retryCount?: number;
  retryForStatusCodes?: number[];
}

// Injection token to configure the interceptor retryCount and error codes to retry for
export const RETRY_REQUEST_CONFIG = new InjectionToken<RetryRequestConfig>(
  'RETRY_REQUEST_CONFIG',
);

@Injectable()
export class RetryForStatusCodesInterceptor implements HttpInterceptor {
  private readonly _retryCount: number = 2; // Default number of retries
  private readonly retryForStatusCodes = [
    HttpStatusCode.RequestTimeout,
    HttpStatusCode.GatewayTimeout,
  ]; // By default retry for timeout reuquests

  constructor(
    @Optional() @Inject(RETRY_REQUEST_CONFIG) config: RetryRequestConfig,
  ) {
    if (config) {
      this._retryCount = config.retryCount || this._retryCount;
      this.retryForStatusCodes =
        config.retryForStatusCodes || this.retryForStatusCodes;
    }
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      retryWhen((errors) =>
        errors.pipe(
          // Count how many times already retried
          scan(
            (acc, value) => ({
              error: value,
              count: 1 + acc.count,
            }),
            {count: 0, error: undefined as any},
          ),
          takeWhile((res) => {
            const error = res.error;
            const count = res.count;

            if (
              count <= this._retryCount &&
              error instanceof HttpErrorResponse &&
              this.retryForStatusCodes.indexOf(error.status) > -1 // Retry only for passed error codes
            ) {
              return true;
            }

            // pass the error to the next interceptor
            throw error;
          }),
        ),
      ),
    );
  }
}
