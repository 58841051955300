import {CommonModule} from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnChanges,
  Output,
} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {RouterModule} from '@angular/router';
import {expandCollapseAnimation} from '@em/shared/ui';
import {TranslateModule} from '@ngx-translate/core';
import {IMenuItem} from '../nav-menu-items-types';
import {EmBindCommandDirective} from '@em/shared/util-configuration';

@Component({
  selector: 'em-nav-item',
  templateUrl: './nav-list-item.component.html',
  styleUrls: ['./nav-list-item.component.scss'],
  animations: [expandCollapseAnimation],
})
export class NavListItemComponent implements OnChanges {
  @Input() item: IMenuItem | undefined;
  @Input() hideNested = false;
  @Input() disabled = false;
  @Output() itemClicked = new EventEmitter<IMenuItem>();

  openSubList = false;
  constructor() {}

  ngOnChanges(): void {
    this.openSubList = false;
  }
}

@NgModule({
  declarations: [NavListItemComponent],
  exports: [NavListItemComponent],
  imports: [
    CommonModule,
    RouterModule,
    MatListModule,
    MatIconModule,
    TranslateModule,
    EmBindCommandDirective,
  ],
})
export class NavListItemModule {}
