import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import {configurationHelper} from '@em/shared/util-configuration';
import {initializeSentry} from '@em/shared/util-error-handling';

if (
  configurationHelper.getConfig('PUBLIC_SENTRY_DSN') !== null &&
  configurationHelper.getConfig('PUBLIC_SENTRY_DSN') !== undefined
) {
  initializeSentry();
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));
