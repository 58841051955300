import {Injectable, inject} from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';

import {Observable} from 'rxjs';
import {API_DOMAIN_SETTINGS_LIST} from '../tokens';

/**The ideal solution is to add an interceptor per lazy loaded module
 *  Angular doesn't support that yet, a feature requesgt exist but still open
 *  https://github.com/angular/angular/issues/36974
 *  Until the feature request is implemented use the API prefix to intercept
 *  and add the correct api domain to http calls
 */
@Injectable()
export class APIDomainInterceptor implements HttpInterceptor {
  private readonly apiDomainSettingsList = inject(API_DOMAIN_SETTINGS_LIST);

  /* eslint-disable @typescript-eslint/no-explicit-any */
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    let httpReq = req;

    const currentUrl = req.url.startsWith('/') ? req.url.substring(1) : req.url;

    // clone request and use the API domain from the environment
    for (const item of this.apiDomainSettingsList) {
      if (currentUrl.startsWith(item.apiPrefix + '/')) {
        const realDomain = item.serviceDomain || '';
        let finalUrl = realDomain.endsWith('/')
          ? realDomain + currentUrl
          : realDomain + '/' + currentUrl;

        if (item.replacePrefixWith) {
          finalUrl = finalUrl.replace(item.apiPrefix, item.replacePrefixWith);
        }

        httpReq = req.clone({
          url: finalUrl,
        });
        return next.handle(httpReq);
      }
    }

    return next.handle(httpReq);
  }
}
