import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {configurationHelper} from '@em/shared/util-configuration';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {TranslateModule} from '@ngx-translate/core';
import {
  RECAPTCHA_SETTINGS,
  RecaptchaModule,
  RecaptchaSettings,
} from 'ng-recaptcha';
import {AccountCreationComponent} from './account-creation/account-creation.component';
import {BlockUiComponent} from './block-ui/block-ui.component';
import {EnterpriseEffects} from './enterprise.effects';
import {reducer} from './enterprise.reducers';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    TranslateModule,
    StoreModule.forFeature('enterprise', reducer),
    EffectsModule.forFeature([EnterpriseEffects]),
    RecaptchaModule,
    BlockUiComponent,
  ],
  declarations: [AccountCreationComponent],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useFactory: () =>
        ({
          siteKey: configurationHelper.needConfig('RECAPTCHA_SITE_KEY'),
        } as RecaptchaSettings),
    },
  ],
})
export class EnterpriseFeatureModule {}
