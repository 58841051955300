<ng-container *ngIf="purchase$ | async as purchases">
  <hr />
  <div class="block m-6 max-w-4xl mx-auto" *ngIf="purchases.length">
    <div class="text-lg font-semibold">
      {{ 'SHARED_LABEL_ONE_TIME_PAYMENTS' | translate }}
    </div>
    <table mat-table [dataSource]="purchases">
      <!-- Key Column -->
      <ng-container matColumnDef="key">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'SHARED_LABEL_NAME' | translate }}
        </th>
        <td mat-cell *matCellDef="let row">
          {{ ('PURCHASED_FEATURE_NAME_' + row.key).toUpperCase() | translate }}
        </td>
      </ng-container>

      <!-- Date Column -->
      <ng-container matColumnDef="created_at">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'SHARED_LABEL_DATE' | translate }}
        </th>
        <td class="col-md" mat-cell *matCellDef="let row">
          {{ row.created_at | date }}
        </td>
      </ng-container>

      <!-- Downlaod Column -->
      <ng-container matColumnDef="url">
        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
        <td class="col-sm w-32" mat-cell *matCellDef="let row">
          <em-primary-button (btnClick)="openStripePortal(row.payment_id)">{{
            'SHARED_LABEL_DETAILS' | translate
          }}</em-primary-button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</ng-container>
