import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {StatusIndicatorState} from './states.model';

@Component({
  selector: 'em-status-indicator',
  templateUrl: './status-indicator.component.html',
  styleUrls: ['./status-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TranslateModule],
})
export class EmStatusIndicatorComponent {
  @Input() state?: StatusIndicatorState | null;
  @Input() label?: string;

  constructor() {}
}
