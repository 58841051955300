import {
  BatchCompleteWorkerType,
  WorkFlowBackendResp,
} from '@em/shared/api-interface';
import {ExportProductsDestination} from '../../types';

const destinationWorkflowType: {
  [key in ExportProductsDestination]: BatchCompleteWorkerType;
} = {
  facebook: 'UseCases::Workflows::EgressFeed::FacebookWorker',
  google: 'UseCases::Workflows::EgressFeed::GoogleWorker',
};

// Retunr true if except arg is empty ("all egress workflow is working")
// Or if the notifications contains BatchCompleted for the specefic destination
export function isEgressInProgressOrComplete(
  destination: ExportProductsDestination,
  workflow: WorkFlowBackendResp,
) {
  const workerKey = destinationWorkflowType[destination];
  if (
    !workflow.finished_at &&
    workflow.args?.['except'] &&
    !(workflow.args['except'] as [])?.length
  ) {
    return true;
  }
  return workflow.notifications.some(
    (not) =>
      not.key === 'UseCases::Workflows::Notifications::BatchCompleted' &&
      not.payload.workers.some((worker) => worker.key === workerKey),
  );
}
