<em-icon class="info-icon flex-shrink-0" name="info_outline"></em-icon>
<div>
  <div class="info-title"><ng-content select="[title]"></ng-content></div>
  <div class="info-description">
    <ng-content select="[description]"></ng-content>
  </div>
</div>

<div class="ml-auto flex-shrink-0">
  <ng-content select="[actions]"></ng-content>
</div>
