<ng-container *ngIf="filter">
  <div class="inner">
    <mat-icon aria-hidden="false" aria-label="filter icon" class="icon filter"
      >filter_list</mat-icon
    >
    <span
      *ngIf="action === 'add'"
      [translate]="
        getFilterGroupI18nKey(filterKey, 'CAMPAIGN_PERFORMANCE_FILTER')
      "
    ></span>

    <ng-container *ngIf="action === 'delete'">
      <div class="flex items-center">
        <span
          class="pr-1"
          [translate]="
            getFilterGroupI18nKey(filterKey, 'CAMPAIGN_PERFORMANCE_FILTER')
          "
        ></span>

        <em-more-info
          [maxWidth]="500"
          [title]="
            getFilterGroupI18nKey(filterKey, 'CAMPAIGN_PERFORMANCE_FILTER')
          "
          [description]="
            getFilterGroupI18nKey(
              filterKey,
              'CAMPAIGN_PERFORMANCE_FILTER',
              'DESCRIPTION'
            )
          "
        >
        </em-more-info>
      </div>
    </ng-container>

    <mat-icon
      (click)="triggerAction()"
      *ngIf="action === 'delete'"
      aria-hidden="false"
      aria-label="home-icon"
      class="align-right delete"
    >
      delete
    </mat-icon>
    <ng-container *ngIf="action === 'add'">
      <span *ngIf="count$ | async as count" [matBadge]="count$ | async"></span>
      <mat-icon
        aria-hidden="false"
        aria-label="chevron-icon"
        class="align-right"
      >
        chevron_right
      </mat-icon>
    </ng-container>
  </div>
  <div
    *ngIf="
      filter.componentType === 'customGooglePrices' && action === 'delete';
      else otherItems
    "
    class="filter-item-options"
  >
    <em-google-prices-options [definition]="filter" [form]="form">
    </em-google-prices-options>
  </div>

  <ng-template #otherItems>
    <div
      *ngIf="action === 'delete'"
      [formGroup]="form"
      class="filter-item-options"
    >
      <ng-container [ngSwitch]="filter.componentType">
        <em-nested-toggle-options
          *ngSwitchCase="'nestedToggle'"
          [definition]="filter"
          formControlName="options"
        >
        </em-nested-toggle-options>

        <em-filter-item-options
          *ngSwitchCase="'default'"
          [definition]="filter"
          formControlName="options"
        >
        </em-filter-item-options>

        <em-dropdown-filter-item-options
          *ngSwitchCase="'dropdown'"
          [definition]="filter"
          formControlName="options"
        >
        </em-dropdown-filter-item-options>

        <em-mode-price-options
          *ngSwitchCase="'modePrice'"
          [definition]="filter"
          formControlName="options"
        >
        </em-mode-price-options>

        <em-with-competitors-options
          *ngSwitchCase="'customWithCompetitors'"
          [definition]="filter"
          formControlName="options"
        >
        </em-with-competitors-options>

        <em-price-changes-options
          *ngSwitchCase="'customPriceChanges'"
          [definition]="filter"
          formControlName="options"
        >
        </em-price-changes-options>

        <em-fetch-status-options
          *ngSwitchCase="'customFetchStatus'"
          [definition]="filter"
          formControlName="options"
        ></em-fetch-status-options>
      </ng-container>
    </div>
  </ng-template>
</ng-container>
