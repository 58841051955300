import {NotificationKey, NotificationRespType} from '@em/shared/api-interface';
import {NotificationModel} from '../notification.model';
import {IPusherNotification} from '../pusher-service/pusher.service';

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type payload = {url: string};

export class CompetitorCsvNotification extends NotificationModel {
  static key: NotificationKey =
    'UseCases::Notifications::CompetitorsCsvCreated';
  readonly url: string;

  constructor(createdAt: Date, workflowUuid: string, url: string) {
    super(createdAt, CompetitorCsvNotification.key, workflowUuid);
    this.url = url;
  }

  static competitorCsvFromPusher(
    resp: IPusherNotification,
  ): CompetitorCsvNotification {
    return new CompetitorCsvNotification(
      resp['created_at'] ? new Date(resp['created_at'] as string) : new Date(),
      resp.workflow_uuid,
      resp['url'] as string,
    );
  }

  static competitorCsvFromResp(
    resp: NotificationRespType,
  ): CompetitorCsvNotification {
    return new CompetitorCsvNotification(
      resp.created_at ? new Date(resp.created_at as string) : new Date(),
      resp.workflow_uuid,
      (resp.payload as payload).url,
    );
  }
}
