<button
  mat-fab
  *ngIf="size === 'md'"
  [color]="finalColor"
  [disabled]="disabled"
  [type]="type"
  [tabIndex]="tabindex"
  (click)="btnClicked($event)"
>
  <mat-icon *ngIf="icon">{{ icon }}</mat-icon>
</button>

<button
  mat-mini-fab
  *ngIf="size === 'sm'"
  [color]="finalColor"
  [disabled]="disabled"
  [type]="type"
  [tabIndex]="tabindex"
  (click)="btnClicked($event)"
>
  <mat-icon *ngIf="icon">{{ icon }}</mat-icon>
</button>
