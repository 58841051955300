import {LanguageCode} from '@em/shared/util-types';
import {LANGUAGE_LOCALE_MAP} from '../constants';

export class LanguageModel {
  readonly locale: string;

  constructor(
    readonly code: LanguageCode,
    readonly fallback?: LanguageModel,
    locale?: string,
  ) {
    this.locale = locale || LANGUAGE_LOCALE_MAP[code];
  }
}
