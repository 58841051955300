import {Component, forwardRef, Input, OnChanges} from '@angular/core';
import {
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import {getOptionType} from '../util';
import {NestedToggleOptionsComponent} from '../nested-toggle-options/nested-toggle-options.component';
import {CommonModule} from '@angular/common';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatInputModule} from '@angular/material/input';
import {TranslateModule} from '@ngx-translate/core';
import {ITypedControlValueAccessor} from '@em/shared/util-types';
import {IFilterOptionValues} from '@em/data-feed/data-access-products';
import {IProductFilterDefinition} from '../product-filter-definition';
import {ChangesObj} from '@em/shared/util-types';

@Component({
  selector: 'em-mode-price-options',
  templateUrl: './mode-price-options.component.html',
  styleUrls: ['./mode-price-options.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MatFormFieldModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatInputModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ModePriceOptionsComponent),
      multi: true,
    },
  ],
})
export class ModePriceOptionsComponent
  extends NestedToggleOptionsComponent
  implements ITypedControlValueAccessor<IFilterOptionValues>, OnChanges
{
  @Input() override definition?: IProductFilterDefinition;

  constructor() {
    super();
  }

  override ngOnChanges(
    changes: ChangesObj<ModePriceOptionsComponent, 'definition'>,
  ) {
    if (changes.definition && changes.definition.currentValue) {
      const definition = changes.definition.currentValue;
      this.vms = definition.availableOptions.map((o) => ({
        key: o.name,
        definition: o,
        inputType: getOptionType(o),
        i18n: {
          placeholder: `CAMPAIGN_PERFORMANCE_FILTER_${definition.key.toUpperCase()}`,
        },
      }));
    }
  }

  i18nDropdownKey(value: string): string {
    return `CAMPAIGN_PERFORMANCE_FILTER_${value.toUpperCase()}_DROPDOWN_LABEL`;
  }
}
