<div class="flex justify-between w-full max-w-screen-md mt-4">
  <img class="logo-img" src="/assets/images/emarketing/logo-dark.svg" />
  <div *ngIf="currentLanguage$ | async as currentLanguage">
    <img
      class="inline mr-2 cursor-pointer"
      width="20"
      src="assets/images/flags/flag_GB.svg"
      (click)="changeLanguage('en')"
    />
    <img
      class="inline cursor-pointer"
      width="20"
      src="assets/images/flags/flag_DE.svg"
      (click)="changeLanguage('de')"
    />
  </div>
</div>

<em-card class="w-full max-w-screen-md mt-8">
  <router-outlet></router-outlet>
</em-card>

<em-card class="w-full max-w-screen-md">
  <ng-container emCardTitle>{{
    'ONBOARDING_HELP_TITLE' | translate
  }}</ng-container>
  <ng-container emCardSubtitle>
    {{ 'ONBOARDING_HELP_DESCRIPTION' | translate }}
  </ng-container>
  <div class="ml-8">
    <div class="flex items-center mb-4 mt-8">
      <mat-icon class="mr-2">email</mat-icon>
      <span>Email: info&#64;emarketing.com</span>
    </div>
    <div class="flex items-center mb-4">
      <mat-icon class="mr-2">call</mat-icon>
      <a href="tel:089/18946560">Tel: 089 / 18946560</a>
    </div>
    <div class="flex items-center mb-4">
      <mat-icon class="mr-2">place</mat-icon>
      <span>Landsberger Straße 110, 80339 München</span>
    </div>
  </div>
</em-card>
