import {ProductsListService} from '@em/data-feed/data-access-products';
import {IDatasource, IGetRowsParams} from 'ag-grid-community';
import {getSorting} from '../sorting/get-sorting/get-sorting';
import {buildFilters} from '../filter/build-filter/build-filter';

export function getGridDataSrouce(
  productsListService: ProductsListService,
  externalFilterFactory: () => {group: string | undefined},
): IDatasource {
  const getRows = (params: IGetRowsParams): void => {
    const count = params.endRow - params.startRow;
    const page = Math.floor(params.startRow / count);

    productsListService
      .loadProductsBatch({
        count,
        page,
        sorting: getSorting(params.sortModel),
        filter: buildFilters(params.filterModel),
        ...externalFilterFactory(),
      })
      .subscribe({
        next: (result) => {
          let lastRow = -1;
          if (result.total_count <= params.endRow) {
            lastRow = result.total_count;
          }

          params.successCallback(result.result, lastRow);
        },
        error: () => {
          params.failCallback();
        },
      });
  };

  return {
    getRows,
    destroy: (): void => {},
  };
}
