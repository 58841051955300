<em-button
  *ngIf="type === 'icon-button'"
  type="icon"
  color="basic"
  [icon]="icon"
  [matMenuTriggerFor]="userMenu"
  data-testid="icon-button"
></em-button>

<em-button
  *ngIf="type === 'button'"
  color="basic"
  [icon]="icon"
  [matMenuTriggerFor]="userMenu"
  data-testid="button"
  ><span class="label">{{ title | translate }}</span>
</em-button>

<mat-menu #userMenu="matMenu">
  <ng-container *ngFor="let item of menuItems">
    <a
      *ngIf="!item?.external"
      mat-menu-item
      [routerLink]="item?.targetPath"
      [queryParams]="item?.queryParams"
    >
      <span>{{ item?.title | translate }}</span>
    </a>
    <a
      *ngIf="item?.external"
      mat-menu-item
      [href]="item?.targetPath"
      emExternalLink
    >
      <span>{{ item?.title | translate }}</span>
    </a>
  </ng-container>

  <ng-content></ng-content>
</mat-menu>
