<mat-checkbox
  [(ngModel)]="controlValue"
  [color]="color"
  [disabled]="!!isDisabled"
  [indeterminate]="indeterminate"
  [checked]="isChecked"
  (ngModelChange)="controlValueChanged($event); controlTouched()"
  (change)="valueChange.emit($event.checked)"
  ><ng-content></ng-content
></mat-checkbox>
