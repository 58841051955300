import {default as format} from './format';

export function getDayHours(): Array<{name: string; hour: number}> {
  return [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23,
  ].map((value) => {
    const now = new Date();
    now.setHours(value);
    now.setMinutes(0);
    return {
      hour: value,
      name: format(now, '	p').trim(),
    };
  });
}
