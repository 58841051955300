import {Router} from '@angular/router';
import {from, Observable, of} from 'rxjs';
import {mapVoid} from '@em/shared/util-rxjs';
import {ICommand} from './command';

export class LinkInternallyCommand implements ICommand<void> {
  constructor(
    private readonly _route: Router,
    private readonly _isAvailable: boolean,
    readonly sectionLink: string,
  ) {}

  canPerform(): Observable<boolean> {
    return of(this._isAvailable);
  }

  perform(): Observable<void> {
    return from(this._route.navigateByUrl(this.sectionLink)).pipe(mapVoid());
  }
}
