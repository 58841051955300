import {
  NotificationKey,
  NotificationRespType,
  WorkflowType,
} from '@em/shared/api-interface';
import {NotificationModel} from '../notification.model';
import {IPusherNotification} from '../pusher-service/pusher.service';

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type payload = {workflow_type: string; failed: boolean};

export const WORKFLOW_COMPLETED_NOTIFICATION_TYPES = {
  DatafeedWorkflow: 'UseCases::Workflows::DatafeedWorkflow' as WorkflowType,
  FullThrottleWorkflow:
    'UseCases::Workflows::FullThrottleWorkflow' as WorkflowType,
  AutomaticCampaignWorkflow:
    'UseCases::Workflows::AutomaticCampaignWorkflow' as WorkflowType,
  DatafeedSetupWorkflow:
    'UseCases::Workflows::DatafeedSetupWorkflow' as WorkflowType,
  ScheduledRefreshWorkflow:
    'UseCases::Workflows::ScheduledRefreshWorkflow' as WorkflowType,
  AuthorizationWorkflow:
    'UseCases::Workflows::AuthorizationWorkflow' as WorkflowType,
  DeletionWorkflow: 'UseCases::Workflows::DeletionWorkflow' as WorkflowType,
  RepricingWorkflow: 'External::RepricingWorkflow' as WorkflowType,
};

export class WorkflowCompletedNotification extends NotificationModel {
  static key: NotificationKey =
    'UseCases::Workflows::Notifications::WorkflowCompleted';
  readonly failed: boolean;
  readonly workflowType: WorkflowType;

  constructor(
    createdAt: Date,
    workflowUuid: string,
    workflowType: WorkflowType,
    failed: boolean,
  ) {
    super(createdAt, WorkflowCompletedNotification.key, workflowUuid);
    this.workflowType = workflowType;
    this.failed = failed;
  }

  get workflowName(): string {
    const parts = this.workflowType.split('::');
    return parts[parts.length - 1];
  }

  static workflowCompletedFromPusher(
    resp: IPusherNotification,
  ): WorkflowCompletedNotification {
    return new WorkflowCompletedNotification(
      resp['created_at'] ? new Date(resp['created_at'] as string) : new Date(),
      resp['workflow_uuid'],
      resp['workflow_type'] as WorkflowType,
      !!resp.failed,
    );
  }

  static workflowCompletedFromResp(
    resp: NotificationRespType,
  ): WorkflowCompletedNotification {
    return new WorkflowCompletedNotification(
      resp.created_at ? new Date(resp.created_at as string) : new Date(),
      resp.workflow_uuid,
      (resp.payload as payload).workflow_type as WorkflowType,
      (resp.payload as payload).failed,
    );
  }
}
