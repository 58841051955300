import {Inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {JwtHelperService} from '@auth0/angular-jwt';
import {Observable, of} from 'rxjs';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {LocationUrlService, LoggerService} from '@em/shared/util-configuration';
import {QueryParamsService} from '@em/shared/util-web';
import {SessionService} from '../../services/session/session.service';
import {APP_ROUTES, IAppRoutes} from '@em/shared/util-configuration';
import {LocalUserDataService} from '../../local-user-data/local-user-data.service';

/**
 * Checks the current URL for a JWT, and if a JWT is given,
 * performs exchange login.
 */
@Injectable({
  providedIn: 'root',
})
export class ExchangeJwtGuard {
  constructor(
    private readonly _queryParams: QueryParamsService,
    private readonly _session: SessionService,
    private readonly _logger: LoggerService,
    private readonly _locationUrl: LocationUrlService,
    private readonly _jwtHelper: JwtHelperService,
    private readonly _localUserData: LocalUserDataService,
    @Inject(APP_ROUTES) private readonly _appRoutes: IAppRoutes,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    const jwt = this._queryParams.getValue(state.url, 'jwt');

    if (jwt) {
      const decodedToken = this._jwtHelper.decodeToken(jwt);
      this._localUserData.remove('challenge');

      if (decodedToken.challenge) {
        // Store challenge for later use
        this._localUserData.setObj('challenge', decodedToken.challenge);

        if (this._session.isLoggedIn()) {
          return this._session.logout().pipe(
            tap(() => {
              // Re-add challenge to localStorage after it's been removed due to logout
              this._localUserData.setObj('challenge', decodedToken.challenge);
              // We need to force a reload otherwise ongoing requests will trigger 401
              // errors when we remove authentication mid-way.
              this._locationUrl.forceChange(this._appRoutes.login);
            }),
            map(() => false),
          );
        }

        this._session.navigateToRoute(this._appRoutes.login);
        return of(false);
      } else {
        this._locationUrl.removeParams();

        return this._session.exchangeLogin(jwt).pipe(
          catchError(() => {
            this._logger.error('Exchange token failed.');
            return of(false);
          }),
          switchMap(() => {
            this._locationUrl.restartWithParam('jwt', undefined, true);
            return of(false);
          }),
        );
      }
    } else {
      this._session.confirmJwt();
    }

    return of(true);
  }
}
