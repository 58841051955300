/* tslint:disable */
/**
* This file was automatically generated.
* DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
* and run the generate-code script again.
*/
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DeleteResp as DeleteDatafeedResp} from '../types/view-models/datafeed/delete';
import {GetInfoResp} from '../types/view-models/datafeed/get-info';
import {GetPluginResp} from '../types/view-models/datafeed/get-plugin';
import {GetSettingsResp} from '../types/view-models/datafeed/get-settings';
import {PutSettingsReq} from '../types/request-objects/datafeed/put-settings';
import {PutSettingsResp} from '../types/view-models/datafeed/put-settings';
import {PutSiteVerificationReq} from '../types/request-objects/datafeed/put-site-verification';
import {PutSiteVerificationResp} from '../types/view-models/datafeed/put-site-verification';

@Injectable({
  providedIn: 'root',
})
export class DatafeedGateway {
  constructor(private _http: HttpClient) {}

  deleteDatafeed(options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<DeleteDatafeedResp> {
    return this._http.delete<DeleteDatafeedResp>('/emarketing_api/datafeed/', options);
  }

  getInfo(options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<GetInfoResp> {
    return this._http.get<GetInfoResp>('/emarketing_api/datafeed/info', options);
  }

  getPlugin(options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<GetPluginResp> {
    return this._http.get<GetPluginResp>('/emarketing_api/datafeed/plugin', options);
  }

  getSettings(options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<GetSettingsResp> {
    return this._http.get<GetSettingsResp>('/emarketing_api/datafeed/settings', options);
  }

  putSettings(params: PutSettingsReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<PutSettingsResp> {
    return this._http.put<PutSettingsResp>('/emarketing_api/datafeed/settings', params, options);
  }

  putSiteVerification(params: PutSiteVerificationReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<PutSiteVerificationResp> {
    return this._http.put<PutSiteVerificationResp>('/emarketing_api/datafeed/site_verification', params, options);
  }
}
