import {IMerchantJwt} from './setup-status/merchant-jwt';

export class MerchantSession {
  readonly partnerName: string;
  readonly uuid: string;

  constructor(token: IMerchantJwt) {
    this.partnerName = token.partner_short_name;
    this.uuid = token.merchant_uuid;
  }
}
