import {workflowStatus} from '@em/activities/data-access';

export enum WorkflowStatusEnum {
  requested,
  finished,
  failed,
}

export function toStatusEnum(status: workflowStatus): WorkflowStatusEnum {
  switch (status) {
    case 'failed':
      return WorkflowStatusEnum.failed;
    case 'finished':
      return WorkflowStatusEnum.finished;
    case 'requested':
      return WorkflowStatusEnum.requested;
    default:
      throw new Error('Unknown status');
  }
}
