<em-card class="h-full relative">
  <em-spinner
    class="m-auto em-spinner-small"
    *ngIf="isLoading"
    [overContent]="true"
  ></em-spinner>
  <div class="flex h-full">
    <div class="h-full w-full flex flex-col justify-between">
      <div
        class="mb-3 pb-2 text-base font-medium border-gray-200 border-b flex gap-2"
      >
        <div class="flex-shrink-0">
          <ng-content select="[icon]"></ng-content>
        </div>
        <div><ng-content select="[title]"></ng-content></div>
      </div>
      <div class="text-3xl text-gray-600">
        <ng-content select="[value]"></ng-content>
      </div>
    </div>
  </div>
</em-card>
