import {MainItems, UserMenuItems} from './nav-menu-items';
import {IMenuItem} from './nav-menu-items-types';

export class NavigationFactory {
  static buildUserMenuItems(): IMenuItem[] {
    return UserMenuItems;
  }

  static buildMainItems(): IMenuItem[] {
    return MainItems;
  }
}
