<div class="ag-header-group-cell-label">
  <div class="customHeaderLabel flex items-center">
    <img *ngIf="params.logo" [src]="params.logo" class="h-[20px] mr-2" />
    <em-icon
      *ngIf="params.icon"
      [name]="params.icon"
      class="mr-2 text-primary-500"
    ></em-icon>
    {{ params.displayName }}
  </div>
  <span
    ref="agOpened"
    class="ag-header-icon ag-header-expand-icon ag-header-expand-icon-expanded"
  ></span>
  <span
    ref="agClosed"
    class="ag-header-icon ag-header-expand-icon ag-header-expand-icon-collapsed ag-hidden"
    aria-hidden="true"
  ></span>
</div>
