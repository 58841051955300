import {optionValueType} from '@em/data-feed/data-access-products';
import trim from 'lodash-es/trim';

export interface IOptionAttrs {
  maxItems?: number;
  maximum?: number;
  minItems?: number;
  minimum?: number;
  type: string;
  enum?: string[];
}

export interface IOptionDefinition {
  maximum: number | undefined;
  minimum: number | undefined;
  name: string;
  required: boolean;
  step: number | undefined;
  type: string;
  maxItems: number | undefined;
  minItems: number | undefined;
  selectableValues: string[];
}

function _validateArray(
  definition: IOptionDefinition,
  value: optionValueType | undefined,
): string | null {
  if (!value) return null;
  if (!Array.isArray(value)) return null;

  if (
    definition.minItems &&
    (value as Array<string | number>).length < definition.minItems
  ) {
    return 'invalid_array';
  }

  if (
    definition.maxItems &&
    (value as Array<string | number>).length > definition.maxItems
  ) {
    return 'invalid_array';
  }

  return null;
}

function _validateNumber(
  definition: IOptionDefinition,
  value: optionValueType | undefined,
): string | null {
  if (!value) return null;
  if (typeof value !== 'number') return null;

  if (definition.minimum && (value as number) < definition.minimum) {
    return 'invalid_value';
  }

  if (definition.maximum && (value as number) > definition.maximum) {
    return 'invalid_value';
  }

  return null;
}

export function validateOptionValue(
  definition: IOptionDefinition,
  value: optionValueType | undefined,
): string | null {
  if (!value || trim(value as string) === '') {
    if (definition.required) return 'blank';
  }

  switch (definition.type) {
    case 'integer':
    case 'number':
      return _validateNumber(definition, value);
    case 'array':
      return _validateArray(definition, value);
    default:
      return null;
  }
}

export function buildOptionDefinition(
  name: string,
  attrs: IOptionAttrs,
  required: boolean,
): IOptionDefinition {
  return {
    name,
    required,
    type: attrs.type,
    minimum: attrs.minimum,
    maximum: attrs.maximum,
    minItems: attrs.minItems,
    maxItems: attrs.maxItems,
    step: undefined,
    selectableValues: attrs.enum || [],
  };
}

export class OptionDefinition implements IOptionDefinition {
  maximum: number | undefined;
  minimum: number | undefined;
  name: string;
  required: boolean;
  step: number | undefined;
  type: string;
  readonly maxItems: number | undefined;
  readonly minItems: number | undefined;
  readonly selectableValues: string[] = [];

  constructor(name: string, attrs: IOptionAttrs, required?: boolean) {
    this.name = name;
    this.required = !!required;
    this.type = attrs.type;
    this.minimum = attrs.minimum;
    this.maximum = attrs.maximum;
    this.minItems = attrs.minItems;
    this.maxItems = attrs.maxItems;
  }

  validate(value: optionValueType | undefined): string | null {
    return validateOptionValue(this, value);
  }
}
