import {HttpClient} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

// AoT requires an exported function for factories
export function GetHttpLoaderFactory(path?: string) {
  return (http: HttpClient) => {
    if (path) {
      return new TranslateHttpLoader(http, path, '.json');
    }
    return new TranslateHttpLoader(http);
  };
}
