import {Component, OnInit} from '@angular/core';
import {createSelector, Store} from '@ngrx/store';
import {take} from 'rxjs/operators';
import {SessionService} from '@em/auth/data-access';
import {CurrencyCode, IMoney, toMoney} from '@em/shared/util-types';
import {FullTextStore} from '../full-text-store/full-text-store';
import {
  DeleteOauthToken,
  LoadGoogleSdk,
  LoadPerformanceData,
  OauthSignIn,
  OpenAccountCreationFlow,
} from '../feature/enterprise.actions';
import {
  getAdwordsAccounts,
  getAdwordsAccountsLoaded,
  getAdwordsAccountsLoading,
  getEnterpriseState,
  getOauthState,
  getPerformanceData,
  getSdkLoading,
  getSdkReady,
} from '../feature/enterprise.selectors';
import {
  IAdwordsAccount,
  IPerformanceCollection,
} from '../feature/enterprise.reducers';

export interface IPerformanceVmData {
  costs: IMoney | null;
  conversions: number | null;
  clicks: number | null;
  revenue: IMoney | null;
}

export const selectComponentState = createSelector(
  getEnterpriseState,
  getSdkLoading,
  getAdwordsAccountsLoading,
  getOauthState,
  getSdkReady,
  getAdwordsAccounts,
  getAdwordsAccountsLoaded,
  getPerformanceData,
  (
    state,
    sdkLoading,
    adwordsAccountsLoading,
    oauthState,
    sdkReady,
    adwordsAccounts,
    adwordsAccountsLoaded,
    performanceData,
  ) => ({
    loading:
      sdkLoading || adwordsAccountsLoading || oauthState === 'in-progress',
    sdkReady,
    authState: oauthState,
    adwordsAccounts: new FullTextStore<IAdwordsAccount>(
      'id',
      'name',
      'managedByName',
      'managedById',
    ).add(adwordsAccounts),
    noAccountsSelectable:
      oauthState === 'done' &&
      adwordsAccountsLoaded &&
      adwordsAccounts.length === 0,
    performanceData,
  }),
);

@Component({
  selector: 'em-enterprise-page',
  templateUrl: './enterprise-page.component.html',
  styleUrls: ['./enterprise-page.component.scss'],
})
export class EnterprisePageComponent implements OnInit {
  searchValue?: string;
  readonly componentState = this._store.select(selectComponentState);
  private readonly _requestedIds: {[k: number]: boolean | undefined} = {};
  orderBy?: string;
  orderDirection: 'asc' | 'desc' = 'asc';

  constructor(
    private readonly _store: Store,
    private readonly _session: SessionService,
  ) {}

  adwordsTrackByFn(index: number, account: IAdwordsAccount) {
    return account.id;
  }

  ngOnInit() {
    this._store.dispatch(new LoadGoogleSdk());
  }

  logout() {
    this._store.dispatch(new DeleteOauthToken());
  }

  loginWithGoogle() {
    this._store.dispatch(new OauthSignIn());
  }

  login(jwt: string) {
    this._session.exchangeLogin(jwt).pipe(take(1)).subscribe();
  }

  create(
    adwordsId: number,
    currencyCode: CurrencyCode,
    loginCustomerId: number,
  ) {
    this._store.dispatch(
      new OpenAccountCreationFlow({adwordsId, currencyCode, loginCustomerId}),
    );
  }

  getPerformanceData(
    adwordsId: number,
    performanceCollections: IPerformanceCollection,
    currencyCode: CurrencyCode,
    loginCustomerId: number,
  ): IPerformanceVmData | undefined {
    const performanceData = performanceCollections[adwordsId];

    if (
      this._requestedIds[adwordsId] &&
      !!performanceData &&
      !performanceData.loading &&
      performanceData.loaded &&
      performanceData.data
    ) {
      return {
        ...performanceData.data,
        revenue: toMoney(performanceData.data.revenue, currencyCode),
        costs: toMoney(performanceData.data.costs, currencyCode),
      };
    }

    if (performanceData === undefined && !this._requestedIds[adwordsId]) {
      // Angular's Virtual-Scroll-CDK issues a huge amount of initial events
      // that prevent Redux from updating the store in time.
      // Therefore (as a hotfix) we need to use this (duplicated) lookup-object
      // to opt-out of (re-)fetching data early.
      this._requestedIds[adwordsId] = true;
      this._store.dispatch(
        new LoadPerformanceData({adwordsId, loginCustomerId}),
      );
    }

    return undefined;
  }

  isActive(column: string): boolean {
    return this.orderBy === column;
  }

  changeOrderBy(orderBy?: string) {
    // New field selected - asc
    if (this.orderBy !== orderBy) {
      this.orderDirection = 'asc';
      this.orderBy = orderBy;
      // Same field selected again - 'desc'
    } else if (this.orderDirection === 'asc') {
      this.orderDirection = 'desc';
      // Same field selected for second time - no order + asc
    } else {
      this.orderDirection = 'asc';
      this.orderBy = undefined;
    }
  }
}
