export interface IStorageDriver {

  getItem(key: string): string | undefined;

  setItem(key: string, val: string): void;

  removeItem(key: string): void;

  clear(): void;
}

export class LocalStorageDriver implements IStorageDriver {
  private readonly _storage: Storage;

  constructor(storage: Storage) {
    this._storage = storage;
  }

  getItem(key: string): string | undefined {
    return this._storage.getItem(key) || undefined;
  }

  setItem(key: string, val: string) {
    this._storage.setItem(key, val);
  }

  removeItem(key: string) {
    this._storage.removeItem(key);
  }

  clear() {
    this._storage.clear();
  }
}

export class MemoryStorageDriver implements IStorageDriver {
  private _storage: {[key: string]: string};

  constructor() {
    this._storage = {};
  }

  getItem(key: string): string | undefined {
    return this._storage[key];
  }

  setItem(key: string, val: string) {
    this._storage[key] = val;
  }

  removeItem(key: string) {
    delete this._storage[key];
  }

  clear() {
    this._storage = {};
  }
}
