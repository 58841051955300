<div class="wrapper">
  <em-spinner *ngIf="isLoading" [overContent]="true"></em-spinner>
  <div class="chart-wrapper">
    <div class="progress-container">
      <div class="progress-wrapper" *ngFor="let entry of data">
        <div class="data-description">
          <div class="label text-titleColor">{{ entry.name }}</div>
        </div>
        <div
          class="progress-bar-wrapper"
          [class.is-positive]="isPositive(entry.value)"
        >
          <div class="progress-bar">
            <div class="bar" [style.width.%]="getSize(entry.value)"></div>
          </div>
          <div class="value" *ngIf="fractionSize">
            {{ entry.value | currency: currency || undefined }}
          </div>
          <div class="value" *ngIf="!fractionSize">
            {{ entry.value }}
            <span translate>LABEL_PRODUCTS</span>
          </div>
        </div>
      </div>
      <div *ngIf="noData">
        <div class="progress-wrapper" *ngFor="let entry of emptyElements">
          <div class="data-description">
            <div class="label" translate>DASHBOARD_CHART_NO_DATA</div>
          </div>
          <div class="progress-bar-wrapper">
            <div class="progress-bar">
              <div class="bar"></div>
            </div>
            <div class="value"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="performance-data-date-range">
    <span>{{
      'PERFORMANCE_DATA_DATE_RANGE'
        | translate: {days: 30, platform: i18nName()}
    }}</span>
  </div>
</div>
