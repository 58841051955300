<button
  mat-icon-button
  class="leading-none"
  [color]="finalColor"
  [disabled]="disabled"
  [type]="type"
  [tabIndex]="tabindex"
  (click)="btnClicked($event)"
>
  <mat-icon *ngIf="icon">{{ icon }}</mat-icon>
</button>
