<form [formGroup]="form" *ngIf="form">
  <label>{{ 'ONBOARDING_CHANGE_EMAIL_LABEL' | translate }}</label>
  <em-input
    [label]="'ENTER_EMAIL_EMAIL_PLACEHOLDER' | translate"
    matInput
    type="email"
    formControlName="email"
  >
    <em-error>
      <ng-container *ngIf="form?.controls.email.hasError('validateEmail')">
        {{ 'VALIDATION_EMAIL_PATTERN_WRONG' | translate }}
      </ng-container>
      <ng-container *ngIf="form?.controls.email.hasError('required')">
        {{ 'VALIDATION_FIELD_REQUIRED' | translate }}
      </ng-container>
    </em-error>
  </em-input>
</form>
