/* tslint:disable */
/**
* This file was automatically generated.
* DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
* and run the generate-code script again.
*/
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {GetPortalReq} from '../../../types/request-objects/contracts/subscription/stripe/get-portal';
import {GetPortalResp} from '../../../types/view-models/contracts/subscription/stripe/get-portal';
import {GetStatusReq} from '../../../types/request-objects/contracts/subscription/stripe/get-status';
import {GetStatusResp} from '../../../types/view-models/contracts/subscription/stripe/get-status';
import {PostCheckoutReq} from '../../../types/request-objects/contracts/subscription/stripe/post-checkout';
import {PostCheckoutResp} from '../../../types/view-models/contracts/subscription/stripe/post-checkout';
import {PostUpdateReq} from '../../../types/request-objects/contracts/subscription/stripe/post-update';
import {PostUpdateResp} from '../../../types/view-models/contracts/subscription/stripe/post-update';

@Injectable({
  providedIn: 'root',
})
export class StripeGateway {
  constructor(private _http: HttpClient) {}

  getPortal(params: GetPortalReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<GetPortalResp> {
    const request = params as any as { [param: string]: string | string[]; };
    return this._http.get<GetPortalResp>('/payment_api/contracts/subscription/stripe/portal', {...options, params: request});
  }

  getStatus(params: GetStatusReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<GetStatusResp> {
    const request = params as any as { [param: string]: string | string[]; };
    return this._http.get<GetStatusResp>('/payment_api/contracts/subscription/stripe/status', {...options, params: request});
  }

  postCheckout(params: PostCheckoutReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<PostCheckoutResp> {
    return this._http.post<PostCheckoutResp>('/payment_api/contracts/subscription/stripe/checkout', params, options);
  }

  postUpdate(params: PostUpdateReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<PostUpdateResp> {
    return this._http.post<PostUpdateResp>('/payment_api/contracts/subscription/stripe/update', params, options);
  }
}
