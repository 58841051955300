<div>
  <div *ngIf="!hasCategories()" class="no-result">
    <mat-icon>error</mat-icon>
    <span>
      {{ 'CATEGORY_NO_RESULT' | translate }}
    </span>
  </div>
  <div *ngIf="hasCategories()">
    <div class="bg-panel">
      <em-search-input
        class="product-filter"
        (inputChange)="searchCategory($event)"
      ></em-search-input>
      <div class="meta">
        {{ selectedCategories?.length }} / {{ availableCategories?.length }}
        {{ 'CATEGORY_PRODUCTS_TOTAL' | translate }}
      </div>
    </div>
    <div class="container">
      <div class="miller-columns">
        <div
          class="miller-column"
          *ngFor="let level of vm?.levels; let i = index"
          [style.flex]="getRelativeWidth(i)"
        >
          <div class="padding-wrapper">
            <div
              class="entry"
              *ngFor="let node of level"
              [class.active]="vm?.isActive(node)"
              [class.leaf]="node.isLeaf()"
              [class.selected]="node.content.selected"
              [class.some-selected]="node.content.someSelected"
            >
              <div class="category-checkbox">
                <mat-checkbox
                  [indeterminate]="
                    !node.content.selected && node.content.someSelected
                  "
                  [checked]="node.content.selected"
                  color="primary"
                  class="em-checkbox-small"
                  (change)="clicked(node)"
                >
                </mat-checkbox>
              </div>
              <div
                class="category-label"
                (click)="vm?.open(node)"
                [attr.role]="node.isLeaf() ? '' : 'button'"
              >
                <span
                  [attr.data-selected-products-count-sum]="
                    getSelectedProductCountSum(node)
                  "
                  [attr.data-unselected-products-count-sum]="
                    getUnselectedProductCountSum(node)
                  "
                >
                  {{ node.id }}
                </span>
              </div>
              <div
                class="children-indicator"
                (click)="vm?.open(node)"
                *ngIf="showChevron(node)"
                [attr.role]="node.isLeaf() ? '' : 'button'"
              >
                <mat-icon *ngIf="!node.isLeaf()">chevron_right</mat-icon>
                <mat-icon *ngIf="node.isLeaf()"></mat-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="back-btn" *ngIf="canNavigateUp()">
      <em-button
        type="mini-fab"
        icon="keyboard_arrow_left"
        [matTooltip]="'CATEGORY_NAVIGATE_UP' | translate"
        (btnClick)="navigateUp()"
      >
      </em-button>
    </div>
  </div>
</div>
