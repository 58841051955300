import {CommonModule} from '@angular/common';
import {Component} from '@angular/core';
import {EmSpinnerModule} from '@em/shared/ui';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';

@Component({
  selector: 'em-loading-cell-renderer',
  standalone: true,
  imports: [CommonModule, EmSpinnerModule],
  template: `<span *ngIf="this.displayValue">{{ this.displayValue }}</span>
    <em-spinner
      class="em-spinner-mini m-3"
      *ngIf="!this.displayValue"
    ></em-spinner> `,
  styleUrls: ['./loading-cell-renderer.scss'],
})
export class EmLoadingCellRendererComponent
  implements ICellRendererAngularComp
{
  displayValue!: string;

  agInit(params: ICellRendererParams): void {
    this.displayValue = params.value;
  }

  refresh() {
    return false;
  }
}
