export class ProductCategoryModel {
  readonly path: string[];

  constructor(
    readonly name: string,
    readonly conversions: number,
    readonly productCount: number,
  ) {
    this.path = name.split(' > ');
  }
}
