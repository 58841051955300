<mat-form-field
  class="w-full"
  appearance="outline"
  [floatLabel]="floatLabel"
  [ngClass]="subscriptSizing === 'dynamic' ? '-mb-6' : ''"
>
  <mat-label *ngIf="label">{{ label }}</mat-label>

  <mat-select
    [disabled]="!!isDisabled"
    [(ngModel)]="controlValue"
    [errorStateMatcher]="errorMatcher"
    [multiple]="multiple"
    [required]="!!_required"
    [placeholder]="placeholder || ''"
    [compareWith]="compareWith"
    (selectionChange)="selectionChange.emit($event.value)"
    (blur)="controlTouched(); blur.emit()"
    (ngModelChange)="controlValueChanged($event)"
  >
    <mat-option
      [value]="option.value"
      [disabled]="option.disabled"
      *ngFor="let option of options; let i = index"
    >
      <ng-container *ngIf="option.templateRef">
        <ng-template [ngTemplateOutlet]="option.templateRef"></ng-template>
      </ng-container>
    </mat-option>
    <mat-optgroup
      *ngFor="let group of optionGroups"
      [label]="group.label"
      [disabled]="group.disabled"
    >
      <mat-option
        [value]="option.value"
        [disabled]="option.disabled"
        *ngFor="let option of group.options; let i = index"
      >
        <ng-container *ngIf="option.templateRef">
          <ng-template [ngTemplateOutlet]="option.templateRef"></ng-template>
        </ng-container>
      </mat-option>
    </mat-optgroup>
    <mat-select-trigger *ngIf="selectTrigger"
      ><ng-content select="[emSelectTrigger]"></ng-content
    ></mat-select-trigger>
  </mat-select>
  <mat-hint>{{ hint }}</mat-hint>
  <mat-error>
    <ng-template [ngTemplateOutlet]="errors"></ng-template>
  </mat-error>
  <ng-container matPrefix *ngIf="emPrefix">
    <ng-content select="[emPrefix]"></ng-content>
  </ng-container>
</mat-form-field>

<ng-template #errors>
  <ng-content select="em-error"></ng-content>
</ng-template>
