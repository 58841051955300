import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GoogleLinkGeneratorService {
  constructor() {}

  getMerchantCenterLink(merchantCenterId: string | number) {
    return `https://merchants.google.com/mc/overview?a=${merchantCenterId}&authoruser=0`;
  }

  getAdsAccountLink(accountId: string | number) {
    return 'https://ads.google.com/aw/overview';
  }
}
