import {Directive, ElementRef} from '@angular/core';

@Directive({
  standalone: true,
  selector: '[emExternalLink]',
})
export class EmExternalLinkDirective {
  constructor(el: ElementRef) {
    // Open link in new tab/window.
    el.nativeElement.target = '_blank';
    // Prevent process sharing and exploitation of the window.opener API.
    el.nativeElement.rel = 'noopener noreferrer';
  }
}
