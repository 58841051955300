<em-card class="cursor-pointer" *ngIf="!asBox">
  <div class="flex flex-col h-full items-center justify-center min-h-[100px]">
    <em-icon name="add_circle" class="text-primary-500" size="lg"></em-icon>
    <div class="text-xl mt-2 font-medium text-center">
      {{ title }}
    </div>
  </div>
</em-card>

<em-box class="cursor-pointer bg-white h-full" *ngIf="asBox">
  <div class="flex flex-col h-full items-center justify-center min-h-[100px]">
    <em-icon name="add_circle" class="text-primary-500" size="lg"></em-icon>
    <div class="text-xl mt-2 font-medium text-center">
      {{ title }}
    </div>
  </div>
</em-box>
