import {ScrollingModule} from '@angular/cdk/scrolling';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatTooltipModule} from '@angular/material/tooltip';
import {EmFormatAdwordsIdPipe} from '@em/google/ui';
import {
  EmAllowZeroPipe,
  EmCurrencySymbolPipe,
  EmDisabledDirective,
  EmSpinnerModule,
  EmWithCurrencyPipe,
} from '@em/shared/ui';
import {TranslateModule} from '@ngx-translate/core';
import {EnterpriseAreaRoutingModule} from './enterprise-area-routing.module';
import {EnterprisePageComponent} from './enterprise-page/enterprise-page.component';
import {BlockUiComponent} from './feature/block-ui/block-ui.component';
import {EnterpriseFeatureModule} from './feature/enterprise-feature.module';
import {OrderByPipe} from './pipes/order-by.pipe';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    EnterpriseAreaRoutingModule,
    EnterpriseFeatureModule,
    FormsModule,
    ReactiveFormsModule,
    ScrollingModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    EmCurrencySymbolPipe,
    BlockUiComponent,
    EmFormatAdwordsIdPipe,
    EmWithCurrencyPipe,
    EmSpinnerModule,
    EmDisabledDirective,
    EmAllowZeroPipe,
  ],
  declarations: [EnterprisePageComponent, OrderByPipe],
})
export class EnterpriseAreaModule {}
