import {
  ProductsListFilter,
  ProductsListNumberFilterCols,
} from '@em/shared/api-interface';
import {NumberFilterModel} from 'ag-grid-community';
import {isNullaryOperator} from '../../../is-nullary-operator/is-nullary-operator';
import {GRID_TO_BACKEND_NUMBER_OPERATOR} from '../../../consts/operator-maps';

export function addNumberFilter(
  filter: NonNullable<ProductsListFilter>,
  key: ProductsListNumberFilterCols,
  filterModel: NumberFilterModel,
) {
  if (filterModel.type) {
    const backendOperator = GRID_TO_BACKEND_NUMBER_OPERATOR[filterModel.type];
    if (backendOperator) {
      if (isNullaryOperator(backendOperator)) {
        filter[key] = {
          operator: backendOperator,
        };
      } else if (
        filterModel.filter !== null &&
        filterModel.filter !== undefined
      ) {
        filter[key] = {
          operator: backendOperator,
          value: filterModel.filter,
        };
      }
    }
  }
}
