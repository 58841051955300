import {inject} from '@angular/core';
import {CanActivateFn} from '@angular/router';
import {Contract} from '@em/subscription/api-interface';
import {map, of, switchMap} from 'rxjs';
import {PaymentService} from '../services/payment-service/payment.service';
import {ContractService} from '@em/subscription/data-access';

export function contractGuard(
  contract: Partial<Contract>,
  isActive: (c: Contract) => boolean,
): CanActivateFn {
  return () => {
    const contractService: ContractService = inject(ContractService);
    const paymentService: PaymentService = inject(PaymentService);

    return contractService.activeContract$.pipe(
      switchMap((liveContract) => {
        if (!!liveContract && isActive(liveContract)) {
          return of(true);
        } else {
          return paymentService
            .includeAndUpgradePlan(contract)
            .pipe(map(() => false));
        }
      }),
    );
  };
}
