import {DOCUMENT} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Observable, of, throwError} from 'rxjs';
import {shareReplay, switchMap, tap} from 'rxjs/operators';
import {mapVoid} from '@em/shared/util-rxjs';
import {IGeneralizedWindow, WINDOW} from '../injection-tokens';

@Injectable({
  providedIn: 'root',
})
export class WebComponentService {
  private readonly _loadSignals: {[tag: string]: Observable<void>} = {};

  constructor(
    private readonly _http: HttpClient,
    private readonly _translate: TranslateService,
    @Inject(WINDOW) private readonly _window: IGeneralizedWindow,
    @Inject(DOCUMENT) private readonly _document: Document,
  ) {}

  loadOnSignal(
    hostUrl: string,
    tag: string,
    headersObs: Observable<{[k: string]: string} | null>,
  ): Observable<void> {
    return headersObs.pipe(
      switchMap((headers) => {
        if (!headers) {
          return throwError('Shop token not defined');
        }

        return of(headers);
      }),
      switchMap((headers) => this.load(hostUrl, tag, headers)),
    );
  }

  load(
    hostUrl: string,
    tag: string,
    headers: {[k: string]: string} = {},
  ): Observable<void> {
    // Skip if the custom element is already registered.
    if (this._window.customElements.get(tag)) return of(undefined as void);
    // Reuse load signal if the web component has already been requested.
    if (this._loadSignals[tag]) return this._loadSignals[tag];
    // Drop forward slash at the end of the host URL.
    if (hostUrl.slice(-1) === '/') {
      hostUrl = hostUrl.slice(0, hostUrl.length - 1);
    }

    const src = `${hostUrl}/${tag}.js?lang=${this._translate.currentLang}`;
    const loadSignal = this._http
      .get(src, {
        headers,
        responseType: 'text',
      })
      .pipe(
        tap(
          (code: string) => this._bootstrapWebComponent(code),
          () => delete this._loadSignals[tag], // uncache on error
        ),
        mapVoid(),
        shareReplay({refCount: false, bufferSize: 1}),
      );

    this._loadSignals[tag] = loadSignal;
    return loadSignal;
  }

  private _bootstrapWebComponent(code: string) {
    const el = this._document.createElement('script');
    el.innerHTML = code;
    this._document.body.appendChild(el);
  }
}
