<div class="recommend-box" *ngIf="recommended || current">
  <div class="recommend-box-inner" *ngIf="recommended && !current">
    {{ 'SHARED_LABEL_RECOMMENDED' | translate }}
  </div>
  <div class="current-box-inner" *ngIf="current">
    {{ 'SHARED_LABEL_CURRENT_PLAN' | translate }}
  </div>
</div>
<em-box
  *ngIf="contract"
  class="!flex flex-col h-full"
  [ngClass]="recommended ? 'bg-primary-50' : ''"
>
  <div class="text-center mb-6 mt-2">
    <div class="text-3xl font-medium text-primary-500">
      {{
        contract.pricePreview.price || 0
          | currency: contract.pricePreview.currency || 'EUR':'symbol':'0.0-0'
      }}
    </div>
    <div>{{ 'SHARED_LABEL_PRO_MONTH' | translate }}</div>
  </div>

  <div class="features-list">
    <div
      class="feature-section"
      *ngIf="contract.contract.product_management?.total_products"
    >
      <div class="feature section-title">
        <em-icon name="file_download" class="mr-1"></em-icon>
        {{ 'PLAN_LABLE_IMPORT_DATA' | translate }}
      </div>
      <div class="feature">
        <em-icon class="text-success-500" name="check"></em-icon>

        {{ 'PLAN_LABLE_PRODUCTS_COUNT' | translate }}:
        <span
          [ngClass]="
            highlightDiff &&
            highlightDiff.contract.product_management.total_products !==
              contract.contract.product_management.total_products
              ? 'highlight-diff'
              : ''
          "
          >{{ contract.contract.product_management.total_products }}</span
        >
      </div>
      <div class="feature">
        <em-icon class="text-success-500" name="check"></em-icon>

        {{ 'PLAN_LABLE_UPLOAD_PRODUCTS' | translate }}
      </div>
    </div>

    <div class="feature-section" *ngIf="crawlEnabled">
      <div class="feature section-title">
        <em-icon name="radar" class="mr-1"></em-icon>
        {{ 'NAV_COMPETITORS_RADAR' | translate }}
      </div>
      <div class="feature ml-">
        <em-icon class="text-success-500" name="check"></em-icon>

        <div>
          {{ 'PLAN_LABLE_PRODUCTS_COUNT' | translate }} :
          <span
            [ngClass]="
              highlightDiff && crawlProducts !== diffCrawlProducts
                ? 'highlight-diff'
                : ''
            "
            >{{ crawlProducts }}</span
          >
        </div>
      </div>

      <div class="feature ml-">
        <em-icon class="text-success-500" name="check"></em-icon>
        <div>
          {{ 'SHARED_LABEL_PRODUCTS_SOURCES' | translate }}:
          <span
            [ngClass]="
              highlightDiff && sources.length !== diffSources.length
                ? 'highlight-diff'
                : ''
            "
            >{{ sources.length }}</span
          >
        </div>
      </div>

      <div class="feature">
        <em-icon class="text-success-500" name="check"></em-icon>
        <div>
          {{ 'SHARED_LABEL_FREQUENCY' | translate }}:

          <span
            [ngClass]="
              highlightDiff && higherFrequency !== diffHigherFrequency
                ? 'highlight-diff'
                : ''
            "
            >{{
              'SHARED_LABEL_' + higherFrequency.toUpperCase() | translate
            }}</span
          >
        </div>
      </div>
    </div>

    <ng-container *ngIf="contract.contract.google_prices?.enabled">
      <div class="feature-section">
        <div
          class="feature"
          [ngClass]="
            highlightDiff &&
            highlightDiff.contract.google_prices?.enabled !==
              contract.contract.google_prices?.enabled
              ? 'highlight-diff'
              : ''
          "
        >
          <em-icon class="text-success-500" name="check"></em-icon>
          <img [src]="Google.logos['googlePrices']" width="20" class="mr-2" />
          {{ 'SHARED_LABEL_GOOGLE_PRICES' | translate }}
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="contract.contract.repricing?.enabled">
      <div class="feature-section">
        <div
          class="feature"
          [ngClass]="
            highlightDiff &&
            highlightDiff.contract.repricing?.enabled !==
              contract.contract.repricing?.enabled
              ? 'highlight-diff'
              : ''
          "
        >
          <em-icon class="text-success-500" name="check"></em-icon>
          <em-icon class="text-primary-500" name="local_offer"></em-icon>
          <div>
            {{ 'NAV_REPRICING' | translate }} ({{
              'PLAN_LABLE_PRODUCTS_COUNT' | translate
            }}:
            <span
              [ngClass]="
                highlightDiff &&
                highlightDiff.contract.repricing?.total_products !==
                  contract.contract.repricing?.total_products
                  ? 'highlight-diff'
                  : ''
              "
              >{{ contract.contract.repricing?.total_products }}</span
            >)
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="contract.contract.datafeed?.csv_export">
      <div class="feature-section">
        <div
          class="feature"
          [ngClass]="
            highlightDiff &&
            highlightDiff.contract.datafeed?.csv_export !==
              contract.contract.datafeed?.csv_export
              ? 'highlight-diff'
              : ''
          "
        >
          <em-icon class="text-success-500" name="check"></em-icon>
          <img
            src="/assets/images/shared/csv-file.png"
            width="20"
            class="mr-2"
          />
          {{ 'PLANS_CSV_EXPORT' | translate }}
        </div>
      </div>
    </ng-container>

    <div
      class="feature-section"
      *ngIf="
        contract.contract.datafeed?.google_upload ||
        contract.contract.advertising?.google_campaigns ||
        contract.contract.advertising?.google_css
      "
    >
      <div class="feature section-title">
        <img [src]="Google.logos.campaign" width="20" class="mr-2" />
        {{ 'NAV_GOOGLE_SHOPPING' | translate }}
      </div>
      <div
        class="feature"
        [ngClass]="
          highlightDiff &&
          highlightDiff.contract.datafeed?.google_upload !==
            contract.contract.datafeed?.google_upload
            ? 'highlight-diff'
            : ''
        "
        *ngIf="contract.contract.datafeed?.google_upload"
      >
        <em-icon class="text-success-500" name="check"></em-icon>

        {{ 'SHARED_DF_UPLOAD' | translate }}
        {{ 'ONBOARDING_SERVICE_DATAFEED_GOOGLE' | translate }}
      </div>
      <div
        class="feature"
        [ngClass]="
          highlightDiff &&
          highlightDiff.contract.advertising?.google_campaigns !==
            contract.contract.advertising?.google_campaigns
            ? 'highlight-diff'
            : ''
        "
        *ngIf="contract.contract.advertising?.google_campaigns"
      >
        <em-icon class="text-success-500" name="check"></em-icon>

        {{ 'CAMPAIGN_TYPE_GOOGLE_ADS' | translate }}
      </div>
      <div
        class="feature"
        [ngClass]="
          highlightDiff &&
          highlightDiff.contract.advertising?.google_campaigns !==
            contract.contract.advertising?.google_campaigns
            ? 'highlight-diff'
            : ''
        "
        *ngIf="contract.contract.advertising?.google_campaigns"
      >
        <em-icon class="text-success-500" name="check"></em-icon>

        {{ 'INTEGRATIONS_CONVERSION_TRACKER_TITLE' | translate }}
      </div>
      <div
        class="feature"
        [ngClass]="
          highlightDiff &&
          highlightDiff.contract.advertising?.google_css !==
            contract.contract.advertising?.google_css
            ? 'highlight-diff'
            : ''
        "
        *ngIf="contract.contract.advertising?.google_css"
      >
        <em-icon class="text-success-500" name="check"></em-icon>

        {{ 'SHARED_GOOGLE_CSS_PROGRAM' | translate }}
      </div>
    </div>

    <div
      class="feature-section"
      *ngIf="
        contract.contract.datafeed?.meta_upload ||
        contract.contract.advertising?.meta_campaigns
      "
    >
      <div class="feature section-title">
        <img [src]="facebook.logos.campaign" width="20" class="mr-2" />
        {{ 'NAV_FACEBOOK' | translate }}
      </div>
      <div
        class="feature"
        [ngClass]="
          highlightDiff &&
          highlightDiff.contract.datafeed?.meta_upload !==
            contract.contract.datafeed?.meta_upload
            ? 'highlight-diff'
            : ''
        "
        *ngIf="contract.contract.datafeed?.meta_upload"
      >
        <em-icon class="text-success-500" name="check"></em-icon>

        {{ 'SHARED_DF_UPLOAD' | translate }}
        {{ 'SHARED_LABEL_FACEBOOK_MERCHANT_CENTER' | translate }}
      </div>
      <div
        class="feature"
        [ngClass]="
          highlightDiff &&
          highlightDiff.contract.advertising?.meta_campaigns !==
            contract.contract.advertising?.meta_campaigns
            ? 'highlight-diff'
            : ''
        "
        *ngIf="contract.contract.advertising?.meta_campaigns"
      >
        <em-icon class="text-success-500" name="check"></em-icon>

        {{ 'SHARED_LABEL_FACEBOOK_RETARGETING' | translate }},
      </div>
      <div
        class="feature"
        [ngClass]="
          highlightDiff &&
          highlightDiff.contract.advertising?.meta_campaigns !==
            contract.contract.advertising?.meta_campaigns
            ? 'highlight-diff'
            : ''
        "
        *ngIf="contract.contract.advertising?.meta_campaigns"
      >
        <em-icon class="text-success-500" name="check"></em-icon>

        {{ 'SHARED_LABEL_FACEBOOK_LOOKALIKE' | translate }}
      </div>
      <div
        class="feature"
        [ngClass]="
          highlightDiff &&
          highlightDiff.contract.advertising?.meta_campaigns !==
            contract.contract.advertising?.meta_campaigns
            ? 'highlight-diff'
            : ''
        "
        *ngIf="contract.contract.advertising?.meta_campaigns"
      >
        <em-icon class="text-success-500" name="check"></em-icon>

        {{ 'SHARED_FACEBOOK_DYNAMIC_ADS' | translate }}
      </div>
      <div
        class="feature"
        [ngClass]="
          highlightDiff &&
          highlightDiff.contract.advertising?.meta_campaigns !==
            contract.contract.advertising?.meta_campaigns
            ? 'highlight-diff'
            : ''
        "
        *ngIf="contract.contract.advertising?.meta_campaigns"
      >
        <em-icon class="text-success-500" name="check"></em-icon>

        {{ 'SHARED_FACEBOOK_CONVERSION_TRACKING' | translate }}
      </div>
    </div>

    <div
      class="feature-section"
      *ngIf="
        contract.contract.datafeed?.microsoft_upload ||
        contract.contract.advertising?.microsoft_campaigns
      "
    >
      <div class="feature section-title">
        <img [src]="microsoft.logos.campaign" width="20" class="mr-2" />
        {{ 'NAV_BING' | translate }}
      </div>
      <div
        class="feature"
        [ngClass]="
          highlightDiff &&
          highlightDiff.contract.datafeed?.microsoft_upload !==
            contract.contract.datafeed?.microsoft_upload
            ? 'highlight-diff'
            : ''
        "
        *ngIf="contract.contract.datafeed?.microsoft_upload"
      >
        <em-icon class="text-success-500" name="check"></em-icon>

        {{ 'SHARED_DF_UPLOAD' | translate }}
        {{ 'SHARED_LABEL_MICROSOFT_MC' | translate }}
      </div>
      <div
        class="feature"
        [ngClass]="
          highlightDiff &&
          highlightDiff.contract.advertising?.microsoft_campaigns !==
            contract.contract.advertising?.microsoft_campaigns
            ? 'highlight-diff'
            : ''
        "
        *ngIf="contract.contract.advertising?.microsoft_campaigns"
      >
        <em-icon class="text-success-500" name="check"></em-icon>

        {{ 'SHARED_LABEL_MICROSOFT_SMART_SHOPPING' | translate }}
      </div>
      <div
        class="feature"
        [ngClass]="
          highlightDiff &&
          highlightDiff.contract.advertising?.microsoft_campaigns !==
            contract.contract.advertising?.microsoft_campaigns
            ? 'highlight-diff'
            : ''
        "
        *ngIf="contract.contract.advertising?.microsoft_campaigns"
      >
        <em-icon class="text-success-500" name="check"></em-icon>

        {{ 'SHARED_MICROSOFT_CONVERSION_TRACKING' | translate }}
      </div>
    </div>
  </div>

  <div class="mt-auto" *ngIf="!current">
    <em-primary-button
      class="block mt-6"
      [fullWidth]="true"
      [disabled]="disabled"
      (btnClick)="subscribe.emit(contract)"
      >{{ CTA || 'SHARED_LABEL_UPGRADE' | translate }}</em-primary-button
    >
  </div>
</em-box>
