/* tslint:disable */
/**
* This file was automatically generated.
* DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
* and run the generate-code script again.
*/
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DeleteReq as DeleteMappingEngineReq} from '../types/request-objects/mapping-engine/delete';
import {GetDetailsReq} from '../types/request-objects/mapping-engine/get-details';
import {GetDetailsResp} from '../types/view-models/mapping-engine/get-details';
import {GetReq as GetMappingEngineReq} from '../types/request-objects/mapping-engine/get';
import {GetResp as GetMappingEngineResp} from '../types/view-models/mapping-engine/get';
import {PostReq as PostMappingEngineReq} from '../types/request-objects/mapping-engine/post';
import {PostResp as PostMappingEngineResp} from '../types/view-models/mapping-engine/post';

@Injectable({
  providedIn: 'root',
})
export class MappingEngineGateway {
  constructor(private _http: HttpClient) {}

  deleteMappingEngine(params: DeleteMappingEngineReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<undefined> {
    const request = params as any as { [param: string]: string | string[]; };
    return this._http.delete<undefined>('/emarketing_api/mapping_engine/', {...options, params: request});
  }

  getDetails(params: GetDetailsReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<GetDetailsResp> {
    const request = params as any as { [param: string]: string | string[]; };
    return this._http.get<GetDetailsResp>('/emarketing_api/mapping_engine/details', {...options, params: request});
  }

  getMappingEngine(params: GetMappingEngineReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<GetMappingEngineResp> {
    const request = params as any as { [param: string]: string | string[]; };
    return this._http.get<GetMappingEngineResp>('/emarketing_api/mapping_engine/', {...options, params: request});
  }

  postMappingEngine(params: PostMappingEngineReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<PostMappingEngineResp> {
    return this._http.post<PostMappingEngineResp>('/emarketing_api/mapping_engine/', params, options);
  }
}
