import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterStateSnapshot,
} from '@angular/router';
import {contractGuard} from './contract-guard';

export function campaignsContractGuard(): CanActivateFn {
  return (route: ActivatedRouteSnapshot, snapshot: RouterStateSnapshot) => {
    if (route.parent?.params['platform'] === 'google-shopping') {
      return contractGuard(
        {advertising: {google_campaigns: true}},
        (contract) => !!contract.advertising?.google_campaigns,
      )(route, snapshot);
    } else {
      return contractGuard(
        {advertising: {meta_campaigns: true}},
        (contract) => !!contract.advertising?.meta_campaigns,
      )(route, snapshot);
    }
  };
}
