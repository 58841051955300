import {
  ProductsListFilter,
  ProductsListTextFilterCols,
} from '@em/shared/api-interface';
import {TextFilterModel} from 'ag-grid-community';
import {GRID_TO_BACKEND_TEXT_OPERATOR} from '../../../consts/operator-maps';
import {isNullaryOperator} from '../../../is-nullary-operator/is-nullary-operator';

export function addTextFilter(
  filter: NonNullable<ProductsListFilter>,
  key: ProductsListTextFilterCols,
  filterModel: TextFilterModel,
) {
  if (filterModel.type) {
    const backendOperator = GRID_TO_BACKEND_TEXT_OPERATOR[filterModel.type];
    if (backendOperator) {
      if (isNullaryOperator(backendOperator)) {
        filter[key] = {
          operator: backendOperator,
        };
      } else {
        filter[key] = {
          operator: backendOperator,
          value: filterModel.filter || '',
        };
      }
    }
  }
}
