import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {PipelineResp, ProductPipelinesGateway} from '@em/shared/api-interface';
import {
  ComponentStoreBase,
  ComponentStoreStateBase,
} from '@em/shared/util-types';
import {OnStateInit, tapResponse} from '@ngrx/component-store';
import {combineLatest, of} from 'rxjs';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {PipelineListStore} from '../pipeline-list/pipeline-list.store';
import {PipelineSourceType} from '../types/pipeline-source-type';
import {AvailablePipelineSources} from '../types/available-pipeline-source';

type errorTypes = 'add-pipeline';

export interface AddPipelineState extends ComponentStoreStateBase<errorTypes> {
  pipeline?: PipelineResp;
  newPipelineAddedId?: string;
}

const initialState: AddPipelineState = {isLoading: false};

@Injectable()
export class AddPipelineStore
  extends ComponentStoreBase<AddPipelineState, errorTypes>
  implements OnStateInit
{
  readonly availablePipelineSources$ =
    this._pipelineListStore.pipelinesList$.pipe(
      map((existingItems) =>
        Array.from(AvailablePipelineSources).map((item) => ({
          ...item,
          disabled: existingItems?.find((p) => p.source === item.name),
        })),
      ),
    );
  readonly newPipelineAddedId$ = this.select(
    (state) => state.newPipelineAddedId,
  );

  override isLoading$ = combineLatest([
    this.select((s) => s.isLoading),
    this._pipelineListStore.isLoading$,
  ]).pipe(map(([isLoading1, isLoading2]) => !!isLoading1 || !!isLoading2));

  constructor(
    private readonly _pipelinesGateway: ProductPipelinesGateway,
    private readonly _pipelineListStore: PipelineListStore,
  ) {
    super(initialState);
  }

  ngrxOnStateInit() {
    this._pipelineListStore.loadPipelines();
  }

  readonly addNewPipeline = this.effect<PipelineSourceType>((pipelineSource$) =>
    pipelineSource$.pipe(
      tap(() => {
        this.startLoading();
        this.patchState({newPipelineAddedId: undefined});
      }),
      switchMap((source) =>
        this._pipelinesGateway.getList({}).pipe(
          catchError(() => of([])),
          switchMap((list) =>
            this._pipelinesGateway.postProductPipelines({
              active: list.length ? false : true,
              source,
            }),
          ),
          tapResponse(
            (newPipeline) => {
              this._pipelineListStore.loadPipelines(true);
              this.patchState({
                pipeline: newPipeline,
                newPipelineAddedId: newPipeline.uuid,
                isLoading: false,
              });
            },
            (err: HttpErrorResponse) => {
              this.addError({
                httpError: err,
                errorMessage: {
                  key: 'add-pipeline',
                },
              });
            },
          ),
        ),
      ),
    ),
  );
}
