/* tslint:disable */
/**
* This file was automatically generated.
* DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
* and run the generate-code script again.
*/
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {GetResp as GetFacebookPixelsResp} from '../../types/view-models/datafeed/facebook-pixels/get';
import {PutSelectedPixelReq} from '../../types/request-objects/datafeed/facebook-pixels/put-selected-pixel';

@Injectable({
  providedIn: 'root',
})
export class FacebookPixelsGateway {
  constructor(private _http: HttpClient) {}

  getFacebookPixels(options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<GetFacebookPixelsResp> {
    return this._http.get<GetFacebookPixelsResp>('/emarketing_api/datafeed/facebook_pixels/', options);
  }

  putSelectedPixel(params: PutSelectedPixelReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<undefined> {
    return this._http.put<undefined>('/emarketing_api/datafeed/facebook_pixels/selected_pixel', params, options);
  }
}
