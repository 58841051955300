<section class="flex gap-2">
  <div class="steps-list">
    <div
      class="step"
      [class.active]="selectedIndex === i"
      *ngFor="let step of steps; let i = index"
      (click)="selectStepByIndex(i)"
    >
      <em-icon
        *ngIf="showIcons"
        class="mr-4"
        [ngClass]="
          step.completed
            ? 'text-success-500'
            : step.optional
            ? ''
            : 'text-danger-500'
        "
        [name]="
          step.completed
            ? 'check_circle_outline'
            : step.optional
            ? 'remove_circle_outline'
            : 'highlight_off'
        "
      ></em-icon>
      <div>{{ step.label }}</div>
    </div>
  </div>

  <em-box size="sm" class="bg-white content">
    <div [ngTemplateOutlet]="selected ? selected.content : null"></div>
  </em-box>
</section>
