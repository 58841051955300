import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {EmButtonModule} from '../button/button.module';
import {EmMoreInfoComponent} from './more-info.component';

@NgModule({
  imports: [CommonModule, MatIconModule, EmButtonModule],
  declarations: [EmMoreInfoComponent],
  exports: [EmMoreInfoComponent],
})
export class EmMoreInfoModule {}
