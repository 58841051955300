<div class="flex items-center">
  <div class="font-semibold mr-20">
    {{ 'SHARED_LABEL_CANCEL_SUBSCRIPTION' | translate }}:
  </div>

  <em-primary-button
    color="danger"
    icon="highlight_off"
    (btnClick)="cancelSubscription()"
    >{{ 'SHARED_LABEL_CANCEL_SUBSCRIPTION' | translate }}</em-primary-button
  >
</div>
