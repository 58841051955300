import {CommonModule} from '@angular/common';
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {stripUndefinedOrNull} from '@em/shared/util-types';
import {validateEmail} from '@em/shared/ui';
import {
  PaymentDataService,
  PaymentSettings,
} from '@em/subscription/data-access';
import {TranslateModule} from '@ngx-translate/core';
import {
  EmIconModule,
  EmInputModule,
  EmPrimaryButtonComponent,
  EmSelectModule,
} from '@em/shared/ui';

const REQUIRED_FIELD_CONSTRUCT = {
  defaultValue: undefined,
  isRequired: true,
  validators: () => [Validators.required],
};

const OPTIONAL_FIELD_CONSTRUCT = {
  defaultValue: undefined,
  isRequired: false,
  validators: () => [],
};

type defaultValueType = undefined | string;

interface IFormField {
  defaultValue: defaultValueType;
  isRequired: boolean;
  validators: () => ValidatorFn[];
}

export const FORM_FIELDS: {[k: string]: IFormField} = {
  salutation: {...REQUIRED_FIELD_CONSTRUCT},
  firstName: {...REQUIRED_FIELD_CONSTRUCT},
  lastName: {...REQUIRED_FIELD_CONSTRUCT},
  street: {...REQUIRED_FIELD_CONSTRUCT},
  location: {...REQUIRED_FIELD_CONSTRUCT},
  zip: {...REQUIRED_FIELD_CONSTRUCT},
  state: {...REQUIRED_FIELD_CONSTRUCT},
  country: {...REQUIRED_FIELD_CONSTRUCT},
  phoneNumber: {...REQUIRED_FIELD_CONSTRUCT},
  company: {...OPTIONAL_FIELD_CONSTRUCT},
  job: {...OPTIONAL_FIELD_CONSTRUCT},
  vatId: {...OPTIONAL_FIELD_CONSTRUCT},
  invoiceRecipient: {
    ...OPTIONAL_FIELD_CONSTRUCT,
    defaultValue: 'GERMANY',
  },
  url: {
    ...REQUIRED_FIELD_CONSTRUCT,
    validators: () => [
      ...REQUIRED_FIELD_CONSTRUCT.validators(),
      Validators.pattern(
        new RegExp('^((?:https?:\\/\\/)?[^./]+(?:\\.[^./]+)+(?:\\/.*)?)$'),
      ),
    ],
  },
  accountingEmail: {
    ...OPTIONAL_FIELD_CONSTRUCT,
    validators: () => [validateEmail],
  },
};

@Component({
  selector: 'em-user-payment-form',
  templateUrl: './user-payment-form.component.html',
  styleUrls: ['./user-payment-form.component.scss'],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    EmInputModule,
    EmSelectModule,
    EmPrimaryButtonComponent,
    EmIconModule,
  ],
  standalone: true,
  providers: [PaymentDataService],
})
export class UserPaymentFormComponent {
  // This is being used in the payment-page
  @Output() interacted = new EventEmitter<void>();
  form: UntypedFormGroup;
  readonly salutations: Array<PaymentSettings['salutation']> = [
    'mr',
    'mrs',
    'other',
  ];
  readonly invoiceRecipients: Array<PaymentSettings['invoiceRecipient']> = [
    'GERMANY',
    'NON_EU_W_VAT',
    'NON_EU_WO_VAT',
    'THIRD_COUNTRY',
  ];
  @Input() inModal = true;
  private _isLoading = true;

  constructor(
    private readonly _fb: UntypedFormBuilder,
    private readonly _paymentData: PaymentDataService,
  ) {
    this.form = this._buildForm();

    this._paymentData.getSettings().subscribe((blob) => {
      this._initializeData(blob);
      this._isLoading = false;
    });
  }

  get canSubmit() {
    return this.form.valid && !this._isLoading;
  }

  isRequired(control: string): boolean {
    return FORM_FIELDS[control].isRequired;
  }

  submit() {
    if (this.canSubmit) {
      this._isLoading = true;
      this._paymentData
        .patchSettings(stripUndefinedOrNull(this.form.value))
        .subscribe(() => {
          this.interacted.emit();
          this._isLoading = false;
        });
    }
  }

  cancel() {
    this.interacted.emit();
  }

  private _buildForm(): UntypedFormGroup {
    const controls: {[k: string]: [defaultValueType, ValidatorFn[]]} = {};

    Object.keys(FORM_FIELDS).forEach((key) => {
      const {defaultValue, validators} = FORM_FIELDS[key];
      controls[key] = [defaultValue, validators()];
    });

    return this._fb.group(controls);
  }

  private _initializeData(settings: PaymentSettings) {
    const values: {[k: string]: defaultValueType | unknown} = {};
    Object.keys(settings).forEach(
      (key) =>
        (values[key] = (settings[key] ||
          FORM_FIELDS[key].defaultValue) as defaultValueType),
    );

    this.form.patchValue({...values});
  }
}
