<em-button
  type="text"
  color="basic"
  [matMenuTriggerFor]="countriesMenu"
  aria-label="Regions Menu"
>
  <em-country-flag class="ml-2" [countryCode]="currentCountry | async">
  </em-country-flag>

  <span class="label">{{ 'NAV_REGION' | translate }}</span>
</em-button>

<mat-menu #countriesMenu="matMenu">
  <div class="menu-header" *ngIf="currentCountry | async as currentCountry">
    <div class="label"><span translate>NAVIGATION_CURRENT_COUNTRY</span>:</div>
    <div class="country-container">
      <em-country-flag [countryCode]="currentCountry"></em-country-flag>
      <span class="country-label" [translate]="i18nKey(currentCountry)"></span>
      <em-icon class="text-success-500 ml-auto" name="check_circle"></em-icon>
    </div>
  </div>

  <mat-divider></mat-divider>

  <ng-container *ngIf="countries | async as countries">
    <button
      mat-menu-item
      class="country-container"
      *ngFor="let countryCode of countries | orderByTranslated: 'COUNTRY_'"
      (click)="select(countryCode)"
    >
      <div class="flex">
        <em-country-flag [countryCode]="countryCode"></em-country-flag>
        <span class="country-label">{{
          i18nKey(countryCode) | translate
        }}</span>
      </div>
    </button>
  </ng-container>
</mat-menu>
