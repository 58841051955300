import {
  ProductsListFilter,
  ProductsListBooleanFilterCols,
} from '@em/shared/api-interface';
import {NumberFilterModel} from 'ag-grid-community';
import {BooleanFilterType} from '../boolean-filter-type';

export function addBooleanFilter(
  filter: NonNullable<ProductsListFilter>,
  key: ProductsListBooleanFilterCols,
  filterModel: NumberFilterModel,
) {
  switch (filterModel.type as BooleanFilterType) {
    case 'eq_true':
    case 'eq_false':
      filter[key] = {
        operator: 'eq',
        value:
          (filterModel.type as BooleanFilterType) === 'eq_true' ? true : false,
      };
      break;
    case 'boolean_blank':
      filter[key] = {
        operator: 'is_empty',
      };
      break;
    case 'boolean_notBlank':
      filter[key] = {
        operator: 'not_empty',
      };
      break;
    default:
  }
}
