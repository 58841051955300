import {createFeatureSelector, createSelector} from '@ngrx/store';
import {EnterpriseState} from './enterprise.reducers';

export const enterpriseState = createFeatureSelector<EnterpriseState>('enterprise');

export const getEnterpriseState = createSelector(
  enterpriseState,
  (state) => state,
);

export const getAdwordsAccounts = createSelector(
  enterpriseState,
  (state) => state.adwordsAccounts.list,
);

export const getHasOauthToken = createSelector(
  enterpriseState,
  (state) => state.hasOauthToken,
);

export const getAccessToken = createSelector(
  enterpriseState,
  (state) => state.oauth.accessToken,
);

export const getSdkLoading = createSelector(
  enterpriseState,
  (state) => state.sdk.loading,
);

export const getSdkReady = createSelector(
  enterpriseState,
  (state) => state.sdk.ready,
);

export const getAdwordsAccountsLoaded = createSelector(
  enterpriseState,
  (state) => state.adwordsAccounts.loaded,
);

export const getPerformanceData = createSelector(
  enterpriseState,
  (state) => state.performanceData,
);

export const getAdwordsAccountsLoading = createSelector(
  enterpriseState,
  (state) => state.adwordsAccounts.loading,
);

export const getOauthState = createSelector(
  enterpriseState,
  (state) => state.oauth.state,
);

export const getAuthorizationState = createSelector(
  enterpriseState,
  (state) => state.authorization.state,
);
