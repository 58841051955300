import {
  ProductsListFetchStatusFilterCols,
  ProductsListFilter,
} from '@em/shared/api-interface';
import {NumberFilterModel} from 'ag-grid-community';
import {FetchStatusFilterType} from '../fetch-status-filter-type';

export function addFetchStatusFilter(
  filter: NonNullable<ProductsListFilter>,
  key: ProductsListFetchStatusFilterCols,
  filterModel: NumberFilterModel,
) {
  switch (filterModel.type as FetchStatusFilterType) {
    case 'eq_found':
      filter[key] = {
        operator: 'eq',
        value: 'found',
      };
      break;
    case 'eq_not_found':
      filter[key] = {
        operator: 'eq',
        value: 'not_found',
      };
      break;
    case 'eq_error':
      filter[key] = {
        operator: 'eq',
        value: 'error',
      };
      break;
    case 'fetch_status_blank':
      filter[key] = {
        operator: 'is_empty',
      };
      break;
    case 'fetch_status_notBlank':
      filter[key] = {
        operator: 'not_empty',
      };
      break;
    default:
  }
}
