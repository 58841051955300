<mat-sidenav-container [autosize]="isAutoSize">
  <!-- Side Menu-->
  <mat-sidenav
    [ngClass]="{shrink: (isSmall$ | async)}"
    #snav
    [opened]="isOpen$ | async"
    [mode]="navMode$ | async"
    [autoFocus]="false"
    (closedStart)="sideNavClosed()"
    (mouseenter)="mouseOverSideBar()"
    (mouseleave)="mouseOutSideBar()"
    *ngIf="(showMenu$ | async) && !hideNavigation"
  >
    <div class="side-nav-wrapper">
      <!-- Upper Left Menu-->
      <div>
        <div
          class="shrink-icon"
          (click)="toggleShrinkMenu()"
          [ngClass]="{shrink: isShrinkMode$ | async}"
          *ngIf="(showMobileMenuIcon$ | async) !== true"
        >
          <em-button
            type="icon"
            color="basic"
            class="icon-open"
            icon="keyboard_double_arrow_right"
          ></em-button>
          <em-button
            type="icon"
            color="basic"
            class="icon-close"
            icon="keyboard_double_arrow_left"
          ></em-button>
        </div>

        <a class="logo-link" [routerLink]="['/']">
          <img [src]="logoUrl$ | async" />
        </a>

        <mat-nav-list>
          <ng-container
            *ngFor="
              let item of mainItemsObservable$ | async;
              trackBy: trackByIndex
            "
          >
            <em-nav-item
              [item]="item"
              [disabled]="disabled"
              [hideNested]="isSmall$ | async"
              (itemClicked)="menuItemClicked()"
            ></em-nav-item>
          </ng-container>
        </mat-nav-list>
      </div>

      <!-- Lower Left Menu-->
      <div class="side-bottom-menu">
        <em-country-selection *ngIf="!disabled"></em-country-selection>

        <em-button
          color="basic"
          type="link"
          icon="help_outline"
          linkUrl="https://support.emarketing.com"
          linkTarget="_blank"
        >
          <span class="btn-label">{{ 'NAV_SUPPORT' | translate }}</span>
        </em-button>
      </div>
    </div>
  </mat-sidenav>

  <mat-sidenav-content id="scrollable-content">
    <!-- Top Menu-->
    <mat-toolbar *ngIf="(showMenu$ | async) && !hideNavigation">
      <em-button
        class="mobile-menu-icon"
        type="icon"
        color="basic"
        icon="menu"
        *ngIf="showMobileMenuIcon$ | async"
        (click)="toggleMenu()"
      >
      </em-button>

      <!-- Header part will display the router outlet 'header' if provided or default title-->
      <ng-container *ngIf="(headerOutletActive$ | async) !== true">
        <ng-container *ngIf="activeItem$ | async as activeItem">
          <img
            class="header-icon"
            *ngIf="activeItem.iconImage"
            [src]="activeItem.iconImage"
          />
          {{ activeItem.pageTitle || activeItem.title | translate }}
        </ng-container>
      </ng-container>
      <router-outlet
        name="header"
        (activate)="headerOutletActive(true)"
        (deactivate)="headerOutletActive(false)"
      ></router-outlet>

      <span class="spacer"></span>
      <ng-container *ngIf="isAdmin()">
        <mat-icon class="cursor-pointer mr-2" (click)="goToStaging()"
          >commit</mat-icon
        >
        <mat-icon class="admin-icon">admin_panel_settings</mat-icon>
      </ng-container>

      <em-user-menu icon="account_circle" [menuItems]="userMenuItems$ | async">
        <mat-divider></mat-divider>
        <button mat-menu-item class="logout-btn" (click)="logout()">
          <mat-icon class="!text-danger-500">power_settings_new</mat-icon>
          <span class="text-danger-500">{{
            'ACTIONS_LOGOUT' | translate
          }}</span>
        </button>
      </em-user-menu>
    </mat-toolbar>

    <!-- Actual Conten of the page-->
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
