import {INumberFilterParams} from 'ag-grid-community';
import {BACKEND_TO_GRID_NUMBER_OPERATOR} from '../../../consts/operator-maps';
import {getBasicFilterParams} from '../../../get-basic-filter-params/get-basic-filter-params';

export function getNumberFilterParams(): INumberFilterParams {
  return {
    ...getBasicFilterParams(),
    filterOptions: Object.values(BACKEND_TO_GRID_NUMBER_OPERATOR),
  };
}
