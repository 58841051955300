/* tslint:disable */
/**
* This file was automatically generated.
* DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
* and run the generate-code script again.
*/
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {GetNotificationsReq} from '../types/request-objects/workflows/get-notifications';
import {GetNotificationsResp} from '../types/view-models/workflows/get-notifications';
import {GetStatusReq} from '../types/request-objects/workflows/get-status';
import {GetStatusResp} from '../types/view-models/workflows/get-status';
import {GetReq as GetWorkflowsReq} from '../types/request-objects/workflows/get';
import {GetResp as GetWorkflowsResp} from '../types/view-models/workflows/get';

@Injectable({
  providedIn: 'root',
})
export class WorkflowsGateway {
  constructor(private _http: HttpClient) {}

  getNotifications(params: GetNotificationsReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<GetNotificationsResp> {
    const request = params as any as { [param: string]: string | string[]; };
    return this._http.get<GetNotificationsResp>('/emarketing_api/workflows/notifications', {...options, params: request});
  }

  getStatus(params: GetStatusReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<GetStatusResp> {
    const request = params as any as { [param: string]: string | string[]; };
    return this._http.get<GetStatusResp>('/emarketing_api/workflows/status', {...options, params: request});
  }

  getWorkflows(params: GetWorkflowsReq, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<GetWorkflowsResp> {
    const request = params as any as { [param: string]: string | string[]; };
    return this._http.get<GetWorkflowsResp>('/emarketing_api/workflows/', {...options, params: request});
  }
}
