import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MATERIAL_SANITY_CHECKS} from '@angular/material/core';
import {MatCardModule} from '@angular/material/card';
import {
  EmCardActionsDirective,
  EmCardComponent,
  EmCardSubTitleDirective,
  EmCardTitleDirective,
} from './card.component';

@NgModule({
  imports: [CommonModule, MatCardModule],
  declarations: [
    EmCardComponent,
    EmCardTitleDirective,
    EmCardSubTitleDirective,
    EmCardActionsDirective,
  ],
  exports: [
    EmCardComponent,
    EmCardTitleDirective,
    EmCardSubTitleDirective,
    EmCardActionsDirective,
  ],
  providers: [{provide: MATERIAL_SANITY_CHECKS, useValue: false}],
})
export class EmCardModule {}
