import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'orderBy',
})
export class OrderByPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(arr: any[], field?: string, direction: 'asc' | 'desc' = 'asc'): any[] {
    if (!field) return arr;

    return arr.sort((a, b) => {
      const fieldA = `${a[field]}`.toLowerCase();
      const fieldB = `${b[field]}`.toLowerCase();

      return fieldA !== fieldB
        ? fieldA < fieldB
          ? direction === 'asc' ? -1 : 1
          : direction === 'desc' ? -1 : 1
        : 0;
    });
  }

}
