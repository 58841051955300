import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {EmIconModule} from '../icon/icon.component';
import {EmInfoBoxComponent} from './info-box.component';

@NgModule({
  imports: [CommonModule, EmIconModule],
  declarations: [EmInfoBoxComponent],
  exports: [EmInfoBoxComponent],
})
export class EmInfoBoxModule {}
