import {IPlatform} from '../types/platform';

export const FACEBOOK: Readonly<IPlatform> = {
  id: 'facebook',
  name: 'Facebook',
  i18nKey: 'FACEBOOK',
  colors: {
    charts: '#1778f2',
  },
  logos: {
    general: 'assets/images/facebook/facebook_logo_small.svg',
    mono: 'assets/images/facebook/facebook_mono.svg',
    campaign: 'assets/images/facebook/facebook-instagram-icon.svg',
    withInstagram: 'assets/images/facebook/facebook-instagram-icon.svg',
  },
  supportsKpis: true,
  supportsPerformanceTimeline: true,
  supportsMultiCampaignTypes: true,
};
