import {ValidationErrors, ValidatorFn} from '@angular/forms';
import {
  PRODUCT_FILTER_DEFINITIONS,
  validateAgainst,
} from './product-filter-definitions';
import {getProductFilterErrorKey} from './product-filter-error';
import {IGenericFilterSetting} from '@em/data-feed/data-access-products';

export const filterValidator: ValidatorFn = (control) => {
  if (!control.value) return null;
  const errorList = validateAgainst(
    control.value as IGenericFilterSetting[],
    PRODUCT_FILTER_DEFINITIONS,
  );
  if (!errorList.length) return null;

  const errors: ValidationErrors = {};
  for (const err of errorList) {
    errors[getProductFilterErrorKey(err)] = {option: err.option.name};
  }

  return errors;
};

export const minElements: (min: number) => ValidatorFn = (min) => (control) => {
  if (!control.value) {
    return {noFilterSelected: {}};
  }

  if (control.value.length < min) {
    return {noFilterSelected: {}};
  }

  return null;
};

interface IBestPriceSetting {
  key: 'best_price';
  options: {
    tolerance_enabled?: boolean;
    tolerance_percent?: number;
    shipping_costs_excluded?: boolean;
  };
}

export const bestPriceFilterValidator: ValidatorFn = (control) => {
  if (!control.value) return null;

  const filters = control.value as IGenericFilterSetting[];
  const bestPrice = filters.find((f) => f.key === 'best_price') as
    | IBestPriceSetting
    | undefined;

  if (!bestPrice) return null;
  if (!bestPrice.options.tolerance_enabled) return null;

  if (bestPrice.options.tolerance_percent) return null;

  return {bestPriceTolerancePercent: {}};
};
