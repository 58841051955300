import {Injectable} from '@angular/core';
import {SessionEvent} from '@em/auth/data-access';
import {SessionService} from '@em/auth/data-access';
import {
  NotificationKey,
  NotificationRespType,
  WorkflowType,
} from '@em/shared/api-interface';
import {WorkflowsGateway} from '@em/shared/api-interface/lib/gateways/workflows.gateway';
import {GetNotificationsResp} from '@em/shared/api-interface/lib/types/view-models/workflows/get-notifications';
import {
  ConnectableObservable,
  EMPTY,
  Observable,
  Subject,
  combineLatest,
  from,
  merge,
  of,
} from 'rxjs';
import {
  concatMap,
  filter,
  first,
  map,
  publishReplay,
  scan,
  startWith,
  switchMap,
} from 'rxjs/operators';
import {BatchCompletedNotification} from '../notification-types/batch-completed-notification';
import {CategoryFetchNotification} from '../notification-types/category-fetch-notification';
import {CompetitorCsvNotification} from '../notification-types/competitor-csv-notification';
import {PriceFetchNotification} from '../notification-types/price-fetch-notification';
import {ProductCsvNotification} from '../notification-types/product-csv-notification';
import {ProductFetchNotification} from '../notification-types/product-fetch-notification';
import {WorkflowCompletedNotification} from '../notification-types/workflow-completed-notification';
import {NotificationModel} from '../notification.model';
import {
  IPusherNotification,
  PusherService,
} from '../pusher-service/pusher.service';
import {IInitializable} from '@em/shared/util-types';
import {SetupStatusService} from '@em/auth/data-access';

type respNotificationFactory = (
  resp: NotificationRespType,
) => NotificationModel;
type pusherNotificationFactory = (
  pusher: IPusherNotification,
) => NotificationModel;

@Injectable({
  providedIn: 'root',
})
export class NotificationsService implements IInitializable {
  name = 'Notifications Service';
  private readonly _loggedOut: Observable<SessionEvent>;
  private readonly _loggedIn: Observable<SessionEvent>;
  private readonly _pusherFactories: {
    [key: string]: pusherNotificationFactory;
  } = {
    [ProductFetchNotification.key]:
      ProductFetchNotification.productFetchFromPusher,
    [CategoryFetchNotification.key]:
      CategoryFetchNotification.categoryFetchFromPusher,
    [WorkflowCompletedNotification.key]:
      WorkflowCompletedNotification.workflowCompletedFromPusher,
    [ProductCsvNotification.key]: ProductCsvNotification.productCsvFromPusher,
    [CompetitorCsvNotification.key]:
      CompetitorCsvNotification.competitorCsvFromPusher,
    [PriceFetchNotification.key]: PriceFetchNotification.priceFetchFromPusher,
    [BatchCompletedNotification.key]:
      BatchCompletedNotification.batchCompletedFromPusher,
  };
  private readonly _observable: ConnectableObservable<NotificationModel[]>;
  private readonly _refreshObservable: Subject<void> = new Subject<void>();
  private readonly _respFactories: {[key: string]: respNotificationFactory} = {
    [ProductFetchNotification.key]:
      ProductFetchNotification.productFetchFromResp,
    [CategoryFetchNotification.key]:
      CategoryFetchNotification.categoryFetchFromResp,
    [WorkflowCompletedNotification.key]:
      WorkflowCompletedNotification.workflowCompletedFromResp,
    [ProductCsvNotification.key]: ProductCsvNotification.productCsvFromResp,
    [CompetitorCsvNotification.key]:
      CompetitorCsvNotification.competitorCsvFromResp,
    [PriceFetchNotification.key]: PriceFetchNotification.priceFetchFromResp,
  };

  constructor(
    private readonly _session: SessionService,
    private readonly _setupStatusService: SetupStatusService,
    private readonly _pusher: PusherService,
    private readonly _workflowsGateway: WorkflowsGateway,
  ) {
    this._loggedIn = _session.sessionReady;
    this._loggedOut = _session.sessionEnded;

    this._observable = combineLatest([
      this._refreshObservable.pipe(switchMap(() => this._loadNotifications())),
      this.newNotificationListObservable().pipe(
        startWith([] as NotificationModel[]),
        scan((a: NotificationModel[], b: NotificationModel[]) => a.concat(b)),
      ),
    ]).pipe(
      map(([existing, incoming]) => this._mergeIncoming(existing, incoming)),
      publishReplay(1),
    ) as ConnectableObservable<NotificationModel[]>;

    this._initSession();
  }

  initialize(): Observable<void> {
    // Legacy: Reload Application SetupStaus on every workflow completion
    this.newNotificationListObservable()
      .pipe(
        concatMap((notifications) => from(notifications)),
        filter(
          (notification) =>
            notification instanceof WorkflowCompletedNotification,
        ),
      )
      .subscribe(() => {
        this._setupStatusService.invalidateSetupStatus();
      });

    return of(undefined);
  }

  scopedNotifications(key: NotificationKey): Observable<NotificationModel[]> {
    return combineLatest([
      this._loadNotifications(key),
      this.newNotificationListObservable().pipe(
        startWith([] as NotificationModel[]),
        scan((a: NotificationModel[], b: NotificationModel[]) => a.concat(b)),
      ),
    ]).pipe(
      map(([existing, incoming]) => this._mergeIncoming(existing, incoming)),
    ) as ConnectableObservable<NotificationModel[]>;
  }

  newNotificationListObservable(): Observable<NotificationModel[]> {
    return this._pusher.notifications().pipe(
      filter(
        (notification) =>
          notification.owner_uuid === this._session.getMerchantUuid(),
      ),
      map((notification) => this._buildEntitiesFromPusher([notification])),
    );
  }

  // emit the workflow completion notification of a specific workflow uuid
  workflowCompletedNotification(
    workflowUuid: string,
  ): Observable<WorkflowCompletedNotification | null> {
    return this._pusher.notifications().pipe(
      filter(
        (notification) =>
          notification.key === WorkflowCompletedNotification.key &&
          notification.owner_uuid === this._session.getMerchantUuid() &&
          notification?.workflow_uuid === workflowUuid,
      ),
      map(
        (notification) =>
          this._buildEntityFromPusher(
            notification,
          ) as WorkflowCompletedNotification,
      ),
    );
  }

  typedWorkflowCompletedNotification(
    workflowType: WorkflowType,
    onlyNew: boolean = false,
  ): Observable<WorkflowCompletedNotification | null> {
    return merge(
      onlyNew
        ? EMPTY
        : this._loadNotifications(WorkflowCompletedNotification.key).pipe(
            concatMap((list) => from(list)),
            filter(
              (not) =>
                (not as WorkflowCompletedNotification).workflowType ===
                workflowType,
            ),
            map((v) => v as WorkflowCompletedNotification),
          ),
      this._pusher.notifications().pipe(
        filter(
          (notification) =>
            notification.key === WorkflowCompletedNotification.key &&
            notification.owner_uuid === this._session.getMerchantUuid() &&
            notification['workflow_type'] === workflowType,
        ),
        map(
          (notification) =>
            this._buildEntityFromPusher(
              notification,
            ) as WorkflowCompletedNotification,
        ),
      ),
    );
  }

  newNotificationObservable<T extends NotificationModel>(): Observable<T> {
    return this.newNotificationListObservable().pipe(
      concatMap((list) => from(list)),
      map((notification) => notification as T),
    );
  }

  observable(): Observable<NotificationModel[]> {
    return this._observable;
  }

  refresh() {
    this._refreshObservable.next();
  }

  _initSession() {
    this._loggedIn.subscribe(() => {
      this._onLogin();
    });
  }

  protected _onLogin() {
    const disposable = this._observable.connect();
    this._refreshObservable.next();

    this._loggedOut.pipe(first()).subscribe(() => disposable.unsubscribe());
  }

  private _buildEntities(
    notifications: GetNotificationsResp,
  ): NotificationModel[] {
    const entities: NotificationModel[] = [];

    for (const resp of notifications) {
      const factory = this._respFactories[resp.key];

      if (factory) entities.push(factory(resp));
    }

    return entities;
  }

  private _buildEntitiesFromPusher(
    notifications: IPusherNotification[],
  ): NotificationModel[] {
    const entities: NotificationModel[] = [];

    for (const resp of notifications) {
      const factory = this._pusherFactories[resp.key];

      if (factory) entities.push(factory(resp));
    }

    return entities;
  }

  private _buildEntityFromPusher(
    notification: IPusherNotification,
  ): NotificationModel | null {
    const factory = this._pusherFactories[notification.key];

    if (factory) return factory(notification);

    return null;
  }

  private _loadNotifications(key?: string): Observable<NotificationModel[]> {
    const params = key ? {key} : {};
    return this._workflowsGateway
      .getNotifications(params)
      .pipe(map((notifications) => this._buildEntities(notifications)));
  }

  private _mergeIncoming(
    existing: NotificationModel[],
    incoming: NotificationModel[],
  ): NotificationModel[] {
    return [...incoming.concat(existing)].sort((n1, n2) =>
      n1.createdAt > n2.createdAt ? -1 : 1,
    );
  }
}
