import {Component, inject} from '@angular/core';
import {MatTooltipModule} from '@angular/material/tooltip';
import {DownloadCsvService} from '@em/data-feed/data-access-products';
import {EmIconButtonComponent} from '@em/shared/ui';
import {PaymentService} from '@em/subscription/feature';
import {TranslateModule} from '@ngx-translate/core';
import {EMPTY, switchMap} from 'rxjs';

@Component({
  selector: 'em-csv-download',
  standalone: true,
  imports: [TranslateModule, MatTooltipModule, EmIconButtonComponent],
  templateUrl: './csv-download.component.html',
  styleUrls: ['./csv-download.component.scss'],
  providers: [DownloadCsvService],
})
export class EmCsvDownloadComponent {
  private readonly _downloadService = inject(DownloadCsvService);
  private readonly _paymentService = inject(PaymentService);

  downloadClick() {
    this._paymentService
      .mustHavePlan()
      .pipe(
        switchMap((hasPlan) => {
          if (hasPlan) {
            return this._downloadService.downloadCsv();
          }
          return EMPTY;
        }),
      )
      .subscribe();
  }
}
