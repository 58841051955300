import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {MerchantsGateway} from '@em/shared/api-interface/lib/gateways/merchants.gateway';
import {PatchSettingsReq} from '@em/shared/api-interface/lib/types/request-objects/merchants/patch-settings';
import {GetSettingsResp} from '@em/shared/api-interface/lib/types/view-models/merchants/get-settings';

export type PaymentSettings = Exclude<GetSettingsResp['invoice_details'], null>;

@Injectable()
export class PaymentDataService {
  constructor(private readonly _merchantsGateway: MerchantsGateway) {}

  getSettings(): Observable<PaymentSettings> {
    return this._merchantsGateway.getSettings().pipe(
      map(({invoice_details}) => ({
        company: invoice_details?.company,
        salutation: invoice_details?.salutation,
        firstName: invoice_details?.firstName,
        lastName: invoice_details?.lastName,
        job: invoice_details?.job,
        street: invoice_details?.street,
        location: invoice_details?.location,
        zip: invoice_details?.zip,
        state: invoice_details?.state,
        country: invoice_details?.country,
        vatId: invoice_details?.vatId,
        url: invoice_details?.url,
        phoneNumber: invoice_details?.phoneNumber,
        invoiceRecipient: invoice_details?.invoiceRecipient,
        accountingEmail: invoice_details?.accountingEmail,
      })),
    );
  }

  patchSettings(data: PaymentSettings) {
    const params: PatchSettingsReq = {
      invoice_details: {
        ...data,
      },
    };
    return this._merchantsGateway.patchSettings(params);
  }
}
