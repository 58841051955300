export const availableCountryCodes = [
  'AE',
  'AR',
  'AT',
  'AU',
  'BE',
  'BR',
  'CA',
  'CH',
  'CL',
  'CO',
  'CZ',
  'DE',
  'DK',
  'ES',
  'FI',
  'FR',
  'GB',
  'GR',
  'HK',
  'HU',
  'ID',
  'IE',
  'IL',
  'IN',
  'IT',
  'JP',
  'KR',
  'MX',
  'MY',
  'NL',
  'NO',
  'NZ',
  'PH',
  'PL',
  'PT',
  'RO',
  'RU',
  'SA',
  'SE',
  'SG',
  'SK',
  'TR',
  'TW',
  'UA',
  'US',
  'ZA',
] as const;

export type CountryCode = typeof availableCountryCodes[number];
