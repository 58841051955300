<form [formGroup]="form" *ngIf="form">
  <label>{{ 'ONBOARDING_CHANGE_LANGUAGE_LABEL' | translate }}</label>

  <em-select formControlName="language">
    <em-option>--</em-option>
    <em-option *ngFor="let language of languages" [value]="language">
      <div class="label">{{ i18nLanguageKey(language) | translate }}</div>
    </em-option>

    <em-error *ngIf="form?.controls.language.hasError('required')">{{
      'VALIDATION_FIELD_REQUIRED' | translate
    }}</em-error>
  </em-select>
</form>
