import {PluginName} from '../types';

export function getPluginLogo(plugin: PluginName | undefined): string {
  switch (plugin) {
    case 'plentymarkets':
      return 'assets/images/shops/plentymarkets/logo-plentymarkets.svg';
    case 'jtl_4':
    case 'jtl_5':
      return 'assets/images/shops/jtl/logo-jtl.png';
    case 'shopware':
      return 'assets/images/shops/shopware/logo-shopware.svg';
    case 'shopify':
      return 'assets/images/shops/shopify/logo-shopify.svg';
    case 'magento':
      return 'assets/images/shops/magento/logo-magento.svg';
    case 'prestashop':
      return 'assets/images/shops/prestashop/logo-prestashop.svg';
    case 'lightspeed':
      return 'assets/images/shops/lightspeed/lightspeed-logo.svg';
    default:
      return '';
  }
}
