import {CurrencyPipe} from '@angular/common';
import {Injectable} from '@angular/core';
import {ComponentStore} from '@ngrx/component-store';
import {TranslateService} from '@ngx-translate/core';
import {ChartType} from 'ng-apexcharts';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {CompetitorChartOptions, CompetitorsLandscapeState} from './state';
import {CompetitorDetails} from './types';
import {scatterChartOptions} from '../../utils/apex-charts.helper';

interface TooltipDetails {
  shopName: string;
  shopDomain?: string;
  price: number | null | undefined;
  shipping: number | null | undefined;
}

@Injectable()
export class CompetitorsLandscapeStore extends ComponentStore<CompetitorsLandscapeState> {
  readonly chartData$: Observable<CompetitorsLandscapeState | undefined> =
    this.select((state) => state);

  private _minPrice: number | undefined;
  private _maxPrice: number | undefined;
  private _minYAxis: number | undefined;
  private _maxYAxis: number | undefined;

  private readonly outliers: number[][] = [];
  private readonly otherCompetitors: number[][] = [];
  private readonly tooltipDetailsMap = new Map<number, TooltipDetails>();

  constructor(
    private readonly _currencyPipe: CurrencyPipe,
    private readonly _translateService: TranslateService,
  ) {
    super();
  }

  readonly generateChart = this.effect<{
    chartData: CompetitorDetails[];
    chartOptions: CompetitorChartOptions;
  }>((trigger$) =>
    trigger$.pipe(
      tap(
        (trigger: {
          chartData: CompetitorDetails[];
          chartOptions: CompetitorChartOptions;
        }) => {
          this.setState({
            series: this.getSeriesData(
              trigger?.chartData,
              trigger?.chartOptions,
            ),
            config: this.getChartConfig(trigger?.chartOptions),
          });
        },
      ),
    ),
  );

  readonly updateDisplayOptions = this.effect<CompetitorChartOptions>(
    (displayOptions$) =>
      displayOptions$.pipe(
        tap((displayOptions: CompetitorChartOptions) => {
          this.patchState({
            config: this.getChartConfig(displayOptions),
          });
        }),
      ),
  );

  private getSeriesData(
    chartData: CompetitorDetails[],
    chartOptions: CompetitorChartOptions,
  ) {
    this._maxPrice =
      (chartOptions.productData.sale_price ||
        chartOptions.productData.price ||
        0) + (chartOptions.productData.shipping || 0);
    this._minPrice = this._maxPrice;

    this._splitCompetitors(chartData, chartOptions);

    this.calculateMinMaxAxis(chartOptions);

    const yourPriceArr = [
      chartData.length ? Math.floor(chartData.length / 2) : 1,

      (chartOptions.productData.sale_price ||
        chartOptions.productData.price ||
        0) + (chartOptions.productData.shipping || 0),
    ];
    const yourPriceDetails: TooltipDetails = {
      shopName: 'Your Price',
      price:
        chartOptions.productData.sale_price || chartOptions.productData.price,
      shipping: chartOptions.productData.shipping,
    };
    this.tooltipDetailsMap.set(yourPriceArr[0], yourPriceDetails);

    return [
      {
        name: 'Your Price',
        data: [yourPriceArr],
      },
      {
        name: 'Competitors',
        data: this.otherCompetitors,
      },
      {
        name: 'Outliers',
        data: this.outliers,
      },
    ];
  }

  private _splitCompetitors(
    chartData: CompetitorDetails[],
    chartOptions: CompetitorChartOptions,
  ) {
    chartData.forEach((element, index) => {
      const finalX =
        index >= Math.floor(chartData.length / 2) ? index + 1 : index;
      const finalY = element.price + (element.shipping || 0);

      if (!this._minPrice || finalY < this._minPrice) {
        this._minPrice = finalY;
      }

      if (!this._maxPrice || finalY > this._maxPrice) {
        this._maxPrice = finalY;
      }

      if (
        (chartOptions.outlierUpperBound &&
          finalY > chartOptions.outlierUpperBound) ||
        (chartOptions.outlierUpperBound &&
          finalY < chartOptions.outlierLowerBound)
      ) {
        this.outliers.push([finalX, finalY]);
      } else {
        this.otherCompetitors.push([finalX, finalY]);
      }

      this.tooltipDetailsMap.set(finalX, {
        price: element.price,
        shipping: element.shipping,
        shopDomain: element.shop_domain,
        shopName: element.shop_name,
      });
    });
  }

  private getChartConfig(chartOptions: CompetitorChartOptions) {
    const competitorsLength =
      this.otherCompetitors.length + this.outliers.length;
    return {
      chart: {
        height: chartOptions.height || scatterChartOptions.chart?.height,
        type: 'scatter' as ChartType,
      },
      tooltip: {
        custom: this.tooltipGenerator(
          this.tooltipDetailsMap,
          this._currencyPipe,
          this._translateService,
          chartOptions.currency,
        ),
        followCursor: true,
      },
      toolbar: {show: false},
      yaxis: {
        labels: {
          formatter: this.yAxisFormatter(
            this._currencyPipe,
            chartOptions.currency,
          ),
        },
        min: this._minYAxis,
        max: this._maxYAxis,
      },
      xaxis: {
        labels: {
          show: false,
          minHeight: 30,
        },
        crosshairs: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
        min: competitorsLength ? -1 : 0,
        max: competitorsLength ? competitorsLength + 1 : 2,
      },
      colors: ['#ff7900', '#333c4e', '#9ea1a9'],
      legend: {
        show: true,
      },
      annotations: {
        position: 'back',
        yaxis: [
          ...this.getOutlierAnnotations(chartOptions),
          ...this.getMostlyUsedPriceAnnotation(chartOptions),
          ...(chartOptions.productData.sale_price ||
          chartOptions.productData.price
            ? [
                {
                  y:
                    (chartOptions.productData.sale_price ||
                      chartOptions.productData.price ||
                      0) + (chartOptions.productData.shipping || 0),
                  borderColor: '#ff7900',
                  strokeDashArray: 0,
                  label: {
                    text: 'Your Price',
                    style: {
                      color: '#fff',
                      background: '#ff7900',
                    },
                  },
                },
              ]
            : []),
        ],
      },
    };
  }

  private tooltipGenerator(
    detailsMap: Map<number, TooltipDetails>,
    currencyPipe: CurrencyPipe,
    translateService: TranslateService,
    currency: string = '',
  ) {
    return ({
      series,
      seriesIndex,
      dataPointIndex,
      w,
    }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any) => {
      const details = detailsMap.get(
        w.config.series[seriesIndex].data[dataPointIndex][0],
      );
      const competitorName = details?.shopName;
      const price = details?.price || 0;
      const priceIncShipping = price + (details?.shipping || 0) || 0;
      const domain = details?.shopDomain || '';
      const domainHTML = domain
        ? `<div>${translateService.instant(
            'SHARED_LABEL_DOMAIN',
          )}: <strong>${domain}</strong></div>`
        : '';
      return `<div class="chart-custom-tooltip">
                <div class="tooltip-title"><strong>${competitorName}</strong></div>
                <br/>
                <div>${translateService.instant(
                  'SHARED_LABEL_PRICE_INC_SHIPPING',
                )}: <strong>${currencyPipe.transform(
        priceIncShipping,
        currency,
      )}</strong></div>
                <div>${translateService.instant(
                  'LABEL_PRICE',
                )}: <strong>${currencyPipe.transform(
        price,
        currency,
      )}</strong></div>
                ${domainHTML}
              </div>`;
    };
  }

  private yAxisFormatter(currencyPipe: CurrencyPipe, currency: string = '') {
    return (val: number, index: number) =>
      currencyPipe.transform(val, currency) || '';
  }

  private calculateMinMaxAxis(chartOptions: CompetitorChartOptions) {
    const yMinValue = Math.min(
      chartOptions.outlierLowerBound,
      this._minPrice || 0,
    );
    const yMaxValue = Math.max(
      chartOptions.outlierUpperBound,
      this._maxPrice || 0,
    );
    const yDiff = (yMaxValue || 0) - (yMinValue || 0);
    this._minYAxis = Math.max((yMinValue || 0) - 0.15 * yDiff, 0);
    this._maxYAxis = (yMaxValue || 0) + 0.15 * yDiff;
  }

  private getOutlierAnnotations(chartOptions: CompetitorChartOptions) {
    const annotations = [];
    if (chartOptions.displayOptions.outliers) {
      if (chartOptions.outlierUpperBound) {
        annotations.push({
          y: chartOptions.outlierUpperBound,
          borderColor: '#72b0ff',
          strokeDashArray: 0,
          label: {
            text: 'Outliers Upper Bound',
            style: {
              color: '#fff',
              background: '#308bff',
            },
          },
        });
      }

      if (chartOptions.outlierLowerBound) {
        annotations.push({
          y: chartOptions.outlierLowerBound,
          borderColor: '#72b0ff',
          strokeDashArray: 0,
          label: {
            text: 'Outliers Lower Bound',
            offsetY: 16,
            style: {
              color: '#fff',
              background: '#308bff',
            },
          },
        });
      }
    }

    return annotations;
  }

  private getMostlyUsedPriceAnnotation(chartOptions: CompetitorChartOptions) {
    return chartOptions.displayOptions.mostlyUsedPrice &&
      chartOptions.mostUsedPriceLowerBound
      ? [
          {
            y: chartOptions.mostUsedPriceLowerBound,
            y2:
              chartOptions.mostUsedPriceUpperBound ===
              chartOptions.mostUsedPriceLowerBound
                ? undefined
                : chartOptions.mostUsedPriceUpperBound,
            fillColor: '#c4ecd6',
            borderColor: '#32d47b',
            strokeDashArray: 0,
            label: {
              style: {
                color: '#fff',
                background: '#32d47b',
              },
              text: 'Mostly used prices',
              position: 'left',
              textAnchor: 'start',
            },
          },
        ]
      : [];
  }
}
