<mat-card class="h-full" [ngClass]="padding ? 'p-5' : 'p-0'">
  <mat-card-title *ngIf="cardTitle">
    <div class="text-title">
      <ng-content select="[emCardTitle], em-card-title"></ng-content>
    </div>
  </mat-card-title>

  <mat-card-subtitle *ngIf="cardSubtitle">
    <ng-content select="[emCardSubtitle], em-card-subtitle"></ng-content>
  </mat-card-subtitle>

  <ng-content></ng-content>

  <mat-card-actions [align]="'end'" *ngIf="cardActions">
    <ng-content select="[emCardActions], em-card-actions"></ng-content>
  </mat-card-actions>
</mat-card>
