import {Injectable} from '@angular/core';
import {MerchantsGateway} from '@em/shared/api-interface';
import {Store} from '@ngrx/store';
import {EMPTY, Observable, of} from 'rxjs';
import {catchError, filter, map, switchMap, take, tap} from 'rxjs/operators';
import {LocalUserDataService} from '@em/auth/data-access';
import {
  MerchantsService,
  basicDataConfirmed,
} from '@em/user-account/data-access';
import {SetupStatusService} from '@em/auth/data-access';
import {
  selectCommonWizard,
  selectOnboardingDataLoaded,
} from '../state/selectors';
import {ChallengeType} from '@em/data-feed/data-access-setup';

export interface StorageChallenge {
  partner_short_name: string;
  registration_callback: string;
  authorizations: ChallengeType[];
}

export interface ProcessChallengeResult {
  type:
    | 'commonWizardIncomplete'
    | 'challengePosted'
    | 'emptyChallengePosted'
    | 'commonWizardComplete'
    | 'empty';
  challenge?: ChallengeType[];
}

@Injectable({
  providedIn: 'root',
})
export class ChallengeService {
  readonly commonWizard$ = this._store.select(selectCommonWizard);
  readonly onboardingDataLoaded$ = this._store.select(
    selectOnboardingDataLoaded,
  );

  constructor(
    private readonly _store: Store,
    private readonly _merchantService: MerchantsService,
    private readonly _merchantsGateway: MerchantsGateway,
    private readonly _setupStatusService: SetupStatusService,
    private readonly _localUserData: LocalUserDataService,
  ) {}

  processChallenges(
    postEmptyChallenge: boolean,
  ): Observable<ProcessChallengeResult> {
    return this.onboardingDataLoaded$.pipe(
      filter((loaded) => !!loaded), // wait till onboarding data loaded
      take(1),
      switchMap(() => this._checkCommonWizardCompletion()),
      switchMap((result) => {
        if (result.type === 'commonWizardComplete') {
          const challenge = this._getRawChallenge();

          // If challenge exist, process it
          // otherwize push null challenge if needed
          if (challenge) {
            return this._postAndDeleteChallenge(challenge);
          } else if (postEmptyChallenge) {
            return this._processEmptyChallenge();
          }
        }
        return of(result);
      }),
      take(1),
      catchError(() => of({type: 'empty'} as ProcessChallengeResult)),
    );
  }

  private _processEmptyChallenge(): Observable<ProcessChallengeResult> {
    // if no challenge exist, check if we need to post an empty Challenge
    // we post empty challenge if the first visit of the user and plugin setup is not complete
    // always update the basicDataConfirmed to flag that the user is not longer new user
    return this._merchantService.observable().pipe(
      take(1),
      switchMap((merchantModel) => {
        if (merchantModel.basicDataConfirmed) {
          return of({type: 'empty'} as ProcessChallengeResult);
        } else {
          return this._checkPluginStatus();
        }
      }),
    );
  }

  private _checkPluginStatus(): Observable<ProcessChallengeResult> {
    return this._setupStatusService.observable().pipe(
      take(1),
      switchMap((setupStatus) => {
        // the plugin setup is not completed
        if (!setupStatus.datafeed?.isSetup) {
          // automatically connect to the plugin
          return this._merchantsGateway
            .postChallenge({
              challenge: null,
            })
            .pipe(
              map(() => true),
              catchError(() => of(false)),
            );
        } else {
          return of(false);
        }
      }),
      switchMap((emptyChallengePosted) =>
        this._merchantService
          .update({
            basicDataConfirmed: true,
          })
          .pipe(
            tap(() =>
              this._store.dispatch(basicDataConfirmed({isConfirmed: true})),
            ),
            map(() =>
              emptyChallengePosted
                ? ({type: 'emptyChallengePosted'} as ProcessChallengeResult)
                : ({type: 'empty'} as ProcessChallengeResult),
            ),
          ),
      ),
      catchError(() => EMPTY),
    );
  }

  private _postAndDeleteChallenge(
    challenge: StorageChallenge,
  ): Observable<ProcessChallengeResult> {
    // Post the challenge to the back-end and remove it from the local storage
    return this._merchantsGateway
      .postChallenge({
        challenge: {
          authorizations: challenge.authorizations,
          registration_callback: challenge.registration_callback,
        },
      })
      .pipe(
        tap(() => this._localUserData.remove('challenge')),
        map(
          () =>
            ({
              type: 'challengePosted',
              challenge: challenge.authorizations,
            } as ProcessChallengeResult),
        ),
        catchError(() => EMPTY),
      );
  }

  private _checkCommonWizardCompletion(): Observable<ProcessChallengeResult> {
    // get common wizard data, procedd only if data completed
    // if data incomplete return EMPTY to complete the Observable
    return this.commonWizard$.pipe(
      take(1),
      map((commonWizard) => {
        if (
          !!commonWizard.country &&
          !!commonWizard.email &&
          !!commonWizard.language
        ) {
          return {type: 'commonWizardComplete'} as ProcessChallengeResult;
        } else {
          return {type: 'commonWizardIncomplete'} as ProcessChallengeResult; // complete observable directly if data not completed
        }
      }),
    );
  }

  private _getRawChallenge(): StorageChallenge | null {
    return this._localUserData.getObj<StorageChallenge>('challenge');
  }
}
