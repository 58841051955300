<ng-container *ngIf="componentState$ | async as compState">
  <div class="overlay-wrapper" cdkDropListGroup>
    <div class="overlay-dialog">
      <form [formGroup]="form" (keydown.enter)="$event.preventDefault()">
        <!--  Add / Remove Filters  -->
        <em-product-filter-overlay
          overlayTitle="PRODUCT_FILTER_ADD_FILTER"
          class="second-level"
          [hidden]="compState.overlayState !== 'edit-filter-set'"
        >
          <div data-slot-type="content">
            <div
              *ngIf="
                (getProductCountForList('manuallyAdded') === 0 &&
                  getProductCountForList('manuallyRemoved') === 0) ||
                compState.selectedFilterSet.isGoogleSPD
              "
              class="section"
            >
              <div class="filter-group-header" translate>
                PRODUCT_FILTER_TITLE_MANUAL
              </div>
              <div class="filter-set" (click)="openManualProductSelection()">
                <mat-icon
                  aria-hidden="false"
                  aria-label="add icon"
                  class="add-set"
                >
                  filter_list
                </mat-icon>
                <span translate>PRODUCT_FILTER_MANUAL_SELECTION</span>
              </div>
            </div>
            <div
              *ngIf="compState.selectedFilterSet.isGoogleSPD"
              class="section section-warn"
            >
              <div><mat-icon>warning</mat-icon></div>
              <div>
                {{ 'PRODUCT_FILTER_COST_OF_GOODS_WARNING' | translate }}
              </div>
            </div>
            <div
              *ngIf="
                !isHighFrequencyFilterSet(compState.selectedFilterSet) &&
                selectedCategoriesCount === 0 &&
                !compState.selectedFilterSet.isGoogleSPD
              "
              class="section"
            >
              <div class="filter-group-header" translate>
                PRODUCT_FILTER_TITLE_CATEGORIES
              </div>
              <div class="filter-set" (click)="openCategorySelection()">
                <mat-icon
                  aria-hidden="false"
                  aria-label="filter icon"
                  class="add-set"
                >
                  filter_list
                </mat-icon>
                <span translate>PRODUCT_FILTER_SELECT_CATEGORIES</span>
              </div>
            </div>
            <div class="section" *ngIf="!excludeSetIdControl.value">
              <div class="filter-group-header" translate>
                PRODUCT_FILTER_TITLE_EXCLUDE_FITLER_SET
              </div>
              <div class="filter-set" (click)="addExcludeGroup()">
                <mat-icon
                  aria-hidden="false"
                  aria-label="filter icon"
                  class="add-set"
                >
                  filter_list
                </mat-icon>
                <span translate>PRODUCT_FILTER_TITLE_EXCLUDE_FITLER_SET</span>
              </div>
            </div>
            <div
              class="section"
              *ngIf="
                !isHighFrequencyFilterSet(compState.selectedFilterSet) &&
                !compState.selectedFilterSet.isGoogleSPD
              "
            >
              <div class="filter-group-header" translate>
                PRODUCT_FILTER_TITLE_AUTOMATIC
              </div>
              <div
                cdkDropList
                *ngIf="filterDefinitions"
                [cdkDropListSortingDisabled]="true"
              >
                <ng-container
                  *ngFor="
                    let filterKey of availableFilters;
                    trackBy: trackByIndex
                  "
                >
                  <em-filter-item
                    [ngClass]="isExcluded(filterKey) ? 'opacity-30' : ''"
                    (click)="addFilter(filterKey)"
                    [filter]="filterDefinitions[filterKey]"
                    action="add"
                    cdkDrag
                    [cdkDragData]="filterKey"
                    [cdkDragDisabled]="isExcluded(filterKey)"
                    [cdkDragPreviewClass]="'highlight-drag-item'"
                  >
                    <em-filter-item
                      [filter]="filterDefinitions[filterKey]"
                      action="add"
                      class="highlight-drag-item"
                      *cdkDragPlaceholder
                    >
                    </em-filter-item>
                  </em-filter-item>
                </ng-container>
              </div>
            </div>
          </div>
        </em-product-filter-overlay>

        <em-product-filter-overlay
          overlayTitle="PRODUCT_FILTER_EDIT_SET"
          [isCloseable]="true"
          class="has-actions"
          (closed)="openFilterSetsOverview()"
          [hidden]="compState.overlayState !== 'edit-filter-set'"
        >
          <div data-slot-type="content">
            <mat-form-field
              *ngIf="compState.selectedFilterSet?.type === 'template'"
              class="section filter-set-name-wrapper w-full"
              [subscriptSizing]="'dynamic'"
            >
              <input
                matInput
                class="filter-set-name"
                [value]="i18nSystemFilterSet(compState.selectedFilterSet?.name)"
                disabled
              />
            </mat-form-field>

            <!-- This input is needed at all times, must just not be **visible** for system-specific filter-sets -->
            <mat-form-field
              class="section filter-set-name-wrapper w-full form-field-sm"
              [subscriptSizing]="'dynamic'"
              [class.hidden]="compState.selectedFilterSet?.type === 'template'"
            >
              <input
                matInput
                formControlName="name"
                class="filter-set-name"
                [placeholder]="'FILTER_SET_NAME' | translate"
              />
            </mat-form-field>

            <div
              class="section"
              *ngIf="showExcludeInput || excludeSetIdControl.value"
            >
              <div class="filter-group-header" translate>
                PRODUCT_FILTER_TITLE_EXCLUDE_FITLER_SET
              </div>
              <div class="flex items-center gap-2">
                <mat-form-field
                  class="w-full form-field-sm"
                  [subscriptSizing]="'dynamic'"
                >
                  <mat-select formControlName="excludeSetId">
                    <mat-option
                      [value]="option.uuid"
                      *ngFor="let option of groups$ | async; let i = index"
                    >
                      {{ option.name | translate }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <div>
                  <mat-icon
                    class="cursor-pointer"
                    (click)="removeExcludeGroup()"
                    >delete</mat-icon
                  >
                </div>
              </div>
            </div>
            <div class="inner-scroll">
              <div
                *ngIf="
                  getProductCountForList('manuallyAdded') > 0 ||
                  getProductCountForList('manuallyRemoved') > 0
                "
                class="section"
              >
                <div class="filter-group-header" translate>
                  PRODUCT_FILTER_TITLE_MANUAL
                </div>
                <div class="filter-set" (click)="openManualProductSelection()">
                  <mat-icon
                    aria-hidden="false"
                    aria-label="add icon"
                    class="add-set"
                  >
                    add_circle_outline
                  </mat-icon>
                  <span class="value">{{
                    getProductCountForList('manuallyAdded')
                  }}</span>
                  <span translate>FILTER_MANUAL_INCLUDED</span>
                </div>

                <div
                  *ngIf="!isHighFrequencyFilterSet(compState.selectedFilterSet)"
                  (click)="openManualProductSelection()"
                  class="filter-set"
                >
                  <mat-icon
                    aria-hidden="false"
                    aria-label="add icon"
                    class="add-set filter-list"
                  >
                    remove_circle_outline
                  </mat-icon>
                  <span class="value">{{
                    getProductCountForList('manuallyRemoved')
                  }}</span>
                  <span translate>FILTER_MANUAL_EXCLUDED</span>
                </div>
              </div>

              <div *ngIf="selectedCategoriesCount > 0" class="section">
                <div class="filter-group-header flex items-center">
                  {{ 'PRODUCT_FILTER_TITLE_CATEGORIES' | translate }}

                  <ng-container
                    *ngIf="form.controls['invertCategorySelection'].value"
                  >
                    <em-more-info
                      class="ml-1"
                      [maxWidth]="500"
                      [description]="'PRODUCT_FILTER_EXCLUDED_CATEGORIES'"
                    >
                    </em-more-info>
                  </ng-container>
                </div>
                <div class="filter-set" (click)="openCategorySelection()">
                  <mat-icon
                    aria-hidden="false"
                    aria-label="filter icon"
                    class="add-set"
                  >
                    filter_list
                  </mat-icon>
                  <span class="value">{{ selectedCategoriesCount }}</span>
                  <span translate>PRODUCT_FILTER_SELECTED_CATEGORIES</span>
                </div>
              </div>
              <div class="section">
                <div class="filter-group-header" translate>
                  PRODUCT_FILTER_TITLE_AUTOMATIC
                </div>

                <div cdkDropList (cdkDropListDropped)="filterDrop($event)">
                  <div
                    cdkDrag
                    [cdkDragDisabled]="true"
                    class="pb-1 pt-1"
                    formArrayName="selectedFilters"
                    *ngFor="
                      let filterGroup of compState.selectedFilters.toArray();
                      let i = index
                    "
                  >
                    <div
                      *ngFor="
                        let item of filterGroup.items.toArray();
                        let y = index
                      "
                    >
                      <em-filter-connect
                        *ngIf="showFilterDisconnect(filterGroup.items.size, y)"
                        [connectGroup]="filterGroup"
                        [groupIndex]="i"
                        [item]="item"
                        [itemIndex]="y"
                        [connectItem]="filterGroup.items.get(y - 1)"
                        action="disconnect"
                      ></em-filter-connect>

                      <em-filter-connect
                        *ngIf="
                          showFilterConnect(
                            compState.selectedFilters.size,
                            i,
                            filterGroup.items.size
                          ) && !showFilterDisconnect(filterGroup.items.size, y)
                        "
                        [connectGroup]="compState.selectedFilters.get(i - 1)"
                        [group]="filterGroup"
                        [item]="item"
                        [itemIndex]="y"
                        action="connect"
                      ></em-filter-connect>

                      <div
                        [formGroupName]="getFormIndex(item.uuid)"
                        *ngIf="filterDefinitions"
                      >
                        <em-filter-item
                          (actionTriggered)="
                            removeFilter(item.uuid, filterGroup)
                          "
                          [action]="'delete'"
                          [filter]="
                            item.key ? filterDefinitions[item.key] : undefined
                          "
                          [form]="
                            selectedFiltersForm.controls[
                              getFormIndex(item.uuid)
                            ]
                          "
                          [ngClass]="{
                            connected: isFilterConnected(filterGroup)
                          }"
                        >
                        </em-filter-item>
                      </div>
                    </div>

                    <em-filter-group-connect
                      *ngIf="
                        showFilterGroupConnect(compState.selectedFilters, i)
                      "
                      [connectGroup]="filterGroup"
                      [group]="compState.selectedFilters.getIn([i + 1])"
                    ></em-filter-group-connect>
                  </div>
                </div>
              </div>
            </div>

            <div class="actions">
              <em-button
                htmlType="submit"
                [disabled]="!canSave"
                (btnClick)="saveFilterSet()"
              >
                <span translate>PRODUCT_FILTER_SET_SAVE</span>
              </em-button>
            </div>
          </div>
        </em-product-filter-overlay>

        <!-- Manual Product Selection -->
        <em-manual-product-selection
          formControlName="products"
          [countryCode]="countryCode | async"
          [productLimit]="productLimit(compState.selectedFilterSet)"
          [costOfGoodsRequired]="!!compState.selectedFilterSet.isGoogleSPD"
          [hidden]="compState.overlayState !== 'edit-manual-products'"
          (backClick)="openEditFilterSet()"
        >
        </em-manual-product-selection>

        <!-- Category Selection -->
        <em-category-selection
          formControlName="categories"
          [countryCode]="countryCode | async"
          [hidden]="compState.overlayState !== 'edit-categories'"
          (backClick)="openEditFilterSet()"
        >
          <div
            class="invert-checkbox"
            data-slot-type="invert-category-selection"
          >
            <mat-checkbox
              formControlName="invertCategorySelection"
              color="primary"
            >
              {{ 'PRODUCT_FILTER_INVERT_FILTER' | translate }}
            </mat-checkbox>
            <em-more-info
              [maxWidth]="500"
              class="ml-1"
              [description]="'HTML_TOOLTIP_FILTER_INVERT_FILTER'"
            >
            </em-more-info>
          </div>
        </em-category-selection>
      </form>
    </div>
  </div>
</ng-container>
