import {GetListResp} from '../types/view-models/product-exports/get-list';
import {PostReq} from '../types/request-objects/product-exports/post';
import {Flatten} from '@em/shared/util-types';
export type ExportJob = Flatten<GetListResp>;
export type ExportJobAddReq = PostReq;

export type CsvColSeparator = ExportJob['options']['col_sep'];
export type CsvRowSeparator = ExportJob['options']['row_sep'];
export type CsvQuote = ExportJob['options']['quote_char'];

export const availableColSeparators: {value: CsvColSeparator; label: string}[] =
  [
    {value: ',', label: ','},
    {value: ';', label: ';'},
    {value: '\t', label: '/t'},
  ];
export const availableRowSeparators: {value: CsvRowSeparator; label: string}[] =
  [
    {value: 'auto', label: 'auto'},
    {value: '\r\n', label: '/r/n'},
    {value: '\n', label: '/n'},
    {value: '\r', label: '/r'},
  ];

export const availableCsvQuote: CsvQuote[] = ["'", '"'];
