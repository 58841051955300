import {Component, forwardRef, Input} from '@angular/core';
import {
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import {FilterItemOptionsComponent} from '../filter-item-options/filter-item-options.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {
  IFilterOptionValues,
  optionValueType,
} from '@em/data-feed/data-access-products';
import {ITypedControlValueAccessor} from '@em/shared/util-types';
import {IProductFilterDefinition} from '../product-filter-definition';
import {IOptionDefinition} from '../option-definition';

@Component({
  selector: 'em-nested-toggle-options',
  templateUrl: './nested-toggle-options.component.html',
  styleUrls: ['./nested-toggle-options.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NestedToggleOptionsComponent),
      multi: true,
    },
  ],
})
export class NestedToggleOptionsComponent
  extends FilterItemOptionsComponent
  implements ITypedControlValueAccessor<IFilterOptionValues>
{
  override disabled = false;
  override currentValues: IFilterOptionValues = {};

  @Input() override definition?: IProductFilterDefinition;

  constructor() {
    super();
  }

  override setOptionValue(
    key: string,
    value: optionValueType | undefined,
    optionType: IOptionDefinition['type'],
  ) {
    super.setOptionValue(key, value, optionType);

    if (key === 'tolerance_enabled' && value) {
      this.currentValues['tolerance_percent'] = 0;
    } else if (key === 'tolerance_enabled' && !value) {
      delete this.currentValues['tolerance_percent'];
    }
  }

  i18nToleranceDescription(): string {
    if (!this.definition) return '';
    return `CAMPAIGN_PERFORMANCE_FILTER_${this.definition.key.toUpperCase()}_TOLERANCE_DESCRIPTION`;
  }
}
