<em-select
  class="w-full max-w-sm"
  [label]="'SHARED_LABEL_LAYOUT' | translate"
  [floatLabel]="'always'"
  [value]="selectedLayoutUuid"
  (selectionChange)="selectLayout($event)"
  [subscriptSizing]="'dynamic'"
>
  <em-option *ngFor="let layout of layouts$ | async" [value]="layout.uuid">{{
    layout.name
  }}</em-option>

  <em-option [value]="defaultLayout.uuid">{{
    defaultLayout.name | translate
  }}</em-option>
</em-select>

<em-icon-button
  icon="add"
  color=""
  (btnClick)="addNewLayout()"
  matTooltip="{{ 'SHARED_LABEL_ADD_NEW_LAYOUT' | translate }}"
></em-icon-button>

<em-icon-button
  icon="save"
  color=""
  *ngIf="canEdit"
  (btnClick)="saveLayout()"
  matTooltip="{{ 'SHARED_LABEL_SAVE_LAYOUT' | translate }}"
></em-icon-button>

<em-icon-button
  icon="delete"
  color=""
  *ngIf="canEdit"
  (btnClick)="deleteLayout(selectedLayoutUuid)"
  matTooltip="{{ 'SHARED_LABEL_DELETE_LAYOUT' | translate }}"
></em-icon-button>
