import {DOCUMENT} from '@angular/common';
import {Inject, Injectable, NgZone} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {combineLatest, from, Observable, of} from 'rxjs';
import {debounceTime, map, startWith, switchMap, tap} from 'rxjs/operators';
import {mapVoid} from '@em/shared/util-rxjs';
import {WINDOW} from '@em/shared/util-web';
import {NotificationService} from '@em/shared/util-web';
import {SessionService} from '@em/auth/data-access';
import {TokenStorageService} from '@em/auth/data-access';
import {injectIntercomSnippet} from './intercom-snippet';
import {IntercomGateway} from './intercom.gateway';
import {IInitializable} from '@em/shared/util-types';
import {MerchantsService} from '@em/user-account/data-access';
import {
  IS_PRODUCTION_MODE,
  configurationHelper,
  isIndependentRoute,
} from '@em/shared/util-configuration';

interface IIntercomSettings {
  app_id?: string;
  user_id?: string;
  user_hash?: string;
  last_request_at?: number;
}

export interface IIntercomWindow extends Window {
  Intercom: (command: string, settings?: IIntercomSettings | string) => void;
}

@Injectable({
  providedIn: 'root',
})
export class IntercomService implements IInitializable {
  readonly name = 'IntercomService';
  appId: string | null | undefined;

  constructor(
    private readonly _merchants: MerchantsService,
    private readonly _session: SessionService,
    private readonly _tokenStorage: TokenStorageService,
    private readonly _translate: TranslateService,
    private readonly _notification: NotificationService,
    private readonly _gateway: IntercomGateway,
    private readonly _ngZone: NgZone,
    @Inject(WINDOW) private readonly _window: IIntercomWindow,
    @Inject(DOCUMENT) private readonly _document: Document,
    @Inject(IS_PRODUCTION_MODE) private readonly _isProduction: boolean,
  ) {
    this.appId = configurationHelper.getConfig('INTERCOM_APP_ID');
  }

  initialize(): Observable<void> {
    if (
      !this._isProduction ||
      !configurationHelper.getFlag('INTERCOM_ENABLED')
    ) {
      return of();
    }
    const isAdmin = !!this._tokenStorage.decodedToken?.['admin'];
    if (
      isAdmin ||
      !this.appId ||
      isIndependentRoute(this._window.location.pathname)
    ) {
      return of(undefined as void);
    }

    return this.injectIntercomSnippet().pipe(
      tap(() => this._startIntercom()),
      mapVoid(),
    );
  }

  injectIntercomSnippet(): Observable<void> {
    return from(
      this._ngZone.run(() =>
        injectIntercomSnippet(this.appId || '').catch(() =>
          this._showIntercomBlockedNotice(),
        ),
      ),
    );
  }

  show() {
    this._intercom('show');
  }

  send(messageKey: string, interpolationValue?: {[key: string]: string}) {
    const translatedMessage = this._translate.instant(
      messageKey,
      interpolationValue,
    );

    this._intercom('showNewMessage', translatedMessage);
  }

  updatePopupMessages(): void {
    this._intercom('update', {
      last_request_at: Number.parseInt(`${new Date().getTime() / 1000}`, 10),
    });
  }

  clear() {
    for (const key of this._document.cookie.match(/intercom[^=]*/gi) || []) {
      this._document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
    }
    this._intercom('shutdown');
  }

  private _intercom(command: string, settings?: IIntercomSettings | string) {
    if (
      !this._window.Intercom ||
      !!this._tokenStorage.decodedToken?.['admin']
    ) {
      return;
    }
    this._window.Intercom(command, settings);
  }

  private _showIntercomBlockedNotice() {
    const intercomWidgetPresent =
      !!this._document.getElementById('intercom-container');

    if (!intercomWidgetPresent) {
      this._notification.show('ADBLOCKER_DETECTED', 20000);
    }
  }

  private _startIntercom() {
    combineLatest([
      this._session.observable().pipe(startWith(undefined)),
      this._merchants.observable().pipe(startWith(undefined)),
      this._fetchUserHash(),
    ])
      .pipe(debounceTime(300))
      .subscribe(([session, merchant, userHash]) => {
        const settings: IIntercomSettings = {app_id: this.appId || ''};

        if (!!merchant && !!session && !!userHash) {
          settings.user_id = merchant.uuid;
          settings.user_hash = userHash;
        }

        this._intercom('boot', settings);
      });
  }

  private _fetchUserHash(): Observable<string | null> {
    return of(this._tokenStorage.getToken()).pipe(
      switchMap((jwt) => {
        if (!jwt) return of(null);
        return this._gateway.getId(jwt);
      }),
      map((resp) => resp?.user_hash ?? null),
    );
  }
}
