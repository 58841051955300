import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {combineLatest, Observable, of, Subject, throwError} from 'rxjs';
import {
  catchError,
  map,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import {AdwordsGateway} from '@em/shared/api-interface/lib/gateways/adwords.gateway';
import {MerchantsGateway} from '@em/shared/api-interface/lib/gateways/merchants.gateway';
import {GetSettingsResp as GetAdwordsSettingsResp} from '@em/shared/api-interface/lib/types/view-models/adwords/get-settings';
import {GetSettingsResp} from '@em/shared/api-interface/lib/types/view-models/merchants/get-settings';
import {CountryCode} from '@em/shared/util-types';
import {CurrencyCode, LanguageCode} from '@em/shared/util-types';
import {IMerchantModel} from './merchant.model';
import {IState} from './state/reducers';
import {loadMerchantSuccess} from './state/action';
import {HttpErrorResponse} from '@angular/common/http';
import {MerchantSession, SessionService} from '@em/auth/data-access';

export interface IUpdateMerchantArgs {
  uuid?: string;
  email?: string;
  language?: LanguageCode;
  name?: string;
  countryCode?: CountryCode;
  currencyCode?: CurrencyCode;
  adwordsId?: number;
  password?: string;
  basicDataConfirmed?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class MerchantsService {
  private readonly _observable: Observable<IMerchantModel>;
  private readonly _reload = new Subject<void>();
  readonly currencyCode$: Observable<CurrencyCode | undefined>;

  constructor(
    private readonly _session: SessionService,
    private readonly _merchantsGateway: MerchantsGateway,
    private readonly _adwordsGateway: AdwordsGateway,
    private readonly _store: Store<IState>,
  ) {
    this._observable = combineLatest([
      this._reload.pipe(startWith({})),
      this._session.observable(),
    ]).pipe(
      switchMap(([_, session]) => this._getMerchantData(session)),
      tap((data) => {
        this._store.dispatch(loadMerchantSuccess({data}));
      }),
      shareReplay({refCount: false, bufferSize: 1}),
    );

    this.currencyCode$ = this._observable.pipe(map((m) => m.currencyCode));
  }

  observable(): Observable<IMerchantModel> {
    return this._observable;
  }

  get(): Observable<IMerchantModel> {
    this._reload.next();
    return this.observable();
  }

  setEmail(email: string): Observable<void> {
    const params = {email: email.toLowerCase()};

    return this._merchantsGateway.patchSettings(params).pipe(
      switchMap(() => this._merchantsGateway.postSignupEmail({})),
      tap(() => this._reloadMerchant()),
    );
  }

  update(updateParams: IUpdateMerchantArgs): Observable<undefined> {
    const params = {
      language: updateParams.language,
      adwords_id: updateParams.adwordsId,
      country: updateParams.countryCode,
      password: updateParams.password,
      email: updateParams.email,
      configuration_details: updateParams.basicDataConfirmed
        ? {basic_data_confirmed: true}
        : undefined,
    };

    const patchSettingsObservable = this._merchantsGateway
      .patchSettings(this._removeUndefined(params))
      .pipe(shareReplay({bufferSize: 1, refCount: false}));

    patchSettingsObservable.subscribe(() => {
      this._reloadMerchant();
    });

    return patchSettingsObservable;
  }

  private _getMerchantData(session: MerchantSession) {
    return this._merchantsGateway.getSettings().pipe(
      switchMap((settingsResp) =>
        this._adwordsGateway.getSettings().pipe(
          catchError(() =>
            of({currency_code: 'EUR'} as GetAdwordsSettingsResp),
          ),
          map((adwordsResp) =>
            this._buildEntity(session, settingsResp, adwordsResp),
          ),
          catchError((err: unknown) => {
            // If oauth was not finished yet, we expect the endpoint to throw a 404
            if ((err as HttpErrorResponse).status === 404) {
              return of(this._buildEntity(session, settingsResp, null));
            }
            return throwError(err);
          }),
        ),
      ),
    );
  }

  private _buildEntity(
    session: MerchantSession,
    resp: GetSettingsResp,
    adwordsResp: GetAdwordsSettingsResp | null,
  ): IMerchantModel {
    return {
      adwordsId: adwordsResp?.adwords_id ?? undefined,
      countryCode: resp.country || undefined,
      currencyCode: adwordsResp?.currency_code ?? undefined,
      email: resp.email || undefined,
      language: resp.language || undefined,
      name: resp.name || undefined,
      uuid: session.uuid,
      basicDataConfirmed:
        !!resp.configuration_details?.['basic_data_confirmed'],
    };
  }

  private _reloadMerchant() {
    this._reload.next();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _removeUndefined(params: {[key: string]: any}) {
    for (const key in params) {
      if (params[key] === undefined) {
        delete params[key];
      }
    }
    return params;
  }
}
