import {CurrencyCode} from './currencies';

export interface IMoney {
  value: number;
  currencyCode: CurrencyCode;
}

export function toMoney(
  value: number | null,
  currencyCode: string | null,
): IMoney | null {
  if (currencyCode && value !== null) {
    return {value, currencyCode: currencyCode as CurrencyCode};
  }

  return null;
}
