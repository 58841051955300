<ng-container [ngSwitch]="state">
  <div
    class="status"
    [ngClass]="{warn: state === 'PartialSetup' || state === 'NotSetup'}"
  >
    <div class="dot"></div>
    <div class="label">
      <ng-content></ng-content>
      <ng-container *ngIf="label">{{ label | translate }}</ng-container>
    </div>
  </div>
</ng-container>
