import sortBy from 'lodash-es/sortBy';
import each from 'lodash-es/each';
import {Category} from './category';

export interface ICategoryConverterOptions<TInput, TOutput> {
  getPath: (item: TInput) => string[];
  getContent: (item?: TInput) => TOutput;
  sortBy?: (content: TOutput | undefined) => number | string;
}

export class CategoryConverter<TInput, TOutput> {
  options: ICategoryConverterOptions<TInput, TOutput>;

  constructor(options: ICategoryConverterOptions<TInput, TOutput>) {
    this.options = options;
  }

  createTree(categoryList: TInput[]): Array<Category<string, TOutput>> {
    const splitted = categoryList.map((item) => ({
      item,
      path: this.options.getPath(item),
    }));

    const root = new Category<string, TOutput>('', this.options.getContent());

    each(splitted, ({item, path}) => {
      let parent = root;

      each(path, (id) => {
        let node = parent.children.find((child) => child.id === id);

        if (!node) {
          node = new Category(id, this.options.getContent());
          node.content = this.options.getContent();
          parent.addChild(node);
        }

        parent = node;
      });

      // parent is now the leaf node
      parent.content = this.options.getContent(item);
    });

    if (this.options.sortBy) {
      root.eachNode((n) => {
        n.children = sortBy(
          n.children,
          (c) => this.options.sortBy && this.options.sortBy(c.content),
        );
      });
    }

    return root.children;
  }

  createList(categoryRoots: Array<Category<string, TOutput>>): TOutput[] {
    const list: TOutput[] = [];
    const addSubTree = (node: Category<string, TOutput>) => {
      if (node.content) {
        list.push(node.content);
      }

      each(node.children, (x) => addSubTree(x));
    };

    each(categoryRoots, (root) => {
      addSubTree(root);
    });

    return list;
  }
}
